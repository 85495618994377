import styled from 'styled-components/macro';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { PaymentsTableRow } from '../../types';

interface PaymentsTableProps {
  tableData: Array<PaymentsTableRow>;
}

export default function PaymentsTable({ tableData }: PaymentsTableProps) {
  const tableConfig: ColumnsType<PaymentsTableRow> = [
    {
      title: 'Date Due',
      key: 'dateDue',
      dataIndex: 'dateDue',
      sorter: (a, b) => {
        if (new Date(a.dateDue) < new Date(b.dateDue)) {
          return -1;
        }
        if (new Date(a.dateDue) > new Date(b.dateDue)) {
          return 1;
        }
        return 0;
      },
    },
    {
      title: 'Invoice Number',
      key: 'invoiceNumber',
      dataIndex: 'invoiceNumber',
    },
    {
      title: 'Order',
      key: 'order',
      dataIndex: 'order',
      render: (v) => <BlueText>{v}</BlueText>,
    },
    {
      title: 'Buyer',
      key: 'buyer',
      dataIndex: 'buyer',
    },
    {
      title: 'BTS Number',
      key: 'btsNumber',
      dataIndex: 'btsNumber',
    },
    {
      title: 'Amount Due',
      key: 'amountDue',
      dataIndex: 'amountDue',
    },
  ];

  return (
    <Table<PaymentsTableRow>
      columns={tableConfig}
      dataSource={tableData}
      style={{ borderTop: '2px solid #2682DE', marginRight: 2 }}
    />
  );
}

const BlueText = styled.span`
  color: #2682DE;
`;
