import styled from 'styled-components/macro';
import { useHistory } from 'react-router';
import { DropDownItem } from '../../../ui/DropDown';
import ROUTER_CONFIG from '../../../router/router-config';
import AccountMenu from '../../layout/Menu/AccountMenu';
import { removeToken } from '../../../api';

interface SchoolAccountMenuProps {
  accountName: string;
}

export default function SchoolAccountMenu({ accountName }: SchoolAccountMenuProps) {
  const { push } = useHistory();

  const onDonationCampaignsClick = () => push(ROUTER_CONFIG.SCHOOL_CAMPAIGNS_LIST.getPath());
  const onDeviceTradeinClick = () => push(ROUTER_CONFIG.SCHOOL_DEVICE_TRADEIN.getPath());
  const onAccountSettingsClick = () => push(ROUTER_CONFIG.SCHOOL_SETTINGS.getPath());
  const onLogout = () => {
    push(ROUTER_CONFIG.DONOR_LOGIN.getPath());
    removeToken();
  };

  const MenuContent = () => (
    <>
      <StyledText>your scheme support</StyledText>
      <DropDownItem title="Donation Campaigns" onClick={onDonationCampaignsClick} />
      <DropDownItem title="Device Trade-in" onClick={onDeviceTradeinClick} />
      <StyledText>settings</StyledText>
      <DropDownItem title="Account Settings" onClick={onAccountSettingsClick} />
      <DropDownItem title="Sign Out" onClick={onLogout} />
    </>
  );

  return (
    <AccountMenu accountName={accountName}>
      <MenuContent />
    </AccountMenu>
  );
}

const StyledText = styled.span`
  font-size: 10px;
  color: #909DA8;
  text-transform: uppercase;
  padding: 10px;
`;
