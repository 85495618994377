import React from 'react';
import styled from 'styled-components/macro';
import BackButton from '../../../ui/elements/BackButton';
import BuyerLayout from './BuyerLayout';

interface BuyerLayoutWithBackProps {
  children: React.ReactNode;
  onBack: () => void;
}

export default function BuyerLayoutWithBack({ children, onBack }: BuyerLayoutWithBackProps) {
  return (
    <StyledLayout>
      <Container>
        <BackButton onClickHandler={onBack} />
        {children}
      </Container>
    </StyledLayout>
  );
}

const StyledLayout = styled(BuyerLayout)`
  .buyer-content-wrapper {
    padding: 48px 88px 70px 88px;
  }
`;

const Container = styled.div`
  display: flex;
  gap: 30px;
`;
