import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { ReactNode } from 'react';
import Logo from './Logo';
import ROUTER_CONFIG from '../../router/router-config';

interface HeaderProps {
  children?: ReactNode;
  loginLinkTo?: string;
  className?: string;
  logo?: ReactNode;
}

export default function Header({
  children, loginLinkTo, className, logo,
}: HeaderProps) {
  // const currentProfile = useSelector(getUserProfile);
  // const location = useLocation();
  // const { pathname } = location;
  // const { push } = useHistory();

  // const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(loadProfile());
  // }, []);

  // const onLoginClickHandler = () => {
  //   if (pathname === '/') return;

  //   if (/^\/school/.test(pathname)) {
  //     push(ROUTER_CONFIG.LOGIN_SCHOOL_PAGE.getPath());
  //   } else if (/^\/donor/.test(pathname)) {
  //     push(ROUTER_CONFIG.DONOR_LOGIN.getPath(), { from: location });
  //   }
  // };

  // let headerMenu = (<MenuPublick />);

  // if (pathname !== '/') {
  //   if (!currentProfile && !pathname.includes('login') && !pathname.includes('forgot-password')) {
  //     headerMenu = (<LoginLink onLoginClick={onLoginClickHandler} />);
  //   }
  // }

  return (
    <HeaderContainer className={className}>
      <HeaderWrap className="header__wrapper">
        <Link to={loginLinkTo || ROUTER_CONFIG.DONOR_LOGIN.path}>
          {logo ? (
            <>
              {logo}
            </>
          ) : (
            <Logo />
          )}
        </Link>
        {/* <a href="/"> */}
        {/* </a> */}
        {children}
      </HeaderWrap>
    </HeaderContainer>
  );
}

const HeaderContainer = styled.header`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  filter: drop-shadow(0px 2px 6px rgba(34, 89, 120, 0.102));
  position: relative;
  z-index: 10;
`;

const HeaderWrap = styled.div`
  padding: 15px 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  background: #FFFFFF;
`;
