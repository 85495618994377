import { useHistory } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import BuyerLayout from '../layout/BuyerLayout';
import { BuyerOverviewServiceType, CATEGORY_GROUPTYPE } from '../types';
import BuyerOverview from './BuyerOverview';
import ROUTER_CONFIG from '../../../router/router-config';
import {
  useLazyGetOffersByCategoryQuery,
  useLazyGetCategoriesQuery,
} from '../../../api/services/buyerApi';
import { groupSellerOffersByProductId } from '../groupSellerOffersByProductId';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectSelectedTabs,
  setSelectedTab,
} from '../../../feature/buyerState';

export default function BuyerOverviewPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const selectedTab = useSelector(selectSelectedTabs);
  const services = (
    onLearnMoreClickHandler: () => void
  ): Array<BuyerOverviewServiceType> => [
    {
      serviceName: 'Operating Leases',
      description: 'Compliant, affordable financing options',
      onLearnClick: () => {
        history.push('/static-page/page/lease');
      },
      bgColor: '#0199FF',
    },
    {
      serviceName: 'Device Insurance',
      description: 'Protect student devices at low affordable costs',
      onLearnClick: () => {
        history.push('/static-page/page/insurance');
      },
      bgColor: '#1047C0',
    },
    {
      serviceName: 'Parental Contribution Schemes',
      description: 'Affordable donations schemes',
      onLearnClick: () => {
        history.push('/static-page/page/collections');
      },
      bgColor: '#9934FF',
    },
    {
      serviceName: 'Support & Training',
      description: 'Support programmes and free discovery calls available',
      onLearnClick: onLearnMoreClickHandler,
      bgColor: '#24B490',
    },
  ];

  const tabsRef = useRef<HTMLDivElement>(null);

  const onLearnMoreClickHandler = () => {
    tabsRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const onSelectTab = (tab: string) => {
    dispatch(setSelectedTab(tab));
  };

  const { push } = useHistory();

  const onDeviceClick = (deviceId: string) =>
    push(ROUTER_CONFIG.BUYER_ORDER_CREATE.getPath({ deviceId }));
  const onCategoryClickHandler = (categoryId: string) =>
    push(ROUTER_CONFIG.BUYER_PRODUCTS_CATEGORY.getPath({ categoryId }));

  // const { data: sellerOffers } = useGetOffersQuery();
  const [getCategory, { data: categories }] = useLazyGetCategoriesQuery();

  useEffect(() => {
    getCategory();
  }, [getCategory]);

  const selectedTabId = categories?.find((c) => c.name === selectedTab)?._id;

  const [
    getOffersByCategory,
    { data: sellerOffersByCategory },
  ] = useLazyGetOffersByCategoryQuery();

  // const { data: sellerOffersByCategory } = useGetOffersByCategoryQuery(selectedTabId || '', { skip: !selectedTabId });

  useEffect(() => {
    if (selectedTabId) {
      getOffersByCategory(selectedTabId);
    }
  }, [selectedTabId, getOffersByCategory]);

  const groupedOffers = groupSellerOffersByProductId(sellerOffersByCategory);

  useEffect(() => {
    if (categories && categories.length) {
      const tabs = categories.filter(
        (c) => c.groupType === CATEGORY_GROUPTYPE.TOP
      );
      dispatch(setSelectedTab(tabs.length ? tabs[0].name : ''));
    }
  }, [categories]);

  return (
    <BuyerLayout>
      <BuyerOverview
        services={services(onLearnMoreClickHandler).slice(0, 3)}
        categories={categories}
        onDeviceClick={onDeviceClick}
        groupedOffers={groupedOffers}
        onCategoryClickHandler={onCategoryClickHandler}
        selectedTab={selectedTab}
        onChangeSelectedTab={onSelectTab}
        forwardedTabsRef={tabsRef}
      />
    </BuyerLayout>
  );
}
