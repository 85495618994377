import { useFormik } from 'formik';
import styled from 'styled-components/macro';
import { useState } from 'react';
import { ButtonDark } from '../../../../ui/Button';
import { renderFormConfig } from '../../../../utils/form';
import Input from '../../../../ui/forms/Input';
import { BuyerUpdateBillingDetails } from '../../types';
import { updateBuyerBillingDetailsSchema } from '../../buyer-schemas';
import InputWrap from '../../../../ui/forms/InputWrap';

interface BillingTabProps {
  onSubmit: (values: BuyerUpdateBillingDetails) => void;
}

export default function BillingTab({ onSubmit }: BillingTabProps) {
  const [isEdit, setIsEdit] = useState(false);

  const formik = useFormik({
    initialValues: {
      accountNumber: '',
      branchSortCode1: '',
      branchSortCode2: '',
      branchSortCode3: '',
      bankAccountName: '',
    },
    validationSchema: updateBuyerBillingDetailsSchema,
    onSubmit: (values) => onSubmit(values),
  });

  return (
    <Container onSubmit={formik.handleSubmit}>
      <StyledButtonDark onClick={(e) => {
        e.preventDefault();
        setIsEdit(!isEdit);
      }}
      >
        Edit Details
      </StyledButtonDark>
      <Form>
        <Title>Bank Details</Title>
        <Text>The account for which any scheme support funds should be sent to.</Text>
        {renderFormConfig([
          {
            title: 'Name(s) of account holder(s)',
            name: 'bankAccountName',
            component: StyledInput,
            props: {
              disabled: !isEdit,
            },
          },
        ], formik)}
        <InputWrap title="Branch Sort Code">
          <BranchInputWrap>
            <BranchInput isError={!!formik.errors.branchSortCode1} disabled={!isEdit} name="branchSortCode1" value={formik.values.branchSortCode1} onChange={formik.handleChange} />
            <Dot />
            <BranchInput isError={!!formik.errors.branchSortCode2} disabled={!isEdit} name="branchSortCode2" value={formik.values.branchSortCode2} onChange={formik.handleChange} />
            <Dot />
            <BranchInput isError={!!formik.errors.branchSortCode3} disabled={!isEdit} name="branchSortCode3" value={formik.values.branchSortCode3} onChange={formik.handleChange} />
          </BranchInputWrap>
        </InputWrap>
        {renderFormConfig([
          {
            title: 'Bank/building society account number',
            name: 'accountNumber',
            component: StyledInput,
            props: {
              disabled: !isEdit,
            },
          },
        ], formik)}
      </Form>
      <StyledButtonDark type="submit">Confirm</StyledButtonDark>
    </Container>
  );
}

const Container = styled.form`
  display: flex;
  flex-direction: column;
  gap: 40px;
  max-width: 420px;
`;

const Title = styled.h1`
  margin: 0;
  margin-bottom: 20px;
  font-size: 21px;
  line-height: 26px;
  color: #102f54;
  font-weight: bold;
`;

const Text = styled.p`
  margin: 0;
  margin-bottom: 40px;
  font-size: 14px;
  line-height: 17px;
  color: #102f54;
`;

const StyledButtonDark = styled(ButtonDark)`
  padding: 14px;
  width: 210px;
`;

const Form = styled.div``;

const BranchInputWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const BranchInput = styled(Input).attrs({ maxLength: 2 })<{ isError: boolean }>`
  width: 60px;
  font-size: 14px;
  padding: 21px;
  border: ${({ isError }) => isError && '1px solid red'};

  &:disabled {
    background-color: #F5F5F5;
  }
`;

const Dot = styled.div`
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #102F54;
`;

const StyledInput = styled(Input)`
  width: 100%;
  height: unset;
  padding: 10.5px 11.5px;

  &:disabled {
    background-color: #F5F5F5;
  }
`;
