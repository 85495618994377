import styled from 'styled-components/macro';
import { Radio } from 'antd';
import { showPriceDigits } from '../../../../utils/formats';

interface DeliveryOptionProps {
  isSelect: boolean;
  onClick: () => void;
  price: number;
  title: string;
}

export default function DeliveryOption({
  isSelect, onClick, price, title,
}: DeliveryOptionProps) {
  return (
    <Container isSelect={isSelect} onClick={onClick}>
      <StyledRadio checked={isSelect} onClick={() => 3} />
      <Content>
        <Text>{title}</Text>
        <Text>{showPriceDigits(price)}</Text>
      </Content>
    </Container>
  );
}

const Container = styled.div<{ isSelect: boolean }>`
  box-sizing: border-box;
  width: 100%;
  max-width: 550px;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 25px 20px;
  border-radius: 5px;
  border: ${({ isSelect }) => (isSelect ? '1px solid #2682DE' : '1px solid #D5DCE5')};
  cursor: pointer;
`;

const StyledRadio = styled(Radio)`
  .ant-radio-inner {
    box-sizing: border-box;
  }
`;

const Text = styled.span`
  font-size: 14px;
  line-height: 17px;
  color: #102F54;
  font-weight: bold;
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
`;
