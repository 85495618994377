import styled from 'styled-components/macro';
import {
  useEffect, useMemo, useRef,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import TabButton from './TabButton';
import StudentsTab from './tabs-content/StudentsTab';
import TeachersTab from './tabs-content/TeachersTab';
import EsportsTab from './tabs-content/EsportsTab';
import ProPlatformTab from './tabs-content/ProPlatformTab';
import LearningTab from './tabs-content/LearningTab';
import ArrowLeftIcon from '../../../../../img/icons/arrow_left_icon.svg';
import { ICategoryPublicType, OVERVIEW_TABS_ICONS } from '../../../types';

interface TabsProps {
  tabs: Array<ICategoryPublicType>;
  selectedTab: string;
  onChangeSelectedTab: (tab: string) => void;
}

export default function Tabs({ tabs, selectedTab, onChangeSelectedTab }: TabsProps) {
  const history = useHistory();
  const location = useLocation();

  const tabsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (location.search && location.search.includes('selectedTab')) {
      onChangeSelectedTab(location.search.split('=')[1]);
      history.replace(location.pathname);

      tabsRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [tabsRef, location, history]);

  const selectedTabObject = useMemo(() => tabs.find((t) => t.name === selectedTab) || tabs[0], [tabs, selectedTab]);

  const tabContent = useMemo(() => {
    switch (selectedTabObject.icons) {
      case OVERVIEW_TABS_ICONS.STUDENTS:
        return <StudentsTab />;
      case OVERVIEW_TABS_ICONS.TEACHERS:
        return <TeachersTab />;
      case OVERVIEW_TABS_ICONS.ESPORTS:
        return <EsportsTab />;
      case OVERVIEW_TABS_ICONS.PRO:
        return <ProPlatformTab />;
      case OVERVIEW_TABS_ICONS.LEARNING:
        return <LearningTab />;
      default:
        return <StudentsTab />;
    }
  }, [selectedTabObject]);

  const onNextClickHandler = () => {
    const i = tabs.findIndex((s) => s.name === selectedTab);

    if (i < tabs.length - 1) {
      onChangeSelectedTab(tabs[i + 1].name);
    } else {
      onChangeSelectedTab(tabs[0].name);
    }
  };

  const onPrevClickHandler = () => {
    const i = tabs.findIndex((s) => s.name === selectedTab);

    if (i > 0) {
      onChangeSelectedTab(tabs[i - 1].name);
    } else {
      onChangeSelectedTab(tabs[tabs.length - 1].name);
    }
  };

  return (
    <Wrapper ref={tabsRef}>
      <Container>
        {tabs.map((tab) => (
          <TabButton
            key={tab.name}
            onClick={() => onChangeSelectedTab(tab.name)}
            tab={tab}
            isSelected={tab.name === selectedTab}
          />
        ))}
      </Container>
      <MobileContainer>
        <ArrowIcon onClick={onPrevClickHandler}>
          <img src={ArrowLeftIcon} alt="" />
        </ArrowIcon>
        <TabButton onClick={() => 3} tab={selectedTabObject} isSelected />
        <ArrowIcon isRight onClick={onNextClickHandler}>
          <img src={ArrowLeftIcon} alt="" />
        </ArrowIcon>
      </MobileContainer>
      {tabContent}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 35px;
  margin-bottom: 60px;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  gap: 16px;
  border-bottom: 1px solid #D5DCE5;
  justify-content: center;

  @media (max-width: 660px) {
    display: none;
  }
`;

const ArrowIcon = styled.div<{ isRight?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(205, 214, 222, 0.7);
  padding: 12px;
  cursor: pointer;
  transform: ${({ isRight }) => isRight && 'rotate(180deg)'};
`;

const MobileContainer = styled.div`
  display: none;
  justify-content: space-between;
  border-bottom: 1px solid #D5DCE5;

  @media (max-width: 660px) {
    display: flex;
  }
`;
