import styled from 'styled-components/macro';
import ProductSummaryInfoTable from '../../layout/ProductSummaryInfoTable';
import Input from '../../../../ui/forms/Input';
import { ButtonDark } from '../../../../ui/Button';

interface EditOfferProps {
  price: string;
  onChangePrice: (price: string) => void;
  onSaveClickHandler: () => void;
}

export default function EditOffer({ price, onChangePrice, onSaveClickHandler }: EditOfferProps) {
  return (
    <Container>
      <Content>
        <SummaryBlock>
          <Title>Edit Offer</Title>
          <SubTitle>1. Specify Customer Buy Price</SubTitle>
          <Description>
            This is the unit price that customers will pay when they choose you as their reseller. This price should include the Silverwing Fee amount. All prices are Ex-Vat.
          </Description>
          <ContentBlock>
            <InputBlock>
              <Label>Customer Buy Price</Label>
              <StyledInput value={price} onChange={(e) => onChangePrice(e.target.value)} type="number" min={0.01} step={0.01} />
            </InputBlock>
            <PriceBlock>
              <PriceRow>
                <span>Customer Buy Price</span>
                <span>
                  £
                  {' '}
                  {price || '-'}
                </span>
              </PriceRow>
              <PriceRow>
                <span>Silverwing Fee</span>
                <span>
                  £
                  {' '}
                  {price ? (+price * 0.02).toFixed(2) : '-'}
                </span>
              </PriceRow>
              <Divider />
              <PriceRow>
                <span>Your Earnings</span>
                <span>
                  £
                  {' '}
                  {price ? (+price * 0.98).toFixed(2) : '-'}
                </span>
              </PriceRow>
            </PriceBlock>
            <StyledButtonDark onClick={onSaveClickHandler}>Save Edit</StyledButtonDark>
            <InfoBlock>
              <p>
                If you would like to leave this offer, please contact
                {' '}
                <CustomLink>Silverwing Support</CustomLink>
                {' '}
                quoting the relevant offer product.
              </p>
            </InfoBlock>
          </ContentBlock>
        </SummaryBlock>
        <OverviewInfoBlock>
          <ProductSummaryInfoTable />
        </OverviewInfoBlock>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  gap: 29px;
  flex: 1;
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  gap: 100px;
`;

const SummaryBlock = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const Title = styled.h1`
  font-size: 30px;
  line-height: 34px;
  color: #102f54;
  font-weight: bold;
`;

const SubTitle = styled.h2`
  font-size: 21px;
  line-height: 26px;
  color: #102f54;
  font-weight: bold;
`;

const OverviewInfoBlock = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Description = styled.p`
  font-size: 14px;
  line-height: 17px;
  color: #102F54;
`;

const ContentBlock = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 313px;
`;

const InputBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const Label = styled.span`
  font-weight: bold;

  &::after {
    content: '*';
    padding-left: 2px;
    color: red;
  }
`;

const StyledInput = styled(Input)`
  position: relative;
`;

const PriceBlock = styled.div`
  margin-top: 20px;
  padding: 20px;
  background-color: #FAFBFC;
  display: flex;
  flex-direction: column;
`;

const Divider = styled.div`
  height: 1px;
  border-top: 1px solid #D5DCE5;
  margin: 10px 0;
`;

const PriceRow = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  line-height: 17px;
  color: #102F54;
  font-weight: bold;
  margin-bottom: 8px;
`;

const StyledButtonDark = styled(ButtonDark)`
  padding: 14px;
  margin-top: 40px;
`;

const InfoBlock = styled.div`
  margin-top: 40px;
  padding: 20px;
  background-color: #FFF5F7;
  font-size: 14px;
  line-height: 17px;
  color: #102F54;
`;

const CustomLink = styled.span`
  font-weight: bold;
  color: #2682DE;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;
