import styled from 'styled-components/macro';
import { ArrowRightOutlined } from '@ant-design/icons';
import { BuyerOrderStateType } from '../../../../feature/buyerState';
import { ButtonDark } from '../../../../ui/Button';
import { showPriceDigits } from '../../../../utils/formats';
import calculateOrderPrice from '../../calculateOrderPrice';
import {
  IInsurance,
  IReseller,
  ORDER_TYPES,
  ISellerOfferPublicType,
  IAccessorySelected,
  IService,
} from '../../types';

interface YourOrderBlockProps {
  onEditClickHandler: () => void;
  onContinue: () => void;
  className?: string;
  isContinueEnabled?: boolean;
  buttonText?: string;
  currentOrder: BuyerOrderStateType;
  offer: ISellerOfferPublicType;
  accessories?: Array<IAccessorySelected>;
  insurance?: IInsurance;
  reseller?: IReseller;
  services?: Array<IService>;
}

export default function YourOrderBlock({
  className,
  onEditClickHandler,
  onContinue,
  isContinueEnabled = true,
  buttonText = 'Continue',
  currentOrder,
  accessories,
  insurance,
  reseller,
  offer,
  services,
}: YourOrderBlockProps) {
  const { product: orderDevice } = offer?.offer;

  const { productsSubtotalExVat, insuranceSubtotalExVat } = calculateOrderPrice(
    {
      quantity: currentOrder?.quantity || 50,
      reseller,
      insurance,
      accessories: currentOrder?.accessories,
      offer,
      orderType: currentOrder?.orderType,
      lease: currentOrder?.lease,
      services,
    }
  );

  const getPrice = (accessory: IAccessorySelected) => {
    if (currentOrder?.orderType !== ORDER_TYPES['Outright Purchase']) {
      return `${showPriceDigits(
        accessory.price * (currentOrder?.lease?.rate || 1)
      )}/${currentOrder?.paymentSchedule}`;
    }

    return showPriceDigits(accessory.price);
  };

  return (
    <Container className={className}>
      <TitleBlock>
        <Title>Your Order</Title>
        <EditButton onClick={onEditClickHandler}>Edit</EditButton>
      </TitleBlock>
      <ContentItem>
        <ProductItem>
          <ProductImageContainer>
            <ProductImage
              alt=""
              src={`https://image-sds-5pjg3ckx7a-uc.a.run.app/${orderDevice.imageUrl}`}
            />
          </ProductImageContainer>
          <ProductDescription>
            <ProductTitle>{orderDevice.name}</ProductTitle>
            <ProductInfo>
              <span
                style={{ marginRight: 5, fontWeight: 500 }}
              >{`${currentOrder?.quantity} units`}</span>
              <span style={{ fontWeight: 500 }}>
                {reseller &&
                  offer &&
                  (currentOrder?.orderType === ORDER_TYPES['Outright Purchase']
                    ? showPriceDigits(offer.price)
                    : `${showPriceDigits(
                        offer.price * (currentOrder?.lease?.rate || 1)
                      )}/${currentOrder?.paymentSchedule}`)}
              </span>
              {/* <span>{`£${showPriceDigits(orderDevice.price)}/quarter`}</span> */}
            </ProductInfo>
            <GreyText>{`Sold by: ${reseller?.name || ''}`}</GreyText>
          </ProductDescription>
        </ProductItem>
        {accessories?.map((accessory) => {
          const { product } = accessory.offer;

          return (
            <ProductItem>
              <ProductImageContainer>
                <ProductImage
                  alt=""
                  src={`https://image-sds-5pjg3ckx7a-uc.a.run.app/${product.imageUrl}`}
                />
              </ProductImageContainer>
              <ProductDescription>
                <ProductTitle>{product.name}</ProductTitle>
                <ProductInfo>
                  <span>{`${accessory.quantity} units`}</span>
                  <span>{getPrice(accessory)}</span>
                </ProductInfo>
                <GreyText>{`Sold by: ${reseller?.name || ''}`}</GreyText>
              </ProductDescription>
            </ProductItem>
          );
        })}
        {services?.map((service) => (
          <ProductItem>
            <ProductImageContainer>
              <ProductImage alt="" src={service.serviceIcon} />
            </ProductImageContainer>
            <ProductDescription>
              <ProductTitle>{service.serviceName}</ProductTitle>
              <ProductInfo>
                <span>{`${currentOrder?.quantity} units`}</span>
                <span>{showPriceDigits(service.price)}</span>
              </ProductInfo>
              <GreyText>{`Provided by: ${reseller?.name || ''}`}</GreyText>
            </ProductDescription>
          </ProductItem>
        ))}

        <Calculations>
          {currentOrder?.orderType !== ORDER_TYPES['Outright Purchase'] && (
            <ProductInfo>
              <ProductTitle>Term Length</ProductTitle>
              <ProductTitle>{currentOrder?.paymentSchedule}</ProductTitle>
            </ProductInfo>
          )}
          <ProductInfo>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <ProductTitle>Subtotal (Ex-VAT)</ProductTitle>
              {currentOrder?.orderType !== ORDER_TYPES['Outright Purchase'] && (
                <GreyText
                  style={{ fontSize: 14, marginTop: 2 }}
                >{`Billed ${currentOrder?.paymentSchedule}`}</GreyText>
              )}
            </div>
            <ProductTitle>{productsSubtotalExVat}</ProductTitle>
          </ProductInfo>
        </Calculations>
      </ContentItem>
      {insurance && (
        <ContentItem>
          <ProductDescription>
            <ProductTitle>Accidental Damage & Theft Insurance</ProductTitle>
            <TitleBlock>
              <ProductTitle>{`${currentOrder?.quantity} Devices`}</ProductTitle>
              <ProductTitle>
                {`${showPriceDigits(insurance?.price || 0)}/quarter`}
              </ProductTitle>
            </TitleBlock>
            <GreyText>{insurance?.insurer || ''}</GreyText>
          </ProductDescription>
          <Divider />
          <Calculations>
            <ProductInfo>
              <ProductTitle>Term Length</ProductTitle>
              <ProductTitle>3 Years</ProductTitle>
            </ProductInfo>
            <ProductInfo>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <ProductTitle>Subtotal (Ex-VAT)</ProductTitle>
                <GreyText>{`Billed ${insurance.paymentSchedule}`}</GreyText>
              </div>
              <ProductTitle>{insuranceSubtotalExVat}</ProductTitle>
            </ProductInfo>
          </Calculations>
        </ContentItem>
      )}
      <StyledButtonDark disabled={!isContinueEnabled} onClick={onContinue}>
        <>
          {buttonText}
          <StyledArrowRightOutlined />
        </>
      </StyledButtonDark>
      <QuestionBlock>
        <ProductTitle>Any questions?</ProductTitle>
        <Text style={{ fontSize: 14 }}>
          Speak to our customer support{' '}
          <CustomLink
            href="https://www.devicesforeducation.com/support"
            style={{ fontSize: 14 }}
          >
            here.
          </CustomLink>
        </Text>
      </QuestionBlock>
    </Container>
  );
}

const Container = styled.div`
  padding: 20px;
  background-color: #F5F8FB;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  gap: 20px;
  width: 100%;
  max-width: 380px;
  border-radius: 5px;
`;

const TitleBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.h2`
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
  color: #102f54;
`;

const EditButton = styled.a`
  color: #2682DE;
  cursor: pointer;
  text-decoration: none;
  font-weight: 600;

  &:hover {
    text-decoration: underline;
  }
`;

const CustomLink = styled.a`
  color: #2682DE;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const GreyText = styled.span`
  font-size: 12px;
  line-height: 12px;
  color: #6A7E95;
`;

const Text = styled(GreyText)`
  color: #102f54;
`;

const ContentItem = styled.div`
  border-radius: 3px;
  background-color: #fff;
  padding: 20px;
`;

const ProductItem = styled.div`
  display: flex;
  gap: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #EBF2FA;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const ProductImageContainer = styled.div`
  width: 100px;
  height: 60px;
`;

const ProductImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const ProductDescription = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
`;

const ProductTitle = styled.span`
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #102F54;
`;

const ProductInfo = styled(ProductDescription)`
  flex-direction: row;
`;

const Calculations = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 20px;
`;

const StyledButtonDark = styled(ButtonDark)`
  padding: 13px;
  width: 100%;
  border-radius: 3px;

  &:disabled {
    background: #F5F5F5;
    color: #909DA8;
  }
`;

const QuestionBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #D5DCE5;
  margin-top: 24px;
`;

const StyledArrowRightOutlined = styled(ArrowRightOutlined)`
  font-size: 15px;
  margin-left: 5px;
`;
