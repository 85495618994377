import { Tabs } from 'antd';
import { ReactNode, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import styled from 'styled-components/macro';
import { getIsUserAuth, getUserProfile, loadProfile } from '../../../feature/userState';
import ROUTER_CONFIG from '../../../router/router-config';
import DonorLayout from './DonorLayout';
import DonorAccountMenu from './menu/DonorAccountMenu';

const { TabPane } = Tabs;

interface DonorLayoutWithTabsProps {
  children: ReactNode;
}

export default function DonorLayoutWithTabs({ children }: DonorLayoutWithTabsProps) {
  const history = useHistory();
  const dispatch = useDispatch();

  const profile = useSelector(getUserProfile);
  const isAuth = useSelector(getIsUserAuth);

  useEffect(() => {
    if (!isAuth) {
      dispatch(loadProfile());
    }
  }, [isAuth, dispatch]);

  const onTabChange = (activeKey: string) => {
    switch (activeKey) {
      case ROUTER_CONFIG.DONOR_DASHBOARD.path:
        history.push(ROUTER_CONFIG.DONOR_DASHBOARD.getPath());
        break;
      case ROUTER_CONFIG.DONOR_DEVICE_TRADEIN.path:
        history.push(ROUTER_CONFIG.DONOR_DEVICE_TRADEIN.getPath());
        break;
      default:
        break;
    }
  };

  return (
    <DonorLayout
      menu={(
        <DonorAccountMenu
          accountName={`${profile?.donorProfile?.firstName} ${profile?.donorProfile?.lastName}` || 'Account'}
        />
    )}
      headerLoginLinkTo={ROUTER_CONFIG.DONOR_DASHBOARD.path}
    >
      <TabsContainer>
        <Tabs onChange={onTabChange} activeKey={history.location.pathname}>
          <TabPane tab="Donation Campaigns" key={ROUTER_CONFIG.DONOR_DASHBOARD.path} />
          <TabPane tab="Device Trade-in" key={ROUTER_CONFIG.DONOR_DEVICE_TRADEIN.path} />
        </Tabs>
      </TabsContainer>
      <Content>
        {children}
      </Content>
    </DonorLayout>
  );
}

const TabsContainer = styled.div`
  .ant-tabs-nav-wrap {
    background: #F5F8FB;

    .ant-tabs-nav-list {
      padding-left: 150px;
    }
  }
`;

const Content = styled.div`
  padding: 15px 150px;
`;
