import { useState, useEffect } from 'react';
import { Table, DatePicker, Input } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import styled from 'styled-components/macro';
import { SchoolCollectionsTableRow } from '../../types';
import { COLLECTION_STATUSES } from '../../../school/types';
import IconSearch from '../../../../img/icons/icon_search.svg';

interface CollectionsTableProps {
  tableData: Array<SchoolCollectionsTableRow>;
  donorSearch: string;
  onChangeDonorSearch: (donor: string) => void;
  campaignSearch: string;
  onChangeCampaignSearch: (campaign: string) => void;
  dateSearch: string;
  onChangeDateSearch: (date: string) => void;
  onChangeStatusSearch: (status: Array<any> | null) => void;
}

export default function CollectionsTable({
  tableData, donorSearch, onChangeDonorSearch, campaignSearch, onChangeCampaignSearch, dateSearch, onChangeDateSearch, onChangeStatusSearch,
}: CollectionsTableProps) {
  const [filteredTableData, setFilteredTableData] = useState(tableData);

  useEffect(() => {
    setFilteredTableData(tableData.filter((row) => row.donor.toLowerCase().includes(donorSearch.toLowerCase()) && row.campaignId.toLowerCase().includes(campaignSearch.toLowerCase()) && row.date.includes(dateSearch)));
  }, [donorSearch, dateSearch, campaignSearch, tableData]);

  const statusFilterOptions = Object.keys(COLLECTION_STATUSES).map((status) => ({ text: status, value: status }));

  const TableDateColumnCell = (
    <TableColumnCell>
      Date
      <DatePicker onChange={(value, dateString) => onChangeDateSearch(dateString)} format="DD MMM YYYY" />
    </TableColumnCell>
  );
  const TableDonorColumnCell = (
    <TableColumnCell>
      Donor
      <Input value={donorSearch} onChange={(e) => onChangeDonorSearch(e.target.value)} prefix={<InputPrefix iconSource={IconSearch} />} />
    </TableColumnCell>
  );
  const TableCampaignIdColumnCell = (
    <TableColumnCell>
      Campaign ID
      <Input value={campaignSearch} onChange={(e) => onChangeCampaignSearch(e.target.value)} prefix={<InputPrefix iconSource={IconSearch} />} />
    </TableColumnCell>
  );

  const tableConfig: ColumnsType<SchoolCollectionsTableRow> = [
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      filters: statusFilterOptions,
      onFilter: (value, record) => record.status.indexOf(COLLECTION_STATUSES[value as keyof typeof COLLECTION_STATUSES]) === 0,
      render: (text: string) => text[0] + text.slice(1).toLowerCase(),
    },
    {
      title: TableDateColumnCell,
      dataIndex: 'date',
      key: 'date',
      render: (text: Text) => <DateSpan>{text}</DateSpan>,
    },
    {
      title: TableDonorColumnCell,
      dataIndex: 'donor',
      key: 'donor',
    },
    {
      title: TableCampaignIdColumnCell,
      dataIndex: 'campaignId',
      key: 'campaignId',
    },
    {
      title: 'Total amount',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'Gift aid (y/n)',
      dataIndex: 'giftAid',
      key: 'giftAid',
    },
    {
      title: 'Gift aid amount',
      dataIndex: 'giftAidAmount',
      key: 'giftAidAmount',
    },
  ];

  return (
    <Table<SchoolCollectionsTableRow> columns={tableConfig} onChange={(_p, filters) => onChangeStatusSearch(filters.status)} dataSource={filteredTableData} />
  );
}

const TableColumnCell = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 8px;
`;

const DateSpan = styled.span`
  white-space: nowrap;
`;

const InputPrefix = styled.div<{ iconSource: string }>`
  box-sizing: border-box;
  width: 17px;
  height: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: ${(props) => `url(${props.iconSource})`};
  background-repeat: no-repeat;
  background-position: center center;
`;
