import { useHistory } from 'react-router-dom';
import ROUTER_CONFIG from '../../../../router/router-config';
import { formatDateDDMMMYYYY } from '../../../../ui/DateFormatMethods';
import { showPriceDigits } from '../../../../utils/formats';
import SupplierLayout from '../../layout/SupplierLayout';
import { QuotesTableRow } from '../../types';
import QuotesOverview from './QuotesOverview';

export default function QuotesOverviewPage() {
  const { push } = useHistory();

  const mockTableData: Array<QuotesTableRow> = [
    {
      key: '1',
      date: formatDateDDMMMYYYY(new Date()),
      reference: 'EFR1-2412',
      customer: 'Mona Vale School',
      total: showPriceDigits(16500),
    },
  ];

  const onViewDocClickHandler = (id: string) => console.log(id);
  const onBuildQuoteClickHandler = () => push(ROUTER_CONFIG.SUPPLIER_BUILD_QUOTE.getPath());

  return (
    <SupplierLayout>
      <QuotesOverview
        tableData={mockTableData}
        onViewDocClickHandler={onViewDocClickHandler}
        onBuildQuoteClickHandler={onBuildQuoteClickHandler}
      />
    </SupplierLayout>
  );
}
