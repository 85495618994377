import styled from 'styled-components/macro';
import BuyerLayout from '../layout/BuyerLayout';
import BuyerVerifyAccount from './BuyerVerifyAccount';

export default function BuyerVerifyAccountPage() {
  return (
    <StyledLayout>
      <BuyerVerifyAccount />
    </StyledLayout>
  );
}

const StyledLayout = styled(BuyerLayout)`
  .buyer-content-wrapper {
    padding: 0;
  }
`;
