import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Tabs } from 'antd';
import styled from 'styled-components/macro';
import {
  loadCampaign, loadCampaignCollections, selectCurrentCampaign, selectCurrentCampaignCollections, loadCampaignDonors, selectCampaignDonors, freezeDonors, deleteDonors, updateCampaign,
} from '../../../../feature/campaignState';
import SchoolLayout from '../../layout/SchoolLayoutWithTabs';
import CollectionReport from './Collections/CollectionReports';
import CollectionTable from './Collections/CollectionTable';
import DonorsReports from './Donors/DonorsReports';
import DonorsTable from './Donors/DonorsTable';
import ROUTER_CONFIG from '../../../../router/router-config';
import { formatDateDDMMMYYYY } from '../../../../ui/DateFormatMethods';
import {
  DonorTableRow, DonorStatuses, DonationsTableRow, COLLECTION_STATUSES,
} from '../../types';
import { DonorPublicFields } from '../../../../project-types/donor/types';
import {
  serializeStatisticsData, serializeStatisticsPrice, showPriceDigits, formatDateString,
} from '../../../../utils/formats';
import InfoTable from '../../../../ui/elements/InfoTable';
import SummaryTable from './SummaryTable';
import ModalInPortal from '../../../../ui/Modal/ModalPortal';
import EditDescriptionModalContent from './Modals/EditDescriptionModal';
import EditCollectionRulesModalContent from './Modals/EditCollectionRulesModal';
import EditCollectionNotificationsModalContent from './Modals/EditCollectionNotificationsModal';

import BackButton from '../../../../ui/elements/BackButton';
import { CampaignEditType } from '../../../../project-types/campaign/types';

const { TabPane } = Tabs;

export default function CampaignInfo() {
  const dispatch = useDispatch();
  const campaign = useSelector(selectCurrentCampaign);
  const donorList = useSelector(selectCampaignDonors);
  const campaignCollections = useSelector(selectCurrentCampaignCollections);
  const { campaignId } = useParams<{ campaignId: string }>();

  const EditDescriptionModal = ModalInPortal();
  const EditCollectionRulesModal = ModalInPortal();
  const EditCollectionNotificationsModal = ModalInPortal();

  const [selectedDonors, setSelectedDonors] = useState<Array<DonorPublicFields['_id']>>([]);
  const [selectedCollectionStatus, setSelectedCollectionStatus] = useState<keyof typeof COLLECTION_STATUSES | null>(null);
  const [selectedCollectionPeriod, setSelectedCollectionPeriod] = useState<[string, string]>(['', '']);
  const [selectedFilter, setSelectedFilter] = useState<DonorStatuses>('Active');

  const donorStatuses: Array<DonorStatuses> = ['Active', 'Frozen'];

  useEffect(() => {
    dispatch(loadCampaign(campaignId));
    dispatch(loadCampaignDonors(campaignId));
  }, [campaignId, dispatch]);

  useEffect(() => {
    const startDate = selectedCollectionPeriod[0] ? formatDateString(selectedCollectionPeriod[0]) : '';
    const endDate = selectedCollectionPeriod[1] ? formatDateString(selectedCollectionPeriod[1], true) : '';
    const collectionStatus = selectedCollectionStatus ? COLLECTION_STATUSES[selectedCollectionStatus] : '';
    // const collectionStatus = '';

    dispatch(loadCampaignCollections(campaignId, startDate, endDate, collectionStatus));
  }, [campaignId, selectedCollectionStatus, selectedCollectionPeriod, dispatch]);

  const onFreeze = () => {
    dispatch(freezeDonors(campaignId, selectedDonors, true));
  };

  const onUnFreeze = () => {
    dispatch(freezeDonors(campaignId, selectedDonors, false));
  };

  const onDelete = () => {
    dispatch(deleteDonors(campaignId, selectedDonors));
  };

  const onSubmitDescriptionChange = (newDescription: CampaignEditType['description']) => dispatch(updateCampaign(campaignId, { description: newDescription }));
  const onSubmitCollectionRulesChange = (newRules: { collectionAttempts: CampaignEditType['collectionAttempts'] }) => {
    dispatch(updateCampaign(campaignId, { collectionAttempts: newRules.collectionAttempts }));
  };
  const onSubmitCollectionNotifications = (newArr: CampaignEditType['notificationsEmails']) => dispatch(updateCampaign(campaignId, { notificationsEmails: newArr }));

  const onBackClickHandler = () => push(ROUTER_CONFIG.SCHOOL_CAMPAIGNS_LIST.getPath());
  const onEditDescriptionClickHandler = () => EditDescriptionModal.onShow();
  const onEditCollectionRulesClickHandler = () => EditCollectionRulesModal.onShow();
  const onEditCollectionNotificationsClickHandler = () => EditCollectionNotificationsModal.onShow();

  const CampaignStatData = [
    {
      title: 'Total amount raised',
      value: serializeStatisticsPrice(campaignCollections.stats?.donationsSum),
    },
    {
      title: 'Registred donors',
      value: serializeStatisticsData(campaignCollections.stats?.donorsCount),
    },
    {
      title: 'Donations collected',
      value: serializeStatisticsData(campaignCollections.stats?.donationsCount),
    },
    {
      title: 'Gift aid claimed',
      value: '£ 99.99',
    },
    {
      title: 'Platform payouts',
      value: '£ 99.99',
    },
  ];

  const donorTableSource: Array<DonorTableRow> = donorList.map((donor) => ({
    key: donor._id,
    fullName: `${donor.fullName}`,
    email: `${donor.email}`,
    amount: showPriceDigits(donor.donationAmount),
    numberOfDonations: `${donor.donationsNumber}`,
    total: showPriceDigits(donor.totalDonatiosSum),
    giftAid: 'yes',
    giftAidAmount: showPriceDigits(donor.donationAmount * 0.25),
    studentReferences: donor.students && donor.students.length ? donor.students.join(', ') : '-',
  })).reduce((acc: Array<DonorTableRow>, donor) => {
    switch (selectedFilter) {
      case 'Active':
        !campaign?.freezed.includes(donor.key) && acc.push(donor);
        break;
      case 'Frozen':
        campaign?.freezed.includes(donor.key) && acc.push(donor);
        break;
      default:
        acc.push(donor);
    }

    return acc;
  }, []);

  const donationsTableSource: Array<DonationsTableRow> = campaignCollections.donationsList?.map((collection) => ({
    key: collection._id,
    postedDate: formatDateDDMMMYYYY(new Date(collection.postedDate)),
    fullName: typeof collection.donor === 'string' ? '' : `${collection.donor.firstName} ${collection.donor.lastName}`,
    status: collection.status,
    amount: showPriceDigits(collection.amount),
    giftAidAmount: '£ 9.99',
    studentReferences: collection.students && collection.students.length ? collection.students.join(', ') : '-',
  }));

  const { push } = useHistory();

  const onAddNewDonor = () => {
    push(ROUTER_CONFIG.SCHOOL_INVITE.getPath({ campaignId }));
  };

  return (
    <SchoolLayout>
      {EditDescriptionModal.ModalRender && (
        <EditDescriptionModal.ModalRender>
          <EditDescriptionModalContent campaignDescription={campaign?.description || ''} onClose={EditDescriptionModal.onClose} onSubmit={onSubmitDescriptionChange} />
        </EditDescriptionModal.ModalRender>
      )}
      {EditCollectionRulesModal.ModalRender && (
        <EditCollectionRulesModal.ModalRender>
          <EditCollectionRulesModalContent campaignCollectionAttempts={campaign?.collectionAttempts} onClose={EditCollectionRulesModal.onClose} onFormSubmit={onSubmitCollectionRulesChange} />
        </EditCollectionRulesModal.ModalRender>
      )}
      {EditCollectionNotificationsModal.ModalRender && (
        <EditCollectionNotificationsModal.ModalRender>
          <EditCollectionNotificationsModalContent onChangeSubmit={onSubmitCollectionNotifications} onClose={EditCollectionNotificationsModal.onClose} initialData={campaign?.notificationsEmails} />
        </EditCollectionNotificationsModal.ModalRender>
      )}
      {campaign && (
      <CreateCampaignWrap>
        <StyledBackButton onClickHandler={onBackClickHandler} />
        <Content>
          <MainHeader>{campaign.name}</MainHeader>
          <StyledInfoTable data={CampaignStatData} />
          <StyledSummaryTable campaign={campaign} campaignStats={campaignCollections.stats} onEditDescriptionClickHandler={onEditDescriptionClickHandler} onEditCollectionRulesClickHandler={onEditCollectionRulesClickHandler} onEditCollectionNotificationsClickHandler={onEditCollectionNotificationsClickHandler} />
          <TabsStyled defaultActiveKey="1">
            <TabsPaneStyled tab="Donations" key="1">
              <CollectionReport onChangeTimePeriod={setSelectedCollectionPeriod} onChangeFilter={setSelectedCollectionStatus} />
              <CollectionTable tableData={donationsTableSource ?? []} />
            </TabsPaneStyled>
            <TabsPaneStyled tab="Donors" key="2">
              <DonorsReports
                onAddNewDonor={onAddNewDonor}
                numberOfDonors={donorList.length}
              // onSelectAction={setSelectedAction}
                onFreeze={onFreeze}
                onUnFreeze={onUnFreeze}
                onDelete={onDelete}
                donorStatuses={donorStatuses}
                onFilterChange={setSelectedFilter}
                selectedFilter={selectedFilter}
              />
              <DonorsTable selectedDonorsKeys={selectedDonors} tableSource={donorTableSource} onSelectDonor={setSelectedDonors} />
            </TabsPaneStyled>
          </TabsStyled>
        </Content>

      </CreateCampaignWrap>
      )}
    </SchoolLayout>
  );
}

const CreateCampaignWrap = styled.main`
  gap: 34px;
  display: flex;
  align-items: flex-start;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const StyledBackButton = styled(BackButton)`
  padding-top: 10px;
`;

const MainHeader = styled.h1`
  font-weight: 750;
  font-size: 30px;
  margin-top: 0;
  margin-bottom: 0;
`;

const TabsStyled = styled(Tabs)`
  margin-top: 57px;
`;

const TabsPaneStyled = styled(TabPane)`
  & .ant-tabs-tabpane {
  }
  padding-top: 25px;
`;

const StyledInfoTable = styled(InfoTable)`
  margin-top: 30px;
`;

const StyledSummaryTable = styled(SummaryTable)`
  margin-top: 42px;
`;
