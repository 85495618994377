import styled from 'styled-components/macro';
import InputWrap from '../../../../ui/forms/InputWrap';
import Input from '../../../../ui/forms/Input';

export default function AccountReceivablesTab() {
  return (
    <Container>
      <InputWrap title="Account Name">
        <StyledInput disabled value="Demo Reseller A Account" />
      </InputWrap>
      <InputWrap title="Account Number">
        <StyledInput disabled value="12512124" />
      </InputWrap>
      <InputWrap title="Sort Code">
        <StyledInput disabled value="55-81-11" />
      </InputWrap>
      <PleaseContactBlock>
        <Text>
          Please contact
          {' '}
          <LinkText>Silverwing Support</LinkText>
          {' '}
          if you require this information to be changed.
        </Text>
      </PleaseContactBlock>
    </Container>
  );
}

const Container = styled.div`
  width: 420px;
`;

const StyledInput = styled(Input)`
  width: 100%;
  max-width: 420px;
  height: unset;
  padding: 11px 12px;

  &:disabled {
    background: #f2f2f2;
  }
`;

const PleaseContactBlock = styled.div`
  padding: 13px;
  background-color: rgba(193, 230, 255, 0.2);
`;

const Text = styled.p`
  margin: 0;
  font-size: 14px;
  line-height: 17px;
  color: #102F54;
`;

const LinkText = styled.span`
  cursor: pointer;
  color: #2682DE;

  &:hover {
    text-decoration: underline;
  }
`;
