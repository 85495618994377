import styled from 'styled-components/macro';
import LinkWithArrow from '../../../../ui/elements/LinkWithArrow';

export default function IntelVisionBlock() {
  return (
    <Container>
      <Title>Intel’s Vision For Education</Title>
      <Content>
        <ServiceItemContainer>
          <ServiceItem bgColor="#ECC2F6">
            <div>
              <SubTitle>Learn from Anywhere</SubTitle>
              <Text>
                “Make it easy to multitask and be with everyone while remote or
                in person”
              </Text>
            </div>
          </ServiceItem>
        </ServiceItemContainer>
        <ServiceItemContainer>
          <ServiceItem bgColor="#D5F1A0">
            <div>
              <SubTitle>Intelligent and Adaptive</SubTitle>
              <Text>
                “Assists me throughout my learning and teaching experience”
              </Text>
            </div>
          </ServiceItem>
        </ServiceItemContainer>
        <ServiceItemContainer>
          <ServiceItem bgColor="#FFC497">
            <div>
              <SubTitle>Engaging and Immersive</SubTitle>
              <Text>
                “Help students and teachers enable engaging and interactive
                activities”
              </Text>
            </div>
          </ServiceItem>
        </ServiceItemContainer>
        <ServiceItemContainer>
          <ServiceItem bgColor="#B8D4E3">
            <div>
              <SubTitle>Simple and Secure</SubTitle>
              <Text>
                “Help students access secure and personalised education
                environments”
              </Text>
            </div>
          </ServiceItem>
        </ServiceItemContainer>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 35px;
  margin-bottom: 18px;

  @media (max-width: 660px) {
    padding-bottom: 40px;
  }
`;

const Title = styled.h2`
  margin: 0;
  font-size: 24px;
  line-height: 24px;
  color: #102f54;
  font-weight: bold;
  text-align: center;
`;

const SubTitle = styled.h3`
  color: #102f54;
  font-weight: bold;
  font-size: 21px;
  line-height: 19px;
  margin: 0;
  margin-bottom: 10px;
`;

const Text = styled.p`
  font-size: 16px;
  margin: 0;
  line-height: 21px;
  color: #4C555F;
`;

const Content = styled.div`
  display: flex;
  gap: 16px;

  @media (max-width: 660px) {
    flex-direction: column;
  }
`;

const StyledLink = styled(LinkWithArrow)`
  span {
    color: inherit;
  }

  svg {
    color: #fff !important;
  }
`;

const ServiceItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  // cursor: pointer;

  &:hover {
    ${StyledLink} {
      text-decoration: underline;
    }
  }

  @media (max-width: 660px) {
    padding-top: 0;
  }
`;

const ServiceItem = styled.div<{ bgColor: string }>`
  flex: 1;
  border-radius: 5px;
  background-color: ${({ bgColor }) => bgColor};
  padding: 24px 16px;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
