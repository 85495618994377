/* eslint-disable no-unused-vars */
import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import campaignState from '../feature/campaignState';
import schoolState from '../feature/schoolsState';
import adminState from '../feature/adminState';
import { userProfile } from '../feature/userState';
import { buyerApi } from '../api/services/buyerApi';
import buyerState from '../feature/buyerState';
// import counterReducer from '../features/counter/counterSlice';

// import user from '../features/user/userState';

const persistConfig = {
  key: 'root',
  storage,
}

const rootReducer = combineReducers({
  [buyerApi.reducerPath]: buyerApi.reducer,
  buyerState,
  userProfile: userProfile.reducer,
  campaignState,
  schoolState,
  adminState,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);
 
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }).concat(buyerApi.middleware),
});

setupListeners(store.dispatch);

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
