import { Tabs } from 'antd';
import styled from 'styled-components/macro';
import { AvailableOffersTableRow, JoinedOffersTableRow } from '../../types';
import AvailableOffersTable from './AvailableOffersTable';
import JoinedOffersTable from './JoinedOffersTable';

const { TabPane } = Tabs;

interface OffersViewProps {
  onJoinedOffersRowClick: (id: string) => void;
  joinedOffersTableData: Array<JoinedOffersTableRow>;
  availableOffersTableData: Array<AvailableOffersTableRow>;
  onAvailableOffersJoinClick: (id: string) => void;
}

export default function OffersView({
  joinedOffersTableData, onJoinedOffersRowClick, availableOffersTableData, onAvailableOffersJoinClick,
}: OffersViewProps) {
  return (
    <Container>
      <Title>Offers</Title>
      <Tabs defaultActiveKey="1">
        <StyledTabPane tab="Your Joined Offers" key="1">
          <JoinedOffersTable tableData={joinedOffersTableData} onRowClickHandler={onJoinedOffersRowClick} />
        </StyledTabPane>
        <StyledTabPane tab="Available Offers" key="2">
          <AvailableOffersTable tableData={availableOffersTableData} onJoinClickHandler={onAvailableOffersJoinClick} />
        </StyledTabPane>
      </Tabs>
    </Container>
  );
}

const Container = styled.div``;

const Title = styled.h1`
  font-size: 30px;
  line-height: 37px;
  color: #071730;
  font-weight: bold;
  margin-bottom: 40px;
`;

const StyledTabPane = styled(TabPane)`
  padding-top: 40px;
`;
