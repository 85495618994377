/* eslint-disable @typescript-eslint/no-unused-vars */
import { message } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  useCreateQuoteMutation,
  useGetOfferQuery,
} from '../../../api/services/buyerApi';
import {
  changeItemsQuantity,
  removeOrderAccessory,
  removeOrderInsurance,
  removeOrderService,
  selectBuyerCalculations,
  selectBuyerOfferId,
  selectBuyerOrder,
  setOrderCalculations,
} from '../../../feature/buyerState';
import ROUTER_CONFIG from '../../../router/router-config';
import calculateOrderPrice from '../calculateOrderPrice';
import BuyerLayout from '../layout/BuyerLayout';
import { CONFIGURE_ORDER_STEPS, ICreateQuote } from '../types';
import BuyerOrderCurrentView, {
  ORDER_POSITION_TYPE,
} from './BuyerOrderCurrentView';

export default function BuyerOrderCurrentPage() {
  const dispatch = useDispatch();
  const { push } = useHistory();

  const calculations = useSelector(selectBuyerCalculations);
  const currentOrder = useSelector(selectBuyerOrder);
  const offerId = useSelector(selectBuyerOfferId);

  const { data: offer } = useGetOfferQuery(offerId || '', { skip: !offerId });

  useEffect(() => {
    if (!currentOrder) return;

    const reCalculations = calculateOrderPrice({
      quantity: currentOrder.quantity,
      reseller: currentOrder.reseller,
      accessories: currentOrder.accessories,
      insurance: currentOrder.insurance,
      offer,
      orderType: currentOrder.orderType,
      lease: currentOrder.lease,
      services: currentOrder.services,
    });

    dispatch(setOrderCalculations(reCalculations));
  }, [currentOrder, dispatch, offer]);

  const [
    createQuote,
    { isSuccess, isError, isLoading },
  ] = useCreateQuoteMutation({});

  const onProceedClickHandler = () => push(
    ROUTER_CONFIG.BUYER_ORDER_SECURE_CHECKOUT_FORM.getPath({
      orderId: offer?._id || '123',
    }),
  );

  const onChangeQuantity = (type: 'product' | 'accessory', quantity: number, accessoryId?: string) => {
    dispatch(changeItemsQuantity({ type, quantity, accessoryId }));
  };

  const onEditOrderPosition = (orderPositionType: ORDER_POSITION_TYPE) => {
    switch (orderPositionType) {
      case ORDER_POSITION_TYPE.INSURANCE: {
        push(
          ROUTER_CONFIG.BUYER_ORDER_CONFIGURE.getPath({
            orderId: currentOrder?.productId || '',
          }),
          { defaultStep: CONFIGURE_ORDER_STEPS.MODIFY_INSURANCE },
        );
        break;
      }

      case ORDER_POSITION_TYPE.ACCESSORY: {
        push(
          ROUTER_CONFIG.BUYER_ORDER_CONFIGURE.getPath({
            orderId: currentOrder?.productId || '',
          }),
          { defaultStep: CONFIGURE_ORDER_STEPS.SELECT_ACCESSORIES },
        );
        break;
      }

      case ORDER_POSITION_TYPE.SERVICE: {
        push(
          ROUTER_CONFIG.BUYER_ORDER_CONFIGURE.getPath({
            orderId: currentOrder?.productId || '',
          }),
          { defaultStep: CONFIGURE_ORDER_STEPS.SELECT_RESELLER },
        );
        break;
      }

      default:
        break;
    }
  };

  const onDeleteOrderPosition = (
    orderPositionType: ORDER_POSITION_TYPE,
    id?: string,
  ) => {
    switch (orderPositionType) {
      case ORDER_POSITION_TYPE.INSURANCE: {
        dispatch(removeOrderInsurance());
        break;
      }

      case ORDER_POSITION_TYPE.ACCESSORY: {
        if (id) {
          dispatch(removeOrderAccessory(id));
        }
        break;
      }

      case ORDER_POSITION_TYPE.SERVICE: {
        if (id) {
          dispatch(removeOrderService(id));
        }
        break;
      }

      default:
        break;
    }
  };

  useEffect(() => {
    if (isError) {
      message.error('An error occurred while creating the quote');
    }

    if (isSuccess) {
      message.success('Quote successfully created');
    }
  }, [isError, isSuccess]);

  const onSaveClickHandler = () => {
    if (!currentOrder || !currentOrder.reseller) return;

    const {
      deliveryOption,
      attentionOf,
      deliveryAddress,
      deliveryNotes,
      contactEmail,
      contactName,
      invoiceAddress,
      ...rest
    } = currentOrder;

    const quote: ICreateQuote = {
      ...rest,
      quantity: +rest.quantity,
      sellerOfferId: offerId || '',
      reseller: JSON.stringify(rest.reseller),
      insurance: rest.insurance ? JSON.stringify(rest.insurance) : undefined,
      accessories: rest.accessories ? JSON.stringify(rest.accessories) : undefined,
      services: rest.services || [],
      lease: rest.lease ? JSON.stringify(rest.lease) : undefined,
    };

    createQuote(quote);
  };

  return (
    <BuyerLayout>
      {currentOrder && offer && (
        <BuyerOrderCurrentView
          onEditOrderPosition={onEditOrderPosition}
          onDeleteOrderPosition={onDeleteOrderPosition}
          onProceedClickHandler={onProceedClickHandler}
          onSaveClickHandler={onSaveClickHandler}
          calculations={calculations}
          order={currentOrder}
          offer={offer}
          isGenerateQuoteDisabled={isLoading || isError || isSuccess}
          isQuoteCreateSuccess={isSuccess}
          onChangeQuantity={onChangeQuantity}
        />
      )}
    </BuyerLayout>
  );
}
