import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { loadCampaignByInvite, selectCampaignInvite, selectCurrentCampaign } from "../../feature/campaignState";
import { getCurrentSchool } from "../../feature/schoolsState";
import ROUTER_CONFIG from "../../router/router-config";

export const useCampaignFromInvite = () => {
  const { campaignId, inviteCode } = useParams<{ campaignId: string, inviteCode: string }>();
  const campaignInvite = useSelector(selectCampaignInvite);

  const { push } = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadCampaignByInvite(campaignId, inviteCode));
  }, [campaignId, inviteCode]);

  useEffect(() => {
    if(!campaignInvite.donationAmount) {
      push(ROUTER_CONFIG.DONOR_INVITE_ACCEPT.getPath({ campaignId, inviteCode }))
    }
  }, [campaignInvite, campaignId, inviteCode]);

  const campaign = useSelector(selectCurrentCampaign);
  const currentSchool = useSelector(getCurrentSchool);
  const campaignJoin = useSelector(selectCampaignInvite);

  return { campaign, currentSchool, campaignJoin, inviteCode, campaignId };
}
