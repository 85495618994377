import { useHistory } from 'react-router-dom';
import { useGetOrdersListQuery } from '../../../api/services/buyerApi';
import ROUTER_CONFIG from '../../../router/router-config';
import { formatDateDDMMMYYYY } from '../../../ui/DateFormatMethods';
import BuyerLayout from '../layout/BuyerLayout';
import { BuyerQuotesOverviewTableRow, ORDER_STATUS_ENUM } from '../types';
import BuyerQuotesOverview from './BuyerQuotesOverview';

export default function BuyerQuotesOverviewPage() {
  const { push } = useHistory();

  const { data: orders } = useGetOrdersListQuery();

  const quotes = orders?.filter((order) => order.status === ORDER_STATUS_ENUM.QUOTE);

  const tableData: Array<BuyerQuotesOverviewTableRow> = quotes?.map((quote) => ({
    key: quote._id,
    dateSubmitted: formatDateDDMMMYYYY(new Date()),
    reference: `REF#:${quote._id}`,
    supplier: JSON.parse(quote.reseller as any).name,
    quoteExpirationDate: formatDateDDMMMYYYY(new Date(new Date().setDate(new Date().getDate() + 7))),
    orderType: quote.orderType,
  })) || [];

  const onRowClickHandler = (quoteId: string) => push(ROUTER_CONFIG.BUYER_QUOTE_DETAILS.getPath({ quoteId }));

  return (
    <BuyerLayout>
      <BuyerQuotesOverview
        tableData={tableData}
        onRowClickHandler={onRowClickHandler}
      />
    </BuyerLayout>
  );
}
