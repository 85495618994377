import styled from 'styled-components/macro';
import { BuyerAdminUsersTableRow, BuyerInviteUserType } from '../../../types';
import UsersTable from './UsersTable';
import UsersTableControls from './UsersTableControls';
import ModalInPortal from '../../../../../ui/Modal/ModalPortal';
import InviteNewUserModalContent from './InviteNewUesrModalContent';

interface UsersTabProps {
  usersTableData: Array<BuyerAdminUsersTableRow>;
  onSelectUser: (userIds: Array<string>) => void;
  selectedUsers: Array<string>;
  onRemoveUserClickHandler: () => void;
  onSubmitInviteUserForm: (values: BuyerInviteUserType) => void;
}

export default function UsersTab({
  usersTableData, selectedUsers, onSelectUser, onRemoveUserClickHandler, onSubmitInviteUserForm,
}: UsersTabProps) {
  const InviteNewUserModal = ModalInPortal();

  const onInviteUserClickHandler = () => InviteNewUserModal.onShow();

  return (
    <Container>
      {InviteNewUserModal.ModalRender && (
        <InviteNewUserModal.ModalRender>
          <InviteNewUserModalContent
            onClose={InviteNewUserModal.onClose}
            onSubmit={onSubmitInviteUserForm}
          />
        </InviteNewUserModal.ModalRender>
      )}
      <UsersTableControls
        onRemoveUserClickHandler={onRemoveUserClickHandler}
        onInviteUserClickHandler={onInviteUserClickHandler}
      />
      <UsersTable
        tableData={usersTableData}
        onSelectUser={onSelectUser}
        selectedUsers={selectedUsers}
      />
    </Container>
  );
}

const Container = styled.div``;
