import { useFormik } from 'formik';
import styled from 'styled-components/macro';
import { Switch } from 'antd';
import { useState } from 'react';
import { RadioGroup, RadioItem } from '../../../../../ui/forms/Radio';

import CloseIcon from '../../../../../img/close.svg';
import NotifyIcon from '../../../../../img/icons/VerificationIcon.svg';
import { ButtonDark } from '../../../../../ui/Button';
import { CampaignCollectionRulesSchema } from '../../../school-schemas';
import InputWrap from '../../../../../ui/forms/InputWrap';
import { CampaignEditType } from '../../../../../project-types/campaign/types';

interface EditCollectionRulesModalContentProps {
  onClose: () => void;
  onFormSubmit: (values: { collectionAttempts: CampaignEditType['collectionAttempts'] }) => void;
  campaignCollectionAttempts?: number;
}

export default function EditCollectionRulesModalContent({ onClose, onFormSubmit, campaignCollectionAttempts }: EditCollectionRulesModalContentProps) {
  const [isAttempts, setIsAttempts] = useState(!!campaignCollectionAttempts);

  const formik = useFormik({
    initialValues: {
      collectionAttempts: campaignCollectionAttempts,
    },
    validationSchema: CampaignCollectionRulesSchema,
    onSubmit: (values) => {
      onFormSubmit(values);
      onClose();
    },
  });

  const onReattemptChange = (value: boolean) => {
    if (value) {
      formik.setFieldValue('collectionAttempts', 2);
    } else {
      formik.setFieldValue('collectionAttempts', 0);
    }
    setIsAttempts(value);
  };

  return (
    <Container onSubmit={formik.handleSubmit}>
      <CloseButton onClick={onClose} alt="" src={CloseIcon} />
      <Title>Collection Rules</Title>
      <InputWrap
        style={{
          flexDirection: 'row-reverse', justifyContent: 'flex-end', alignItems: 'center', gap: '10px',
        }}
        title="Re-attempt collection after an unsuccessful collection"
      >
        <Switch size="small" checked={isAttempts} onChange={onReattemptChange} />
      </InputWrap>
      <Message>
        <Icon alt="" src={NotifyIcon} />
        <MessageText>
          Collections will be re-attempted on the next available collection date, typically this is two weeks after the collection attempt.
        </MessageText>
      </Message>
      <Divider />
      {isAttempts && (
      <RadioGroupWithLabel>
        <Label>Select from the following rules</Label>
        <RadioGroup
          onChange={(e) => formik.setFieldValue('collectionAttempts', e.target.value)}
          value={formik.values.collectionAttempts}
        >
          <VerticalRadio>
            <RadioItem value={1}>After 1 unsuccessful collection attempts freeze donor account</RadioItem>
            <RadioItem value={2}>After 2 unsuccessful collection attempts freeze donor account (Default)</RadioItem>
            <RadioItem value={3}>After 3 unsuccessful collection attempts freeze donor account</RadioItem>
          </VerticalRadio>
        </RadioGroup>
      </RadioGroupWithLabel>
      )}
      <SubmitButton type="submit">Save Edits</SubmitButton>
    </Container>
  );
}

const Container = styled.form`
  width: 100vw;
  max-width: 570px;
  padding: 24px 45px;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Title = styled.h3`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 40px;
`;

const CloseButton = styled.img`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  border-top: 1px solid #D5DCE5;
  margin: 29px 0;
`;

const Label = styled.span`
  font-size: 16px;
  color: #1F1F1F;
  margin-bottom: 4px;
`;

const RadioGroupWithLabel = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;

const VerticalRadio = styled.div`
  display: flex;
  flex-direction: column;
`;

const Message = styled.div`
  display: flex;
  gap: 4px;
  align-items: flex-start;
`;

const Icon = styled.img`
  width: 14px;
  height: 14px;
  margin: 0;
  padding-top: 1px;
`;

const MessageText = styled.p`
  font-size: 12px;
  margin: 0;
  width: 100%;
  max-width: 421px;
`;

const SubmitButton = styled(ButtonDark)`
  margin-top: 70px;
  padding: 14px 70px;
  align-self: flex-start;
`;
