import { useHistory, useParams } from 'react-router-dom';
import { useGetOfferQuery, useGetOrderQuery } from '../../../api/services/buyerApi';
import ROUTER_CONFIG from '../../../router/router-config';
import calculateOrderPrice from '../calculateOrderPrice';
import BuyerLayoutWithBack from '../layout/BuyerLayoutWithBack';
import { parseOrder } from '../parseOrder';
import { IBuyerOrderPublic } from '../types';
import BuyerOrderDetailsView from './BuyerOrderDetailsView';

export default function BuyerOrderDetailsPage() {
  const { push } = useHistory();
  const { orderId } = useParams<{ orderId: string }>();

  const { data: order, isSuccess } = useGetOrderQuery({ orderId });
  const { data: offer } = useGetOfferQuery(order?.sellerOfferId || '', { skip: !isSuccess });

  const parsedOrder = order && parseOrder(order) as IBuyerOrderPublic;

  // TODO - get seller from order by sellerId, not from offer;

  const calculations = calculateOrderPrice({
    quantity: parsedOrder?.quantity || 0,
    reseller: parsedOrder?.reseller,
    offer,
    orderType: parsedOrder?.orderType,
    accessories: parsedOrder?.accessories,
    lease: parsedOrder?.lease,
  });

  const onBack = () => push(ROUTER_CONFIG.BUYER_ORDERS.getPath());

  return (
    <BuyerLayoutWithBack onBack={onBack}>
      {parsedOrder && offer && (
        <BuyerOrderDetailsView
          order={parsedOrder}
          calculations={calculations}
          offer={offer}
          reseller={offer.seller}
        />
      )}
    </BuyerLayoutWithBack>
  );
}
