import { formatDateDDMMMYYYY } from '../../../../ui/DateFormatMethods';
import { showPriceDigits } from '../../../../utils/formats';
import SupplierLayout from '../../layout/SupplierLayout';
import { PaymentsTableRow } from '../../types';
import PaymentsOverview from './PaymentsOverview';

export default function PaymentsOverviewPage() {
  const mockTableData: Array<PaymentsTableRow> = [
    {
      key: '1',
      dateDue: formatDateDDMMMYYYY(new Date()),
      invoiceNumber: '123118381',
      order: '123118381',
      buyer: 'Combie Wood School',
      btsNumber: '-',
      amountDue: showPriceDigits(20467.58),
    },
  ];

  return (
    <SupplierLayout>
      <PaymentsOverview
        tableData={mockTableData}
      />
    </SupplierLayout>
  );
}
