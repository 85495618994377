import styled from 'styled-components/macro';
import { Input } from 'antd';
import { useState } from 'react';
import { ButtonDark } from '../../../../../ui/Button';
import CloseIcon from '../../../../../img/close.svg';

const { TextArea } = Input;

interface EditDescriptionModalProps {
  onClose: () => void;
  onSubmit: (newDescription: string) => void;
  campaignDescription: string;
}

export default function EditDescriptionModal({ onClose, onSubmit, campaignDescription }: EditDescriptionModalProps) {
  const [description, setDescription] = useState(campaignDescription);

  const onSubmitClickHandler = () => {
    onSubmit(description);
    onClose();
  };

  return (
    <Container>
      <CloseButton onClick={onClose} alt="" src={CloseIcon} />
      <Title>Campaign Description</Title>
      <Text>Campaign Description</Text>
      <TextArea value={description} onChange={(e) => setDescription(e.target.value)} />
      <SubmitButton onClick={onSubmitClickHandler}>Save Edits</SubmitButton>
    </Container>
  );
}

const Container = styled.div`
  width: 100vw;
  max-width: 570px;
  padding: 24px 45px;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Title = styled.h3`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 40px;
`;

const Text = styled.span`
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 10px;
`;

const SubmitButton = styled(ButtonDark)`
  margin-top: 100px;
  padding: 14px 70px;
  align-self: flex-start;
`;

const CloseButton = styled.img`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
`;
