import { useFormik } from 'formik';
import styled from 'styled-components/macro';
import { FormConfig, renderFormConfig } from '../../../utils/form';
import { UpdateDonorFields } from '../../../project-types/donor/types';
import Input from '../../../ui/forms/Input';
import { EditAccountSettingsSchema } from '../donor-schemas';
import { ButtonDark } from '../../../ui/Button';

interface UpdateDonorSettingsFormProps {
  firstName: string;
  lastName: string;
  onFormSubmitHandler: (formData: UpdateDonorFields) => void;
}

export default function UpdateDonorSettingsForm({ firstName, lastName, onFormSubmitHandler }: UpdateDonorSettingsFormProps) {
  const formik = useFormik({
    initialValues: {
      firstName,
      lastName,
    },
    validationSchema: EditAccountSettingsSchema,
    onSubmit: (values) => onFormSubmitHandler(values),
  });

  return (
    <StyledForm onSubmit={formik.handleSubmit}>
      {renderFormConfig(formConfig, formik)}
      <StyledConfirmButton type="submit">Save Edits</StyledConfirmButton>
    </StyledForm>
  );
}

const StyledForm = styled.form`
  width: 480px;
  padding-bottom: 20px;
`;

const StyledInput = styled(Input)`
  width: 100%;
  max-width: 420px;
`;

const StyledConfirmButton = styled(ButtonDark)`
  padding: 13px 70px;
  margin-top: 20px;
`;

const formConfig: FormConfig = [
  {
    title: 'First Name',
    name: 'firstName',
    component: StyledInput,
  },
  {
    title: 'Last Name',
    name: 'lastName',
    component: StyledInput,
  },
];
