import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { DeviceTradeinTableRow } from '../../types';

interface OverviewTableProps {
  tableData: Array<DeviceTradeinTableRow>;
  className?: string;
  onRowClickHandler: (campaignId: string) => void;
}

export default function OverviewTable({ tableData, className, onRowClickHandler }: OverviewTableProps) {
  const tableConfig: ColumnsType<DeviceTradeinTableRow> = [
    {
      title: 'Date Submitted',
      dataIndex: 'dateSubmitted',
      key: 'dateSubmitted',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Name/Reference',
      dataIndex: 'nameOrReference',
      key: 'nameOrReference',
    },
    {
      title: 'quantityRegistred',
      dataIndex: 'quantityRegistred',
      key: 'quantityRegistred',
    },
    {
      title: 'Expected Value',
      dataIndex: 'expectedValue',
      key: 'expectedValue',
    },
  ];

  return (
    <Table<DeviceTradeinTableRow>
      onRow={(rowData) => ({
        onClick: () => onRowClickHandler(rowData.key),
        style: { cursor: 'pointer' },
      })}
      columns={tableConfig}
      dataSource={tableData}
      className={className}
    />
  );
}
