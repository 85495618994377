import { ErrorData, ResGlobalError } from '../../components/buyer/types';

export const mapErrors = (
  error: ErrorData
): Record<string, string[]> | ResGlobalError => {
  if ([401, 422].includes(error?.status) ) {
    return error?.data as ResGlobalError;
  }
  return error?.data?.error as Record<string, string[]>;
};
