import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import BuyerLayout from '../../layout/BuyerLayout';
import {
  BuyerSecureCheckoutFormType,
} from '../../types';
import BuyerOrderSecureCheckoutFormView from './BuyerOrderSecureCheckoutFormView';
import ROUTER_CONFIG from '../../../../router/router-config';
import { selectBuyerOrder, setOrderSecureCheckoutDetails } from '../../../../feature/buyerState';
import { useGetOfferQuery, useGetProfileInfoQuery } from '../../../../api/services/buyerApi';

export default function BuyerOrderSecureCheckoutFormPage() {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { orderId } = useParams<{ orderId: string }>();
  const currentOrder = useSelector(selectBuyerOrder);

  const { data: offer } = useGetOfferQuery(orderId);
  const { data: profile } = useGetProfileInfoQuery();

  const onSubmit = (values: BuyerSecureCheckoutFormType) => {
    dispatch(setOrderSecureCheckoutDetails(values));
    push(
      ROUTER_CONFIG.BUYER_ORDER_SECURE_CHECKOUT_SUBMIT.getPath({
        orderId: offer?._id || '123',
      }),
    );
  };

  return (
    <BuyerLayout>
      {offer && profile && (
        <BuyerOrderSecureCheckoutFormView
          offer={offer}
          onSubmit={onSubmit}
          currentOrder={currentOrder}
          addressInitialData={profile?.schoolAddress || ''}
        />
      )}
    </BuyerLayout>
  );
}
