import styled from 'styled-components/macro';
import { useFormik } from 'formik';
import { UpdateDonorFields } from '../../../project-types/donor/types';
import { ChangeDebitDetailsSchema } from '../donor-schemas';
import DebitDetailsForm from '../invite/create-donor/DebitDetailsForm';

interface EditDetailsFormProps {
  profile: UpdateDonorFields;
  isEdit: boolean;
  email: string;
  onFormSubmit: (formData: UpdateDonorFields) => void;
}

export default function EditDetailsForm({
  profile, isEdit, email, onFormSubmit,
}: EditDetailsFormProps) {
  const formik = useFormik({
    initialValues: {
      ...profile, email,
    },
    validationSchema: ChangeDebitDetailsSchema,
    onSubmit: ({ email, ...values }) => onFormSubmit(values),
  });

  return (
    <FormStyled onSubmit={formik.handleSubmit}>
      <DebitDetailsForm formikInstance={formik} isEditPage isEdit={isEdit} />
    </FormStyled>
  );
}

const FormStyled = styled.form`
  width: 100%;
`;
