import styled from 'styled-components/macro';
import { ButtonLight, ButtonDark } from '../../../ui/Button';
import DocumentInfoItem from '../../../ui/elements/DocumentInfoItem';
import ResellerOverview from '../layout/elements/ResellerOverview';
import LessorOverview from '../layout/elements/LessorOverview';
import QuoteOverviewInfo from './quote-overview-info/QuoteOverviewInfo';
import {
  IBuyerQuotePublic, IReseller, ISellerOfferPublicType, ORDER_TYPES,
} from '../types';
import { CalculatedPricesType } from '../calculateOrderPrice';

interface BuyerQuoteDetailsViewProps {
  isSignedIn: boolean;
  onGenerateOrderClick: () => void;
  onLoginClick: () => void;
  onCreateAccountClick: () => void;
  quote: IBuyerQuotePublic;
  calculations: CalculatedPricesType;
  reseller?: IReseller;
  offer: ISellerOfferPublicType;
}

export default function BuyerQuoteDetailsView({
  isSignedIn, onCreateAccountClick, onGenerateOrderClick, onLoginClick, quote, calculations, reseller, offer,
}: BuyerQuoteDetailsViewProps) {
  return (
    <Container>
      <QuoteDetailsBlock>
        <Title>Quote Details</Title>
        <QuoteOverviewInfo
          reseller={reseller}
          quote={quote}
          offer={offer}
          calculations={calculations}
        />
        <DocumentsBlock>
          <SubTitle>Documents</SubTitle>
          <Divider />
          <DocumentRows>
            <DocumentRow>
              <StyledDocumentInfoItem
                documentTitle="QUOTE #12181381"
                createdDate={new Date()}
              />
              <StyledButtonLight>View Document</StyledButtonLight>
            </DocumentRow>
          </DocumentRows>
          <Divider />
        </DocumentsBlock>
      </QuoteDetailsBlock>
      <QuoteControlsBlock>
        <ButtonsBlock>
          {isSignedIn ? (
            <StyledButtonDark onClick={onGenerateOrderClick}>Proceed to Generate Purchase Order</StyledButtonDark>
          ) : (
            <>
              <StyledButtonDark onClick={onLoginClick}>Login To Accept Quote</StyledButtonDark>
              <CreateAccButton onClick={onCreateAccountClick}>Create An Account</CreateAccButton>
            </>
          )}
        </ButtonsBlock>
        <ResellerOverview reseller={reseller} />
        {quote.orderType === ORDER_TYPES['Operating Lease'] && (
          <LessorOverview />
        )}
      </QuoteControlsBlock>
    </Container>
  );
}

const Container = styled.div`
  flex: 1;
  display: flex;
  gap: 100px;
`;

const Title = styled.h1`
  font-weight: bold;
  font-size: 30px;
  line-height: 34px;
  color: #102F54;
`;

const SubTitle = styled.h2`
  font-size: 21px;
  line-height: 26px;
  color: #102F54;
  font-weight: bold;
  margin-bottom: 30px;
`;

const QuoteDetailsBlock = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const QuoteControlsBlock = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 48px;
`;

const DocumentsBlock = styled.div``;

const DocumentRows = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 20px 0;
`;

const DocumentRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledDocumentInfoItem = styled(DocumentInfoItem)`
  flex: 1;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #D5DCE5;
`;

const StyledButtonLight = styled(ButtonLight)`
  font-size: 14px;
  padding: 8px 28px;
`;

const ButtonsBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 21px;
  padding: 26px 20px;
  background: #FAFBFC;
`;

const StyledButtonDark = styled(ButtonDark)`
  font-size: 16px;
  padding: 14px;
`;

const CreateAccButton = styled(ButtonLight)`
  padding: 14px;
`;
