import React from 'react';
import styled from 'styled-components/macro';
import { IService } from '../../../types';

const StyledService = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  flex: 1;
`;

const StyledName = styled.span`
  font-size: 14px;
  line-height: 14px;
  color: #102f54;
  font-weight: bold;
`;

interface ResellerServiceProps {
  service: IService;
}

export default function ResellerService({ service }: ResellerServiceProps) {
  return (
    <StyledService>
      <img alt="" src={service.serviceIcon} style={{ width: 18, height: 18 }} />
      <StyledName>{service.serviceName}</StyledName>
    </StyledService>
  );
}
