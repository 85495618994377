import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { signInSchool } from '../../../feature/schoolsState';
import { getUserProfile } from '../../../feature/userState';
import ROUTER_CONFIG from '../../../router/router-config';
import { SchoolLoginFields } from '../../school/types';
import Login from '../../school/school-login/SchoolLogin';
import { signInAdmin } from '../../../feature/adminState';

export default function AdminLoginPage() {
  const history = useHistory();
  const dispatch = useDispatch();

  const fromState = history.location.state as { from: Location } | undefined;

  const profile = useSelector(getUserProfile);

  const onSignIn = (adminLoginFields: SchoolLoginFields) => {
    dispatch(signInAdmin(adminLoginFields));
  };

  const onForgotPasswordClickHandler = () => history.push(ROUTER_CONFIG.FORGOT_PASSWORD_PAGE.getPath());

  useEffect(() => {
    if (profile?.user.role === 'ADMIN') {
      if (fromState && fromState.from) {
        history.replace(fromState.from);
      } else {
        history.push(ROUTER_CONFIG.ADMIN_DONORS.getPath());
      }
    }
  }, [profile, history, fromState]);

  return (
    <Login onForgotPasswordClickHandler={onForgotPasswordClickHandler} title="Admin login" onSignIn={onSignIn} />
  );
}

// const Accept
