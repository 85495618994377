import * as yup from 'yup';
import {
  ORDER_TYPES, QuoteCreateType, SupplierCreateServiceType, SupplierEditServiceType, UpdateSupplierDeliveryChargesDetails, UpdateSupplierOrganisationDetails, UpdateSupplierPersonalDetails,
} from './types';

const isValidateLeaseFields = (value: ORDER_TYPES) => (!!(value === ORDER_TYPES['Financing Lease'] || value === ORDER_TYPES['Operating Lease']));

export const createQuoteSchema: yup.SchemaOf<QuoteCreateType> = yup.object({
  school: yup.string().required(),
  recipientEmail: yup.string().required(),
  recipientName: yup.string().required(),
  orderType: yup.mixed().oneOf(Object.values(ORDER_TYPES)),
  products: yup.array().of(
    yup.object().shape({
      name: yup.string().required(),
      unitPrice: yup.string().required(),
      quantity: yup.string().required(),
    }),
  ).required(),
  services: yup.array().of(
    yup.object().shape({
      name: yup.string().required(),
      price: yup.string().required(),
      quantity: yup.string().required(),
    }),
  ).optional(),
  isInsurance: yup.boolean().required(),
  quoteExpirationDate: yup.string().required(),
  deliveryCharge: yup.number().optional(),
  leaseLength: yup.string().when('orderType', {
    is: isValidateLeaseFields,
    then: yup.string().required(),
    otherwise: yup.string().optional(),
  }),
  leaseProvider: yup.string().when('orderType', {
    is: isValidateLeaseFields,
    then: yup.string().required(),
    otherwise: yup.string().optional(),
  }),
  paymentSchedule: yup.string().when('orderType', {
    is: isValidateLeaseFields,
    then: yup.string().required(),
    otherwise: yup.string().optional(),
  }),
});

export const updateSupplierOrganisationDetailsSchema: yup.SchemaOf<UpdateSupplierOrganisationDetails> = yup.object({
  name: yup.string().required(),
  adress1: yup.string().required(),
  adress2: yup.string().optional(),
  adress3: yup.string().optional(),
  town: yup.string().required(),
  country: yup.string().required(),
  postcode: yup.string().required(),
  phoneNumber: yup.string().required(),
  email: yup.string().required(),
  vatNumber: yup.string().required(),
  companyNumber: yup.string().required(),
});

export const updateSupplierDeliveryChargesDetails: yup.SchemaOf<UpdateSupplierDeliveryChargesDetails> = yup.object({
  defaultDeliveryChargeAmount: yup.string().required(),
});

export const updateSupplierPersonalDetails: yup.SchemaOf<UpdateSupplierPersonalDetails> = yup.object({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().required(),
});

export const updateSupplierService: yup.SchemaOf<SupplierEditServiceType> = yup.object({
  description: yup.string().required(),
  unitPrice: yup.string().required(),
});

export const createSupplierService: yup.SchemaOf<SupplierCreateServiceType> = yup.object({
  serviceType: yup.string().required(),
}).concat(updateSupplierService);
