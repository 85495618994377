import { formatDateDDMMMYYYY } from '../../../../ui/DateFormatMethods';
import { showPriceDigits } from '../../../../utils/formats';
import SupplierLayout from '../../layout/SupplierLayout';
import { BalanceTransfersTableRow } from '../../types';
import BalanceTransfers from './BalanceTransfers';

export default function BalanceTransfersPage() {
  const mockTableData: Array<BalanceTransfersTableRow> = new Array(5).fill(1).reduce((acc, item, i) => {
    const tableItem: BalanceTransfersTableRow = {
      key: `${i}`,
      date: formatDateDDMMMYYYY(new Date(`2${i} nov 2021`)),
      btsNumber: `BTS121318${i}`,
      balanceSettled: `+ ${showPriceDigits(19464.56)}`,
    };
    acc.push(tableItem);
    return acc;
  }, []);

  const onViewClickHandler = (id: string) => console.log(id);

  return (
    <SupplierLayout>
      <BalanceTransfers
        tableData={mockTableData}
        onViewClickHandler={onViewClickHandler}
      />
    </SupplierLayout>
  );
}
