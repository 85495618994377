import axios from 'axios';
import { persistor } from '../store';

export const BASE_URL =
  process.env.REACT_APP_BACKEND || 'http://localhost:5000/api/';

const instance = axios.create({
  baseURL: BASE_URL,
  headers: {},
});

export const setBearerToken = (token: string) => {
  instance.defaults.headers['x-access-token'] = `${token}`;
  localStorage.setItem('token', token);
};

export const removeToken = () => {
  instance.defaults.headers['x-access-token'] = null;
  instance.defaults.headers['Authorization'] = null;
  localStorage.removeItem('token');
  localStorage.removeItem('accessToken');
  persistor
    .purge()
    .then(() => {
      return persistor.flush();
    })
    .then(() => {
      persistor.pause();
    });
  window.location.reload();
};

export const getToken = () => localStorage.getItem('token');

export const request = (params: any, allowedStatuses: number[] = [200]) =>
  instance(params).catch((e) => {
    const status = e?.response?.status;

    if (allowedStatuses.indexOf(status) === -1) {
      if (e?.response?.status === 401) {
        removeToken();
        window.location.href = `${process.env.PUBLIC_URL}/donor/login`;
      } else {
        throw e;
      }
    } else {
      return e;
    }
    return null;
  });

function restoreToken() {
  const token = localStorage.getItem('token');
  if (token) {
    setBearerToken(token);
  }
}

export interface RequestParamsType {}

restoreToken();
