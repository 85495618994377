import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useGetOffersByCategoryQuery } from '../../../api/services/buyerApi';
import ROUTER_CONFIG from '../../../router/router-config';
import { groupSellerOffersByProductId } from '../groupSellerOffersByProductId';
import BuyerLayout from '../layout/BuyerLayout';
import BuyerProductsCategory from './BuyerProductsCategory';

export default function BuyerProductsCategoryPage() {
  const { categoryId } = useParams<{ categoryId: string }>();
  const { push } = useHistory();

  const { data: offers } = useGetOffersByCategoryQuery(categoryId);

  const groupedOffers = groupSellerOffersByProductId(offers);

  const onDeviceClick = (deviceId: string) =>
    push(ROUTER_CONFIG.BUYER_ORDER_CREATE.getPath({ deviceId }));

  return (
    <BuyerLayout>
      {offers && (
        <BuyerProductsCategory
          category={offers[0].offer.offerGroup.name}
          groupedOffers={groupedOffers}
          onDeviceClick={onDeviceClick}
        />
      )}
    </BuyerLayout>
  );
}
