import styled from 'styled-components/macro';
import { BuyerOrderStateType } from '../../../../../feature/buyerState';
import { GroupedOfferItemType, IReseller } from '../../../types';
import ResellerRowItem from './ResellerRowItem';

interface SelectResellerStepProps {
  onSelectReseller: (reseller: IReseller) => void;
  selectedReseller?: IReseller;
  groupedOffer: GroupedOfferItemType;
  currentOrder: BuyerOrderStateType;
  onViewPricingClickHandler: (seller: IReseller) => void;
}

export default function SelectResellerStep({
  onSelectReseller, selectedReseller, groupedOffer, currentOrder, onViewPricingClickHandler,
}: SelectResellerStepProps) {
  return (
    <>
      <StepTitle>Select A Reseller For Your Order</StepTitle>
      <Table>
        <TableHeader>
          <HeaderCell>Reseller</HeaderCell>
          <HeaderCell>Device Price (Ex-VAT)</HeaderCell>
        </TableHeader>
        {groupedOffer.offers.map((offer) => (
          <ResellerRowItem
            key={offer._id}
            reseller={offer.seller}
            currentOrder={currentOrder}
            offer={offer}
            onSelect={() => onSelectReseller(offer.seller)}
            isSelected={selectedReseller ? selectedReseller._id === offer.seller._id : false}
            onViewPricingClickHandler={onViewPricingClickHandler}
          />
        ))}
      </Table>
    </>
  );
}

const StepTitle = styled.h2`
  font-size: 20px;
  line-height: 24px;
  color: #102f54;
  font-weight: bold;
  margin: 0;
  margin-bottom: 22px;
`;

const Table = styled.div`
  border: 1px solid #D5DCE5;
  border-top: 2px solid #2682DE;
  padding-bottom: 20px;
`;

const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 17px 40px;
  background: #F9F9F9;
`;

const HeaderCell = styled.span`
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #102F54;
  flex: 1;
`;
