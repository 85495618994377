import {
  FieldWrap, FieldName, FieldTextarea, FieldTextareaText,
} from '../LayoutComponents';

export function BankOrBuildingSociety() {
  return (
    <FieldWrap>
      <FieldName>Bank or Building Society</FieldName>
      <FieldTextarea>
        <FieldTextareaText />
      </FieldTextarea>
    </FieldWrap>
  );
}
