import styled from 'styled-components/macro';
import { ButtonDark, ButtonLight } from '../../../../../ui/Button';
import { showPriceDigits } from '../../../../../utils/formats';
import { IInsurance } from '../../../types';

interface InsuranceOptionProps {
  insurance: IInsurance;
  isSelected: boolean;
  onSelect: () => void;
}

export default function InsuranceOption({
  insurance,
  isSelected,
  onSelect,
}: InsuranceOptionProps) {
  return (
    <Container>
      <Title>{insurance.name}</Title>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <PriceBlock>
          <Price>{`${showPriceDigits(insurance.price)}/${
            insurance.paymentSchedule === 'Quarterly'
              ? 'unit per quarter'
              : 'unit'
          }`}</Price>
          {insurance.paymentSchedule === 'Quarterly' && (
            <Text>{`${showPriceDigits(
              insurance.price * 12
            )}/unit over the three year period`}</Text>
          )}
        </PriceBlock>
        {isSelected ? (
          <SelectedButton>Selected</SelectedButton>
        ) : (
          <SelectButton onClick={onSelect}>Select</SelectButton>
        )}
      </div>
    </Container>
  );
}

const Container = styled.div`
  padding: 28px 20px;
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  border: 1px solid #D5DCE5;
  align-items: center;
`;

const Text = styled.span`
  font-size: 14px;
  line-height: 17px;
  color: #6B7A87;
`;

const Title = styled(Text)`
  font-weight: bold;
  font-size: 16px;
  color: #102f54;
`;

const PriceBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-right: 10px;
`;

const Price = styled(Title)`
  color: #DE2644;
`;

const SelectButton = styled(ButtonDark)`
  box-sizing: border-box;
  padding: 10px;
  height: 40px;
  width: 110px;
`;

const SelectedButton = styled(ButtonLight)`
  box-sizing: border-box;
  padding: 10px;
  height: 40px;
  width: 110px;
`;
