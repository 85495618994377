import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import BuyerLayout from '../buyer/layout/BuyerLayout';
import staticPageConfig from './staticPageConfig';
import { StaticPagesKeys } from './types';

export default function StaticPage () {
  const { pageName } = useParams<{ pageName: StaticPagesKeys }>();

  return (
    <StyledLayout>
      <StyledIframe
        title="ffdfd"
        id="iframe"
        src={staticPageConfig[pageName].url}
        style={{ height: staticPageConfig[pageName].height }}
        scrolling="no"
      />
    </StyledLayout>
  );
}


const StyledLayout = styled(BuyerLayout)`
  .buyer-content-wrapper {
    padding: 0;
  }
`;

const StyledIframe = styled.iframe`
  width: 100vw;
  border: none;
  height: 1700px;
`;
