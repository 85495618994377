import styled from 'styled-components/macro';
import { Dropdown, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';

interface UsersTableControlsProps {
  onRemoveUserClickHandler: () => void;
  onInviteUserClickHandler: () => void;
}

export default function UsersTableControls({ onRemoveUserClickHandler, onInviteUserClickHandler }: UsersTableControlsProps) {
  const dropdownContent = (
    <Menu>
      <Menu.Item onClick={onRemoveUserClickHandler}>
        Remove User
      </Menu.Item>
    </Menu>
  );

  return (
    <Container>
      <InviteButton onClick={onInviteUserClickHandler}>+ Invite New User</InviteButton>
      <Dropdown overlay={dropdownContent}>
        <InviteButton onClick={(e) => e.preventDefault()}>
          Actions
          {' '}
          <DownOutlined />
        </InviteButton>
      </Dropdown>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  gap: 35px;
  justify-content: flex-end;
  margin-bottom: 25px;
`;

const InviteButton = styled.span`
  cursor: pointer;
  color: #2682DE;
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }
`;
