import styled from 'styled-components/macro';
import { ButtonDark, ButtonLight } from '../../../../../ui/Button';
import { IService } from '../../../types';
import CloseIcon from '../../../../../img/close.svg';
import { showPriceDigits } from '../../../../../utils/formats';

interface SelectServicesModalContentProps {
  onClose: () => void;
  resellerName: string;
  resellerImage?: string;
  resellerId: string;
  services: Array<IService>;
  onAddClickHandler: (service: IService, resellerId: string) => void;
  quantity: number;
  selectedServices: Array<IService>;
}

export default function SelectServicesModalContent({
  onClose, resellerName, resellerImage, services, onAddClickHandler, quantity, resellerId, selectedServices,
}: SelectServicesModalContentProps) {
  const checkIfSelected = (service: IService) => selectedServices.find((s) => s.serviceName === service.serviceName);

  return (
    <Container>
      <CloseButton alt="" src={CloseIcon} onClick={onClose} />
      <Title>Add Reseller Service</Title>
      <ResellerRow>
        <ResellerImageContainer>
          {resellerImage && <Image alt="" src={resellerImage} />}
        </ResellerImageContainer>
        <BoldText>{resellerName}</BoldText>
      </ResellerRow>
      <Divider />
      {services.map((service) => (
        <ServiceItem>
          <div style={{ width: 24, height: 24, backgroundColor: '#fff' }}>
            {service.serviceIcon && (
              <img style={{ width: '100%', height: '100%', objectFit: 'contain' }} alt="" src={service.serviceIcon} />
            )}
          </div>
          <ServiceItemContent>
            <ServiceItemRow>
              <ServiceItemRowLeftSide>
                <BoldText>{service.serviceName}</BoldText>
                <div style={{ display: 'flex', gap: 5 }}>
                  <Price>{showPriceDigits(service.price * quantity)}</Price>
                  <GreyText>{`(${showPriceDigits(service.price)}/unit)`}</GreyText>
                </div>
              </ServiceItemRowLeftSide>
              {checkIfSelected(service) ? (
                <AddedButton onClick={() => onAddClickHandler(service, resellerId)}>
                  Added to Order
                </AddedButton>
              ) : (
                <AddButton onClick={() => onAddClickHandler(service, resellerId)}>
                  Add to Order
                </AddButton>
              )}
            </ServiceItemRow>
            <Text>
              {service.description}
            </Text>
          </ServiceItemContent>
        </ServiceItem>
      ))}
    </Container>
  );
}

const Container = styled.div`
  width: 100vw;
  max-width: 570px;
  padding: 24px 45px;

  position: relative;
`;

const Title = styled.h1`
  margin: 0;
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
  color: #102f54;
  margin-bottom: 40px;
`;

const CloseButton = styled.img`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
`;

const ResellerRow = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const ResellerImageContainer = styled.div`
  width: 45px;
  height: 45px;
  background-color: #fff;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const BoldText = styled.span`
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #102F54;
`;

const Text = styled.span`
  font-size: 14px;
  line-height: 14px;
  color: #102F54;
`;

const Price = styled(BoldText)`
  color: #DE2644;
  line-height: 16px;
  font-size: 16px;
`;

const GreyText = styled(BoldText)`
  font-size: 14px;
  line-height: 14px;
  font-weight: normal;
  color: #6B7A87;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #D5DCE5;
  margin: 35px 0 20px;
`;

const ServiceItem = styled.div`
  box-sizing: border-box;
  border: 1px solid #D5DCE5;
  border-radius: 5px;
  padding: 16px;
  display: flex;
  gap: 14px;
  width: 100%;
  margin-bottom: 15px;
`;

const ServiceItemContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

const ServiceItemRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const ServiceItemRowLeftSide = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const AddButton = styled(ButtonDark)`
  padding: 10px 35px;
`;

const AddedButton = styled(ButtonLight)`
  padding: 10px 35px;
`;
