import styled from 'styled-components/macro';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { SupplierAdminServicesTableRow } from '../../../types';
import { ButtonDark } from '../../../../../ui/Button';

interface ServicesTableProps {
  tableData: Array<SupplierAdminServicesTableRow>;
  onSelectService: (userIds: Array<string>) => void;
  selectedServices: Array<string>;
  onEditClickHandler: (serviceId: string) => void;
}

export default function ServicesTable({
  tableData, onSelectService, selectedServices, onEditClickHandler,
}: ServicesTableProps) {
  const tableConfig: ColumnsType<SupplierAdminServicesTableRow> = [
    {
      title: 'Service Type',
      dataIndex: 'serviceType',
      key: 'serviceType',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Unit Price',
      dataIndex: 'unitPrice',
      key: 'unitPrice',
    },
    {
      title: '',
      dataIndex: 'editButton',
      key: 'editButton',
      render: (_v, r) => <StyledButtonDark onClick={() => onEditClickHandler(r.key)}>Edit</StyledButtonDark>,
    },
  ];

  return (
    <Table
      rowSelection={{
        type: 'checkbox',
        onChange: (ids) => {
          onSelectService(ids as Array<string>);
        },
        selectedRowKeys: selectedServices,
      }}
      columns={tableConfig}
      dataSource={tableData}
      style={{ borderTop: '2px solid #2682DE' }}
    />
  );
}

const StyledButtonDark = styled(ButtonDark)`
  padding: 10px 35px;
`;
