import { Select } from 'antd';
import styled from "styled-components/macro";
import DownloadIcon from "../../../../img/icons/download.svg";

const { Option } = Select;

const UserStatuses = ['October'];

export default function Documentation() {
  return (<Wrap>
    <MainHeader>
      <MainTitle>Documentation</MainTitle>
      <MainText>Download payout summary and invoices for platform fees</MainText>
    </MainHeader>
    <SelectStyled placeholder="Time Period">
      {UserStatuses.map(perriod => (
        <Option value={perriod}>{perriod}</Option>
      ))}
    </SelectStyled>
    <DownloadWrap>
      <DownloadLink target="_blank" href="/PCS_BTS_REPORT.pdf">
        <DownloadLinkText>Payout Summary PDF</DownloadLinkText>
        <DownloadLinkImg src={DownloadIcon} />
      </DownloadLink>
    </DownloadWrap>
  </Wrap>);
}

const Wrap = styled.div`
  display: block;
`;

const MainHeader = styled.div``;

const MainTitle = styled.h2`
  margin-bottom: 8px;
  font-weight: 700;
  font-size: 24px;
  line-height: 26px;
`;

const MainText = styled.p`
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 38px;
`;

const SelectStyled = styled(Select)`
  width: 231px;
  margin-bottom: 16px;
`;

const DownloadWrap = styled.div`
  width: 100%;
  border-top: 1px solid #E0E0E0;
  padding: 16px 0 0;
`;

const DownloadLink = styled.a`
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  line-height: 17px;
`;

const DownloadLinkText = styled.a`
  margin-right: 5px;
`;

const DownloadLinkImg = styled.img`
  width: 15px;
  height: 15px;
`;