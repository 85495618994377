import { useState } from 'react';
import styled from 'styled-components/macro';
import { BuyerOrderStateType } from '../../../feature/buyerState';
import ModalInPortal from '../../../ui/Modal/ModalPortal';
import {
  CONFIGURE_ORDER_STEPS,
  IReseller,
  IInsurance,
  GroupedOfferItemType,
  IAccessorySelected,
  IService,
} from '../types';
import CheckoutStep from './steps/checkout/CheckoutStep';
import ModifyInsuranceStep from './steps/modify-insurance/ModifyInsuranceStep';
import SelectAccessoriesStep from './steps/select-accessories/SelectAccessoriesStep';
import SelectResellerStep from './steps/select-reseller/SelectResellerStep';
import SelectServicesModalContent from './steps/select-reseller/SelectServicesModalContent';
import YourOrderBlock from './steps/YourOrderBlock';
import StepsComponent from './StepsComponent';

interface BuyerOrderCurrentViewProps {
  insuranceOptions: Array<IInsurance>;
  orderAccessories: Array<IAccessorySelected>;
  onChangeAccessoriesQuantity: (
    accessory: IAccessorySelected,
    quantity: number
  ) => void;
  onChangeReseller: (reseller: IReseller) => void;
  onChangeInsurance: (insurance?: IInsurance) => void;
  onChangeAccessories: (
    accessories: Array<IAccessorySelected>
  ) => void;
  onComplete: () => void;
  groupedOffer: GroupedOfferItemType;
  currentOrder: BuyerOrderStateType;
  defaultStep?: CONFIGURE_ORDER_STEPS;
  onChangeServices: (service: IService, resellerId: string) => void;
}

const editOrderStepsState = [
  CONFIGURE_ORDER_STEPS.SELECT_RESELLER,
  CONFIGURE_ORDER_STEPS.MODIFY_INSURANCE,
];

export default function BuyerOrderCurrentView({
  insuranceOptions,
  orderAccessories,
  onChangeAccessoriesQuantity,
  onChangeReseller,
  onChangeInsurance,
  onChangeAccessories,
  onComplete,
  groupedOffer,
  currentOrder,
  defaultStep,
  onChangeServices,
}: BuyerOrderCurrentViewProps) {
  const [currentStep, setCurrentStep] = useState<CONFIGURE_ORDER_STEPS>(
    defaultStep || CONFIGURE_ORDER_STEPS.SELECT_RESELLER,
  );
  const [completedSteps, setCompletedSteps] = useState<
    Array<CONFIGURE_ORDER_STEPS>
  >(defaultStep ? editOrderStepsState : []);
  const [sellerWithServices, setSellerWithServices] = useState<IReseller | null>(null);

  const SelectServices = ModalInPortal();

  const onViewPricingClickHandler = (seller: IReseller) => {
    setSellerWithServices(seller);
    SelectServices.onShow();
  };

  const isContinueEnabled = () => {
    if (currentStep === CONFIGURE_ORDER_STEPS.SELECT_RESELLER) {
      return !!currentOrder?.reseller;
    }
    if (currentStep === CONFIGURE_ORDER_STEPS.SELECT_ACCESSORIES) {
      return completedSteps.length === 2;
    }

    return true;
  };

  const onContinue = () => {
    if (currentStep === CONFIGURE_ORDER_STEPS.SELECT_RESELLER) {
      setCompletedSteps([CONFIGURE_ORDER_STEPS.SELECT_RESELLER]);
      setCurrentStep(CONFIGURE_ORDER_STEPS.MODIFY_INSURANCE);
    }
    if (currentStep === CONFIGURE_ORDER_STEPS.MODIFY_INSURANCE) {
      setCompletedSteps([
        CONFIGURE_ORDER_STEPS.SELECT_RESELLER,
        CONFIGURE_ORDER_STEPS.MODIFY_INSURANCE,
      ]);
      setCurrentStep(CONFIGURE_ORDER_STEPS.SELECT_ACCESSORIES);
    }
    if (currentStep === CONFIGURE_ORDER_STEPS.SELECT_ACCESSORIES) {
      onComplete();
    }
  };

  const onSelectAccesory = (accessory: IAccessorySelected) => {
    const isFound = currentOrder?.accessories?.find((acc) => {
      if (acc.offer._id === accessory.offer._id) {
        return true;
      }

      return false;
    });

    if (isFound) {
      onChangeAccessories(currentOrder?.accessories?.filter((acc) => acc.offer._id !== accessory.offer._id) || []);
    } else {
      onChangeAccessories([...(currentOrder?.accessories || []), accessory]);
    }
  };

  const selectedOffer = currentOrder?.reseller && groupedOffer.offers.find((offer) => offer.seller._id === currentOrder.reseller?._id);

  return (
    <Container>
      <Title>Configure Your Order</Title>
      <StepsComponent
        currentStep={currentStep}
        onStepClick={setCurrentStep}
        completedSteps={completedSteps}
      />
      <StepsContainer>
        {SelectServices.ModalRender && sellerWithServices && (
          <SelectServices.ModalRender>
            <SelectServicesModalContent
              services={sellerWithServices.availableServices || []}
              resellerName={sellerWithServices?.name || ''}
              resellerImage={`https://image-sds-5pjg3ckx7a-uc.a.run.app/${sellerWithServices._id}.jpg`}
              resellerId={sellerWithServices._id}
              onClose={SelectServices.onClose}
              onAddClickHandler={onChangeServices}
              quantity={currentOrder?.quantity || 1}
              selectedServices={currentOrder?.services || []}
            />
          </SelectServices.ModalRender>
        )}
        <StepsContent>
          {currentStep === CONFIGURE_ORDER_STEPS.SELECT_RESELLER && (
            <SelectResellerStep
              groupedOffer={groupedOffer}
              currentOrder={currentOrder}
              onSelectReseller={onChangeReseller}
              selectedReseller={currentOrder?.reseller}
              onViewPricingClickHandler={onViewPricingClickHandler}
            />
          )}
          {currentStep === CONFIGURE_ORDER_STEPS.MODIFY_INSURANCE && (
            <ModifyInsuranceStep
              insuranceOptions={insuranceOptions}
              selectedInsurance={currentOrder?.insurance}
              onSelectInsurance={onChangeInsurance}
              onRemoveInsurance={() => onChangeInsurance(undefined)}
            />
          )}
          {currentStep === CONFIGURE_ORDER_STEPS.SELECT_ACCESSORIES && (
            <SelectAccessoriesStep
              accessories={orderAccessories}
              selectedAccessories={currentOrder?.accessories || []}
              onSelectAccessory={onSelectAccesory}
              onChangeAccessoryQuantity={onChangeAccessoriesQuantity}
              currentOrder={currentOrder}
            />
          )}
          {currentStep === CONFIGURE_ORDER_STEPS.CHECKOUT && <CheckoutStep />}
        </StepsContent>
        <YourOrderBlock
          onEditClickHandler={() => setCurrentStep(CONFIGURE_ORDER_STEPS.SELECT_RESELLER)}
          onContinue={onContinue}
          isContinueEnabled={isContinueEnabled()}
          currentOrder={currentOrder}
          accessories={currentOrder?.accessories}
          insurance={currentOrder?.insurance}
          reseller={currentOrder?.reseller}
          offer={selectedOffer || groupedOffer.offers[0]}
          services={currentOrder?.services}
        />
      </StepsContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 45px;
`;

const StepsContainer = styled.div`
  display: flex;
  gap: 56px;
`;

const StepsContent = styled.div`
  flex: 1;
`;

const Title = styled.h1`
  font-size: 28px;
  line-height: 34px;
  margin: 0;
  color: #102f54;
  font-weight: bold;
`;
