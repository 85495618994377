import { ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router';

interface IProps {
  children: ReactNode;
}

const ScrollToTop = (props: IProps) => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return <>{props.children}</>;
};

export default ScrollToTop;
