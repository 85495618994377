import styled from 'styled-components/macro';
import LoginImage from '../../../img/loginImg.svg';

export default function BuyerVerifyAccount() {
  return (
    <Container>
      <Content>
        <Title>Please verify your email</Title>
        <Text>
          Thank you for registering, please verify your email by clicking on the link sent to your work email. Once verified, a member of the Devices For Education team will review your registration request.
        </Text>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  background: url(${LoginImage}) no-repeat right 150px top 80px/25%,
    linear-gradient(to right, white 70%, #F1F9F8 70% 100%);
  min-height: 700px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Content = styled.div`
  padding: 66px 0 0 150px;
  max-width: 40%;
`;

const Title = styled.h1`
  font-weight: bold;
  font-size: 30px;
  line-height: 37px;
  color: #071730;
  margin: 0;
  margin-bottom: 31px;
`;

const Text = styled.p`
  margin: 0;
  font-size: 14px;
  line-height: 17px;
  color: #102F54;
`;
