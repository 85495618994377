import styled from 'styled-components/macro';
import { formatDateDDMMMYYYY } from '../../../../ui/DateFormatMethods';
import { showPriceDigits } from '../../../../utils/formats';

import SurfaceImage from '../../../../img/test_images/surface.png';

export default function OrderDetailsInfoTable() {
  return (
    <TableWrapper>
      <TableHeader>
        <Tag color="#2682DE">PO#49318386</Tag>
        <Tag color="#1B9D75">
          Placed:
          {' '}
          {formatDateDDMMMYYYY(new Date('6 jan 2021'))}
        </Tag>
      </TableHeader>
      <TableRowWrapper>
        <TableRowContent>
          <ItemCard>
            <ItemImage alt="" src={SurfaceImage} />
            <ItemDescription>
              <Text>Microsoft Surface Go 2</Text>
              <GrayText>STV-00002</GrayText>
            </ItemDescription>
          </ItemCard>
          <ItemPrice>
            <Text>{showPriceDigits(10464)}</Text>
            <GrayText>30 Units</GrayText>
          </ItemPrice>
        </TableRowContent>
      </TableRowWrapper>
      <TableRowWrapper>
        <TableRowContent>
          <ItemDescription>
            <TitleGrayText>Delivery Address</TitleGrayText>
            <Text>Broomhill Road</Text>
            <Text>Adderlane Academy</Text>
            <Text>Prudhoe</Text>
            <Text>Northumberland</Text>
            <Text>NE42 5HX</Text>
          </ItemDescription>
          <PriceInfoBlock>
            <PriceInfoRow>
              <GrayText>Subtotal:</GrayText>
              <Text>{showPriceDigits(10464)}</Text>
            </PriceInfoRow>
            <PriceInfoRow>
              <GrayText>Shipping:</GrayText>
              <Text>{showPriceDigits(0)}</Text>
            </PriceInfoRow>
            <PriceInfoRow>
              <GrayText>VAT (20%):</GrayText>
              <Text>{showPriceDigits(2092)}</Text>
            </PriceInfoRow>
            <PriceInfoRow>
              <GrayText>Invoice Amount:</GrayText>
              <Text style={{ color: '#DE2644' }}>{showPriceDigits(12556.80)}</Text>
            </PriceInfoRow>
          </PriceInfoBlock>
        </TableRowContent>
      </TableRowWrapper>
      <TableRowWrapper>
        <TableRowContent>
          <ItemDescription>
            <TitleGrayText>Invoce Address</TitleGrayText>
            <Text>Silverwing</Text>
            <Text>243 High Street</Text>
            <Text>Boston Spa</Text>
            <Text>West Yorkshire</Text>
            <Text>LS23 6AL</Text>
          </ItemDescription>
          <ItemDescription>
            <TitleGrayText>Payment Method</TitleGrayText>
            <Text>Operating Lease</Text>
          </ItemDescription>
          <ItemDescription>
            <TitleGrayText>Lease Provider</TitleGrayText>
            <Text>Demo Lessor Ltd.</Text>
          </ItemDescription>
        </TableRowContent>
      </TableRowWrapper>
    </TableWrapper>
  );
}

const Text = styled.span`
  font-size: 14px;
  line-height: 14px;
  color: #102F54;
`;

const BoldText = styled(Text)`
  font-weight: bold;
`;

const GrayText = styled(BoldText)`
  color: #6B7A87;
`;

const TitleGrayText = styled(GrayText)`
  margin-bottom: 5px;
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  border-top: 2px solid #2682DE;
  box-shadow: 0px 1px 6px rgba(161, 166, 172, 0.161);
`;

const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
  background-color: #F9F9F9;
`;

const TableRowContent = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  border-bottom: 1px solid #D5DCE5;
`;

const TableRowWrapper = styled.div`
  padding: 0 20px;

  &:last-child {
    ${TableRowContent} {
      border-bottom: none;
      padding-bottom: 0;
    }
  }
`;

const ItemCard = styled.div`
  display: flex;
  gap: 12px;
`;

const ItemImage = styled.img`
  width: 77px;
  height: 37px;
`;

const ItemDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
`;

const ItemPrice = styled(ItemDescription)`
  align-items: flex-end;
`;

const PriceInfoBlock = styled.div`
  width: 230px;
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const PriceInfoRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Tag = styled.div<{ color: string }>`
  padding: 10px 15px;
  border-radius: 5px;
  background-color: #F0F4F7;

  color: ${({ color }) => color};
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
`;
