import styled from 'styled-components/macro';

interface BoostYourDonationBlockProps {
  schoolCharityName: string;
}

export default function BoostYourDonationBlock({ schoolCharityName }: BoostYourDonationBlockProps) {
  return (
    <Container>
      <NumberInSquare>4</NumberInSquare>
      <Card>
        <CardTitle>
          Boost your donation by 25p of Gift Aid for every £1 you donate
        </CardTitle>
        <CardText>
          Gift Aid is reclaimed by the charity from the tax you pay for the current
          tax year. Your address which is collected upon registration is needed to identify you as a
          current UK taxpayer.
        </CardText>
        <CardInner>
          <CardInnerTitle>
            In order to Gift Aid your donation you must tick the box below:
          </CardInnerTitle>
          <CardInnerCheckbox>
            <CheckboxInput type="checkbox" />
            <CardText>
              I want to Gift Aid my donation and any donations I make in the
              future or have made in the past 4 years to
              {' '}
              {schoolCharityName}
            </CardText>
          </CardInnerCheckbox>
        </CardInner>
        <CardText>
          I am a UK taxpayer and understand that if I pay less Income Tax and/or Capital Gains Tax than
          the amount of Gift Aid claimed on all my donations in that tax year it is my responsibility to
          pay any difference.
        </CardText>
        <CardTitleSmall>
          Please notify the charity if you:
        </CardTitleSmall>
        <CardText>
          • want to cancel this declaration
          <br />
          • change your name or home address
          <br />
          • no longer pay sufficient tax on your income and/or capital gainsIf you pay Income Tax at the higher or additional rate and want to receive the additional tax relief due to you, you must include all your Gift Aid donations on your Self-Assessment tax return or ask HM Revenue and Customs to adjust your tax code.
        </CardText>
      </Card>
    </Container>
  );
}

const Container = styled.div`
  background: rgba(245, 248, 251, 0.7);
  margin: 30px auto 50px;
  padding: 28px;
  display: flex;
  gap: 24px;
`;

const NumberInSquare = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  background-color: #E8F4FF;
  color: #2682DE;
`;

const Card = styled.div`


`;

const CardTitle = styled.h2`
  font-size: 20px;
  line-height: 20px;
  color: #102f54;
  font-weight: bold;
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
`;

const CardTitleSmall = styled.h3`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  margin-top: 12px;
  margin-bottom: 15px;
`;

const CardText = styled.p`
  font-size: 14px;
  line-height: 17px;
  margin: 0;
  max-width: 650px;
`;

const CardInner = styled.div`
  background: #E6E6E6;
  padding: 14px 35px 19px 26px;
  margin-top: 23px;
  margin-bottom: 26px;
  border-radius: 4px;
`;

const CardInnerTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
`;

const CardInnerCheckbox = styled.label`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 6px;
`;

const CheckboxInput = styled.input`
  margin-right: 18px;
`;

const CardList = styled.ul`
  margin-bottom: 14px;
  padding-left: 15px;
`;

const CardListItem = styled.li`
  margin-bottom: 5px;
`;
