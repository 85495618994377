import styled from 'styled-components';
import LogoImg from '../../img/logoColored.svg';

interface LogoProps {
  className?: string,
  onClick?: () => void
}

export default function Logo({ className, onClick }: LogoProps) {
  return (
    <LogoStyled onClick={onClick} alt="logo" className={className} />
  );
}

const LogoStyled = styled.img.attrs({ src: LogoImg })`
  width: 141px;
`;
