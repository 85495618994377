import styled from 'styled-components/macro';
import { RefObject, useRef } from 'react';
import LinkWithArrow from '../../../ui/elements/LinkWithArrow';
import {
  BuyerOverviewServiceType,
  GroupedOffersByProductType,
  CATEGORY_GROUPTYPE,
  ICategoryPublicType,
} from '../types';
import RecommendedDevicesBlock from './content-blocks/RecommendedDevicesBlock';
import GuidesAndServicesBlock from './content-blocks/GuidesAndServicesBlock';
import TopImage from '../../../img/buyer-overview/image1.png';
import TopImageMobile from '../../../img/buyer-overview/image1_mobile.png';
import Tabs from './content-blocks/Tabs/Tabs';
import WhatToConsiderBlock from './content-blocks/WhatToConsiderBlock';
import CategoriesBlock from './content-blocks/CategoriesBlock';
import IntelVisionBlock from './content-blocks/IntelVisionBlock';

interface BuyerOverviewProps {
  services: Array<BuyerOverviewServiceType>;
  groupedOffers: GroupedOffersByProductType;
  categories?: Array<ICategoryPublicType>;
  onDeviceClick: (deviceId: string) => void;
  onCategoryClickHandler: (categoryId: string) => void;
  selectedTab: string;
  onChangeSelectedTab: (tab: string) => void;
  forwardedTabsRef: RefObject<HTMLDivElement>;
}

export default function BuyerOverview({
  services,
  categories,
  onDeviceClick,
  groupedOffers,
  onCategoryClickHandler,
  selectedTab,
  onChangeSelectedTab,
  forwardedTabsRef,
}: BuyerOverviewProps) {
  const importantDevicesRef = useRef<HTMLDivElement>(null);

  const onLearnMoreClickHandler = () => {
    importantDevicesRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const tabs = categories?.filter(
    (c) => c.groupType === CATEGORY_GROUPTYPE.TOP
  );
  const bottomCategories = categories?.filter(
    (c) => c.groupType === CATEGORY_GROUPTYPE.BOTTOM
  );

  return (
    <Container>
      <HeaderBlock>
        <HeaderContent>
          <Title>Intel® & Devices For Education</Title>
          <HeaderBlockDescription>
            Schools incorporating technology in the classroom face daunting
            challenges—especially now that “the classroom” includes students’
            homes. Selecting the right computers for education can help teachers
            and students reach their goals. At Intel, we are committed to
            support the best experiences in teaching and learning through our
            technologies, from PCs delivering the best performance for students
            and teachers, to smart classroom technologies that boost engagement,
            to industry-leading network technologies to public and private cloud
            infrastructure.
          </HeaderBlockDescription>
          <BottomLink linkText="Learn More" onClick={onLearnMoreClickHandler} />
        </HeaderContent>
      </HeaderBlock>
      <TopImageContainer>
        <TopImageStyled src={TopImage} />
        <TopImageMobileStyled src={TopImageMobile} />
      </TopImageContainer>
      <IntelVisionBlock/>
      <ImportantDevicesBlock ref={importantDevicesRef}>
        <SubTitle style={{ marginBottom: 0 }}>
          Education Devices Are More Important Than Ever
        </SubTitle>
        <Description style={{ maxWidth: 800, marginBottom: 0, color: '#102F54' }}>
          <p>
            Remote and hybrid learning. Personalised education. Data science,
            coding, and content creation. And more. Education technology is
            central to the modern learning and teaching experience, impacting
            almost every aspect. For parents, teachers, and school staff, that
            makes selecting the right device more important than ever.
          </p>
          <p>
            With the right tools, students can practice real-world
            problem-solving throughout their educational experience. Doing so,
            they gain critical thinking and problem-solving skills that will be
            invaluable in an emerging job market that’s increasingly shaped by
            technology, automation, and artificial intelligence (AI).
          </p>
        </Description>
        <LinkWithArrow
          linkText="Learn More About Intel® Education"
          href="https://www.intel.co.uk/content/www/uk/en/education/intel-education.html"
          target="_blank"
        />
      </ImportantDevicesBlock>
      <WhatToConsiderBlock />
      <TabsContent ref={forwardedTabsRef}>
        <SubTitle id="sub-title-chose">
          Choose the Right Education Device{' '}
        </SubTitle>
        <Description>
          Find the right solution for your school by specifying below the
          intended user or technology.
        </Description>
        {tabs && tabs.length && (
          <Tabs
            tabs={tabs}
            selectedTab={selectedTab}
            onChangeSelectedTab={onChangeSelectedTab}
          />
        )}
      </TabsContent>
      <RecommendedDevicesBlock
        title="Intel-based Devices"
        groupedOffers={groupedOffers}
        onDeviceClick={onDeviceClick}
      />
      <GuidesAndServicesBlock services={services} />
      {bottomCategories && bottomCategories.length ? (
        <CategoriesBlock
          categories={bottomCategories}
          onViewClick={onCategoryClickHandler}
        />
      ) : null}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-bottom: 20px;

  @media (max-width: 660px) {
    gap: 20px;
  }
`;

const HeaderBlock = styled.div`
  padding: 24px;
  background-color: #F2F6FA;
  border-radius: 5px;

  @media (max-width: 660px) {
    padding: 24px 19px;
  }
`;

const Title = styled.h1`
  margin: 0;
  margin-bottom: 15px;
  font-weight: bold;
  font-size: 32px;
  line-height: 38px;
  color: #102F54;

  @media (max-width: 660px) {
    font-size: 28px;
    line-height: 34px;
  }
`;

const SubTitle = styled.h2`
  margin: 0;
  margin-bottom: 12px;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #102F54;

  @media (max-width: 660px) {
    font-size: 21px;
    line-height: 26px;
    text-align: center;
  }
`;

const Description = styled.p`
  margin: 0;
  margin-bottom: 32px;
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  @media (max-width: 660px) {
    font-size: 14px;
    line-height: 17px;
  }
`;

const HeaderBlockDescription = styled.p`
  margin: 0;
  margin-bottom: 21px;
  font-size: 16px;
  line-height: 21px;
  max-width: 1200px;
  color: #6A7F94;
  text-align: center;

  @media (max-width: 660px) {
    font-size: 14px;
    line-height: 21px;
  }
`;

const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 660px) {
    text-align: center;
  }
`;

const BottomLink = styled(LinkWithArrow)`
  svg {
    transform: rotate(90deg);
  }
`;

const TopImageContainer = styled.div`
  margin-bottom: 58px;

  // @media (max-width: 660px) {
  //   height: 900px;
  //   background-color: tomato;
  // }
`;

const TopImageStyled = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;

  @media (max-width: 660px) {
    display: none;
  }
`;

const TopImageMobileStyled = styled.img`
  display: none;
  width: 103%;
  height: 100%;
  object-fit: cover;

  @media (max-width: 660px) {
    display: block;
  }
`;

const TabsContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 660px) {
    text-align: center;
  }
`;

const ImportantDevicesBlock = styled.div`
  padding: 70px 150px;
  margin: 0 -150px -45px -150px;
  display: flex;
  flex-direction: column;
  gap: 35px;
  align-items: center;
  background-color: #F3F3F3;

  @media (max-width: 660px) {
    padding-top: 38px;
  }
`;
