import { useHistory, useParams } from 'react-router-dom';
import ROUTER_CONFIG from '../../../../router/router-config';
import SupplierLayout from '../../layout/SupplierLayoutWithBack';
import ViewOffer from './ViewOffer';

export default function ViewOfferPage() {
  const history = useHistory();
  const { offerId } = useParams<{ offerId: string }>();

  const onBack = () => history.push(ROUTER_CONFIG.SUPPLIER_OFFERS.getPath());
  const onEditClickHandler = () => history.push(ROUTER_CONFIG.SUPPLIER_EDIT_OFFER.getPath({ offerId }));
  const onViewOrdersClickHandler = () => history.push(ROUTER_CONFIG.SUPPLIER_ORDERS.getPath());

  return (
    <SupplierLayout onBack={onBack}>
      <ViewOffer onEditClickHandler={onEditClickHandler} onViewOrdersClickHandler={onViewOrdersClickHandler} />
    </SupplierLayout>
  );
}
