import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import ROUTER_CONFIG from '../../../router/router-config';
import Footer from '../../layout/Footer';
import Header from '../../layout/Header';
import Logo from '../../layout/Logo';
import IntelLogo from '../../layout/IntelLogo';
import BurgerIcon from '../../../img/icons/burger_icon.svg';
import BuyerHeaderContent from './BuyerHeaderContent';
import MobileMenu from './MobileMenu';
import { IsBuyerAuthenticated } from '../../../feature/buyerState';
import { useGetCategoriesQuery, useGetProfileInfoQuery } from '../../../api/services/buyerApi';
import { CATEGORY_GROUPTYPE } from '../types';

interface BuyerLayoutProps {
  children: React.ReactNode;
  className?: string;
}

export default function BuyerLayout({ children, className }: BuyerLayoutProps) {
  const { push } = useHistory();
  // const { pathname } = useLocation();
  const [search, setSearch] = useState('');
  const [isMobile, setIsMobile] = useState(window.innerWidth < 660);
  const [isMobileMenuOpened, setIsMobileMenuOpened] = useState(false);
  const isSignedIn = useSelector(IsBuyerAuthenticated);

  const isShowIntel = true;
  const { data: profileData } = useGetProfileInfoQuery();
  const { data: categories } = useGetCategoriesQuery();

  const categoriesLinks = categories?.reduce((acc: string[], item) => {
    if (item.groupType === CATEGORY_GROUPTYPE.TOP) {
      acc.push(item.name);
    }

    return acc;
  }, []) || [];

  useEffect(() => {
    const onResize = () => {
      if (window.innerWidth < 660) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  useEffect(() => {
    if (isMobileMenuOpened) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isMobileMenuOpened]);

  const onSearchSubmit = () => console.log(search);
  const onSignInClick = () => push(ROUTER_CONFIG.BUYER_LOGIN.getPath());
  const onRegisterClick = () => push(ROUTER_CONFIG.BUYER_REGISTER.getPath());

  const onDeviceTabClickHandler = (tab: string) => {
    setIsMobileMenuOpened(false);
    push({ pathname: ROUTER_CONFIG.BUYER_OVERVIEW.getPath(), search: `?selectedTab=${tab}` });
  };

  return (
    <Container className={className}>
      <div>
        <StyledHeader isIntelLogo={isShowIntel} logo={<IntelLogo />} loginLinkTo={ROUTER_CONFIG.BUYER_OVERVIEW.path}>
          {isMobile ? (
            <BurgetIconStyled onClick={() => setIsMobileMenuOpened(!isMobileMenuOpened)} />
          ) : (
            <BuyerHeaderContent
              searchValue={search}
              onChangeSearchValue={setSearch}
              onSearchSubmit={onSearchSubmit}
              isSignedIn={isSignedIn}
              onSignInClick={onSignInClick}
              onRegisterClick={onRegisterClick}
              accountName={profileData ? profileData.firstName : 'Account'}
            />
          )}
        </StyledHeader>
        <WrapperWithoutHeader isIntelLogo={isShowIntel}>
          <MobileMenu
            isOpen={isMobileMenuOpened}
            onDeviceTabClickHandler={onDeviceTabClickHandler}
            categoriesLinks={categoriesLinks}
          />
          <GreyLine>
            <StyledLogo />
          </GreyLine>
          <ContentWrapper className="buyer-content-wrapper">
            {children}
          </ContentWrapper>
        </WrapperWithoutHeader>
      </div>
      <Footer />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`;

const WrapperWithoutHeader = styled.div<{ isIntelLogo: boolean }>`
  /* padding-top: 78px; */
  padding-top: ${({ isIntelLogo }) => (isIntelLogo ? '66px' : '78px')};
  position: relative;

  @media (max-width: 660px) {
    padding-top: 66px;
  }
`;

const ContentWrapper = styled.div`
  padding: 48px 150px 70px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 660px) {
    padding: 40px 36px 79px;
  }
`;

const GreyLine = styled.div`
  /* width: 100%; */
  height: 45px;
  background: #FAFBFC;
  display: flex;
  justify-content: flex-end;
  padding-right: 150px;

  @media (max-width: 660px) {
    padding-right: 36px;
  }
`;

const StyledLogo = styled(Logo)`
  width: 91px;
`;

const BurgetIconStyled = styled.img.attrs({ src: BurgerIcon })`
  cursor: pointer;
`;

const StyledHeader = styled(Header)<{ isIntelLogo: boolean }>`
  position: fixed;
  z-index: 20;

  .header__wrapper {
    padding: ${({ isIntelLogo }) => isIntelLogo && '0 150px'};
  }

  @media (max-width: 660px) {
    .header__wrapper {
      padding: 0 36px;
    }
  }
`;
