import { KeyboardEvent } from 'react';
import styled from 'styled-components/macro';
import { ChangeEvent } from 'react';
import Input from '../../../../../ui/forms/Input';

import ArrowIcon from '../../../../../img/icons/arrow-icon-blue.svg';
import { showPriceDigits } from '../../../../../utils/formats';

const invalidChars = ['-', '+', 'e'];

interface StudentsFormProps {
  students: Array<string>;
  onIncButtonClickHandler: () => void;
  onDecButtonClickHandler: () => void;
  onChangeStudents: (newStudents: Array<string>) => void;
  isIdRequired: boolean;
  initialDonationAmount: number;
  recurringDonationAmount: number;
}

export default function StudentsForm({
  students, onChangeStudents, onIncButtonClickHandler, onDecButtonClickHandler, isIdRequired, initialDonationAmount, recurringDonationAmount
}: StudentsFormProps) {
  const onChangeHandler = (key: number) => (e: ChangeEvent<HTMLInputElement>) => {
    const newValues = [
      ...students.slice(0, key),
      e.target.value,
      ...students.slice(key + 1),
    ];

    onChangeStudents(newValues);
  };

  const blockInvalidChars = (e: KeyboardEvent<HTMLInputElement>) => {
    if (invalidChars.includes(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <Container>
      <RowItem>
        <NumberInSquare>1</NumberInSquare>
        <RowContentWrapper>
          <Title>How many students would you like to register for?</Title>
          <RowContent>
            <LabelBold>Number of Students</LabelBold>
            <Row>
              <NumberInputBlock>
                <NumberInput>
                  <NumberInputDisplay>{students.length}</NumberInputDisplay>
                  <NumberInputControls>
                    <IncButton onClick={onIncButtonClickHandler} />
                    <DecButton onClick={onDecButtonClickHandler} />
                  </NumberInputControls>
                </NumberInput>
              </NumberInputBlock>
              <InfoBlock>
                <InfoBlockItem>
                  <Text>Your Initial Donation</Text>
                  <BoldText>{showPriceDigits(initialDonationAmount * students.length)}</BoldText>
                </InfoBlockItem>
                <InfoBlockItem>
                  <Text>Your Recurring Monthly Donation</Text>
                  <BoldText>{showPriceDigits(recurringDonationAmount * students.length)}</BoldText>
                </InfoBlockItem>
              </InfoBlock>
            </Row>
          </RowContent>
        </RowContentWrapper>
      </RowItem>
      {isIdRequired && (
      <RowItem>
        <NumberInSquare>2</NumberInSquare>
        <StudentInputsRowWrapper>
          <Title>Please enter the student’s ID(s)</Title>
          <GrayText>This helps the school link the donation to the relevant student</GrayText>
          <Inputs>
            {students.map((s, i) => (
              <InputBlock key={i}>
                <InputLabel>{`Student #${i + 1} ID`}</InputLabel>
                <StyledTextInput onKeyDown={blockInvalidChars} type="number" value={s} onChange={onChangeHandler(i)} />
              </InputBlock>
            ))}
          </Inputs>
        </StudentInputsRowWrapper>
      </RowItem>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 19px;
  padding: 28px;
  background: rgba(245, 248, 251, 0.7);
`;

const RowItem = styled.div`
  display: flex;
  gap: 24px;
  align-items: flex-start;
`;

const NumberInSquare = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  background-color: #E8F4FF;
  color: #2682DE;
`;

const RowContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 19px;
`;

const Title = styled.h2`
  font-size: 20px;
  line-height: 20px;
  color: #102f54;
  font-weight: bold;
  margin: 0;
  padding: 0;
`;

const RowContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  gap: 44px;
`;

const NumberInputBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const LabelBold = styled.span`
  font-size: 12px;
  color: #102F54;
  font-weight: bold;
`;

const Text = styled.span`
  font-size: 14px;
`;

const BoldText = styled(Text)`
  font-weight: bold;
`;

const GrayText = styled(Text)`
  color: #6B7A87;
  margin-top: 5px;
`;

const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px 20px;
  min-width: 350px;
  background-color: #F2F2F2;
  border: 1px solid #EBECEF;
  border-radius: 4px;
`;

const InfoBlockItem = styled.div`
  display: flex;
  justify-content: space-between;
`;

const NumberInput = styled.div`
  display: flex;
  max-width: 150px;
  border-radius: 4px;
  overflow: hidden;
`;

const NumberInputDisplay = styled.div`
  padding: 8px 50px;
  color: #3F5D8D;
  font-weight: bold;
  background-color: #fff;
`;

const NumberInputControls = styled.div`
  display: flex;
  flex-direction: column;
`;

const IncButton = styled.div`
  background: url(${ArrowIcon}) no-repeat center center;
  background-color: #F2F2F2;
  width: 31px;
  height: 19px;
  cursor: pointer;

  &:hover {
    background-color: #BDBDBD;
  }
`;

const DecButton = styled(IncButton)`
  transform: rotate(180deg);
`;

const StudentInputsRowWrapper = styled(RowContentWrapper)`
  gap: unset;
`;

const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 23px;
`;

const InputBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
`;

const InputLabel = styled(LabelBold)`
  &::after {
    content: '*';
    padding-left: 2px;
    color: red;
  }
`;

const StyledTextInput = styled(Input)``;
