import styled from 'styled-components/macro';
import { useFormik } from 'formik';
import TextArea from 'antd/lib/input/TextArea';
import { useState } from 'react';
import { Switch } from 'antd';
import { ButtonDark } from '../../../../ui/Button';
import Input from '../../../../ui/forms/Input';
import { renderFormConfig } from '../../../../utils/form';
import { CampaignFormFields } from '../../types';
import { campaignSchema } from '../../../../project-types/campaign/schemas';
import { DonationAmout, SingleDonationAmountHack } from './DonationAmout';
import EmailRecipients from './EmailRecipients';
import SwitchCheck from '../../../../ui/forms/Switch';
import DatePicker from '../../../../ui/forms/DatePicker';
import InputWrap from '../../../../ui/forms/InputWrap';
import { COLLECTION_PERRIODS } from '../../../../project-types/campaign/types';
import { RadioGroup, RadioItem } from '../../../../ui/forms/Radio';

import NotifyIcon from '../../../../img/icons/VerificationIcon.svg';

interface CreateCampaignFormTypes {
  onSubmit: (schoolFields: CampaignFormFields) => void
}

const InputStyled = styled(Input)`
  width: 100%;
  max-width: 421px;
`;

const InputShortStyled = styled(Input)`
  width: 100%;
  max-width: 312px;
  height: 47px !important;
`;

const InputTextarea = styled(TextArea)`
  width: 100%;
  max-width: 421px !important;
  height: 165px;
`;

const DatePickerStyled = styled(DatePicker)`
  width: 100%;
  max-width: 312px;
  border-radius: 4px;
  padding: 14px 14px;
`;

export default function CreateCampaignForm({ onSubmit }: CreateCampaignFormTypes) {
  const formik = useFormik<CampaignFormFields>({
    initialValues: {
      name: '',
      description: '',
      initialDonation: 0,
      // otherAmounts: [],
      donationAmounts: [],
      allowCustomAmounts: false,
      differentInitalDonation: false,
      collectionPeriod: COLLECTION_PERRIODS.monthly,
      numberOfCollections: 1,
      startDate: '',
      isStudentsRefRequired: false,
      collectionAttempts: 0,
      notificationsEmails: [],
    },
    validationSchema: campaignSchema,
    onSubmit: (values) => {
      // debugger;
      // const { agree, confirmPassword, ...rows } = values;

      // onSubmit(values);
      onSubmit(values);
    },
  });

  console.log(formik.values);

  const [reattempt, setReattempt] = useState(false);
  const [continiousCollection, setContiniousCollection] = useState<boolean>(false);

  const onReattemptChange = (value: boolean) => {
    if (value) {
      formik.setFieldValue('collectionAttempts', 2);
    } else {
      formik.setFieldValue('collectionAttempts', undefined);
    }
    setReattempt(value);
  };

  return (
    <FormStyled onSubmit={formik.handleSubmit}>
      <InputsGroup>
        <HeaderBlock>
          <StepNumber>1</StepNumber>
          <Header>Scheme Details</Header>
        </HeaderBlock>
        {renderFormConfig([{
          title: 'Enter Campaign Name',
          name: 'name',
          component: InputStyled,
          isRequired: true,
        },
        {
          title: 'Campaign Description',
          name: 'description',
          component: InputTextarea,
          isRequired: true,
        },
        {
          title: 'Campaign Start Date',
          name: 'startDate',
          component: DatePickerStyled,
          isRequired: true,
        },
        {
          title: 'Is the donor required to enter a student reference ID?',
          name: 'isStudentsRefRequired',
          isRequired: true,
          component: SwitchCheck,
        },
        ], formik)}
      </InputsGroup>
      <InputsGroup>
        <Divider />
        <HeaderBlock>
          <StepNumber>2</StepNumber>
          <Header>Collection Details</Header>
        </HeaderBlock>
        <InputWrap title="Collection Frequency" isRequired>
          <RadioGroup value={formik.values.collectionPeriod} onChange={(e) => formik.setFieldValue('collectionPeriod', e.target.value)}>
            <RadioItem value="monthly">Monthly</RadioItem>
            <RadioItem value="quarterly">Quarterly</RadioItem>
            <RadioItem value="annualy">Annual</RadioItem>
            <RadioItem value="oneoff">One-off</RadioItem>
          </RadioGroup>
        </InputWrap>
        <Divider />
      </InputsGroup>

      {formik.values.collectionPeriod === 'oneoff' ? (
        <>
          {
            renderFormConfig([{
              title: 'Recurring donation amount',
              name: 'initialDonation',
              isRequired: true,
              component: DonationAmout,
              props: {
              },

            },
            // {
            //   title: 'Allow custom donation amounts',
            //   name: 'allowCustomAmounts',
            //   component: SwitchCheck,
            // },
            ], formik)
          }
          {/* <Divider /> */}
        </>
      )

        : (

          <>

            <InputsGroup>
              <InputWrap isRequired title="Initial donation amount">
                <RadioGroup value={formik.values.differentInitalDonation} onChange={(e) => formik.setFieldValue('differentInitalDonation', e.target.value)}>
                  <RadioItem value={false}>
                    The same as
                    {' '}
                    {formik.values.collectionPeriod !== COLLECTION_PERRIODS.annualy ? formik.values.collectionPeriod : 'annual'}
                    {' '}
                    payments
                  </RadioItem>
                  <RadioItem value>Initial donation amount is different</RadioItem>
                </RadioGroup>
              </InputWrap>
              {formik.values.differentInitalDonation && renderFormConfig([{
                title: 'Set Initial donation amount',
                name: 'initialDonation',
                component: DonationAmout,
                isRequired: true,
                props: {
                  type: 'number',
                },
              }], formik)}
              {
                renderFormConfig([{
                  title: 'Recurring donation amounts',
                  name: 'donationAmounts',
                  isRequired: true,
                  component: SingleDonationAmountHack,
                },
                {
                  title: 'Allow custom donation amounts',
                  name: 'allowCustomAmounts',
                  component: SwitchCheck,
                },
                ], formik)
              }
              <Divider />
            </InputsGroup>
            <InputsGroup>
              <RadioGroupWithLabel>
                <Label>Collection Period</Label>
                <RadioGroup
                  value={continiousCollection}
                  onChange={(e) => {
                    setContiniousCollection(e.target.value);
                    if (!e.target.value) {
                      formik.setFieldValue('numberOfCollections', 1);
                    } else {
                      formik.setFieldValue('numberOfCollections', undefined);
                    }
                  }}
                >
                  <RadioItem value>Continuous collections</RadioItem>
                  <RadioItem value={false}>Fixed number of collections</RadioItem>
                </RadioGroup>
              </RadioGroupWithLabel>
            </InputsGroup>
            {!continiousCollection && (
              renderFormConfig([
                {
                  title: 'The number of collections to be made',
                  name: 'numberOfCollections',
                  component: InputShortStyled,
                  props: {
                    type: 'number',
                    min: 1,
                  },
                  isRequired: true,
                },
              ], formik)
            )}

          </>
        )}
      <Divider />
      <InputsGroup>
        <HeaderBlock>
          <StepNumber>3</StepNumber>
          <Header>Collection Rules</Header>
        </HeaderBlock>
        <InputWrap
          style={{
            flexDirection: 'row-reverse', justifyContent: 'flex-end', alignItems: 'center', gap: '10px',
          }}
          title="Re-attempt collection after an unsuccessful collection"
        >
          <Switch size="small" checked={reattempt} onChange={onReattemptChange} />
        </InputWrap>
        <Message>
          <Icon alt="" src={NotifyIcon} />
          <MessageText>
            Collections will be re-attempted on the next available collection date, typically this is two weeks after the collection attempt.
          </MessageText>
        </Message>
        <Divider />
      </InputsGroup>
      {reattempt && (
      <InputsGroup>
        <RadioGroupWithLabel>
          <Label>Select from the following rules</Label>
          <RadioGroup
            onChange={(e) => formik.setFieldValue('collectionAttempts', e.target.value)}
            value={formik.values.collectionAttempts}
          >
            <VerticalRadio>
              <RadioItem value={1}>After 1 unsuccessful collection attempts freeze donor account</RadioItem>
              <RadioItem value={2}>After 2 unsuccessful collection attempts freeze donor account (Default)</RadioItem>
              <RadioItem value={3}>After 3 unsuccessful collection attempts freeze donor account</RadioItem>
            </VerticalRadio>
          </RadioGroup>
        </RadioGroupWithLabel>
        <Divider />
      </InputsGroup>
      )}
      <InputsGroup>
        <HeaderBlock>
          <StepNumber>4</StepNumber>
          <Header>Collection Notifications</Header>
        </HeaderBlock>
        <LonelyMessageText>After each collection date for a campaign, a email report will be sent to your accounts email address. You can enter additional email addresses below to be additional recipients of the report.</LonelyMessageText>
        <EmailRecipients name="notificationsEmails" setFieldValue={formik.setFieldValue} />
        <Divider />
      </InputsGroup>
      <ButtonsGroup>
        <SubmitStyled type="submit">Create Campaign</SubmitStyled>
      </ButtonsGroup>
    </FormStyled>
  );
}

const FormStyled = styled.form`
  width: 100%;
  margin-top: 36px;
`;

const ButtonsGroup = styled.div`
  margin: 45px 0;
`;

const SubmitStyled = styled(ButtonDark)`
  padding: 13px 50px;
`;

const InputsGroup = styled.div`
  /* margin-bottom: 75px; */
`;

const HeaderBlock = styled.div`
  display: flex;
  gap: 12px;
  margin-bottom: 29px;
  align-items: center;
`;

const Header = styled.h2`
  font-size: 20px;
  margin: 0;
  font-weight: bold;
`;

const StepNumber = styled.div`
  box-sizing: border-box;
  font-size: 14px;
  color: #388DE5;
  background-color: #E8F4FF;
  padding: 4px 7px;
  font-weight: bold;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  border-top: 1px solid #D5DCE5;
  margin: 29px 0;
`;

const Label = styled.span`
  font-size: 16px;
  color: #1F1F1F;
  margin-bottom: 4px;

  &::after {
    content: '*';
    padding-left: 2px;
    color: red;
  }
`;

const RadioGroupWithLabel = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;

const Message = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const Icon = styled.img`
  width: 14px;
  height: 14px;
  margin: 0;
`;

const MessageText = styled.p`
  font-size: 12px;
  margin: 0;
  width: 100%;
  max-width: 421px;
`;

const LonelyMessageText = styled(MessageText)`
  margin-bottom: 30px;
`;

const VerticalRadio = styled.div`
  display: flex;
  flex-direction: column;
`;
