import { useHistory } from 'react-router';
import { useState } from 'react';
import { showPriceDigits } from '../../../../utils/formats';
import DonorLayoutWithTabs from '../../layout/DonorLayoutWithTabs';
import { RegistredDevicesTradeinTableRow } from '../../../school/types';
import DeviceTradeinDetails from '../../../school/device-tradein/details-page/DeviceTradeinDetails';
import ROUTER_CONFIG from '../../../../router/router-config';

export default function DeviceTradeinDetailsPage() {
  const [selectedRows, setSelectedRows] = useState<Array<string>>([]);
  const { push } = useHistory();

  console.log(selectedRows);

  const tableData: Array<RegistredDevicesTradeinTableRow> = [
    {
      key: '1',
      date: '21 Nov 2021',
      donorName: 'John Doe',
      giftAidOptIn: 'Yes',
      quantity: 10,
      totalValuation: showPriceDigits(40),
    },
    {
      key: '2',
      date: '22 Nov 2021',
      donorName: 'Tristen Cannon',
      giftAidOptIn: 'Yes',
      quantity: 3,
      totalValuation: showPriceDigits(122),
    },
    {
      key: '3',
      date: '23 Nov 2021',
      donorName: 'Delaney Kline',
      giftAidOptIn: 'Yes',
      quantity: 22,
      totalValuation: showPriceDigits(333),
    },
  ];

  const onBackClickHandler = () => push(ROUTER_CONFIG.DONOR_DEVICE_TRADEIN.getPath());

  return (
    <DonorLayoutWithTabs>
      <DeviceTradeinDetails onBackClickHandler={onBackClickHandler} onSelectRows={setSelectedRows} tableData={tableData} />
    </DonorLayoutWithTabs>
  );
}
