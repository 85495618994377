import styled from 'styled-components/macro';
import LinkWithArrow from '../../../../ui/elements/LinkWithArrow';
import { ICategoryPublicType } from '../../types';

interface CategoriesBlockProps {
  categories: Array<ICategoryPublicType>;
  onViewClick: (categoryId: string) => void;
}

export default function CategoriesBlock({ categories, onViewClick }: CategoriesBlockProps) {
  return (
    <Container>
      <Title>Product Categories</Title>
      <Content>
        {categories.map((category) => (
          <ContentItem key={category.name}>
            <ItemInfo>
              <ItemTitle>{category.name}</ItemTitle>
              <LinkWithArrow linkText="View devices" onClick={() => onViewClick(category._id)} />
            </ItemInfo>
            <ItemImageContainer>
              {category.icons && (
                <ItemImage src={category.icons} />
              )}
            </ItemImageContainer>
          </ContentItem>
        ))}
      </Content>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 22px;
`;

const Title = styled.h2`
  margin: 0;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #102F54;
`;

const Content = styled.div`
  display: flex;
  gap: 16px;

  @media (max-width: 660px) {
    flex-direction: column;
  }
`;

const ContentItem = styled.div`
  box-sizing: border-box;
  padding: 22px 25px;
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: space-between;
  width: 100%;
  border-radius: 5px;
  background-color: #FAFBFC;
`;

const ItemInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const ItemTitle = styled.span`
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #102F54;
  margin-bottom: 15px;
`;

const ItemImageContainer = styled.div`
  width: 160px;
  height: 90px;
  background-color: #fff;

  @media (max-width: 660px) {
    width: 110px;
    height: 80px;
  }
`;

const ItemImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
