import styled from 'styled-components/macro';
import { useFormik } from 'formik';
import { renderFormConfig, FormConfig } from '../../../../utils/form';
import Input from '../../../../ui/forms/Input';
import PostcodeInput from '../../../school/create-school/PostcodeInput';
import FindYourAddress from '../../../../ui/forms/FindYourAddress';
import { ButtonDark } from '../../../../ui/Button';
import { updateBuyerOrganisationDetailsSchema } from '../../buyer-schemas';
import { UpdateBuyerOrganisationDetails } from '../../types';

const StyledInput = styled(Input)`
  width: 100%;
  max-width: 420px;
  height: unset;
  padding: 10.5px 11.5px;

  &:disabled {
    background-color: #F5F5F5;
  }
`;

const StyledPostcodeInput = styled(PostcodeInput)`
  width: 100%;
  max-width: 420px;
  height: unset;
  padding: 10.5px 11.5px;
`;

const disabledForm: FormConfig = [
  {
    title: 'School Name',
    name: 'schoolName',
    component: StyledInput,
    props: {
      disabled: true,
    },
  },
  {
    title: 'URN',
    name: 'urn',
    component: StyledInput,
    props: {
      disabled: true,
    },
  },
  {
    title: 'Organisation Type',
    name: 'organisationType',
    component: StyledInput,
    props: {
      disabled: true,
    },
  },
];

const formConfig1: FormConfig = [
  {
    title: 'Address Line 1',
    name: 'adress1',
    component: StyledInput,
  },
  {
    title: 'Address Line 2',
    name: 'adress2',
    component: StyledInput,
  },
  {
    title: 'Address Line 3',
    name: 'adress3',
    component: StyledInput,
  },
  {
    title: 'Town',
    name: 'town',
    component: StyledInput,
  },
  {
    title: 'Country',
    name: 'country',
    component: StyledInput,
  },
  {
    title: 'Telephone Number',
    name: 'phoneNumber',
    component: StyledInput,
  },
  {
    title: 'Postcode',
    name: 'postcode',
    component: StyledPostcodeInput,
  },
];

interface OrganisationDetailsTabProps {
  onSubmit: (values: UpdateBuyerOrganisationDetails) => void;
}

export default function OrganisationDetailsTab({ onSubmit }: OrganisationDetailsTabProps) {
  const formik = useFormik({
    initialValues: {
      schoolName: 'Mona Vale School',
      urn: '1252423422',
      organisationType: 'Maintained School',
      adress1: '',
      adress2: '',
      adress3: '',
      town: '',
      country: '',
      postcode: '',
      phoneNumber: '',
    },
    validationSchema: updateBuyerOrganisationDetailsSchema,
    onSubmit: (values) => onSubmit(values),
  });

  return (
    <Container onSubmit={formik.handleSubmit}>
      {renderFormConfig(disabledForm, formik)}
      <PleaseContactBlock>
        Please contact
        {' '}
        <CustomLink>Support</CustomLink>
        {' '}
        if you require this information is incorrect and needs to be changed.
      </PleaseContactBlock>
      <Divider />
      {renderFormConfig(formConfig1, formik)}
      <FindYourAddress
        postCode={formik.values.postcode}
        formikInstance={formik}
      />
      <Divider />
      <StyledButtonDark type="submit">Save</StyledButtonDark>
    </Container>
  );
}

const Container = styled.form``;

const PleaseContactBlock = styled.div`
  box-sizing: border-box;
  padding: 12px;
  background-color: rgba(193, 230, 255, 0.2);
  margin-top: 15px;
  margin-bottom: 26px;
  max-width: 420px;
`;

const CustomLink = styled.span`
  color: #2682DE;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const Divider = styled.div`
  width: 100%;
  max-width: 516px;
  height: 1px;
  border-bottom: 1px solid #D5DCE5;
  margin: 40px 0;
`;

const StyledButtonDark = styled(ButtonDark)`
  padding: 14px 88px;
`;
