import * as yup from 'yup';
import { phoneRegExp } from '../../utils/phoneRegExp';
import {
  BuyerCreateAddressType, BuyerCreateLeaseOrderType, BuyerCreateOutrightPurchaseOrderType, BuyerInviteUserType, BuyerRegisterType, BuyerSecureCheckoutFormType, BuyerUpdateBillingDetails, OmitProductId, ORDER_TYPES, UpdateBuyerOrganisationDetails, UpdateBuyerPersonalDetails,
} from './types';

export const buyerRegisterSchema: yup.SchemaOf<BuyerRegisterType> = yup.object({
  school: yup.string().required(),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  workEmail: yup
    .string()
    .email('email must be a valid email')
    .defined('email must be a valid email'),
  agree: yup.boolean().required().oneOf([true]),
  password: yup.string().max(256).min(5).defined(),
  confirmPassword: yup.string().required()
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
  phoneNumber: yup.string().matches(phoneRegExp, 'Phone number is not valid').required(),
  jobTitle: yup.string().optional(),
  preferredReseller: yup.string().optional(),
  isStartingParentalContributionScheme: yup.boolean().optional(),
  percentageOfTheDeviceCostsForParentsToContribute: yup.number().optional(),
  preferredPurchaseMethod: yup.mixed<ORDER_TYPES>().oneOf(Object.values(ORDER_TYPES)).optional(),
  devicesToPurchaseCount: yup.number().optional(),
  schoolAddress: yup.string().required(),
});

export const updateBuyerOrganisationDetailsSchema: yup.SchemaOf<UpdateBuyerOrganisationDetails> = yup.object({
  adress1: yup.string().required(),
  adress2: yup.string().optional(),
  adress3: yup.string().optional(),
  town: yup.string().required(),
  country: yup.string().required(),
  postcode: yup.string().required(),
  phoneNumber: yup.string().required(),
});

export const createBuyerAddressSchema: yup.SchemaOf<BuyerCreateAddressType> = yup.object({
  adress1: yup.string().required(),
  adress2: yup.string().optional(),
  adress3: yup.string().optional(),
  town: yup.string().required(),
  country: yup.string().required(),
  postcode: yup.string().required(),
});

export const buyerInviteUserSchema: yup.SchemaOf<BuyerInviteUserType> = yup.object({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().required(),
});

export const updateBuyerPersonalDetailsSchema: yup.SchemaOf<UpdateBuyerPersonalDetails> = yup.object({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
});

export const updateBuyerBillingDetailsSchema: yup.SchemaOf<BuyerUpdateBillingDetails> = yup.object({
  accountNumber: yup.string().required(),
  branchSortCode1: yup.string().required(),
  branchSortCode2: yup.string().required(),
  branchSortCode3: yup.string().required(),
  bankAccountName: yup.string().required(),
});

export const createBuyerLeaseOrderSchema: yup.SchemaOf<Omit<BuyerCreateLeaseOrderType, 'productId' | 'termLength' | 'orderType'>> = yup.object({
  paymentSchedule: yup.string().required(),
  termLength: yup.string().required(),
  quantity: yup.number().required(),
  orderType: yup.string().required(),
});

export const createBuyerOutrightPurchaseOrderSchema: yup.SchemaOf<OmitProductId<BuyerCreateOutrightPurchaseOrderType>> = yup.object({
  quantity: yup.number().required(),
  includeDeviceInsurance: yup.boolean().required(),
  orderType: yup.mixed<ORDER_TYPES>().oneOf(Object.values(ORDER_TYPES)).required(),
});

export const buyerSecureCheckoutSchema: yup.SchemaOf<BuyerSecureCheckoutFormType> = yup.object({
  deliveryOption: yup.string().required(),
  attentionOf: yup.string().required(),
  deliveryAddress: yup.string().required(),
  deliveryNotes: yup.string().optional(),
  contactName: yup.string().required(),
  contactEmail: yup
    .string()
    .email('email must be a valid email')
    .defined('email must be a valid email'),
  invoiceAddress: yup.string().required(),
});
