import { useHistory, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { message } from 'antd';
import { selectCampaignInvite, JoinDonorCampaign } from '../../../../feature/campaignState';
import { getUserProfile } from '../../../../feature/userState';
import DirectPay from './DirectPay';
import { ApiCreateDonor } from '../../../../project-types/donor/api-types';
import ROUTER_CONFIG from '../../../../router/router-config';

export default function DirectPayPage() {
  const { location, push } = useHistory();
  const { campaignId, inviteCode } = useParams<{ campaignId: string; inviteCode: string; }>();
  const donationAmount = useSelector(selectCampaignInvite);
  const profile = useSelector(getUserProfile);
  // TODO fix it
  const state = location.state as ApiCreateDonor['successResponse'];

  const onSubmitHandler = async () => {
    debugger;
    if (profile && profile.donorProfile) {
      try {
        const data = await JoinDonorCampaign(campaignId, inviteCode, donationAmount.donationAmount, donationAmount.students);
        if (data) {
          push(ROUTER_CONFIG.DONOR_COMPLETE.getPath());
        }
      } catch (error) {
        console.log(error);
        message.error('Something gone wrong');
      }
    } else {
      push(ROUTER_CONFIG.DONOR_COMPLETE.getPath());
    }
  };
  const onEditDetailsHandler = () => push(ROUTER_CONFIG.DONOR_INVITE_ACCEPT.getPath({ campaignId, inviteCode }));
  const onCancelHandler = () => push(ROUTER_CONFIG.DONOR_LOGIN.getPath());

  return (
    <>
      {state && state.donorProfile && <DirectPay onSubmitHandler={onSubmitHandler} onCancelHandler={onCancelHandler} onEditDetailsHandler={onEditDetailsHandler} donorFields={state.donorProfile} />}
    </>
  );
}
