import styled from 'styled-components/macro';

const Tag = styled.div<{ color: string, bgColor: string }>`
  padding: 10px 14px;
  color: ${({ color }) => color};
  background-color: ${({ bgColor }) => bgColor};
  font-weight: bold;
  border-radius: 5px;
  font-size: 14px;
`;

export default Tag;
