import styled from 'styled-components/macro';
import { formatDateDDMMMYYYY } from '../../../../ui/DateFormatMethods';
import ProductRowItem from '../../../../ui/elements/ProductRowItem';
import { showPriceDigits } from '../../../../utils/formats';
import Tag from '../../../../ui/elements/Tag';
import { CalculatedPricesType } from '../../calculateOrderPrice';
import {
  IAccessorySelected,
  IBuyerOrderPublic, IReseller, ISellerOfferPublicType, ORDER_TYPES,
} from '../../types';

interface OrderOverviewInfoProps {
  calculations: CalculatedPricesType;
  order: IBuyerOrderPublic;
  offer: ISellerOfferPublicType;
  reseller?: IReseller;
}

export default function OrderOverviewInfo({
  calculations, order, offer, reseller,
}: OrderOverviewInfoProps) {
  const { product } = offer.offer;

  const productLeasePrice = `${showPriceDigits(offer.price * (order?.lease?.rate || 1))}/${order.paymentSchedule}`;
  const purchasePrice = showPriceDigits(offer.price);

  const getPrice = (accessory: IAccessorySelected) => {
    if (order?.orderType !== ORDER_TYPES['Outright Purchase']) {
      return `${showPriceDigits(accessory.price * (order?.lease?.rate || 1))}/${order?.paymentSchedule}`;
    }

    return showPriceDigits(accessory.price);
  };

  return (
    <Container>
      <TagsBlock>
        <Tag color="#2682DE" bgColor="#E8F4FF">{`PO#${order._id}`}</Tag>
        <Tag color="#1B9D75" bgColor="#F0F7F5">
          PLACED:
          {' '}
          {formatDateDDMMMYYYY(new Date())}
        </Tag>
      </TagsBlock>
      <Divider />
      <ProductRowItem
        image={`https://image-sds-5pjg3ckx7a-uc.a.run.app/${product.imageUrl}`}
        productName={product.name}
        amount={order.quantity}
        soldBy={order.reseller.name}
        price={order.orderType === ORDER_TYPES['Outright Purchase'] ? purchasePrice : productLeasePrice}
        onTermsClick={() => 5}
      />
      {order.services?.map((service) => (
        <ProductRowItem
          key={service.serviceName}
          image={service.serviceIcon}
          productName={service.serviceName}
          amount={order.quantity}
          soldBy={order.reseller.name}
          price={`${showPriceDigits(service.price)}/unit`}
          onTermsClick={() => 5}
        />
      ))}
      {order.accessories?.map((acc) => {
        const { product: accessory } = acc.offer;

        return (
          <ProductRowItem
            key={accessory.name}
            image={accessory.name}
            productName={accessory.name}
            amount={acc.quantity}
            soldBy={order.reseller.name}
            price={getPrice(acc)}
            onTermsClick={() => 5}
          />
        );
      })}
      <Divider />
      <DeliveryBlock>
        <BoldText>{order.deliveryOption}</BoldText>
        <BoldText>
          {showPriceDigits(reseller?.deliveryPrice || 0)}
        </BoldText>
      </DeliveryBlock>
      <Divider />
      <PriceBlock>
        <PriceRow>
          <FlexColumnItem>
            <BoldText>Subtotal (Ex-VAT)</BoldText>
            {order.orderType !== ORDER_TYPES['Outright Purchase'] && (
              <GreyBoldText style={{ marginTop: 3 }}>{`Billed ${order.paymentSchedule}`}</GreyBoldText>
            )}
          </FlexColumnItem>
          <BoldText>{calculations.totalExVat}</BoldText>
        </PriceRow>
        <PriceRow>
          <FlexColumnItem>
            <BoldText>VAT (20%)</BoldText>
            {order.orderType !== ORDER_TYPES['Outright Purchase'] && (
              <GreyBoldText style={{ marginTop: 3 }}>{`Billed ${order.paymentSchedule}`}</GreyBoldText>
            )}
          </FlexColumnItem>
          <BoldText>{calculations.totalVat}</BoldText>
        </PriceRow>
        <PriceRow>
          <FlexColumnItem>
            <BoldText>Total (Inc-VAT)</BoldText>
            {order.orderType !== ORDER_TYPES['Outright Purchase'] && (
              <GreyBoldText style={{ marginTop: 3 }}>{`Billed ${order.paymentSchedule}`}</GreyBoldText>
            )}
          </FlexColumnItem>
          <RedBoldText>{calculations.totalWithVat}</RedBoldText>
        </PriceRow>
      </PriceBlock>
      <Divider />
      <SummaryInfoBlock>
        <SummaryInfoItem>
          <GreyBoldText>Delivery Address</GreyBoldText>
          <Text>{order.deliveryAddress}</Text>
        </SummaryInfoItem>
        <SummaryInfoItem>
          <GreyBoldText>Invoice Address</GreyBoldText>
          <Text>{order.deliveryAddress}</Text>
        </SummaryInfoItem>
        <SummaryInfoItem>
          <GreyBoldText>Payment Method</GreyBoldText>
          <Text>{order.orderType}</Text>
        </SummaryInfoItem>
      </SummaryInfoBlock>
    </Container>
  );
}

const Container = styled.div`
  padding: 12px 20px;
  border: 1px solid #F9F9F9;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const TagsBlock = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #D5DCE5;
`;

const DeliveryBlock = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Text = styled.span`
  font-size: 14px;
  line-height: 14px;
  color: #102f54;
`;

const BoldText = styled(Text)`
  font-weight: bold;
`;

const RedBoldText = styled(BoldText)`
  color: #DE2644;
`;

const GreyBoldText = styled(BoldText)`
  color: #6B7A87;
  margin-bottom: 6px;
`;

const PriceBlock = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  gap: 14px;
`;

const PriceRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 250px;
`;

const SummaryInfoBlock = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FlexColumnItem = styled.div`
  display: flex;
  flex-direction: column;
`;

const SummaryInfoItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 120px;
`;
