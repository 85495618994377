import { useFormik } from 'formik';
import styled from 'styled-components/macro';
import { renderFormConfig } from '../../../../utils/form';
import Input from '../../../../ui/forms/Input';
import { ButtonDark } from '../../../../ui/Button';
import { IBuyerProfilePublic, UpdateBuyerPersonalDetails } from '../../types';
import { updateBuyerPersonalDetailsSchema } from '../../buyer-schemas';
import InputWrap from '../../../../ui/forms/InputWrap';

interface PersonalDetailsTabProps {
  onSubmit: (values: UpdateBuyerPersonalDetails) => void;
  onChangePasswordClickHandler: () => void;
  profileData: IBuyerProfilePublic;
}

export default function PersonalDetailsTab({ onSubmit, onChangePasswordClickHandler, profileData }: PersonalDetailsTabProps) {
  const formik = useFormik({
    initialValues: {
      firstName: profileData.firstName,
      lastName: profileData.lastName,
    },
    validationSchema: updateBuyerPersonalDetailsSchema,
    onSubmit: (values) => onSubmit(values),
  });

  return (
    <Container onSubmit={formik.handleSubmit}>
      {renderFormConfig([
        {
          title: 'First Name',
          name: 'firstName',
          component: StyledInput,
        },
        {
          title: 'Second Name',
          name: 'lastName',
          component: StyledInput,
        },
      ], formik)}
      <InputWrap title="Password">
        <StyledButton onClick={(e) => {
          e.preventDefault();
          onChangePasswordClickHandler();
        }}
        >
          Change Password
        </StyledButton>
      </InputWrap>
      <Divider />
      <StyledButton type="submit">Save</StyledButton>
    </Container>
  );
}

const Container = styled.form`
  max-width: 520px;
`;

const StyledInput = styled(Input)`
  width: 100%;
  max-width: 320px;
  height: unset;
  padding: 10.5px 11.5px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #D5DCE5;
  margin: 40px 0;
`;

const StyledButton = styled(ButtonDark)`
  padding: 14px;
  width: 210px;
`;
