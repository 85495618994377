import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { BuyerAdminUsersTableRow } from '../../../types';

const tableConfig: ColumnsType<BuyerAdminUsersTableRow> = [
  {
    title: 'User',
    dataIndex: 'user',
    key: 'user',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Role',
    dataIndex: 'role',
    key: 'role',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
];

interface UsersTabProps {
  tableData: Array<BuyerAdminUsersTableRow>;
  onSelectUser: (userIds: Array<string>) => void;
  selectedUsers: Array<string>;
}

export default function UsersTable({ tableData, onSelectUser, selectedUsers }: UsersTabProps) {
  return (
    <Table
      rowSelection={{
        type: 'checkbox',
        onChange: (ids) => {
          onSelectUser(ids as Array<string>);
        },
        selectedRowKeys: selectedUsers,
      }}
      columns={tableConfig}
      dataSource={tableData}
      style={{ borderTop: '2px solid #2682DE', marginRight: 2 }}
    />
  );
}
