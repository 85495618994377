import styled from 'styled-components/macro';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { MainHeader } from '../../../ui/Headers';
import CollectionPeriodComponent from '../all-collections/CollectionPeriodComponent';
import { DonorTableRow } from '../types';
import { DonorAdminType } from '../../../project-types/admin-donors/type';
import { AdminDonorPaymentItem } from '../../../project-types/admin-payment/types';
import { formatDateDDMMMYYYY } from '../../../ui/DateFormatMethods';
import { showPriceDigits } from '../../../utils/formats';

interface DonorViewProps {
  currentDonor: DonorAdminType;
  onFreezeCollectionsClickHandler: () => void;
  onResumeCollectionsClickHandler: () => void;
  currentMonth: number;
  onMonthChange: (month: number) => void;
  donorCollections: Array<AdminDonorPaymentItem>;
}

export default function DonorView({
  onFreezeCollectionsClickHandler, onResumeCollectionsClickHandler, currentDonor, currentMonth, onMonthChange, donorCollections,
}: DonorViewProps) {
  const tableData: Array<DonorTableRow> = donorCollections.map((collection) => ({
    key: collection._id,
    collectionDate: formatDateDDMMMYYYY(new Date(collection.postedDate)),
    school: collection.schoolName,
    campaignId: collection.campaignName,
    collectionSum: showPriceDigits(collection.amount),
    giftAid: 'yes',
  }));

  const onIncDateRange = () => {
    if (currentMonth < 12) {
      onMonthChange(currentMonth + 1);
    } else {
      onMonthChange(1);
    }
  };
  const onDecrDateRange = () => {
    if (currentMonth > 1) {
      onMonthChange(currentMonth - 1);
    } else {
      onMonthChange(12);
    }
  };

  const getSubscribtionStatus = (): string => {
    let status = 'Active';

    if (currentDonor.campaigns?.length === 0) {
      status = 'Inactive';
      return status;
    }

    currentDonor.campaigns?.forEach((campaign) => {
      if (campaign.freezed.indexOf(currentDonor._id) !== -1) {
        status = 'Freezed';
      }
    });
    return status;
  };

  const tableConfig: ColumnsType<DonorTableRow> = [
    {
      title: 'Collection date',
      dataIndex: 'collectionDate',
      key: 'collectionDate',
      sorter: (a, b) => {
        if (new Date(a.collectionDate) < new Date(b.collectionDate)) {
          return -1;
        }
        if (new Date(a.collectionDate) > new Date(b.collectionDate)) {
          return 1;
        }
        return 0;
      },
    },
    {
      title: 'School',
      dataIndex: 'school',
      key: 'school',
    },
    {
      title: 'Campaign ID',
      dataIndex: 'campaignId',
      key: 'campaignId',
    },
    {
      title: 'Collection sum',
      dataIndex: 'collectionSum',
      key: 'collectionSum',
    },
    {
      title: 'Gift aid (y/n)',
      dataIndex: 'giftAid',
      key: 'giftAid',
    },
  ];

  return (
    <Wrapper>
      <StyledMainHeader>{`${currentDonor.firstName} ${currentDonor.lastName}`}</StyledMainHeader>
      <StatusWrap>
        <StatusBlock>
          <BoldSpan>Status of subscription:</BoldSpan>
          <span>{getSubscribtionStatus()}</span>
        </StatusBlock>
        {getSubscribtionStatus() !== 'Inactive' && (
        <>
          {getSubscribtionStatus() === 'Active' ? (<FreezeSpan onClick={onFreezeCollectionsClickHandler}>Freeze collections</FreezeSpan>) : (<FreezeSpan onClick={onResumeCollectionsClickHandler}>Resume collections</FreezeSpan>)}
        </>
        )}
      </StatusWrap>
      <CollectionPeriodComponent styles={{ margin: '40px 0' }} monthNum={currentMonth} onIncDateRange={onIncDateRange} onDecrDateRange={onDecrDateRange} />
      <Table<DonorTableRow> columns={tableConfig} dataSource={tableData} />
    </Wrapper>
  );
}

const Wrapper = styled.main`
  box-sizing: border-box;
  padding: 102px 54px 48px;
  flex-grow: 1;
  max-width: 1020px;
`;

const StyledMainHeader = styled(MainHeader)`
  font-size: 24px;
  line-height: 26px;
`;

const StatusWrap = styled.div`
  width: 100%;
  max-width: 424px;
  border-bottom: 1px solid #e0e0e0;
  border-top: 1px solid #e0e0e0;
  margin-top: 38px;
  font-size: 14px;
  display: flex;
  gap: 25px;
  padding: 15px 0;
`;

const StatusBlock = styled.div`
  display: flex;
  gap: 13px;
`;

const BoldSpan = styled.span`
  font-weight: 750;
`;

const FreezeSpan = styled.span`
  font-weight: 750;
  text-decoration: underline;
  cursor: pointer;
`;
