import { useState } from 'react';
import styled from 'styled-components/macro';
import { BuyerAddressPublicType, BuyerCreateAddressType } from '../../../types';
import { ButtonDark } from '../../../../../ui/Button';
import ModalInPortal from '../../../../../ui/Modal/ModalPortal';
import AddressModalContent from './AddressModalContent';

interface AddressesTabProps {
  addresses: Array<BuyerAddressPublicType>;
  onSaveNewAddress: (values: BuyerCreateAddressType) => void;
  onSaveEditAddress: (id: string, values: BuyerCreateAddressType) => void;
}

export default function AddressesTab({ addresses, onSaveNewAddress, onSaveEditAddress }: AddressesTabProps) {
  const [addressToEdit, setAddressToEdit] = useState<BuyerAddressPublicType | undefined>();

  const AddNewAddressModal = ModalInPortal();
  const EditAddressModal = ModalInPortal();

  const onAddNewAddressClick = () => AddNewAddressModal.onShow();

  const serializeAddress = (address: BuyerCreateAddressType) => `${address.adress1}, ${address.town}, ${address.country}, ${address.postcode}`;
  const onSubmitEdit = (values: BuyerCreateAddressType) => addressToEdit && onSaveEditAddress(addressToEdit.id, values);

  return (
    <>
      <CustomLink onClick={onAddNewAddressClick}>+ Add New Address</CustomLink>
      {AddNewAddressModal.ModalRender && (
        <AddNewAddressModal.ModalRender>
          <AddressModalContent
            onClose={AddNewAddressModal.onClose}
            onSubmit={onSaveNewAddress}
          />
        </AddNewAddressModal.ModalRender>
      )}
      {EditAddressModal.ModalRender && addressToEdit && (
        <EditAddressModal.ModalRender>
          <AddressModalContent
            onClose={EditAddressModal.onClose}
            onSubmit={onSubmitEdit}
            initialValues={addressToEdit}
          />
        </EditAddressModal.ModalRender>
      )}
      <Container>
        <TableHeader>
          <BoldText>Address</BoldText>
        </TableHeader>
        {addresses.map((address) => (
          <TableRow key={address.adress1}>
            <Text>{serializeAddress(address)}</Text>
            <StyledButtonDark onClick={() => {
              setAddressToEdit(address);
              EditAddressModal.onShow();
            }}
            >
              Edit
            </StyledButtonDark>
          </TableRow>
        ))}
      </Container>
    </>
  );
}

const Container = styled.div`
  border: 1px solid #d5dce5;
  border-top: 2px solid #2682DE;
  border-bottom: none;
  display: flex;
  flex-direction: column;
`;

const TableHeader = styled.div`
  padding: 14px 25px;
  background: #F9F9F9;
  border-bottom: 1px solid #d5dce5;
`;

const Text = styled.span`
  color: #102f54;
  font-size: 14px;
  line-height: 14px;
`;

const BoldText = styled(Text)`
  font-weight: bold;
`;

const TableRow = styled(TableHeader)`
  background: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledButtonDark = styled(ButtonDark)`
  padding: 10px 35px;
`;

const CustomLink = styled(Text)`
  color: #2682de;
  cursor: pointer;
  display: block;
  text-align: right;
  margin-bottom: 14px;

  &:hover {
    text-decoration: underline;
  }
`;
