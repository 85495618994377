import React from 'react';
import styled from 'styled-components/macro';
import ROUTER_CONFIG from '../../../router/router-config';
import Header from '../../layout/Header';
import AccountMenu from './SupplierAccountMenu';
import SideMenu from './SideMenu/SideMenu';
import SilverwingLogo from '../../layout/SilverwingLogo';

interface SupplierLayoutProps {
  children: React.ReactNode;
  className?: string;
}

export default function SupplierLayout({ children, className }: SupplierLayoutProps) {
  return (
    <Container className={className}>
      <StyledHeader logo={<SilverwingLogo />} loginLinkTo={ROUTER_CONFIG.SUPPLIER_OVERVIEW.path}>
        <AccountMenu
          accountName="Account"
        />
      </StyledHeader>
      <SideMenu />
      <ContentWrapper className="supplier_content_wrapper">
        {children}
        <FooterText>
          © 2021 Silverwing, A trading name of CASQOL Ltd. All Rights Reserved. Terms, Privacy and Cookies
        </FooterText>
      </ContentWrapper>
    </Container>
  );
}

const Container = styled.div`
  display: grid;
  min-height: 100vh;
  grid-template-columns: 180px 1fr;
  grid-template-rows: 70px 1fr;
`;

const StyledHeader = styled(Header)`
  grid-column: 1 / -1;

  .header__wrapper {
    padding: 15px 90px 15px 24px;
  }
`;

const ContentWrapper = styled.div`
  padding: 38px 80px 70px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const FooterText = styled.p`
  font-size: 14px;
  line-height: 17px;
  color: #909DA8;
  margin-top: 66px;
`;
