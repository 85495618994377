import styled from 'styled-components/macro';
import { useFormik } from 'formik';
import { ButtonDark } from '../../../ui/Button';
import Input from '../../../ui/forms/Input';
import FindYourAddress from '../../../ui/forms/FindYourAddress';

import { FormConfig, renderFormConfig } from '../../../utils/form';
import Checkbox from '../../../ui/forms/Checkbox';
import { signUpSchoolSchema } from '../school-schemas';
import { SignUpSchoolForm, SignUpSchoolFields } from '../types';
import PostcodeInput from './PostcodeInput';

export interface CreateSchoolFormTypes {
  onSignUp: (schoolFields: SignUpSchoolFields) => void,
  externalErrors: Record<string, string[]>
}

const InputStyled = styled(Input)`
  width: 100%;
  max-width: 420px;
`;

const StyledPostcodeInput = styled(PostcodeInput)`
  max-width: 420px;
`;

const formConfig: FormConfig = [
  {
    title: 'School Name',
    name: 'schoolName',
    component: InputStyled,
  },
  {
    title: 'URN (Unique Reference Number)',
    name: 'URN',
    component: InputStyled,
  },
  {
    title: 'Postcode',
    name: 'postcode',
    component: StyledPostcodeInput,
  },
];

const formConfig2: FormConfig = [
  {
    title: 'Address line 1',
    name: 'adress1',
    component: InputStyled,
  },
  {
    title: 'Address line 2',
    name: 'adress2',
    component: InputStyled,
  },
  {
    title: 'Address line 3',
    name: 'adress3',
    component: InputStyled,
  },
  {
    title: 'Town',
    name: 'town',
    component: InputStyled,
  },
  {
    title: 'Country:',
    name: 'country',
    component: InputStyled,
  },
  {
    title: 'Telephone number',
    name: 'phoneNumber',
    component: InputStyled,
  },
];

const formConfig3: FormConfig = [
  {
    title: 'Email',
    name: 'email',
    component: InputStyled,
  },
  {
    title: 'First Name',
    name: 'firstName',
    component: InputStyled,
  },
  {
    title: 'Last Name',
    name: 'lastName',
    component: InputStyled,
  },
  {
    title: 'Password',
    name: 'password',
    component: InputStyled,
    props: {
      type: 'password',
    },
  },
  {
    title: 'Confirm Password',
    name: 'confirmPassword',
    component: InputStyled,
    props: {
      type: 'password',
    },
  },
];

export default function CreateSchoolForm({ onSignUp, externalErrors }: CreateSchoolFormTypes) {
  const formik = useFormik<SignUpSchoolForm>({
    initialValues: {
      schoolName: '',
      lastName: '',
      phoneNumber: '',
      firstName: '',

      URN: '',

      postcode: '',
      adress1: '',
      adress2: '',
      adress3: '',
      town: '',
      country: '',

      agree: false,
      confirmPassword: '',
      email: '',
      password: '',
    },
    validationSchema: signUpSchoolSchema,
    onSubmit: (values) => {
      const { agree, confirmPassword, ...rows } = values;
      try {
        // debugger;
        onSignUp(rows);
      } catch (e) {
        // debugger;
        console.log(e);
      }
    },
  });

  return (
    <FormStyled onSubmit={formik.handleSubmit}>
      <ColoredContent>
        <SecondHeader>School Details</SecondHeader>
        {renderFormConfig(formConfig, formik)}
        <FindYourAddress postCode={formik.values.postcode} formikInstance={formik} />
        {renderFormConfig(formConfig2, formik)}
        <SecondHeader>
          Person acting on behalf of the school
        </SecondHeader>
        {renderFormConfig(formConfig3, formik)}
        <TermsWrap hasError={!!formik.errors?.agree}>
          <CheckboxStyled
            type="checkbox"
            name="agree"
            value="yes"
            onChange={(e: any) => {
              formik.setFieldValue('agree', e.target.checked);
            }}
          />
          I have read and agree to the&nbsp;
          <TermsLink href="#">Terms & Conditions and Privacy Policy</TermsLink>
        </TermsWrap>
      </ColoredContent>

      <SubmitStyled type="submit">Create account</SubmitStyled>

    </FormStyled>
  );
}

const FormStyled = styled.form`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ColoredContent = styled.div`
  background-color: #f7f9fc;
  padding: 30px 70px;
`;

const SecondHeader = styled.h2`
  font-weight: bold;
  font-size: 20px;
  color: #102F54;
  margin-bottom: 26px;
`;

const SubmitStyled = styled(ButtonDark)`
  padding: 13px 75px;
  align-self: flex-end;
  margin-top: 53px;
`;

const TermsWrap = styled.div<{ hasError?: boolean }>`
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  margin-top: 35px;
  border: 1px solid ${(props) => (props.hasError ? 'red' : 'none')}
`;

const CheckboxStyled = styled(Checkbox)`
  margin-left: 8px;
  margin-right: 15px;
`;

const TermsLink = styled.a`
  color: #2682DE;
`;
