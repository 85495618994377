import { useFormik } from 'formik';
import styled from 'styled-components/macro';
import { renderFormConfig, FormConfig } from '../../../../../utils/form';
import Input from '../../../../../ui/forms/Input';
import TextArea from '../../../../../ui/forms/Textarea';
import { ButtonDark } from '../../../../../ui/Button';
import CloseIcon from '../../../../../img/close.svg';
import { updateSupplierService } from '../../../supplier-schemas';

const StyledInput = styled(Input)`
  width: 100%;
  max-width: 310px;
  height: unset;
  padding: 12px;
`;

const StyledTextarea = styled(TextArea)`
  height: unset;
  padding: 17px 20px;
  width: 100%;
`;

const formConfig: FormConfig = [
  {
    title: 'Service Description',
    name: 'description',
    component: StyledTextarea,
    isRequired: true,
  },
  {
    title: 'Unit Price',
    name: 'unitPrice',
    component: StyledInput,
    isRequired: true,
    props: {
      type: 'number',
      step: 0.01,
      min: 0.01,
    },
  },
];

interface EditServiceModalContentProps {
  onClose: () => void;
  onSubmit: (values: any) => void;
}

export default function EditServiceModalContent({ onClose, onSubmit }: EditServiceModalContentProps) {
  const formik = useFormik({
    initialValues: {
      description: '',
      unitPrice: '',
    },
    validationSchema: updateSupplierService,
    onSubmit: (values) => {
      onSubmit(values);
      onClose();
    },
  });

  return (
    <Container onSubmit={formik.handleSubmit}>
      <CloseButton onClick={onClose} alt="" src={CloseIcon} />
      <Title>Edit Service</Title>
      {renderFormConfig(formConfig, formik)}
      <SubmitButton type="submit">Save Edits</SubmitButton>
    </Container>
  );
}

const Container = styled.form`
  position: relative;
  width: 100vw;
  max-width: 570px;
  padding: 24px 45px;
`;

const Title = styled.h1`
  margin: 0;
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
  color: #102f54;
  margin-bottom: 40px;
`;

const SubmitButton = styled(ButtonDark)`
  padding: 14px 70px;
  margin-top: 50px;
`;

const CloseButton = styled.img`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
`;
