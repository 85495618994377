import { useFormik } from 'formik';
import styled from 'styled-components/macro';
import Input from '../../../../ui/forms/Input';
import { RadioGroup, RadioItem } from '../../../../ui/forms/Radio';
import SelectInput from '../../../../ui/forms/SelectInput';
import { ButtonDark, ButtonLight } from '../../../../ui/Button';
import { FormConfig, renderFormConfig } from '../../../../utils/form';
import { createQuoteSchema } from '../../supplier-schemas';
import {
  ORDER_TYPES, QuoteCreateServiceType, QuoteCreateType, QuoteServiceItem,
} from '../../types';
import ItemsTable from './ItemsTable';
import ServicesTable from './ServicesTable';
import Checkbox from '../../../../ui/forms/Checkbox';
import Datepicker from '../../../../ui/forms/DatePicker';
import QuoteSummary from './QuoteSummary';
import SearchInput from '../../../../ui/forms/SearchInput';
import InputWrap from '../../../../ui/forms/InputWrap';

const StyledInput = styled(Input)`
  width: 100%;
  max-width: 424px;
  padding: 11px;
  height: auto;

  &:disabled {
    background: #f2f2f2;
  }
`;

const StyledSearchInput = styled(SearchInput)`
  width: 100%;
  max-width: 424px;
  padding: 11px;
`;

const StyledSelectInput = styled(SelectInput)`
  width: 100%;
  max-width: 424px;
`;

const OrderDetailsFormConfig: FormConfig = [
  {
    title: 'Lease Length',
    isRequired: true,
    name: 'leaseLength',
    component: StyledSelectInput,
    props: {
      initialData: ['2 Years', '3 Years'],
    },
  },
  {
    title: 'Lease Provider',
    isRequired: true,
    name: 'leaseProvider',
    component: StyledSelectInput,
    props: {
      initialData: ['Wesleyan Bank'],
    },
  },
  {
    title: 'Payment Schedule',
    isRequired: true,
    name: 'paymentSchedule',
    component: StyledSelectInput,
    props: {
      initialData: ['Monthly', 'Quarterly', 'Quarterly (In arrears)', 'Annually'],
    },
  },
];

interface BuildQuoteProps {
  onFormSubmit: (values: QuoteCreateType) => void;
  services: Array<QuoteServiceItem>;
}

export default function BuildQuote({ onFormSubmit, services }: BuildQuoteProps) {
  const formik = useFormik<QuoteCreateType>({
    initialValues: {
      school: '',
      recipientName: '',
      recipientEmail: '',
      deliveryCharge: undefined,
      orderType: ORDER_TYPES['Outright Purchase'],
      products: [
        {
          name: '',
          quantity: '',
          unitPrice: '',
        },
      ],
      services: [],
      isInsurance: false,
      quoteExpirationDate: '',
    },
    validationSchema: createQuoteSchema,
    onSubmit: (values) => onFormSubmit(values),
  });

  console.log(formik.errors);

  const SchoolFormConfig: FormConfig = [
    {
      title: 'Recipient Name',
      isRequired: true,
      name: 'recipientName',
      component: StyledInput,
      props: {
        disabled: !formik.values.school,
        placeholder: formik.values.school ? 'Recipient Name' : 'Please select a school or a trust first',
      },
    },
    {
      title: 'Recipient Email',
      isRequired: true,
      name: 'recipientEmail',
      component: StyledInput,
      props: {
        disabled: !formik.values.school,
        placeholder: formik.values.school ? 'Recipient Name' : 'Please select a school or a trust first',
      },
    },
  ];

  const onAddNewProduct = () => formik.setFieldValue('products', [...formik.values.products, { name: '', quantity: '', unitPrice: '' }]);
  const onAddNewService = (service: QuoteCreateServiceType) => {
    const alreadyAdded = formik.values.services ? formik.values.services.find((s) => s.name === service.name) : false;

    if (alreadyAdded) {
      const newValue = formik.values.services && formik.values.services.filter((s) => s.name !== service.name);
      formik.setFieldValue('services', newValue);
    } else {
      formik.setFieldValue('services', [...(formik.values?.services || []), service]);
    }
  };

  const isShowOrderDetailsBlock = !!(formik.values.orderType === ORDER_TYPES['Financing Lease'] || formik.values.orderType === ORDER_TYPES['Operating Lease']);

  return (
    <Container onSubmit={formik.handleSubmit}>
      <Title>Build Quote</Title>
      <SchoolBlock>
        <SubTitle>
          1. Select a School or a Trust & Recipient
          {' '}
          &
          {' '}
          Recipient
        </SubTitle>
        <DescriptionText>
          Please select a school from the dropdown below. Only schools which have selected Demo Reseller A as their preferred reseller will appear in this dropdown.
        </DescriptionText>
        <div>
          {renderFormConfig([
            {
              title: 'Select School or Trust',
              isRequired: true,
              name: 'school',
              component: StyledSelectInput,
              props: {
                initialData: ['Adderlane Academy'],
              },
            },
          ], formik)}
          <Divider>Or</Divider>
          <DescriptionText style={{ marginBottom: 18 }}>Create a quote for a school or a trust without an account</DescriptionText>
          {renderFormConfig([
            {
              title: 'Find School or Trust',
              isRequired: true,
              // TODO add search
              name: 'schoolSearch',
              component: StyledSearchInput,
              props: {
                placeholder: 'School/Trust Name',
                onSearchClick: () => console.log('search'),
              },
            },
          ], formik)}
        </div>
        <div>
          {renderFormConfig(SchoolFormConfig, formik)}
        </div>
      </SchoolBlock>
      <OrderBlock>
        <SubTitle>2. Order Type</SubTitle>
        <DescriptionText>Please select if the quote is for an operating/financing lease or an outright purchase?</DescriptionText>
        <StyledRagioGroup value={formik.values.orderType} onChange={(e) => formik.setFieldValue('orderType', e.target.value)}>
          {Object.values(ORDER_TYPES).map((order) => (
            <RadioItem key={order} value={order}>{order}</RadioItem>
          ))}
        </StyledRagioGroup>
        <DescriptionText>If lease is selected, the customer will also receive a sepearate quote from the Lessor including lease pricing. Indicative lease pricing can be viewed when adding the line items.</DescriptionText>
        {isShowOrderDetailsBlock && (
          <OrderDetailsBlock>
            {renderFormConfig(OrderDetailsFormConfig, formik)}
          </OrderDetailsBlock>
        )}
      </OrderBlock>
      <ItemsBlock>
        <SubTitle>3. Add Items</SubTitle>
        <DescriptionText>Press the ‘Add Item’ button below to add new line items.</DescriptionText>
        <ItemsTable
          data={formik.values.products}
          setValue={formik.setFieldValue}
          onAddNewItem={onAddNewProduct}
          name="products"
        />
        <InputWrap title="Delivery charge">
          <DeliveryChargeBlock>
            <StyledInput value={formik.values.deliveryCharge || ''} onChange={(e) => formik.setFieldValue('deliveryCharge', +e.target.value)} type="number" min={0.01} />
            <StyledLightButton onClick={() => formik.setFieldValue('deliveryCharge', undefined)}>Remove</StyledLightButton>
          </DeliveryChargeBlock>
        </InputWrap>
      </ItemsBlock>
      <ServiceBlock>
        <SubTitle>4. Add Services</SubTitle>
        <DescriptionText>Press the ‘Add Services’ button below to add new line items.</DescriptionText>
        <ServicesTable
          data={services}
          selectedServices={formik.values.services || []}
          onAddNewItem={onAddNewService}
        />
      </ServiceBlock>
      <InsuranceBlock>
        <SubTitle>5. Insurance</SubTitle>
        {renderFormConfig([
          {
            title: 'The financing partner requires a school to insure the devices. Would the customer like to include an insurance quote? Tick if yes',
            name: 'isInsurance',
            component: Checkbox,
            containerStyle: { flexDirection: 'row-reverse', gap: '20px' },
          },
        ], formik)}
      </InsuranceBlock>
      <ExpirationDateBlock>
        <SubTitle>6. Quote Expiration Date</SubTitle>
        <DescriptionText>Once this date is passed the quote is void and can’t be used to place an order</DescriptionText>
        <StyledDatePicker
          setFieldValue={formik.setFieldValue}
          name="quoteExpirationDate"
        />
      </ExpirationDateBlock>
      {formik.values.products[0].name && (
        <QuoteSummary />
      )}
      <SubmitButton type="submit">Generate Quote</SubmitButton>
    </Container>
  );
}

const Container = styled.form`
  display: flex;
  flex-direction: column;
  gap: 50px;
  flex: 1;
`;

const Divider = styled.div`
  font-size: 14px;
  color: #6A7E95;
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  grid-column-gap: 5px;
  align-items: center;
  margin: 22px 0;
  max-width: 424px;

  &::before,
  &::after {
    content: "";
    display: block;
    height: 1px;
    background-color: #EBF2FA;
  }
`;

const Title = styled.h1`
  margin: 0;
  font-size: 30px;
  line-height: 34px;
  color: #102f54;
  font-weight: bold;
`;

const SubTitle = styled.h2`
  margin: 0;
  font-size: 21px;
  line-height: 26px;
  color: #102f54;
  font-weight: bold;
`;

const DescriptionText = styled.p`
  margin: 0;
  font-size: 14px;
  line-height: 17px;
  color: #102f54;
`;

const ContentBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 23px;
  max-width: 520px;
`;

const SchoolBlock = styled(ContentBlock)``;

const OrderBlock = styled(ContentBlock)``;

const StyledRagioGroup = styled(RadioGroup)`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const OrderDetailsBlock = styled.div`
  padding: 26px;
  background-color: #F5F5F5;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
`;

const ItemsBlock = styled(ContentBlock)`
  max-width: none;
`;

const DeliveryChargeBlock = styled.div`
  display: flex;
  gap: 13px;
`;

const StyledLightButton = styled(ButtonLight)`
  padding: 10px 20px;
  /* align-self: flex-end; */
`;

const ServiceBlock = styled(ContentBlock)`
  max-width: none;
`;

const InsuranceBlock = styled(ContentBlock)``;

const ExpirationDateBlock = styled(ContentBlock)``;

const StyledDatePicker = styled(Datepicker)`
  max-width: 150px;
`;

const SubmitButton = styled(ButtonDark)`
  padding: 13px 100px;
  align-self: flex-start;
`;
