import styled from 'styled-components/macro';
import { CheckCircleTwoTone } from '@ant-design/icons';
import OrderSummary from '../buyer-order-secure-checkout/submit-page/order-summary/OrderSummary';
import WhatHappensNow from '../layout/elements/WhatHappensNow';
import { BuyerOrderStateType } from '../../../feature/buyerState';
import { ISellerOfferPublicType } from '../types';
import { CalculatedPricesType } from '../calculateOrderPrice';

interface BuyerOrderSubmittedViewProps {
  order: BuyerOrderStateType;
  offer: ISellerOfferPublicType;
  calculations: CalculatedPricesType;
}

export default function BuyerOrderSubmittedView({ order, offer, calculations }: BuyerOrderSubmittedViewProps) {
  return (
    <Container>
      <TitleBlock>
        <CheckCircleTwoTone twoToneColor="#21C6BF" style={{ fontSize: 28 }} />
        <Title>Order Submitted</Title>
      </TitleBlock>
      <SubTitle>Order Summary</SubTitle>
      <Content>
        <StyledSummary order={order} offer={offer} calculations={calculations} />
        <StyledWhatHappens />
      </Content>
    </Container>
  );
}

const Container = styled.div``;

const TitleBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
  margin-bottom: 40px;
`;

const Title = styled.h1`
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  color: #102F54;
  margin: 0;
`;

const SubTitle = styled.h2`
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #102F54;
  margin: 0;
  margin-bottom: 20px;
`;

const Content = styled.div`
  display: flex;
  gap: 56px;
`;

const StyledSummary = styled(OrderSummary)`
  flex: 2;
`;

const StyledWhatHappens = styled(WhatHappensNow)`
  flex: 1;
`;
