import styled from 'styled-components/macro';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { BuyerOrdersOverviewTableRow } from '../types';

interface BuyerOrdersTableProps {
  tableData: Array<BuyerOrdersOverviewTableRow>;
  onRowClickHandler: (id: string) => void;
}

export default function BuyerOrdersTable({ tableData, onRowClickHandler }: BuyerOrdersTableProps) {
  const tableConfig: ColumnsType<BuyerOrdersOverviewTableRow> = [
    {
      key: 'dateSubmitted',
      dataIndex: 'dateSubmitted',
      title: 'Date Submitted',
      sorter: (a, b) => {
        if (new Date(a.dateSubmitted) < new Date(b.dateSubmitted)) {
          return -1;
        }
        if (new Date(a.dateSubmitted) > new Date(b.dateSubmitted)) {
          return 1;
        }
        return 0;
      },
    },
    {
      key: 'poNumber',
      dataIndex: 'poNumber',
      title: 'PO Number',
      render: (value) => <BlueText>{value}</BlueText>,
    },
    {
      key: 'products',
      dataIndex: 'products',
      title: 'Product ID',
    },
    {
      key: 'orderType',
      dataIndex: 'orderType',
      title: 'Order type',
    },
    {
      key: 'status',
      dataIndex: 'status',
      title: 'Status',
    },
    {
      key: 'total',
      dataIndex: 'total',
      title: 'Total',
    },
  ];

  return (
    <Table
      dataSource={tableData}
      columns={tableConfig}
      style={{ borderTop: '2px solid #2682DE', marginRight: 2 }}
      onRow={(rowData) => ({
        onClick: () => onRowClickHandler(rowData.key),
        style: { cursor: 'pointer' },
      })}
    />
  );
}

const BlueText = styled.span`
  font-weight: bold;
  color: #2682DE;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;
