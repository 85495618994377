import { useFormik } from 'formik';
import styled from 'styled-components/macro';
import { BuyerOrderStateType } from '../../../../feature/buyerState';
import YourOrderBlock from '../../buyer-order-configure/steps/YourOrderBlock';
import { buyerSecureCheckoutSchema } from '../../buyer-schemas';
import {
  BuyerSecureCheckoutFormType, ISellerOfferPublicType,
} from '../../types';
import SecureCheckoutForm from './SecureCheckoutForm';

interface BuyerOrderSecureCheckoutFormViewProps {
  offer: ISellerOfferPublicType;
  onSubmit: (values: BuyerSecureCheckoutFormType) => void;
  currentOrder: BuyerOrderStateType;
  addressInitialData: string;
}

export default function BuyerOrderSecureCheckoutFormView({
  offer, onSubmit, currentOrder, addressInitialData,
}: BuyerOrderSecureCheckoutFormViewProps) {
  const formik = useFormik<BuyerSecureCheckoutFormType>({
    initialValues: {
      deliveryOption: '',
      attentionOf: '',
      deliveryAddress: '',
      deliveryNotes: '',
      contactName: '',
      contactEmail: '',
      invoiceAddress: '',
    },
    validationSchema: buyerSecureCheckoutSchema,
    onSubmit: (values) => onSubmit(values),
    isInitialValid: false,
  });

  console.log('formik errors: ', formik.errors);
  console.log('formik values: ', formik.values);

  return (
    <Container>
      <Title>Secure Checkout</Title>
      <Content onSubmit={formik.handleSubmit}>
        <SecureCheckoutForm
          formikInstance={formik}
          addressInitialData={addressInitialData}
        />
        <YourOrderBlock
          onEditClickHandler={() => 3}
          onContinue={() => 3}
          buttonText="Review Order"
          currentOrder={currentOrder}
          offer={offer}
          accessories={currentOrder?.accessories}
          insurance={currentOrder?.insurance}
          reseller={currentOrder?.reseller}
          isContinueEnabled={formik.isValid}
          services={currentOrder?.services}
        />
      </Content>
    </Container>
  );
}

const Container = styled.div``;

const Title = styled.h1`
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  color: #102F54;
  margin: 0;
  margin-bottom: 40px;
  display: block;
  width: 100%;
  max-width: 600px;
  padding-bottom: 20px;
  border-bottom: 1px solid #D5DCE5;
`;

const Content = styled.form`
  display: flex;
  gap: 56px;
`;
