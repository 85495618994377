import styled from 'styled-components/macro';
import { SchoolLoginFields } from '../types';
import LoginSchoolForm from './LoginSchoolForm';

import LoginImage from '../../../img/loginImg.svg';
import { ResGlobalError } from '../../buyer/types';
import Logo from '../../layout/Logo';

interface SchoolLoginTypes {
  title: string;
  onSignIn: (schoolFields: SchoolLoginFields) => void;
  onForgotPasswordClickHandler: () => void;
  onRegisterClickHandler?: () => void;
  isShowLogo?: boolean;
  externalErrors?: Record<string, string[]> | ResGlobalError;
}

export default function SchoolLogin({
  title,
  onSignIn,
  onForgotPasswordClickHandler,
  onRegisterClickHandler,
  externalErrors,
  isShowLogo = false,
}: SchoolLoginTypes) {
  return (
    <CreateSchooFormWrap>
      <Content>
        {isShowLogo && <Logo />}
        <MainHeader>{title}</MainHeader>
        <StyledText>Sign in with your Devices For Education account</StyledText>
        <LoginSchoolForm
          onSignIn={onSignIn}
          onForgotPasswordClickHandler={onForgotPasswordClickHandler}
          onRegisterClickHandler={onRegisterClickHandler}
          errors={externalErrors}
        />
      </Content>
    </CreateSchooFormWrap>
  );
}

const CreateSchooFormWrap = styled.div`
  width: 100%;
  background: url(${LoginImage}) no-repeat right 150px center/25%,
    linear-gradient(to right, white 70%, #F1F9F8 70% 100%);
  min-height: 700px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 1000px) {
    background: linear-gradient(to right, white 70%, #F1F9F8 70% 100%);
    backgoundImage: none;
  }
`;

const MainHeader = styled.h1`
  font-weight: 600;
  font-size: 30px;
  margin-bottom: 19px;
  color: #102F54;
`;

const StyledText = styled.p`
  font-size: 21px;
  color: #102f54;
  margin-bottom: 39px;
`;

const Content = styled.div`
  padding: 66px 0 0 150px;
`;
