import { useState } from 'react';
import { showPriceDigits } from '../../../utils/formats';
import SupplierLayout from '../layout/SupplierLayout';
import {
  SupplierAdminServicesTableRow, SupplierAdminUsersTableRow, SupplierCreateServiceType, SupplierEditServiceType, UpdateSupplierPersonalDetails,
} from '../types';
import SupplierSettings from './SupplierSettings';

export default function SupplierSettingsPage() {
  const [selectedUsers, setSelectedUsers] = useState<Array<string>>([]);
  const [selectedServices, setSelectedServices] = useState<Array<string>>([]);

  const onRemoveUserClickHandler = () => console.log(selectedUsers);
  const onSubmitInviteUserForm = (values: UpdateSupplierPersonalDetails) => console.log(values);

  const onDeleteServiceClickHandler = () => console.log(selectedServices);
  const onSubmitNewServiceForm = (values: SupplierCreateServiceType) => console.log(values);
  const onSubmitEditServiceForm = (values: SupplierEditServiceType) => console.log(values);

  const mockUsersTableData: Array<SupplierAdminUsersTableRow> = [
    {
      key: '1',
      user: 'John Doe',
      email: 'johndoe@demoresellera.com',
      role: 'Admin',
      status: 'Active',
    },
    {
      key: '2',
      user: 'Tristen Cannon',
      email: 'johndoe@demoresellera.com',
      role: 'Standard',
      status: 'Active',
    },
  ];
  const mockServicesTableData: Array<SupplierAdminServicesTableRow> = [
    {
      key: '1',
      serviceType: 'Asset Targing',
      description: 'Asset identification and tagging is an essential component of a good asset management strategy. It helps a company’s assets to become quickly identifiable and ties the asset to the overall asset plan.',
      unitPrice: showPriceDigits(4.6),
    },
    {
      key: '2',
      serviceType: 'Installation',
      description: 'Our Installation Service provides for the basic installation of servers, workstations, desktop systems, notebook PCs, thin clients, storage devices, printers, networking, and software products.',
      unitPrice: showPriceDigits(5),
    },
  ];

  return (
    <SupplierLayout>
      <SupplierSettings
        selectedUsers={selectedUsers}
        onSelectUser={setSelectedUsers}
        onRemoveUserClickHandler={onRemoveUserClickHandler}
        usersTableData={mockUsersTableData}
        onSubmitInviteUserForm={onSubmitInviteUserForm}
        servicesTableData={mockServicesTableData}
        selectedServices={selectedServices}
        onSelectService={setSelectedServices}
        onDeleteServiceClickHandler={onDeleteServiceClickHandler}
        onSubmitEditServiceForm={onSubmitEditServiceForm}
        onSubmitNewServiceForm={onSubmitNewServiceForm}
      />
    </SupplierLayout>
  );
}
