import styled from 'styled-components/macro';
import GreenCheckIcon from '../../../../img/icons/greenCheckIcon.svg';
import { formatDateDDMMMYYYY } from '../../../../ui/DateFormatMethods';
import { showPriceDigits } from '../../../../utils/formats';

interface PaymentsInfoTableProps {
  payments: Array<any>;
}

export default function PaymentsInfoTable({ payments }: PaymentsInfoTableProps) {
  return (
    <TableWrapper>
      <TableHeader>
        <BoldText>Transaction</BoldText>
        <BoldText>Amount</BoldText>
      </TableHeader>
      {payments.length ? (
        <>
          {payments.map((p) => (
            <TableRowWrapper key={p}>
              <SuccessPaymentBlock>
                <CheckIconWrapper>
                  <CheckIcon alt="" src={GreenCheckIcon} />
                </CheckIconWrapper>
                <SuccessPaymentInfoBlock>
                  <BoldText>
                    Payment From Lessor Received -
                    {' '}
                    {formatDateDDMMMYYYY(new Date('22 jan 2021'))}
                    {' '}
                    13:48
                  </BoldText>
                  <GrayText>
                    Demo Lessor
                    {' '}
                    {'>'}
                    {' '}
                    Silverwing
                  </GrayText>
                </SuccessPaymentInfoBlock>
              </SuccessPaymentBlock>
              <BoldText>
                {showPriceDigits(12556.80)}
              </BoldText>
            </TableRowWrapper>
          ))}
        </>
      ) : (
        <TableRowWrapper>
          <GrayText>No payments received</GrayText>
        </TableRowWrapper>
      )}
    </TableWrapper>
  );
}

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 2px solid #2682DE;
  box-shadow: 0px 1px 6px rgba(161, 166, 172, 0.161);
`;

const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
  background-color: #F9F9F9;
`;

const TableRowWrapper = styled.div`
  padding: 20px;
  border-bottom: 1px solid #D5DCE5;
  display: flex;
  justify-content: space-between;
`;

const SuccessPaymentBlock = styled.div`
  display: flex;
  gap: 19px;
`;

const SuccessPaymentInfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const CheckIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F2FEFF;
  border: 1px solid #1EADBA;
  padding: 10px;
`;

const CheckIcon = styled.img``;

const Text = styled.span`
  font-size: 14px;
  line-height: 14px;
  color: #102F54;
`;

const BoldText = styled(Text)`
  font-weight: bold;
`;

const GrayText = styled(Text)`
  color: #6B7A87;
`;

const TitleGrayText = styled(GrayText)`
  margin-bottom: 5px;
`;
