import styled from 'styled-components/macro';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { BalanceTransfersTableRow } from '../../types';
import { ButtonDark } from '../../../../ui/Button';

interface BalanceTransfersTableProps {
  tableData: Array<BalanceTransfersTableRow>;
  onViewClickHandler: (id: string) => void;
}

export default function BalanceTransfersTable({ tableData, onViewClickHandler }: BalanceTransfersTableProps) {
  const tableConfig: ColumnsType<BalanceTransfersTableRow> = [
    {
      title: 'Date',
      key: 'date',
      dataIndex: 'date',
      sorter: (a, b) => {
        if (new Date(a.date) < new Date(b.date)) {
          return -1;
        }
        if (new Date(a.date) > new Date(b.date)) {
          return 1;
        }
        return 0;
      },
    },
    {
      title: 'BTS Number',
      key: 'btsNumber',
      dataIndex: 'btsNumber',
    },
    {
      title: 'Balance Settled',
      key: 'balanceSettled',
      dataIndex: 'balanceSettled',
    },
    {
      title: '',
      key: 'linkToDoc',
      dataIndex: 'linkToDoc',
      render: (_v, r) => <ViewButton onClick={() => onViewClickHandler(r.key)}>View</ViewButton>,
    },
  ];

  return (
    <Table
      columns={tableConfig}
      dataSource={tableData}
      style={{ borderTop: '2px solid #2682DE' }}
    />
  );
}

const ViewButton = styled(ButtonDark)`
  padding: 8px 36px;
`;
