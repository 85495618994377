const staticPageConfig = {
  collections: {
    url: '/collections.html',
    height: 1300,
  },
  insurance: {
    url: '/insurance.html',
    height: 1400,
  },
  lease: {
    url: '/lease.html',
    height: 1300,
  },
};

export default staticPageConfig;
