import styled from 'styled-components/macro';
import { BuyerOrderStateType } from '../../../feature/buyerState';
import { CalculatedPricesType } from '../calculateOrderPrice';
import { ISellerOfferPublicType } from '../types';
import OrderTable from './OrderTable';
import SummaryInfo from './SummaryInfo';

export enum ORDER_POSITION_TYPE {
  DEVICE = 'DEVICE',
  INSURANCE = 'INSURANCE',
  ACCESSORY = 'ACCESSORY',
  SERVICE = 'SERVICE',
}

interface BuyerOrderCurrentViewProps {
  onSaveClickHandler: () => void;
  onProceedClickHandler: () => void;
  calculations: CalculatedPricesType;
  offer: ISellerOfferPublicType;
  order: BuyerOrderStateType;
  onEditOrderPosition: (orderPositionType: ORDER_POSITION_TYPE) => void;
  onDeleteOrderPosition: (
    orderPositionType: ORDER_POSITION_TYPE,
    id?: string
  ) => void;
  isQuoteCreateSuccess: boolean;
  isGenerateQuoteDisabled: boolean;
  onChangeQuantity: (type: 'product' | 'accessory', quantity: number, accessoryId?: string) => void;
}

export default function BuyerOrderCurrentView({
  onSaveClickHandler,
  onProceedClickHandler,
  calculations,
  order,
  onEditOrderPosition,
  onDeleteOrderPosition,
  isQuoteCreateSuccess,
  isGenerateQuoteDisabled,
  onChangeQuantity,
  offer,
}: BuyerOrderCurrentViewProps) {
  return (
    <Container>
      <Title>Your Current Order</Title>
      <Content>
        <OrderTable
          order={order}
          offer={offer}
          onEditOrderPosition={onEditOrderPosition}
          onDeleteOrderPosition={onDeleteOrderPosition}
          onChangeQuantity={onChangeQuantity}
        />
        <SummaryInfo
          onSaveClickHandler={onSaveClickHandler}
          onProceedClickHandler={onProceedClickHandler}
          calculations={calculations}
          order={order}
          isCreateQuoteSuccess={isQuoteCreateSuccess}
          isGenerateQuoteDisabled={isGenerateQuoteDisabled}
        />
      </Content>
    </Container>
  );
}

const Container = styled.div``;

const Title = styled.h1`
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  color: #102f54;
  margin: 0;
  margin-bottom: 60px;
`;

const Content = styled.div`
  display: flex;
  gap: 65px;
`;
