import styled from 'styled-components/macro';
import { SchoolFundraisingCampaignInfoType } from '../../../../project-types/payment/types';
import { ButtonDark } from '../../../../ui/Button';
import { formatDateDDMMMYYYY } from '../../../../ui/DateFormatMethods';
import { serializeStatisticsPrice } from '../../../../utils/formats';
import { CampaignsTableRow } from '../../types';
import CampaignsTable from './CampaignsTable';
import { PendingMessage, VerifiedMessage } from './VerificationMessages';

interface CampaignsListProps {
  campaignsList: Array<SchoolFundraisingCampaignInfoType>;
  isProfileActive: boolean;
  onCreateCampaignClickHandler: () => void;
  onChangeTimePeriod: (period: [string, string]) => void;
  onCampaignClickHandler: (campaignId: string) => void;
}

export default function CampaignsList({
  campaignsList, isProfileActive, onCreateCampaignClickHandler, onChangeTimePeriod, onCampaignClickHandler,
}: CampaignsListProps) {
  const tableData: Array<CampaignsTableRow> = campaignsList.map((campaign) => ({
    key: campaign._id,
    campaignName: campaign.campaignName,
    startDate: formatDateDDMMMYYYY(new Date(campaign.startDate)),
    // endDate: getEndDate(campaign),
    endDate: formatDateDDMMMYYYY(new Date(campaign.endDate)),
    registeredDonors: campaign.donorsCount,
    totalDonationsCollected: serializeStatisticsPrice(campaign.totalAmountCollected),
    giftAidClaimed: serializeStatisticsPrice(campaign.giftAidClaimed),
    totalRaised: serializeStatisticsPrice(campaign.totalRaised),
  }));

  const pageContent = () => {
    if (!isProfileActive) {
      return <PendingMessage />;
    }

    // if (isProfileActive && campaignsList.length === 0) {
    //   return <VerifiedMessage />;
    // }

    return <CampaignsTable tableData={tableData} onChangeTimePeriod={onChangeTimePeriod} onCampaignClickHandler={onCampaignClickHandler} />;
  };

  return (
    <Container>
      <Header>
        <StyledTitle>Donation Campaigns</StyledTitle>
        <StyledButton onClick={onCreateCampaignClickHandler} disabled={!isProfileActive} isDisabled={!isProfileActive}>+ New Campaign</StyledButton>
      </Header>
      {pageContent()}
    </Container>
  );
}

const Container = styled.div`
  padding-top: 44px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledTitle = styled.h1`
  font-size: 30px;
  color: #102F54;
`;

const StyledButton = styled(ButtonDark)<{ isDisabled: boolean }>`
  padding: 14px 50px;

  color: ${({ isDisabled }) => isDisabled && 'white'};
  background-color: ${({ isDisabled }) => isDisabled && '#BDBDBD'};
  border: ${({ isDisabled }) => isDisabled && 'none'};
`;
