import styled from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useEffect } from 'react';
import SchoolLayout from '../../layout/SchoolLayoutWithTabs';
import Payouts from './PayoutsSum';
import Documentation from './PayoutsDocumentation';
import { getUserProfile, loadProfile } from '../../../../feature/userState';
import ROUTER_CONFIG from '../../../../router/router-config';
import BackButton from '../../../../ui/elements/BackButton';

export default function PayoutPage() {
  const { push } = useHistory();
  const userProfile = useSelector(getUserProfile);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadProfile());
  }, []);

  const withdrawalsAvalible = !userProfile?.schoolProfile?.branchSortCode1;

  const onWithdrawals = () => {
    push(ROUTER_CONFIG.SCHOOL_SETTINGS.getPath('#ref-bank-details'));
  };

  const onBackClickHandler = () => push(ROUTER_CONFIG.SCHOOL_CAMPAIGNS_LIST.getPath());

  return (
    <SchoolLayout>
      {userProfile && (
        <Wrap>
          <BackButton onClickHandler={onBackClickHandler} />
          <Content>
            <Payouts withdrawalsAvalible={withdrawalsAvalible} onWithdrawals={onWithdrawals} />
            <Documentation />
          </Content>
        </Wrap>
      )}
    </SchoolLayout>
  );
}

const Wrap = styled.main`
  display: flex;
  gap: 34px;
  margin-bottom: 100px;
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;
