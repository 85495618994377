import styled from 'styled-components/macro';
import InputWrap from '../../../../ui/forms/InputWrap';
import DeliveryOption from './DeliveryOption';
import Input from '../../../../ui/forms/Input';
import SelectInput from '../../../../ui/forms/SelectInput';
import TextArea from '../../../../ui/forms/Textarea';
import { FormConfig, renderFormConfig } from '../../../../utils/form';

const StyledInput = styled(Input)`
  height: unset;
  padding: 8px;
  width: 100%;
  max-width: 550px;
`;

const StyledSelect = styled(SelectInput)`
  width: 100%;
  max-width: 550px;
`;

const StyledTextarea = styled(TextArea)`
  width: 100%;
  max-width: 550px;
`;

interface SecureCheckoutFormProps {
  formikInstance: any;
  addressInitialData: string;
}

export default function SecureCheckoutForm({ formikInstance, addressInitialData }: SecureCheckoutFormProps) {
  const formConfig: FormConfig = [
    {
      title: 'To the attention of',
      name: 'attentionOf',
      component: StyledInput,
      isRequired: true,
    },
    {
      title: 'Delivery Address',
      name: 'deliveryAddress',
      component: StyledSelect,
      props: {
        initialData: [addressInitialData],
      },
      isRequired: true,
    },
    {
      title: 'Delivery Notes',
      name: 'deliveryNotes',
      component: StyledTextarea,
    },
  ];

  const formConfig2: FormConfig = [
    {
      title: 'Accounts Payable Contact Name',
      name: 'contactName',
      component: StyledInput,
      isRequired: true,
    },
    {
      title: 'Accounts Payable Contact Email*',
      name: 'contactEmail',
      component: StyledInput,
      isRequired: true,
    },
    {
      title: 'Invoice Address',
      name: 'invoiceAddress',
      component: StyledSelect,
      props: {
        initialData: [addressInitialData],
      },
      isRequired: true,
    },
  ];

  return (
    <Container>
      <FormTitle>1. Delivery Details</FormTitle>
      <InputWrap title="Delivery Options" isRequired>
        <DeliveryOption
          isSelect={formikInstance.values.deliveryOption === 'Standard Delivery'}
          onClick={() => formikInstance.setFieldValue('deliveryOption', 'Standard Delivery')}
          price={0}
          title="Standard Delivery"
        />
      </InputWrap>
      <Text style={{ marginBottom: 20, display: 'block' }}>Please complete the following details:</Text>
      {renderFormConfig(formConfig, formikInstance)}
      <Divider />
      <FormTitle>2. Payment Information</FormTitle>
      <Text style={{ marginBottom: 20, display: 'block' }}>Please complete the following details:</Text>
      {renderFormConfig(formConfig2, formikInstance)}
    </Container>
  );
}

const Container = styled.div`
  flex: 1;
`;

const Text = styled.span`
  font-size: 14px;
  line-height: 17px;
  color: #102f54;
`;

const FormTitle = styled.h2`
  font-weight: bold;
  font-size: 24px;
  line-height: 26px;
  color: #102F54;
  margin-bottom: 26px;
`;

const Divider = styled.div`
  width: 100%;
  max-width: 550px;
  height: 1px;
  border-bottom: 1px solid #D5DCE5;
  margin: 40px 0;
`;
