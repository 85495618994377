import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  useGetOffersQuery,
} from '../../../api/services/buyerApi';
import { createInitialOrder, IsBuyerAuthenticated, setOrderLease } from '../../../feature/buyerState';
import ROUTER_CONFIG from '../../../router/router-config';
import { groupRelatedAccessoriesByProductId, groupSellerOffersByProductId, mergeOfferLeases } from '../groupSellerOffersByProductId';
import BuyerLayout from '../layout/BuyerLayout';
import {
  BuyerCreateLeaseOrderType,
  BuyerCreateOutrightPurchaseOrderType,
  OmitProductId,
} from '../types';
import BuyerOrderCreateView from './BuyerOrderCreateView';

export default function BuyerOrderCreatePage() {
  const dispatch = useDispatch();
  const { deviceId: productId } = useParams<{ deviceId: string }>();
  const { push } = useHistory();
  const isAuth = useSelector(IsBuyerAuthenticated);

  const { data: offers } = useGetOffersQuery();

  const groupedOffers = groupSellerOffersByProductId(offers)[productId];

  const groupedRelatedAccessoryOffers = groupRelatedAccessoriesByProductId(groupedOffers, offers);

  const { leases } = mergeOfferLeases(groupedOffers);

  const onStartLeaseOrderClickHandler = (
    values: OmitProductId<BuyerCreateLeaseOrderType>,
  ) => {
    if (!isAuth) {
      push(ROUTER_CONFIG.BUYER_LOGIN.getPath());
      return;
    }

    const selectedLease = leases.filter((lease) => lease.policyLength === values.termLength && lease.type === values.orderType && lease.paymentSchedule === values.paymentSchedule);

    console.log('amount of suitable lease options at the end: ', selectedLease.length);

    dispatch(createInitialOrder({ ...values, productId }));
    dispatch(setOrderLease(selectedLease[0]));
    push(ROUTER_CONFIG.BUYER_ORDER_CONFIGURE.getPath({ orderId: productId }));
  };

  const onStartOutrightPurchaseOrderClickHandler = (
    values: OmitProductId<BuyerCreateOutrightPurchaseOrderType>,
  ) => {
    if (!isAuth) {
      push(ROUTER_CONFIG.BUYER_LOGIN.getPath());
      return;
    }

    dispatch(createInitialOrder({ ...values, productId }));
    push(ROUTER_CONFIG.BUYER_ORDER_CONFIGURE.getPath({ orderId: productId }));
  };

  return (
    <BuyerLayout>
      {offers && groupedOffers && (
        <BuyerOrderCreateView
          groupedOffer={groupedOffers}
          onStartLeaseOrderClickHandler={onStartLeaseOrderClickHandler}
          onStartOutrightPurchaseOrderClickHandler={
            onStartOutrightPurchaseOrderClickHandler
          }
          leases={leases || []}
          groupedRelatedAccessoryOffers={groupedRelatedAccessoryOffers}
        />
      )}
    </BuyerLayout>
  );
}
