import styled from 'styled-components/macro';
import {
  FieldWrap, FieldName, FieldTextarea, FieldTextareaText,
} from '../LayoutComponents';

interface ContactDetailsProps {
  firstName: string;
  lastName: string;
  adress1: string;
  adress2?: string;
  adress3?: string;
  town?: string;
  country?: string;
}

export function ContactDetails({
  firstName, lastName, adress1, adress2, adress3, town, country,
}: ContactDetailsProps) {
  return (
    <FieldWrap>
      <FieldName>Contact Details</FieldName>
      <FieldTextarea>
        <FieldTextareaText>
          <BlockSpan>
            {firstName}
            {' '}
            {lastName}
          </BlockSpan>
          <BlockSpan>
            {town ?? ''}
            {' '}
            {country ?? ''}
          </BlockSpan>
          <BlockSpan>{adress1}</BlockSpan>
          <BlockSpan>{adress2 ?? ''}</BlockSpan>
          <BlockSpan>{adress3 ?? ''}</BlockSpan>
        </FieldTextareaText>
      </FieldTextarea>
    </FieldWrap>
  );
}

const BlockSpan = styled.span`
  display: block;
`;
