import { Tabs } from 'antd';
import styled from 'styled-components/macro';
import {
  SupplierAdminServicesTableRow, SupplierAdminUsersTableRow, SupplierCreateServiceType, SupplierEditServiceType, UpdateSupplierPersonalDetails,
} from '../types';
import AccountReceivablesTab from './settings-tabs/AccountReceivablesTab';
import DeliveryChargeTab from './settings-tabs/DeliveryChargesTab';
import OrganisationDetailsTab from './settings-tabs/OrganisationDetailsTab';
import PersonalDetailsTab from './settings-tabs/PersonalDetailsTab';
import ServicesTab from './settings-tabs/services-tab/ServicesTab';
import UsersTab from './settings-tabs/users-tab/UsersTab';

const { TabPane } = Tabs;

interface SupplierSettingsProps {
  usersTableData: Array<SupplierAdminUsersTableRow>;
  onSelectUser: (userIds: Array<string>) => void;
  selectedUsers: Array<string>;
  onRemoveUserClickHandler: () => void;
  onSubmitInviteUserForm: (values: UpdateSupplierPersonalDetails) => void;
  servicesTableData: Array<SupplierAdminServicesTableRow>;
  onSelectService: (serviceIds: Array<string>) => void;
  selectedServices: Array<string>;
  onDeleteServiceClickHandler: () => void;
  onSubmitEditServiceForm: (values: SupplierEditServiceType) => void;
  onSubmitNewServiceForm: (values: SupplierCreateServiceType) => void;
}

export default function SupplierSettings({
  usersTableData, onSelectUser, selectedUsers, onRemoveUserClickHandler, onSubmitInviteUserForm, servicesTableData, onSelectService, selectedServices, onDeleteServiceClickHandler, onSubmitEditServiceForm, onSubmitNewServiceForm,
}: SupplierSettingsProps) {
  return (
    <Container>
      <Title>Settings</Title>
      <Tabs defaultActiveKey="organisationDetails">
        <StyledTabPane tab="Organisation Details" key="organisationDetails">
          <OrganisationDetailsTab />
        </StyledTabPane>
        <StyledTabPane tab="Accounts Receivables" key="accountsReceivables">
          <AccountReceivablesTab />
        </StyledTabPane>
        <StyledTabPane tab="Delivery Charges" key="deliveryCharges">
          <DeliveryChargeTab />
        </StyledTabPane>
        <StyledTabPane tab="Users" key="users">
          <UsersTab
            usersTableData={usersTableData}
            onSelectUser={onSelectUser}
            selectedUsers={selectedUsers}
            onRemoveUserClickHandler={onRemoveUserClickHandler}
            onSubmitInviteUserForm={onSubmitInviteUserForm}
          />
        </StyledTabPane>
        <StyledTabPane tab="Services" key="services">
          <ServicesTab
            servicesTableData={servicesTableData}
            onDeleteServicesClickHandler={onDeleteServiceClickHandler}
            onSelectService={onSelectService}
            selectedServices={selectedServices}
            onSubmitEditServiceForm={onSubmitEditServiceForm}
            onSubmitNewServiceForm={onSubmitNewServiceForm}
          />
        </StyledTabPane>
        <StyledTabPane tab="Personal Details" key="personalDetails">
          <PersonalDetailsTab />
        </StyledTabPane>
      </Tabs>
    </Container>
  );
}

const Container = styled.div``;

const Title = styled.h1`
  margin: 0;
  font-size: 30px;
  line-height: 37px;
  color: #102F54;
  font-weight: bold;
  margin-bottom: 40px;
`;

const StyledTabPane = styled(TabPane)`
  padding-top: 40px;
`;
