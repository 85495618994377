import styled from 'styled-components/macro';
import { Table, DatePicker } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { CampaignsTableRow } from '../../types';

const { RangePicker } = DatePicker;

interface CampaignsTableProps {
  tableData: Array<CampaignsTableRow>;
  onChangeTimePeriod: (period: [string, string]) => void;
  onCampaignClickHandler: (campaignId: string) => void;
}

export default function CampaignsTable({ tableData, onChangeTimePeriod, onCampaignClickHandler }: CampaignsTableProps) {
  const tableConfig: ColumnsType<CampaignsTableRow> = [
    {
      title: 'Campaign Name',
      dataIndex: 'campaignName',
      key: 'campaignName',
      render: (value, record) => <CampaignLink onClick={() => onCampaignClickHandler(record.key)}>{value}</CampaignLink>,
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      key: 'endDate',
    },
    {
      title: 'Registred Donors',
      dataIndex: 'registeredDonors',
      key: 'registredDonors',
    },
    {
      title: 'Total Donations Collected',
      dataIndex: 'totalDonationsCollected',
      key: 'totalDonationsCollected',
    },
    {
      title: 'Gift Aid Claimed',
      dataIndex: 'giftAidClaimed',
      key: 'giftAidClaimed',
    },
    {
      title: 'Total Raised',
      dataIndex: 'totalRaised',
      key: 'totalRaised',
    },
  ];

  return (
    <TableContainer>
      <StyledRangePicker onChange={(_, period) => onChangeTimePeriod(period)} picker="month" />
      <Table<CampaignsTableRow> dataSource={tableData} columns={tableConfig} style={{ borderTop: '2px solid #2682DE' }} />
    </TableContainer>
  );
}

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 40px;
`;

const StyledRangePicker = styled(RangePicker)`
  align-self: flex-start;
`;

const CampaignLink = styled.span`
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;
