import styled from 'styled-components/macro';
import { useFormik } from 'formik';
import { Alert } from 'antd';
import { ButtonDark, ButtonLight } from '../../../ui/Button';
import Input from '../../../ui/forms/Input';

import { FormConfig, renderFormConfig } from '../../../utils/form';
import { SchoolLoginFields } from '../types';
import { UserRegisterType } from '../../../project-types/users/types';
import { signUpSchema } from '../../../project-types/users/schemas';
import { ResGlobalError } from '../../buyer/types';

interface CreateSchoolFormTypes {
  onSignIn: (schoolFields: SchoolLoginFields) => void;
  onForgotPasswordClickHandler: () => void;
  onRegisterClickHandler?: () => void;
  errors?: Record<string, string[]> | ResGlobalError;
}

const InputStyled = styled(Input)`
  width: 100%;
`;

const formLoginConfig = (errors: Record<string, string[]> = {}): FormConfig => [
  {
    title: 'Email Address',
    name: 'email',
    component: InputStyled,
    errors: errors.email,
  },
];

export default function LoginSchoolForm({
  onSignIn,
  onForgotPasswordClickHandler,
  onRegisterClickHandler,
  errors,
}: CreateSchoolFormTypes) {
  const formik = useFormik<UserRegisterType>({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: signUpSchema,
    onSubmit: (values) => {
      onSignIn(values);
    },
  });

  const formPasswordConfig = (
    errors: Record<string, string[]> = {}
  ): FormConfig => [
    {
      title: (
        <StyledTitleContainer>
          <span>Password</span>
          <StyledSpan onClick={onForgotPasswordClickHandler}>
            Forgotten your password?
          </StyledSpan>
        </StyledTitleContainer>
      ),
      name: 'password',
      component: InputStyled,
      errors: errors.password,
      props: {
        type: 'password',
      },
    },
  ];

  return (
    <FormStyled onSubmit={formik.handleSubmit}>
      {errors?.message
        ? renderFormConfig(formLoginConfig(), formik)
        : renderFormConfig(
            formLoginConfig(errors as Record<string, string[]>),
            formik
          )}
      {errors?.message
        ? renderFormConfig(formPasswordConfig(), formik)
        : renderFormConfig(
            formPasswordConfig(errors as Record<string, string[]>),
            formik
          )}
      {errors?.message && <Alert message={errors.message} type="error" />}
      <SubmitStyled type="submit">Sign In</SubmitStyled>
      {onRegisterClickHandler && (
        <>
          <Divider>Or</Divider>
          <StyledRegister onClick={onRegisterClickHandler}>
            Register
          </StyledRegister>
        </>
      )}
    </FormStyled>
  );
}

const FormStyled = styled.form`
  width: 100%;
  min-width: 375px;
  display: flex;
  flex-direction: column;
`;

const SubmitStyled = styled(ButtonDark)`
  padding: 13px 0;
  margin-top: 38px;
`;

const StyledSpan = styled.span`
  font-size: 16px;
  align-self: flex-end;
  color: #2682DE;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const Divider = styled.div`
  font-size: 14px;
  color: #6A7E95;
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  grid-column-gap: 5px;
  align-items: center;
  margin: 22px 0;

  &::before,
  &::after {
    content: '';
    display: block;
    height: 1px;
    background-color: #EBF2FA;
  }
`;

const StyledRegister = styled(ButtonLight)`
  padding: 13px 0;
`;

const StyledTitleContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;
