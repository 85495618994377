import { useHistory } from 'react-router';
import ROUTER_CONFIG from '../../../../router/router-config';
import { useCampaignFromInvite } from '../../hooks';
import DonorLayout from '../../layout/DonorSignupLayout';
import Privacy from './Privacy';

export default function PrivacyPage() {
  const { push } = useHistory();
  const { campaign, inviteCode, currentSchool } = useCampaignFromInvite();
  const onNext = () => {
    if (campaign?._id) {
      push(ROUTER_CONFIG.DONOR_SIGNUP_AGREEMENT.getPath({ campaignId: campaign?._id, inviteCode }));
    }
  };

  const onBack = () => {
    if (campaign?._id) {
      push(ROUTER_CONFIG.DONOR_INVITE_ACCEPT.getPath({ campaignId: campaign?._id, inviteCode }));
    }
  };

  return (
    <DonorLayout>
      <Privacy onBack={onBack} campaign={campaign} currentSchool={currentSchool} onNext={onNext} />
    </DonorLayout>
  );
}
