import styled from 'styled-components/macro';
import { ButtonLight } from '../../../../../ui/Button';

interface DeviceTradeinBlockProps {
  onTradeinClickHandler: () => void;
}

export default function DeviceTradeinBlock({ onTradeinClickHandler }: DeviceTradeinBlockProps) {
  return (
    <Container>
      <NumberInSquare>3</NumberInSquare>
      <Content>
        <Title>Do you have an old device you wish to donate?</Title>
        <Button onClick={onTradeinClickHandler}>Trade-in device as a donation</Button>
        <GrayText>How does trade in-work</GrayText>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  gap: 24px;
  padding: 28px;
  background-color: rgba(245, 248, 251, 0.7);
  margin-top: 30px;
`;

const Title = styled.h2`
  font-size: 20px;
  line-height: 20px;
  color: #102f54;
  font-weight: bold;
  margin: 0;
  padding: 0;
`;

const NumberInSquare = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  background-color: #E8F4FF;
  color: #2682DE;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 22px;
`;

const Button = styled(ButtonLight)`
  padding: 14px;
`;

const GrayText = styled.span`
  font-size: 14px;
  color: #6B7A87;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;
