import styled from 'styled-components/macro';
import { Tabs } from 'antd';
import { GroupedOffersByProductType, IProduct } from '../types';
import ProductItem from '../layout/elements/ProductItem';

const { TabPane } = Tabs;

enum TABS {
  OVERVIEW = 'Overview',
  TECH_SPECS = 'Tech Specs',
  ACCESSORIES = 'Accessories',
}

interface ProductOverviewProps {
  product: IProduct;
  groupedRelatedAccessoryOffers: GroupedOffersByProductType;
}

export default function ProductOverview({ product, groupedRelatedAccessoryOffers }: ProductOverviewProps) {
  return (
    <Tabs defaultActiveKey={TABS.OVERVIEW}>
      <StyledTabPane tab={TABS.OVERVIEW} key={TABS.OVERVIEW}>
        <OverviewTab dangerouslySetInnerHTML={{ __html: product.overview }} />
      </StyledTabPane>
      <StyledTabPane tab={TABS.TECH_SPECS} key={TABS.TECH_SPECS}>
        {product.techSpecs ? (
          <>
            <DescriptionTitle style={{ marginBottom: 20 }}>Technical specification:</DescriptionTitle>
            {Object.entries(product.techSpecs).map(([key, value]) => (
              <TechSpecItem key={key}>
                <TechSpecTitle>
                  {key}
                  :
                </TechSpecTitle>
                <TechSpecValue>{value}</TechSpecValue>
              </TechSpecItem>
            ))}
          </>
        ) : (
          <DescriptionTitle style={{ marginBottom: 20 }}>No technical specification for this product</DescriptionTitle>
        )}
      </StyledTabPane>
      <StyledTabPane tab={TABS.ACCESSORIES} key={TABS.ACCESSORIES}>
        <AccessoriesContent>
          {Object.values(groupedRelatedAccessoryOffers)?.map((accessory) => (
            <ProductItem
              key={accessory.product._id}
              product={accessory.product}
              minLeasePrice={accessory.minLeasePrice}
              minOutrightPurchasePrice={accessory.minOutrightPurchasePrice}
              onDeviceClick={() => console.log('accessory clicked')}
              buttonText="View"
            />
          ))}
        </AccessoriesContent>
      </StyledTabPane>
    </Tabs>
  );
}

const StyledTabPane = styled(TabPane)`
  padding-top: 35px;
`;

const OverviewTab = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const DescriptionTitle = styled.p`
  margin: 0;
  font-size: 20px;
  line-height: 24px;
  color: #102f54;
  font-weight: bold;
`;

const TechSpecItem = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 5px;
`;

const TechSpecValue = styled.span`
  font-size: 14px;
  line-height: 16px;
`;

const TechSpecTitle = styled(TechSpecValue)`
  font-weight: bold;
`;

const AccessoriesContent = styled.div`
display: flex;
gap: 16px;
flex-wrap: wrap;

@media (max-width: 660px) {
  flex-direction: column;
}
`;
