import React from 'react';
import styled from 'styled-components/macro';

export interface InfoTableItem {
  title: string;
  value: string;
  description?: string;
  link?: string;
  onLinkClickHandler?: () => void;
}

interface InfoTableProps {
  data: Array<InfoTableItem>;
  className?: string;
}

const InfoTable: React.FC<InfoTableProps> = ({ data, className }) => (
  <Container className={className}>
    {data.map((d) => (
      <TableCell>
        <GreenTitle>{d.title}</GreenTitle>
        <ValueCell>
          <ValueContent>
            <ValueContentWrap>
              <ValueText>{d.value}</ValueText>
              {d.description && (
              <DescriptionText>{d.description}</DescriptionText>
              )}
              {d.link && (
              <StyledLink onClick={d.onLinkClickHandler}>{d.link}</StyledLink>
              )}
            </ValueContentWrap>
          </ValueContent>
        </ValueCell>
      </TableCell>
    ))}
  </Container>
);

const Container = styled.div`
  width: 100%;
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  border: 1px solid #E3E8EE;
  border-radius: 4px;
`;

const ValueCell = styled.div`
  background-color: #F9F9F9;
  padding: 8px 0 8px 20px;
`;

const ValueContent = styled.div`
  border-right: 1px solid #D5DCE5;
  height: 100%;
`;

const ValueContentWrap = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 15px 0px;
`;

const TableCell = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: auto 1fr;

  &:last-child {
    ${ValueContent} {
      border-right: none;
    }
  }
`;

const GreenTitle = styled.h3`
  text-transform: uppercase;
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;
  color: #1B9D75;
  padding: 15px 20px;
`;

const ValueText = styled.p`
  font-size: 21px;
  color: #102f54;
  margin: 0;
`;

const DescriptionText = styled.p`
  margin: 0;
  font-size: 14px;
  line-height: 14px;
  color: #6A7E95;
`;

const StyledLink = styled(DescriptionText)`
  color: #2682DE;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export default InfoTable;
