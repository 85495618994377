import styled from 'styled-components/macro';
import { useFormik } from 'formik';
import { updateSupplierOrganisationDetailsSchema } from '../../supplier-schemas';
import { renderFormConfig, FormConfig } from '../../../../utils/form';
import Input from '../../../../ui/forms/Input';
import FindYourAddress from '../../../../ui/forms/FindYourAddress';
import PostcodeInput from '../../../school/create-school/PostcodeInput';
import { ButtonDark } from '../../../../ui/Button';

const StyledInput = styled(Input)`
  width: 100%;
  max-width: 420px;
  height: unset;
  padding: 10.5px 11.5px;
`;

const StyledPostcodeInput = styled(PostcodeInput)`
  width: 100%;
  max-width: 420px;
  height: unset;
  padding: 10.5px 11.5px;
`;

const formConfig1: FormConfig = [
  {
    title: 'Name',
    name: 'name',
    component: StyledInput,
  },
  {
    title: 'Address Line 1',
    name: 'adress1',
    component: StyledInput,
  },
  {
    title: 'Address Line 2',
    name: 'adress2',
    component: StyledInput,
  },
  {
    title: 'Address Line 3',
    name: 'adress3',
    component: StyledInput,
  },
  {
    title: 'Town',
    name: 'town',
    component: StyledInput,
  },
  {
    title: 'Country',
    name: 'country',
    component: StyledInput,
  },
  {
    title: 'Postcode',
    name: 'postcode',
    component: StyledPostcodeInput,
  },
];

const formConfig2: FormConfig = [
  {
    title: 'Telephone Number',
    name: 'phoneNumber',
    component: StyledInput,
  },
  {
    title: 'Contact Email',
    name: 'email',
    component: StyledInput,
  },
  {
    title: 'VAT Number',
    name: 'vatNumber',
    component: StyledInput,
  },
  {
    title: 'Company Number',
    name: 'companyNumber',
    component: StyledInput,
  },
];

export default function OrganisationDetailsTab() {
  const formik = useFormik({
    initialValues: {
      name: '',
      adress1: '',
      adress2: '',
      adress3: '',
      town: '',
      country: '',
      postcode: '',
    },
    validationSchema: updateSupplierOrganisationDetailsSchema,
    onSubmit: (values) => console.log(values),
  });

  return (
    <Container onSubmit={formik.handleSubmit}>
      {renderFormConfig(formConfig1, formik)}
      <FindYourAddress postCode={formik.values.postcode} formikInstance={formik} />
      {renderFormConfig(formConfig2, formik)}
      <Divider />
      <StyledButtonDark type="submit">Save</StyledButtonDark>
    </Container>
  );
}

const Container = styled.form``;

const Divider = styled.div`
  width: 516px;
  height: 1px;
  border-top: 1px solid #D5DCE5;
  margin: 40px 0;
`;

const StyledButtonDark = styled(ButtonDark)`
  padding: 14px 88px;
`;
