import styled from 'styled-components/macro';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { QuotesTableRow } from '../../types';
import { ButtonLight } from '../../../../ui/Button';

interface QuotesTableProps {
  tableData: Array<QuotesTableRow>;
  onViewDocClickHandler: (id: string) => void;
}

export default function QuotesTable({ tableData, onViewDocClickHandler }: QuotesTableProps) {
  const tableConfig: ColumnsType<QuotesTableRow> = [
    {
      title: 'Date',
      key: 'date',
      dataIndex: 'date',
      sorter: (a, b) => {
        if (new Date(a.date) < new Date(b.date)) {
          return -1;
        }
        if (new Date(a.date) > new Date(b.date)) {
          return 1;
        }
        return 0;
      },
    },
    {
      title: 'Reference',
      key: 'reference',
      dataIndex: 'reference',
    },
    {
      title: 'Customer',
      key: 'customer',
      dataIndex: 'customer',
    },
    {
      title: 'Total',
      key: 'total',
      dataIndex: 'total',
    },
    {
      title: '',
      key: 'linkToDoc',
      dataIndex: 'linkToDoc',
      render: (_v, r) => <ViewDocButton onClick={() => onViewDocClickHandler(r.key)}>View Document</ViewDocButton>,
    },
  ];

  return (
    <Table<QuotesTableRow>
      columns={tableConfig}
      dataSource={tableData}
      style={{ borderTop: '2px solid #2682DE', marginRight: 2 }}
    />
  );
}

const ViewDocButton = styled(ButtonLight)`
  padding: 8px 25px;
`;
