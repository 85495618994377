import styled from 'styled-components/macro';
import { useState } from 'react';
import { Select } from 'antd';
import InputWrap from '../../../../ui/forms/InputWrap';
import { ButtonDark } from '../../../../ui/Button';

const { Option } = Select;

interface PreferredResellerTabProps {
  value?: string;
  onSubmit: (value: string) => void;
  options: Array<string>;
}

export default function PreferredResellerTab({ onSubmit, value, options }: PreferredResellerTabProps) {
  const [selectedValue, setSelectedValue] = useState(value || 'No preferred reseller');

  return (
    <Container>
      <InputWrap title="Your Preferred Reseller">
        <StyledSelect value={selectedValue} onChange={(v) => setSelectedValue(v as string)}>
          <Option value="no-reseller">No preferred reseller</Option>
          {options.map((opt) => (
            <Option key={opt} value={opt}>{opt}</Option>
          ))}
        </StyledSelect>
      </InputWrap>
      <Text>Setting a preferred reseller will ensure that you only see products and services provided by that specific reseller. You can change your preferred reseller at any time.</Text>
      <SubmitButton onClick={() => onSubmit(selectedValue)}>Save</SubmitButton>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  max-width: 540px;
`;

const StyledSelect = styled(Select)`
  width: 100%;

  .ant-select-selector {
    box-sizing: border-box;
    padding: 0;
  }
`;

const Text = styled.p`
  margin: 0;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 17px;
  color: #102f54;
  margin-top: 20px;
  margin-bottom: 44px;
`;

const SubmitButton = styled(ButtonDark)`
  padding: 14px 85px;
`;
