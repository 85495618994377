import { Menu, Dropdown } from 'antd';
import styled from 'styled-components/macro';
import { DownOutlined } from '@ant-design/icons';

interface DonorsReportsProps {
  onCancelClickHandler: () => void;
}

export default function DonorsReports({ onCancelClickHandler }: DonorsReportsProps) {
  const dropDownContent = (
    <Menu>
      <Menu.Item onClick={onCancelClickHandler}>
        Cancel further donations
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Filter>
        <StyledSecondHeader>Active campaigns</StyledSecondHeader>
        <Controls>
          <AttributeItem>
            <Dropdown placement="bottomRight" overlay={dropDownContent}>
              <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                Actions
                {' '}
                <DownOutlined />
              </a>
            </Dropdown>
          </AttributeItem>
        </Controls>
      </Filter>
    </>
  );
}

const Filter = styled.div`
  margin-top: 41px;
  margin-top: 31px;
  display: flex;
  align-items: flex-start;
`;

const Controls = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
`;

const AttributeItem = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  margin-left: 47px;
  cursor: pointer;

  .ant-dropdown-link {
    color: #2682DE;
  }

  &:hover {
    text-decoration: underline;
  }
`;

const StyledSecondHeader = styled.span`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
`;
