import styled from 'styled-components/macro';
import { useFormik } from 'formik';
import { FormConfig, renderFormConfig } from '../../../utils/form';
import Input from '../../../ui/forms/Input';
import { ButtonDark } from '../../../ui/Button';
import { UpdateDonorFields } from '../../../project-types/donor/types';
import { ChangeDonorPasswordSchema } from '../donor-schemas';

interface ChangePasswordFormprops {
  onFormSubmit: (formData: UpdateDonorFields) => void;
  onCancelClickHandler: () => void;
  onForgotPasswordClickHandler: () => void;
}

export default function ChangePasswordForm({ onFormSubmit, onCancelClickHandler, onForgotPasswordClickHandler }: ChangePasswordFormprops) {
  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
      validatePassword: '',
    },
    validationSchema: ChangeDonorPasswordSchema,
    onSubmit: ({ confirmPassword, ...values }) => onFormSubmit(values),
  });

  return (
    <Container onSubmit={formik.handleSubmit}>
      {renderFormConfig(formConfig1, formik)}
      <StyledSpan onClick={onForgotPasswordClickHandler}>Forgot your password?</StyledSpan>
      {renderFormConfig(formConfig2, formik)}
      <ButtonsBlock>
        <LightButton onClick={(e) => {
          e.preventDefault();
          onCancelClickHandler();
        }}
        >
          Cancel

        </LightButton>
        <StyledDarkButton type="submit">Save</StyledDarkButton>
      </ButtonsBlock>
    </Container>
  );
}

const Container = styled.form`
  width: 100%;
`;

const StyledInput = styled(Input)`
  width: 100%;
`;

const StyledSpan = styled.span`
  font-size: 14px;
  cursor: pointer;
  text-decoration: underline;
  margin-bottom: 40px;
  display: block;
`;

const ButtonsBlock = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 45px;
`;

const LightButton = styled(ButtonDark)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 55px;
  background: #fff;
  border: 1px solid #969696;
  color: #969696;
`;

const StyledDarkButton = styled(ButtonDark)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 55px;
`;

const formConfig1: FormConfig = [
  {
    title: 'Old password',
    name: 'validatePassword',
    props: {
      type: 'password',
    },
    component: StyledInput,
  },
];

const formConfig2: FormConfig = [
  {
    title: 'Create new password',
    name: 'password',
    props: {
      type: 'password',
    },
    component: StyledInput,
  },
  {
    title: 'Re-enter password',
    name: 'confirmPassword',
    props: {
      type: 'password',
    },
    component: StyledInput,
  },
];
