/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
import {
  ISellerOfferPublicType, GroupedOffersByProductType, GroupedOfferItemType, ILeasePublicType,
} from './types';

// group by product

export const groupSellerOffersByProductId = (offers: Array<ISellerOfferPublicType> = []) => {
  const groupedOffers = offers.reduce((acc: GroupedOffersByProductType, item) => {
    const { _id: productId } = item.offer.product;

    const lowestRateArr = item.offer.leases && item.offer.leases.length ? [...(item.offer.leases || [])].sort((a, b) => a.rate - b.rate) : [];
    const lowestRate = lowestRateArr.length ? lowestRateArr[0].rate : 0;

    const leasePrice = lowestRate * item.price;

    acc[productId] = acc[productId] || { offers: [], minOutrightPurchasePrice: 0, minLeasePrice: 0 };

    acc[productId].offers = [...acc[productId].offers || [], item];
    acc[productId].minLeasePrice = acc[productId].minLeasePrice ? (
      acc[productId].minLeasePrice < leasePrice ? acc[productId].minLeasePrice : leasePrice
    ) : leasePrice ?? 0;
    acc[productId].minOutrightPurchasePrice = acc[productId].minOutrightPurchasePrice ? (
      acc[productId].minOutrightPurchasePrice < item.price ? acc[productId].minOutrightPurchasePrice : item.price
    ) : item.price;
    acc[productId].product = item.offer.product;

    return acc;
  }, {});

  return groupedOffers;
};

export const groupRelatedAccessoriesByProductId = (groupedOffer: GroupedOfferItemType, allOffers: Array<ISellerOfferPublicType> = []) => {
  const accessoryIds = groupedOffer?.offers.reduce((acc: Array<string>, offer) => {
    const accIdArr = offer?.offer?.product?.accessories?.map((accessory) => accessory._id) || [];

    // eslint-disable-next-line no-param-reassign
    acc = [...acc, ...accIdArr];

    return acc;
  }, []) || [];

  const relatedAccessoryOffers = allOffers?.filter((offer) => offer.offer.product.category === 'ACCESSORY')
    .filter((offer) => accessoryIds.includes(offer.offer.product._id));

  return groupSellerOffersByProductId(relatedAccessoryOffers);
};

// merge leases option

type MergedLeaseOptions = { termLength: string[], paymentSchedule: string[]; orderType: string[] };

export interface MergedLeaseOptionsResponse {
  leases: Array<ILeasePublicType>;
  leaseOptions: MergedLeaseOptions;
}

export const mergeLeaseOptions = (leases: Array<ILeasePublicType>) => {
  const leaseOptions: MergedLeaseOptions = {
    termLength: [],
    paymentSchedule: [],
    orderType: [],
  };

  leases.forEach((lease) => {
    leaseOptions.termLength = [...leaseOptions.termLength, lease.policyLength];
    leaseOptions.paymentSchedule = [...leaseOptions.paymentSchedule, lease.paymentSchedule];
    leaseOptions.orderType = [...leaseOptions.orderType, lease.type];
  });

  return {
    leaseOptions: {
      termLength: Array.from(new Set(leaseOptions.termLength)),
      paymentSchedule: Array.from(new Set(leaseOptions.paymentSchedule)),
      orderType: Array.from(new Set(leaseOptions.orderType)),
    },
  };
};

export const mergeOfferLeases = (groupedOffer: GroupedOfferItemType): MergedLeaseOptionsResponse => {
  if (!groupedOffer) {
    return {
      leases: [],
      leaseOptions: {
        termLength: [],
        paymentSchedule: [],
        orderType: [],
      },
    };
  }

  const { offers } = groupedOffer;

  const leases = offers.reduce((acc: Array<ILeasePublicType>, offer) => {
    acc = [...acc, ...(offer.offer.leases || [])];

    return acc;
  }, []).filter((value, index, self) => index === self.findIndex((t) => (
    t._id === value._id
  )));

  const { leaseOptions } = mergeLeaseOptions(leases);

  return {
    leases,
    leaseOptions,
  };
};
