import { useState } from 'react';
import styled from 'styled-components/macro';
import { useFormik } from 'formik';
import DebitDetailsForm from './DebitDetailsForm';
import DonorPersonalDetailsForm from './DonorPersonalDetailsForm';
import { DonorCreateSchemas } from '../../donor-schemas';
import { ApiCreateDonor } from '../../../../project-types/donor/api-types';

interface CreateDonorFormsProps {
  onDonorDetailsBackClick: () => void;
  onFormConfirm: (formData: ApiCreateDonor['data']) => Promise<void>;
  inviteCode: string;
  onLoginClickHandler: () => void;
}

export default function CreateDonorForms({ onDonorDetailsBackClick, inviteCode, onFormConfirm, onLoginClickHandler }: CreateDonorFormsProps) {
  const [currentPage, setCurrentPage] = useState(1);

  // const onSelectAddressHandler = (address: FindAddressApiResponseAddressItem) => {
  //   formik.setFieldValue('adress1', address.line_1);
  //   formik.setFieldValue('adress2', address.line_2);
  //   formik.setFieldValue('country', address.country);
  //   formik.setFieldValue('town', address.town_or_city);
  // };

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      isCorporation: false,
      email: '',
      password: '',
      confirmPassword: '',
      title: '',
      postcode: '',
      adress1: '',
      adress2: '',
      adress3: '',
      town: '',
      country: '',
      phoneNumber: '',
      bankAccountName: '',
      branchSortCode1: '',
      branchSortCode2: '',
      branchSortCode3: '',
      accountNumber: '',
      agree: false,
    },
    validationSchema: DonorCreateSchemas[currentPage - 1],
    onSubmit: (values) => {
      const {
        email, password, agree, confirmPassword, ...formValues
      } = values;
      const createDonorData = {
        email,
        password,
        captcha: '123456',
        donorProfile: { ...formValues },
      };

      onFormConfirm(createDonorData);
    },
  });

  const renderForm = (page: number) => {
    switch (page) {
      case 1:
        return <DonorPersonalDetailsForm onLoginClickHandler={onLoginClickHandler} onBackDetailsClick={onDonorDetailsBackClick} onButtonClickHandler={setCurrentPage} formikInstance={formik} />;
      case 2:
        return <DebitDetailsForm onButtonClickHandler={setCurrentPage} formikInstance={formik} />;
      default:
        return null;
    }
  };

  return (
    <FormStyled onSubmit={formik.handleSubmit}>
      {renderForm(currentPage)}
    </FormStyled>
  );
}

const FormStyled = styled.form`
  width: 100%;
`;
