import styled from 'styled-components/macro';
import ServicesTable from './ServicesTable';
import ServicesTableControls from './ServicesTableControls';
import ModalInPortal from '../../../../../ui/Modal/ModalPortal';
import { SupplierAdminServicesTableRow, SupplierCreateServiceType, SupplierEditServiceType } from '../../../types';
import EditServiceModalContent from './EditServiceModalContent';
import NewServiceModalContent from './NewServiceModalContent';

interface ServicesTabProps {
  onDeleteServicesClickHandler: () => void;
  servicesTableData: Array<SupplierAdminServicesTableRow>;
  onSelectService: (serviceIds: Array<string>) => void;
  selectedServices: Array<string>;
  onSubmitEditServiceForm: (values: SupplierEditServiceType) => void;
  onSubmitNewServiceForm: (values: SupplierCreateServiceType) => void;
}

export default function ServicesTab({
  onDeleteServicesClickHandler, servicesTableData, onSelectService, selectedServices, onSubmitEditServiceForm, onSubmitNewServiceForm,
}: ServicesTabProps) {
  const EditServiceModal = ModalInPortal();
  const NewServiceModal = ModalInPortal();

  return (
    <Container>
      {EditServiceModal.ModalRender && (
        <EditServiceModal.ModalRender>
          <EditServiceModalContent
            onClose={EditServiceModal.onClose}
            onSubmit={onSubmitEditServiceForm}
          />
        </EditServiceModal.ModalRender>
      )}
      {NewServiceModal.ModalRender && (
        <NewServiceModal.ModalRender>
          <NewServiceModalContent
            onClose={NewServiceModal.onClose}
            onSubmit={onSubmitNewServiceForm}
          />
        </NewServiceModal.ModalRender>
      )}
      <ServicesTableControls
        onDeleteClickHandler={onDeleteServicesClickHandler}
        onNewServiceClickHandler={NewServiceModal.onShow}
      />
      <ServicesTable
        tableData={servicesTableData}
        onEditClickHandler={(serviceId: string) => EditServiceModal.onShow()}
        onSelectService={onSelectService}
        selectedServices={selectedServices}
      />
    </Container>
  );
}

const Container = styled.div``;
