import styled from 'styled-components/macro';
import { Alert, Radio, AutoComplete } from 'antd';
import { renderFormConfig, FormConfig } from '../../../utils/form';
import Input from '../../../ui/forms/Input';
import SelectInput from '../../../ui/forms/SelectInput';
import { ButtonDark } from '../../../ui/Button';
import { ORDER_TYPES, ResGlobalError } from '../types';
import InputWrap from '../../../ui/forms/InputWrap';
import Checkbox from '../../../ui/forms/Checkbox';
import { useSchoolSearchQuery } from '../../../api/services/buyerApi';
import { useEffect } from 'react';

const { Group } = Radio;

const StyledInput = styled(Input)`
  width: 100%;
  height: unset;
  padding: 10.5px 11.5px;

  &:disabled {
    background: #f2f2f2;
  }
`;

const StyledAutoComplete = styled(AutoComplete)`
  width: 100%;
  height: unset;

  &:disabled {
    background: #f2f2f2;
  }
`;

const StyledSelectInput = styled(SelectInput)`
  width: 100%;

  .ant-select-selector {
    box-sizing: border-box;
    padding: 0;
  }
`;

const yourDetailsFormConfig = (
  errors: Record<string, string[]> = {}
): FormConfig => [
  {
    title: 'First Name',
    name: 'firstName',
    component: StyledInput,
    isRequired: true,
    errors: errors.firstName,
  },
  {
    title: 'Last Name',
    name: 'lastName',
    component: StyledInput,
    isRequired: true,
    errors: errors.lastName,
  },
  {
    title: 'Work Email',
    name: 'workEmail',
    component: StyledInput,
    isRequired: true,
    errors: errors.workEmail,
  },
  {
    title: 'Contact Telephone',
    name: 'phoneNumber',
    component: StyledInput,
    errors: errors.phoneNumber,
  },
  {
    title: 'Job Title',
    name: 'jobTitle',
    component: StyledInput,
    errors: errors.jobTitle,
  },
  {
    title: 'Create Password',
    name: 'password',
    isRequired: true,
    component: StyledInput,
    props: {
      type: 'password',
    },
    errors: errors.password,
  },
  {
    title: 'Confirm Password',
    name: 'confirmPassword',
    isRequired: true,
    component: StyledInput,
    props: {
      type: 'password',
    },
    errors: errors.confirmPassword,
  },
];

interface BuyerRegisterFormProps {
  formikInstance: any;
  preferredResellersList: Array<string>;
  errors?: Record<string, string[]> | ResGlobalError;
}

export default function BuyerRegisterForm({
  formikInstance,
  preferredResellersList,
  errors,
}: BuyerRegisterFormProps) {
  const { data: schoolData, refetch } = useSchoolSearchQuery({
    schoolName: formikInstance.values.school,
    address: formikInstance.values.schoolAddress,
  });
  const onChangeSchoolName = (inputString: string) => {
    formikInstance.setFieldValue('school', inputString);
  };

  const onChangeSchoolAddress = (inputString: string) => {
    formikInstance.setFieldValue('schoolAddress', inputString);
  };

  useEffect(() => {
    refetch();
  }, [formikInstance.values.school, formikInstance.values.schoolAddress]);

  return (
    <Container onSubmit={formikInstance.handleSubmit}>
      <Title>School Details</Title>
      {renderFormConfig(
        [
          {
            title: 'Search For Your School',
            name: 'school',
            component: StyledAutoComplete,
            isRequired: true,
            showDropDownIcon:
              schoolData && formikInstance.values.school ? true : false,
            containerStyle: { position: 'relative', width: '94%' },
            props: {
              value: formikInstance.values.school,
              placeholder: 'E.g. High Wycombe School',
              options:
                schoolData &&
                formikInstance.values.school &&
                schoolData?.map((data) => ({
                  value: data.schoolName,
                  label: data.schoolName,
                })),
              onChange: onChangeSchoolName,
              size: 'large',
            },
            errors: errors && (errors as Record<string, string[]>).school,
          },
          {
            title: 'School Address',
            name: 'schoolAddress',
            component: StyledSelectInput,
            isRequired: true,
            props: {
              initialData: (schoolData || [])?.map((data) => data.address),
              disabled:
                !formikInstance.values.school &&
                !formikInstance.values.schoolAddress,
              placeholder: formikInstance.values.school
                ? 'School Address'
                : 'Please select a school first',
              isAllowClear: true,
              size: 'large',
            },
            errors:
              errors && (errors as Record<string, string[]>).schoolAddress,
          },
        ],
        formikInstance
      )}
      <ContactSupportBlock>
        If you can’t find your school, or you are registering as a trust, please
        contact <CustomLink>Support</CustomLink> to register for an account.
      </ContactSupportBlock>
      <Divider />
      <Title>Your Details</Title>
      {errors?.message
        ? renderFormConfig(yourDetailsFormConfig({}), formikInstance)
        : renderFormConfig(
            yourDetailsFormConfig(errors as Record<string, string[]>),
            formikInstance
          )}
      <Divider />
      {renderFormConfig(
        [
          {
            title: 'Do you have a preferred reseller?',
            name: 'preferredReseller',
            component: StyledSelectInput,
            props: {
              initialData: preferredResellersList,
              isAllowClear: true,
              placeholder: 'Select',
            },
            errors:
              errors && (errors as Record<string, string[]>).preferredReseller,
          },
        ],
        formikInstance
      )}
      <Text style={{ marginBottom: 55 }}>
        If you select a preferred reseller, your registration information will
        be shared with them and you will see offers exclusively from the
        reseller. They may choose to contact you to provide additional
        assistance with this promotion. If you do not wish to be contacted by
        the reseller, please leave this answer unselected.
      </Text>
      <InputWrap title="Are you planning on starting a Parental Contribution Scheme?">
        <StyledGroup
          value={formikInstance.values.isStartingParentalContributionScheme}
          onChange={(e) =>
            formikInstance.setFieldValue(
              'isStartingParentalContributionScheme',
              e.target.value
            )
          }
        >
          <Radio value>Yes</Radio>
          <Radio value={false}>No</Radio>
          <Radio value={undefined}>Unsure</Radio>
        </StyledGroup>
      </InputWrap>
      <InputWrap title="What percentage of the device costs would you be looking for the parents to contribute?">
        <StyledGroup
          value={
            formikInstance.values
              .percentageOfTheDeviceCostsForParentsToContribute
          }
          onChange={(e) =>
            formikInstance.setFieldValue(
              'percentageOfTheDeviceCostsForParentsToContribute',
              e.target.value
            )
          }
        >
          <Radio value={50}>50%</Radio>
          <Radio value={80}>80%</Radio>
          <Radio value={100}>100%</Radio>
          <Radio value={undefined}>Unsure</Radio>
        </StyledGroup>
      </InputWrap>
      <InputWrap title="Do you have a preferred purchase method?">
        <StyledGroup
          value={formikInstance.values.preferredPurchaseMethod}
          onChange={(e) =>
            formikInstance.setFieldValue(
              'preferredPurchaseMethod',
              e.target.value
            )
          }
        >
          {Object.values(ORDER_TYPES).map((ot) => (
            <Radio value={ORDER_TYPES[ot]} key={ot}>
              {ot}
            </Radio>
          ))}
          <Radio value={undefined}>Unsure</Radio>
        </StyledGroup>
      </InputWrap>
      {renderFormConfig(
        [
          {
            title: 'How many devices are you looking to purchase?',
            name: 'devicesToPurchaseCount',
            component: StyledInput,
            props: {
              type: 'number',
              min: 0,
              step: 1,
            },
            errors:
              errors &&
              (errors as Record<string, string[]>).devicesToPurchaseCount,
          },
        ],
        formikInstance
      )}
      <Divider />
      <TermsWrap hasError={!!formikInstance.errors?.agree}>
        <CheckboxStyled
          type="checkbox"
          name="agree"
          value="yes"
          onChange={(e: any) => {
            formikInstance.setFieldValue('agree', e.target.checked);
          }}
        />
        <Text>
          You confirm that you have fully read and accepted the{' '}
          <CustomLink>Terms and Conditions</CustomLink> and the{' '}
          <CustomLink>Privacy Policy</CustomLink> of this promotion.
        </Text>
      </TermsWrap>
      {errors?.message && <Alert message={errors.message} type="error" />}
      <StyledButtonDark type="submit">Register</StyledButtonDark>
    </Container>
  );
}

const Container = styled.form``;

const Title = styled.h2`
  font-size: 21px;
  line-height: 26px;
  color: #102F54;
  font-weight: bold;
  margin-bottom: 23px;
`;

const ContactSupportBlock = styled.div`
  padding: 12px;
  font-size: 14px;
  line-height: 17px;
  background-color: rgba(193, 230, 255, 0.2);
`;

const CustomLink = styled.span`
  color: #2682DE;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #D5DCE5;
  margin: 40px 0;
`;

const Text = styled.p`
  margin: 0;
  font-size: 14px;
  line-height: 17px;
  color: #102F54;
`;

const StyledGroup = styled(Group)`
  & .ant-radio-inner {
    box-sizing: border-box;
  }
`;

const TermsWrap = styled.div<{ hasError?: boolean }>`
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: flex-start;
  margin-bottom: 60px;
  border-radius: 5px;
  border: 1px solid ${(props) => (props.hasError ? 'red' : 'none')};
`;

const CheckboxStyled = styled(Checkbox)`
  margin-left: 8px;
  margin-right: 15px;
  width: 30px;
  height: 30px;
`;

const StyledButtonDark = styled(ButtonDark)`
  padding: 13px;
  width: 100%;
  margin-bottom: 100px;
`;
