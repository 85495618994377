import styled from 'styled-components/macro';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { OrdersOverviewTableRow } from '../types';
import { ButtonDark } from '../../../ui/Button';

interface OrdersTableProps {
  tableData: Array<OrdersOverviewTableRow>;
  onReviewClickHandler: (id: string, paymentMethod: string) => void;
  buttonText?: string;
  className?: string;
}

export default function OrdersTable({
  tableData, onReviewClickHandler, className, buttonText = 'Review',
}: OrdersTableProps) {
  const talbeConfig: ColumnsType<OrdersOverviewTableRow> = [
    {
      title: 'Date Submitted',
      key: 'dateSubmitted',
      dataIndex: 'dateSubmitted',
      sorter: (a, b) => {
        if (new Date(a.dateSubmitted) < new Date(b.dateSubmitted)) {
          return -1;
        }
        if (new Date(a.dateSubmitted) > new Date(b.dateSubmitted)) {
          return 1;
        }
        return 0;
      },
    },
    {
      title: 'PO Number',
      key: 'poNumber',
      dataIndex: 'poNumber',
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
    },
    {
      title: 'Buyer',
      key: 'buyer',
      dataIndex: 'buyer',
    },
    {
      title: 'Payment Method',
      key: 'paymentMethod',
      dataIndex: 'paymentMethod',
    },
    {
      title: 'Total',
      key: 'total',
      dataIndex: 'total',
    },
    {
      title: '',
      key: 'linkToReview',
      dataIndex: 'linkToReview',
      render: (_v, record) => <GoToButton onClick={() => onReviewClickHandler(record.key, record.paymentMethod)}>{buttonText}</GoToButton>,
    },
  ];

  return (
    <Table
      className={className}
      columns={talbeConfig}
      dataSource={tableData}
      style={{ borderTop: '2px solid #2682DE', marginRight: 2 }}
    />
  );
}

const GoToButton = styled(ButtonDark)`
  padding: 8px 35px;
`;
