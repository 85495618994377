import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import ROUTER_CONFIG from '../../../../router/router-config';
import SupplierLayoutWithBack from '../../layout/SupplierLayoutWithBack';
import JoinOffer from './JoinOffer';

export default function JoinOfferPage() {
  const [price, setPrice] = useState('');
  const history = useHistory();

  const onJoinClickHandler = () => console.log(price);

  return (
    <SupplierLayoutWithBack onBack={() => history.push(ROUTER_CONFIG.SUPPLIER_OFFERS.getPath())}>
      <JoinOffer
        price={price}
        onChangePrice={setPrice}
        onJoinClickHandler={onJoinClickHandler}
      />
    </SupplierLayoutWithBack>
  );
}
