import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  useCreateOrderMutation,
  useGetOfferQuery,
} from '../../../../api/services/buyerApi';
import {
  selectBuyerCalculations,
  selectBuyerOrder,
} from '../../../../feature/buyerState';
import ROUTER_CONFIG from '../../../../router/router-config';
import BuyerLayout from '../../layout/BuyerLayout';
import { BuyerCreateGlobalOrderType } from '../../types';
import BuyerOrderSecureCheckoutSubmitView from './BuyerOrderSecureCheckoutSubmitView';

export default function BuyerOrderSecureCheckoutSubmitPage() {
  const { push } = useHistory();
  const { orderId } = useParams<{ orderId: string }>();

  const [createOrder, { isSuccess }] = useCreateOrderMutation();
  const { data: offer } = useGetOfferQuery(orderId);
  const order = useSelector(selectBuyerOrder);
  const calculations = useSelector(selectBuyerCalculations);

  useEffect(() => {
    if (isSuccess) {
      push(
        ROUTER_CONFIG.BUYER_ORDER_SUBMITTED.getPath({
          orderId: offer?._id || '123',
        }),
      );
    }
  }, [isSuccess]);

  const onSubmit = () => {
    if (order) {
      const {
        accessories, insurance, reseller, lease, ...rest
      } = order;

      if (!reseller) return;

      const orderToCreate: BuyerCreateGlobalOrderType = {
        ...rest,
        accessories: accessories ? JSON.stringify(accessories) : undefined,
        reseller: JSON.stringify(reseller),
        insurance: JSON.stringify(insurance),
        sellerOfferId: orderId,
        services: rest.services || [],
        quantity: +rest.quantity,
        lease: lease ? JSON.stringify(lease) : undefined,
      };
      createOrder(orderToCreate);
    }
  };

  return (
    <BuyerLayout>
      {offer && (
        <BuyerOrderSecureCheckoutSubmitView
          onSubmit={onSubmit}
          order={order}
          offer={offer}
          calculations={calculations}
        />
      )}
    </BuyerLayout>
  );
}
