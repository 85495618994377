import styled from 'styled-components/macro';
import { useState } from 'react';
import { Table, DatePicker, Input } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { PayoutsTableRow } from '../types';
import IconSearch from '../../../img/icons/icon_search.svg';

interface PayoutsTableProps {
  tableData: Array<PayoutsTableRow>;
}

export default function PayoutsTable({ tableData }: PayoutsTableProps) {
  const [dateSearchValue, setDateSearchValue] = useState('');
  const [schoolSearchValue, setSchoolSearchValue] = useState('');
  const [campaignSearchValue, setCampaignSearchValue] = useState('');
  const [totalAmountSearchValue, setTotalAmountSearchValue] = useState('');

  const TableDateColumnCell = (
    <TableColumnCell>
      Date
      <DatePicker onChange={(value, dateString) => setDateSearchValue(dateString)} format="DD MMM YYYY" />
    </TableColumnCell>
  );
  const TableSchoolColumnCell = (
    <TableColumnCell>
      School
      <Input value={schoolSearchValue} onChange={(e) => setSchoolSearchValue(e.target.value)} prefix={<InputPrefix iconSource={IconSearch} />} />
    </TableColumnCell>
  );
  const TableCampaignIdColumnCell = (
    <TableColumnCell>
      Campaign ID
      <Input value={campaignSearchValue} onChange={(e) => setCampaignSearchValue(e.target.value)} prefix={<InputPrefix iconSource={IconSearch} />} />
    </TableColumnCell>
  );
  const TableTotalAmountColumnCell = (
    <TableColumnCell>
      Total amount
      <Input value={totalAmountSearchValue} onChange={(e) => setTotalAmountSearchValue(e.target.value)} prefix={<InputPrefix iconSource={IconSearch} />} />
    </TableColumnCell>
  );

  const tableConfig: ColumnsType<PayoutsTableRow> = [
    {
      title: TableDateColumnCell,
      dataIndex: 'date',
      key: 'date',
      render: (text: Text) => <DateSpan>{text}</DateSpan>,
    },
    {
      title: TableTotalAmountColumnCell,
      dataIndex: 'totalAmount',
      key: 'totalAmount',
    },
    {
      title: TableSchoolColumnCell,
      dataIndex: 'school',
      key: 'school',
    },
    {
      title: TableCampaignIdColumnCell,
      dataIndex: 'campaignId',
      key: 'campaignId',
    },
    {
      title: 'Balance transfer statement',
      dataIndex: 'balanceTransferStatement',
      key: 'balanceTransferStatement',
    },
    {
      title: 'Gift Aid amount',
      dataIndex: 'giftAidAmount',
      key: 'giftAidAmount',
    },
    {
      title: 'Gift Aid statement',
      dataIndex: 'giftAidStatement',
      key: 'giftAidStatement',
    },
  ];

  return <Table<PayoutsTableRow> columns={tableConfig} dataSource={tableData} />;
}

const TableColumnCell = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 8px;
`;

const DateSpan = styled.span`
  white-space: nowrap;
`;

const InputPrefix = styled.div<{ iconSource: string }>`
  box-sizing: border-box;
  width: 17px;
  height: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: ${(props) => `url(${props.iconSource})`};
  background-repeat: no-repeat;
  background-position: center center;
`;
