import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import DonorDashboard from './DonorDashboard';
import DonorLayout from '../layout/DonorLayoutWithTabs';
import {
  selectDonorCampaignsList, loadDonorCampaignsList, deleteCampaignForDonor, loadDonorCollections, selectDonorCollections,
} from '../../../feature/campaignState';

export default function DonorDashboardPage() {
  const [currentPeriod, setCurrentPeriod] = useState(new Date().getMonth() + 1);
  const [selectedCampaigns, setSelectedCampaigns] = useState<Array<string>>([]);

  const onIncDateRange = () => {
    if (currentPeriod < 12) {
      setCurrentPeriod(currentPeriod + 1);
    } else {
      setCurrentPeriod(1);
    }
  };
  const onDecrDateRange = () => {
    if (currentPeriod > 1) {
      setCurrentPeriod(currentPeriod - 1);
    } else {
      setCurrentPeriod(12);
    }
  };

  const donorCampaignsList = useSelector(selectDonorCampaignsList);
  const donorCollections = useSelector(selectDonorCollections);
  const dispatch = useDispatch();

  useEffect(() => {
    if (donorCampaignsList.length === 0) {
      dispatch(loadDonorCampaignsList());
    }
  }, [dispatch]);

  useEffect(() => {
    const startDate = new Date(new Date().getFullYear(), currentPeriod - 1, 1).toISOString();
    const endDate = new Date(new Date().getFullYear(), currentPeriod, 1, 23, 59, 0).toISOString();

    dispatch(loadDonorCollections(startDate, endDate));
  }, [dispatch, currentPeriod]);

  const onCancelDonationsClickHandler = () => {
    // TODO fix it
    selectedCampaigns.forEach((campaignId) => dispatch(deleteCampaignForDonor(campaignId)));
  };

  return (
    <DonorLayout>
      <DonorDashboard onCancelDonationsClickHandler={onCancelDonationsClickHandler} donorCampaignsList={donorCampaignsList} donorCollections={donorCollections.donationsList} donorStatistics={donorCollections.stats} currentPeriodMonth={currentPeriod} onIncDateRange={onIncDateRange} onDecrDateRange={onDecrDateRange} selectedCampaigns={selectedCampaigns} onCampaignSelectHandler={setSelectedCampaigns} />
    </DonorLayout>
  );
}
