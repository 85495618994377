import styled from 'styled-components/macro';
import InfoIcon from '../../../img/icons/info_icon.svg';

interface MobileMenuProps {
  isOpen: boolean;
  onDeviceTabClickHandler: (tab: string) => void;
  categoriesLinks: Array<string>;
}

export default function MobileMenu({ isOpen, onDeviceTabClickHandler, categoriesLinks }: MobileMenuProps) {
  if (!isOpen) return null;

  return (
    <Container>
      <div>
        <MenuGroup>
          <MenuLabel>Devices</MenuLabel>
          {categoriesLinks.map((t) => (
            <MenuLink onClick={() => onDeviceTabClickHandler(t)} key={t}>{t}</MenuLink>
          ))}
        </MenuGroup>
        <MenuGroup>
          <MenuLabel>Services</MenuLabel>
          <MenuLink href="/static-page/page/lease" target="_blank">
            Leases
          </MenuLink>
          <MenuLink href="/static-page/page/insurance" target="_blank">
            Device Insurance
          </MenuLink>
        </MenuGroup>
        <MenuGroup>
          <MenuLabel>Scheme Support</MenuLabel>
          <MenuLink href="/static-page/page/collections" target="_blank">
            Collection Campaigns
          </MenuLink>
          <MenuLink>
            Device Trade-in
          </MenuLink>
        </MenuGroup>
      </div>
      <InfoBlock>
        <img src={InfoIcon} alt="" style={{ width: 14, height: 14 }} />
        <Text>
          To access your account’s orders, quotes and scheme support. Please login on a desktop device as we do not currently support mobile browsing for account administration.
        </Text>
      </InfoBlock>
    </Container>
  );
}

const Container = styled.div`
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 15;
  background-color: #fff;
  padding: 125px 46px 44px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const MenuLabel = styled.span`
  text-transform: uppercase;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  color: #6B7A87;
  margin-bottom: 16px;
`;

const MenuLink = styled.a`
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #102F54;
  margin-bottom: 20px;
  cursor: pointer;
  text-decoration: none;

  &:last-child {
    margin-bottom: 0;
  }
`;

const Text = styled.span`
  font-size: 14px;
  line-height: 17px;
  color: #102F54;
`;

const MenuGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 26px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const InfoBlock = styled.div`
  padding: 16px 12px;

  display: flex;
  flex-direction: column;
  gap: 11px;
  border-radius: 5px;
  background-color: #F5F8FB;
`;
