import styled from 'styled-components/macro';
import { Tabs } from 'antd';
import { ButtonDark } from '../../../../ui/Button';
import { QuotesTableRow } from '../../types';
import QuotesTable from './QuotesTable';

const { TabPane } = Tabs;

interface QuotesOverviewProps {
  tableData: Array<QuotesTableRow>;
  onViewDocClickHandler: (id: string) => void;
  onBuildQuoteClickHandler: () => void;
}

export default function QuotesOverview({ tableData, onViewDocClickHandler, onBuildQuoteClickHandler }: QuotesOverviewProps) {
  return (
    <Container>
      <Title>Quotes</Title>
      <StyledButtonDark onClick={onBuildQuoteClickHandler}>Build Quote</StyledButtonDark>
      <Tabs defaultActiveKey="1">
        <StyledTabPane tab="All Quotes" key="1">
          <QuotesTable
            tableData={tableData}
            onViewDocClickHandler={onViewDocClickHandler}
          />
        </StyledTabPane>
      </Tabs>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h1`
  margin: 0;
  margin-bottom: 10px;

  font-size: 30px;
  line-height: 37px;
  color: #102F54;
  font-weight: bold;
`;

const StyledButtonDark = styled(ButtonDark)`
  padding: 13px 100px;
  align-self: flex-end;
`;

const StyledTabPane = styled(TabPane)`
  padding-top: 40px;
`;
