import { useFormik } from 'formik';
import styled from 'styled-components/macro';
import { renderFormConfig, FormConfig } from '../../../../../utils/form';
import { ButtonDark } from '../../../../../ui/Button';
import { buyerInviteUserSchema } from '../../../buyer-schemas';
import { BuyerInviteUserType } from '../../../types';
import Input from '../../../../../ui/forms/Input';
import CloseIcon from '../../../../../img/close.svg';

const StyledInput = styled(Input)`
  height: unset;
  padding: 12px;
  width: 100%;
  max-width: 310px;
`;

const formConfig: FormConfig = [
  {
    title: 'First Name',
    name: 'firstName',
    component: StyledInput,
    isRequired: true,
  },
  {
    title: 'Second Name',
    name: 'lastName',
    component: StyledInput,
    isRequired: true,
  },
  {
    title: 'Email',
    name: 'email',
    component: StyledInput,
    isRequired: true,
  },
];

interface InviteNewUserModalContentProps {
  onClose: () => void;
  onSubmit: (values: BuyerInviteUserType) => void;
}

export default function InviteNewUserModalContent({ onClose, onSubmit }: InviteNewUserModalContentProps) {
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
    },
    validationSchema: buyerInviteUserSchema,
    onSubmit: (values) => {
      onSubmit(values);
      onClose();
    },
  });

  return (
    <Container onSubmit={formik.handleSubmit}>
      <CloseButton alt="" src={CloseIcon} onClick={onClose} />
      <Title>New User</Title>
      {renderFormConfig(formConfig, formik)}
      <StyledButtonDark type="submit">Invite User</StyledButtonDark>
    </Container>
  );
}

const Container = styled.form`
  width: 100vw;
  max-width: 570px;
  padding: 24px 45px;

  position: relative;
`;

const Title = styled.h1`
  margin: 0;
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
  color: #102f54;
  margin-bottom: 40px;
`;

const StyledButtonDark = styled(ButtonDark)`
  padding: 14px 70px;
  margin-top: 50px;
`;

const CloseButton = styled.img`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
`;
