import styled from 'styled-components/macro';
import { PROCCESSORS } from '../config';
import FeaturedProcessors from './FeaturedProcessors';
import ChartImage from '../../../../../../img/buyer-overview/chart_image.png';
import LinkWithArrow from '../../../../../../ui/elements/LinkWithArrow';

export default function ProPlatformTab() {
  return (
    <Container>
      <Content>
        <ContentLeftSide>
          <Title>The Intel vPro® Platform</Title>
          <Description>
            <DescriptionText>
              Keeping up with blended learning can be a challenge, but Intel vPro® with 12th Gen Intel® Core™ processors makes it easier than ever before to keep up with a widespread student body.  With manageability features that allow IT to keep a highly dispersed network patched and protected, stability features that allow for flexible rollouts and reliable lifecycle management with platform level validation. Plus, exclusive to Intel vPro, Intel® Hardware Shield.
              Hardware-based, multilayer security that helps reduce the attack surface of the PC.  Students can remain focused and protected thanks to active monitoring against attacks, which doesn’t slow down productivity due to lost performance.
            </DescriptionText>
            <LinkWithArrow
              linkText="Learn More About The Intel vPro® Platform"
              href="https://intel.co.uk/vpro"
              target="_blank"
            />
          </Description>
        </ContentLeftSide>
        <FeaturedProcessors processors={[PROCCESSORS.I7Pro, PROCCESSORS.I5Pro]} />
      </Content>
      <Title>How to best align Intel® processors with student use cases</Title>
      <ChartImageContainer>
        <ChartImageStyled />
      </ChartImageContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  gap: 90px;
  margin-bottom: 40px;

  @media (max-width: 660px) {
    flex-direction: column;
    gap: 40px;
  }
`;

const ContentLeftSide = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Title = styled.h3`
  margin: 0;
  padding: 0;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #102F54;
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const DescriptionText = styled.span`
  font-size: 16px;
  line-height: 19px;
  color: #102F54;
`;

const ChartImageContainer = styled.div`
  width: 100%;
  max-width: 720px;
  height: 310px;
  align-self: center;

  @media (max-width: 660px) {
    flex: 1;
  }
`;

const ChartImageStyled = styled.img.attrs({ src: ChartImage })`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
