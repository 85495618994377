import { Tabs } from 'antd';
import styled from 'styled-components/macro';
import {
  BuyerAddressPublicType, BuyerAdminUsersTableRow, BuyerCreateAddressType, BuyerInviteUserType, BuyerUpdateBillingDetails, IBuyerProfilePublic, UpdateBuyerOrganisationDetails, UpdateBuyerPersonalDetails,
} from '../types';
import AddressesTab from './settings-tabs/addresses-tab/AddressesTab';
import BillingTab from './settings-tabs/BillingTab';
import OrganisationDetailsTab from './settings-tabs/OrganisationDetailsTab';
import PersonalDetailsTab from './settings-tabs/PersonalDetailsTab';
import PreferredResellerTab from './settings-tabs/PreferredResellerTab';
import UsersTab from './settings-tabs/users-tab/UsersTab';

const { TabPane } = Tabs;

enum SETTINGS_TABS {
  ORGANISATION_DETAILS = 'Organisation Details',
  PREFERRED_RESELLER = 'Preferred Reseller',
  ADDRESSES = 'Addresses',
  USERS = 'Users',
  BILLING = 'Billing',
  PERSONAL_DETAILS = 'Personal Details',
}

interface BuyerSettingsViewProps {
  onOrganisationDetailsSubmit: (values: UpdateBuyerOrganisationDetails) => void;
  onPreferredResellerSubmit: (reseller: string) => void;
  preferredResellersOptions: Array<string>;
  addresses: Array<BuyerAddressPublicType>;
  onSaveNewAddress: (values: BuyerCreateAddressType) => void;
  onSaveEditAddress: (id: string, values: BuyerCreateAddressType) => void;
  usersTableData: Array<BuyerAdminUsersTableRow>;
  onSelectUser: (userIds: Array<string>) => void;
  selectedUsers: Array<string>;
  onRemoveUserClickHandler: () => void;
  onSubmitInviteUserForm: (values: BuyerInviteUserType) => void;
  onSubmitUpdateBillingDetails: (values: BuyerUpdateBillingDetails) => void;
  onSubmitUpdatePersonalDetails: (values: UpdateBuyerPersonalDetails) => void;
  onChangePasswordClickHandler: () => void;
  isBuyerAdmin: boolean;
  profileData: IBuyerProfilePublic;
}

export default function BuyerSettingsView({
  onOrganisationDetailsSubmit, onPreferredResellerSubmit, preferredResellersOptions, addresses, onSaveEditAddress, onSaveNewAddress, usersTableData, onSelectUser, onRemoveUserClickHandler, onSubmitInviteUserForm, selectedUsers, onSubmitUpdateBillingDetails, onSubmitUpdatePersonalDetails, onChangePasswordClickHandler, isBuyerAdmin, profileData,
}: BuyerSettingsViewProps) {
  return (
    <Container>
      <Title>Settings</Title>
      <Tabs destroyInactiveTabPane defaultActiveKey={isBuyerAdmin ? SETTINGS_TABS.ORGANISATION_DETAILS : SETTINGS_TABS.PERSONAL_DETAILS}>
        {isBuyerAdmin ? (
          <>
            <StyledTabPane tab={SETTINGS_TABS.ORGANISATION_DETAILS} key={SETTINGS_TABS.ORGANISATION_DETAILS}>
              <OrganisationDetailsTab onSubmit={onOrganisationDetailsSubmit} />
            </StyledTabPane>
            <StyledTabPane tab={SETTINGS_TABS.PREFERRED_RESELLER} key={SETTINGS_TABS.PREFERRED_RESELLER}>
              <PreferredResellerTab
                onSubmit={onPreferredResellerSubmit}
                options={preferredResellersOptions}
              />
            </StyledTabPane>
            <StyledTabPane tab={SETTINGS_TABS.ADDRESSES} key={SETTINGS_TABS.ADDRESSES}>
              <AddressesTab
                addresses={addresses}
                onSaveEditAddress={onSaveEditAddress}
                onSaveNewAddress={onSaveNewAddress}
              />
            </StyledTabPane>
            <StyledTabPane tab={SETTINGS_TABS.USERS} key={SETTINGS_TABS.USERS}>
              <UsersTab
                usersTableData={usersTableData}
                onSelectUser={onSelectUser}
                selectedUsers={selectedUsers}
                onRemoveUserClickHandler={onRemoveUserClickHandler}
                onSubmitInviteUserForm={onSubmitInviteUserForm}
              />
            </StyledTabPane>
            <StyledTabPane tab={SETTINGS_TABS.BILLING} key={SETTINGS_TABS.BILLING}>
              <BillingTab
                onSubmit={onSubmitUpdateBillingDetails}
              />
            </StyledTabPane>
            <StyledTabPane tab={SETTINGS_TABS.PERSONAL_DETAILS} key={SETTINGS_TABS.PERSONAL_DETAILS}>
              <PersonalDetailsTab
                onSubmit={onSubmitUpdatePersonalDetails}
                onChangePasswordClickHandler={onChangePasswordClickHandler}
                profileData={profileData}
              />
            </StyledTabPane>
          </>
        ) : (
          <StyledTabPane tab={SETTINGS_TABS.PERSONAL_DETAILS} key={SETTINGS_TABS.PERSONAL_DETAILS}>
            <PersonalDetailsTab
              onSubmit={onSubmitUpdatePersonalDetails}
              onChangePasswordClickHandler={onChangePasswordClickHandler}
              profileData={profileData}
            />
          </StyledTabPane>
        )}
      </Tabs>
    </Container>
  );
}

const Container = styled.div``;

const Title = styled.h1`
  margin: 0;
  font-size: 30px;
  line-height: 37px;
  color: #102F54;
  font-weight: bold;
  margin-bottom: 40px;
`;

const StyledTabPane = styled(TabPane)`
  padding-top: 40px;
`;
