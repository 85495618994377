import styled from 'styled-components';
import Container from '../layout/Container';
import { ButtonDark } from '../../ui/Button';

const descriptionArr = [
  {
    id: 0,
    description: 'Create a School Donation Scheme',
  },
  {
    id: 1,
    description: 'Automatically get Gift Aid',
  },
];

export default function WelcomeContent() {
  const descriptionListRender = () => descriptionArr.map((el) => <DescriptionListItem>{el.description}</DescriptionListItem>);
  return (
    <Wrapper>
      <Column1>
        <Title>Raise money for your school with Silverwing</Title>
        <DescriptionList>{descriptionListRender()}</DescriptionList>
        <ButtonBox>
          <ButtonHero>Start fundraising</ButtonHero>
        </ButtonBox>
      </Column1>
      <Column2>
        <Image />
      </Column2>
    </Wrapper>
  );
}

const Column1 = styled.div`
  width: 50%;
`;

const ButtonHero = styled(ButtonDark)`
  width: 424px;
  height: 65px;
`;

const Column2 = styled.div`
`;

const Title = styled.h2`
  font-weight: 750;
  font-size: 50px;
  line-height: 61px;
  margin-bottom: 48px;

  @media only screen and (max-width: 1100px) {
    font-size: 40px;
    margin-bottom: 38px;
  }

  @media only screen and (max-width: 900px) {
    font-size: 35px;
  }
`;

const DescriptionList = styled.ul`
  margin-bottom: 70px;
  padding: 0;
  @media only screen and (max-width: 1100px) {
    margin-bottom: 50px;
  }
`;

const DescriptionListItem = styled.ul`
  font-weight: 450;
  font-size: 20px;
  line-height: 25px;
  padding-left: 38px;
  position: relative;
  &:not(:last-child) {
    margin-bottom: 47px;
    @media only screen and (max-width: 1100px) {
      margin-bottom: 37px;
    }
  }


  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background-image: url(${require('../../img/icons/icon_check.svg').default});
    width: 24px;
    height: 24px;
    @media only screen and (max-width: 770px) {
      left: 25%;
    }
  }
`;

const Wrapper = styled.div`
  padding: 60px 204px 43px 156px;
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 770px) {
    flex-direction: column;
  }
`;

const Image = styled.img.attrs({
  alt: 'placeholder',
  src: 'https://via.placeholder.com/550x440',
})`

  max-width: 100%;

`;

const ButtonBox = styled.div``;
