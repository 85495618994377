import { Table } from 'antd';
import styled from 'styled-components/macro';
import { ColumnsType } from 'antd/lib/table';
import { AvailableOffersTableRow } from '../../types';
import { ButtonDark } from '../../../../ui/Button';

interface AvailableOffersTableProps {
  tableData: Array<AvailableOffersTableRow>;
  onJoinClickHandler: (id: string) => void;
}

export default function AvailableOffersTable({ tableData, onJoinClickHandler }: AvailableOffersTableProps) {
  const tableConfig: ColumnsType<AvailableOffersTableRow> = [
    {
      title: 'SKU',
      key: 'sku',
      dataIndex: 'sku',
    },
    {
      title: 'OEM',
      key: 'oem',
      dataIndex: 'oem',
    },
    {
      title: 'Product',
      key: 'product',
      dataIndex: 'product',
    },
    {
      title: 'Available Quantity',
      key: 'availableQuantity',
      dataIndex: 'availableQuantity',
      render: (_v, record) => (
        <div>
          <Text>{record.availableQuantity}</Text>
          /
          <GrayText>{record.totalQuantity}</GrayText>
        </div>
      ),
    },
    {
      title: 'Indicative Reseller Buy Price',
      key: 'resellerBuyPrice',
      dataIndex: 'resellerBuyPrice',
    },
    {
      title: '',
      render: (_v, record) => <GoToButton onClick={() => onJoinClickHandler(record.key)}>Join</GoToButton>,
    },
  ];

  return (
    <Table
      dataSource={tableData}
      columns={tableConfig}
      style={{ borderTop: '2px solid #2682DE', marginRight: 2 }}
    />
  );
}

const Text = styled.span`
  font-size: 14px;
  line-height: 17px;
`;

const GrayText = styled(Text)`
  color: #98a6b4;
`;

const GoToButton = styled(ButtonDark)`
  padding: 8px 35px;
`;
