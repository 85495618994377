import styled from 'styled-components/macro';
import { SearchOutlined } from '@ant-design/icons';

interface SearchInputProps {
  setFieldValue: (field: string, value: string) => void;
  name: string;
  value: string;
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  onSearchClick: () => void;
}

export default function SearchInput({
  setFieldValue, name, value, className, placeholder, disabled, onSearchClick,
}: SearchInputProps) {
  return (
    <Container className={className}>
      <Input disabled={disabled} value={value} onChange={(e) => setFieldValue(name, e.target.value)} placeholder={placeholder} />
      <StyledSearch onClick={onSearchClick} />
    </Container>
  );
}

const Container = styled.div`
  box-sizing: border-box;
  background: #FFFFFF;
  border: 1px solid #C4C4C4;
  border-radius: 4px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
`;

const Input = styled.input`
  flex: 1;
  box-sizing: border-box;
  border-radius: 4px;
  border: none;
  outline: none;
`;

const StyledSearch = styled(SearchOutlined)`
  font-size: 18px;
  cursor: pointer;
  margin-left: 10px;
`;
