import styled from 'styled-components/macro';
import { ButtonDark } from '../../../../ui/Button';
import FindYourAddress from '../../../../ui/forms/FindYourAddress';
import Input from '../../../../ui/forms/Input';
import { renderFormConfig } from '../../../../utils/form';
import PostcodeInput from '../../create-school/PostcodeInput';

interface SchoolDetailsFormProps {
  formik: any;
}

export default function SchoolDetailsForm({ formik }: SchoolDetailsFormProps) {
  return (
    <Container>
      <Title>School Details</Title>
      {renderFormConfig([
        {
          title: 'School Name',
          name: 'schoolName',
          component: ShortInputStyled,
        },
        {
          title: 'Postcode',
          name: 'postcode',
          component: PostcodeInputStyled,
        },
      ], formik)}
      <FindYourAddress postCode={formik.values.postcode} formikInstance={formik} />
      {
        renderFormConfig([
          {
            title: 'Address line 1',
            name: 'adress1',
            component: InputStyled,
          },
          {
            title: 'Address line 2',
            name: 'adress2',
            component: InputStyled,
          },
          {
            title: 'Address line 3',
            name: 'adress3',
            component: InputStyled,
          },
          {
            title: 'Town',
            name: 'town',
            component: InputStyled,
          },
          {
            title: 'Country:',
            name: 'country',
            component: InputStyled,
          },
          {
            title: 'Phone number',
            name: 'phoneNumber',
            component: InputStyled,
          },
        ], formik)
      }
      <SubmitStyled type="submit">Save</SubmitStyled>
    </Container>
  );
}

const Container = styled.div`
  margin-top: 40px;
`;

const Title = styled.h2`
  font-size: 20px;
  margin: 0;
  margin-bottom: 24px;
  font-weight: bold;
`;

const InputStyled = styled(Input)`
  width: 100%;
  max-width: 421px;

  &:disabled {
    background: #f2f2f2;
  }
`;

const PostcodeInputStyled = styled(PostcodeInput)`
  max-width: 421px;

  &:disabled {
    background: #f2f2f2;
  }
`;

const SubmitStyled = styled(ButtonDark)`
  padding: 14px 67px;
  margin-top: 80px;
`;

const ShortInputStyled = styled(InputStyled)`
  max-width: 313px;
`;
