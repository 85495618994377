import styled from 'styled-components/macro';
import BigSurfaceImage from '../../../../img/test_images/big_surface.png';
import { showPriceDigits } from '../../../../utils/formats';

export default function QuoteSummary() {
  return (
    <>
      <Title>Quote Summary</Title>
      <Container>
        <ItemBlock>
          <ItemImageWrapper>
            <ItemImage alt="" src={BigSurfaceImage} />
          </ItemImageWrapper>
          <ItemInfoBlock>
            <ItemInfo>
              <Text>Microsoft Surface Go 2</Text>
              <NormalText>50 Units</NormalText>
              <NormalText>Items sold by: Demo Reseller A</NormalText>
              <CustomLink>Terms and conditions</CustomLink>
            </ItemInfo>
            <Text>{showPriceDigits(293.50)}</Text>
          </ItemInfoBlock>
        </ItemBlock>
        <InfoBlock>
          <Divider />
          <SpaceBetweenItem>
            <Text>Standart Delivery</Text>
            <Text>{showPriceDigits(5.25)}</Text>
          </SpaceBetweenItem>
          <Divider />
          <CalculationsBlock>
            <SpaceBetweenItem>
              <Text>Subtotal (Ex-VAT)</Text>
              <Text>{showPriceDigits(14660.25)}</Text>
            </SpaceBetweenItem>
            <SpaceBetweenItem>
              <Text>VAT (20%)</Text>
              <Text>{showPriceDigits(2932.05)}</Text>
            </SpaceBetweenItem>
            <SpaceBetweenItem>
              <Text>Total (Inc-VAT)</Text>
              <Text style={{ color: '#DE2644' }}>{showPriceDigits(17592.30)}</Text>
            </SpaceBetweenItem>
          </CalculationsBlock>
          <Divider />
          <Text>Indicative Lease Pricing</Text>
          <InfoTitleBlock>
            <InfoTitleItem>
              <GrayText>Lease Provider</GrayText>
              <Text>Wesleyan Bank</Text>
            </InfoTitleItem>
            <InfoTitleItem>
              <GrayText>Lease Length</GrayText>
              <Text>3 Years</Text>
            </InfoTitleItem>
            <InfoTitleItem>
              <GrayText>Payment Schedule</GrayText>
              <Text>Quarterly (In arrears)</Text>
            </InfoTitleItem>
            <InfoTitleItem>
              <GrayText>Quarterly Amount</GrayText>
              <Text>{showPriceDigits(1175)}</Text>
            </InfoTitleItem>
          </InfoTitleBlock>
          <BottomCalculationsBlock>
            <SpaceBetweenItem>
              <InfoTitleItem>
                <Text>Subtotal (Ex-VAT)</Text>
                <GrayText>Billed Quarterly</GrayText>
              </InfoTitleItem>
              <Text>{showPriceDigits(1118)}</Text>
            </SpaceBetweenItem>
            <SpaceBetweenItem>
              <InfoTitleItem>
                <Text>VAT (20%)</Text>
                <GrayText>Billed Quarterly</GrayText>
              </InfoTitleItem>
              <Text>{showPriceDigits(223.60)}</Text>
            </SpaceBetweenItem>
            <SpaceBetweenItem>
              <InfoTitleItem>
                <Text>Total (Inc-VAT)</Text>
                <GrayText>Billed Quarterly</GrayText>
              </InfoTitleItem>
              <Text>{showPriceDigits(1341.60)}</Text>
            </SpaceBetweenItem>
          </BottomCalculationsBlock>
        </InfoBlock>
      </Container>
    </>
  );
}

const Container = styled.div`
  box-sizing: border-box;
  max-width: 650px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  border: 1px solid #F9F9F9;
  border-top: none;
  padding: 20px;
`;

const Title = styled.h3`
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #102F54;
  margin: 0;
`;

const Text = styled.span`
  font-size: 14px;
  line-height: 14px;
  color: #102f54;
  font-weight: bold;
`;

const NormalText = styled(Text)`
  font-weight: normal;
`;

const GrayText = styled(Text)`
  color: #6B7A87;
`;

const ItemBlock = styled.div`
  display: flex;
  flex-direction: row;
  gap: 25px;
`;

const ItemInfoBlock = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
`;

const ItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ItemImageWrapper = styled.div`
  max-width: 70px;
  max-height: 70px;
`;

const CustomLink = styled(NormalText)`
  color: #2682DE;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const ItemImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* border: 1px solid #F9F9F9; */
  /* padding: 20px; */
`;

const Divider = styled.div`
  height: 1px;
  width: 100%;
  border-top: 1px solid #d5dce5;
`;

const CalculationsBlock = styled.div`
  width: 240px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-self: flex-end;
`;

const SpaceBetweenItem = styled.div`
  display: flex;
  justify-content: space-between;
`;

const InfoTitleBlock = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 11px 20px;
  background-color: #FAFBFC;
`;

const InfoTitleItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const BottomCalculationsBlock = styled(CalculationsBlock)`
  width: 260px;
  gap: 17px;
`;

//! 400 / 600
