import styled from 'styled-components/macro';
import DropDown, { DropDownItem } from '../../../../ui/DropDown';

export default function BuyerServicesMenu() {
  return (
    <StyledDropdown title="Services">
      <StyledText>Services</StyledText>
      <DropDownItem title="Device Insurance" href="/static-page/page/insurance" />
      <DropDownItem title="Leases" href="/static-page/page/lease" />
    </StyledDropdown>
  );
}

const StyledDropdown = styled(DropDown)`
  cursor: pointer;

  .dropdown__title {
    font-size: 14px;
  }
`;

const StyledText = styled.span`
  font-size: 10px;
  color: #909DA8;
  text-transform: uppercase;
  padding: 10px;
`;
