import { UserRegisterType } from '../../project-types/users/types';

export enum ProductTypesEnum {
  DEVICE = 'DEVICE',
  ACCESSORY = 'ACCESSORY',
}

export enum PAYMENT_SCHEDULE_ENUM {
  MONTHLY = 'monthly',
  QUARTERLY = 'quarterly',
  ANNUALLY = 'annually',
}

export enum ORDER_TYPES {
  'Outright Purchase' = 'Outright Purchase',
  'Operating Lease' = 'Operating Lease',
  'Financing Lease' = 'Financing Lease',
}

export interface ErrorData {
  data: ResFieldsError | ResGlobalError;
  status: number;
}

export interface ResFieldsError {
  error: Record<string, string[]>;
}

export interface ResGlobalError {
  message: string;
  error: string;
}

export interface BuyerRegisterType {
  school: string;
  firstName: string;
  lastName: string;
  workEmail: string;
  phoneNumber?: string;
  jobTitle?: string;
  preferredReseller?: string;
  isStartingParentalContributionScheme?: boolean;
  percentageOfTheDeviceCostsForParentsToContribute?: number;
  preferredPurchaseMethod?: ORDER_TYPES;
  agree: boolean;
  password: string;
  confirmPassword: string;
  devicesToPurchaseCount?: number;
  schoolAddress: string;
}

export interface BuyerOrdersOverviewTableRow {
  key: string;
  dateSubmitted: string;
  poNumber: string;
  products: string;
  orderType: ORDER_TYPES;
  status: ORDER_STATUS_ENUM;
  total: string;
}

export interface BuyerQuotesOverviewTableRow {
  key: string;
  dateSubmitted: string;
  reference: string;
  supplier: string;
  quoteExpirationDate: string;
  orderType: ORDER_TYPES;
}

export interface UpdateBuyerOrganisationDetails {
  adress1: string;
  adress2?: string;
  adress3?: string;
  town: string;
  country: string;
  postcode: string;
  phoneNumber: string;
}

export interface BuyerCreateAddressType
  extends Omit<UpdateBuyerOrganisationDetails, 'phoneNumber'> {}

export interface BuyerAddressPublicType extends BuyerCreateAddressType {
  id: string;
}

export interface UpdateBuyerPersonalDetails {
  firstName: string;
  lastName: string;
}

export interface BuyerInviteUserType extends UpdateBuyerPersonalDetails {
  email: string;
}

export interface BuyerAdminUsersTableRow {
  key: string;
  user: string;
  email: string;
  role: string;
  status: string;
}

export interface BuyerUpdateBillingDetails {
  bankAccountName: string;
  branchSortCode1: string;
  branchSortCode2: string;
  branchSortCode3: string;
  accountNumber: string;
}

// overview page types

export interface BuyerCategoryType {
  name: string;
  image?: string;
  onViewClick: () => void;
}

export interface BuyerOverviewServiceType {
  serviceName: string;
  description: string;
  onLearnClick: () => void;
  bgColor: string;
}

export interface IAccessory {
  _id: string;
  SKU: string;
  name: string;
  description: string;
  manufacturer: string;
  defaultVatRate: number;
  otherPhotosUrl: string[];
  relatedFilesUrl: string[];
  headlines: string;
  attributes: string[];
  overview: string;
  insuranceAvailable: boolean;
  operatingLeasePrice: number;
  outrightPurchasePrice: number;
  imageUrl: string;
  category: string;
  relatedProducts?: string[];
  techSpecs?: Record<string, string>;
}

export interface IAccessorySelected extends ISellerOfferPublicType {
  quantity: number;
}

export interface IProduct extends IAccessory {
  accessories?: Array<IAccessory>;
}

// create order types

interface BuyerCreateOrderType {
  productId: string;
  orderType: ORDER_TYPES;
  quantity: number;
}

export interface BuyerCreateLeaseOrderType extends BuyerCreateOrderType {
  termLength: string;
  paymentSchedule: string;
}

export interface BuyerCreateOutrightPurchaseOrderType
  extends BuyerCreateOrderType {
  includeDeviceInsurance: boolean;
}

export type OmitProductId<T extends { productId: string }> = Omit<
  T,
  'productId'
>;

// configure order types

export enum CONFIGURE_ORDER_STEPS {
  SELECT_RESELLER = 'Select Your Reseller',
  MODIFY_INSURANCE = 'Modify Insurance',
  SELECT_ACCESSORIES = 'Select Accessories',
  CHECKOUT = 'Checkout',
}

// export enum RESELLER_SERVICES {
//   ASSET_TAGGING = 'Asset tagging',
//   INSTALLATION = 'Installation',
//   DEVICE_DISPOSAL = 'Device disposal',
//   WARRANTY_REGISTRATION = 'Warranty registration',
//   BIOS_PASSWORD_SETUP = 'Bios Password Set-up',
// }

export interface IOrderAccessories {
  image?: string;
  name: string;
  price: number;
  description: string;
}

export interface IService {
  serviceName: string;
  description: string;
  price: number;
  serviceIcon?: string;
}

export interface IReseller {
  _id: string;
  name: string;
  deliveryPrice: number;
  devicePrice: number;
  availableServices: IService[];
  accessories: IOrderAccessories[];
  resellerImage?: string;
}

export interface IInsurance {
  _id: string;
  insurer: string;
  name: string;
  price: number;
  policyLength: string;
  paymentSchedule: string;
}

export interface BuyerOrderDeviceType {
  image: string;
  name: string;
  price: number;
}

export interface BuyerSelectedOrderAccessoriesType extends IOrderAccessories {
  quantity: number;
}

export interface BuyerSelectedOrderDeviceType extends BuyerOrderDeviceType {
  quantity: number;
}

export interface BuyerSecureCheckoutFormType {
  deliveryOption: string;
  attentionOf: string;
  deliveryAddress: string;
  deliveryNotes?: string;
  contactName: string;
  contactEmail: string;
  invoiceAddress: string;
}

export type BuyerLoginFields = UserRegisterType;

export interface BuyerLoginRes {
  accessToken: string;
}

export interface ICreateQuote {
  orderType: ORDER_TYPES;

  // orderType = OUTRIGHT PURCHASE
  includeDeviceInsurance?: boolean;

  // orderType = LEASE
  termLength?: string;
  paymentSchedule?: string;

  productId: string;
  quantity: number;
  reseller: string;
  services?: Array<IService>;
  lease?: string;
  insurance?: string;
  accessories?: string;
  sellerOfferId: string;
}

export interface BuyerCreateGlobalOrderType
  extends ICreateQuote,
    BuyerSecureCheckoutFormType {}

export interface IBuyerOrderPublic
  extends Omit<
    BuyerCreateGlobalOrderType,
    'insurance' | 'reseller' | 'accessories' | 'lease'
  > {
  _id: string;
  user: string;
  status: ORDER_STATUS_ENUM;
  insurance?: IInsurance;
  reseller: IReseller;
  accessories?: Array<IAccessorySelected>;
  lease?: ILeasePublicType;
}

export enum ORDER_STATUS_ENUM {
  QUOTE = 'Quote',
  PLACED = 'Placed',
  ACCEPTED = 'Accepted',
  REJECTED = 'Rejected',
}

export interface IBuyerQuotePublic
  extends Omit<
    ICreateQuote,
    'insurance' | 'reseller' | 'accessories' | 'lease'
  > {
  _id: string;
  __v?: number;
  user: string;
  status: ORDER_STATUS_ENUM;
  insurance?: IInsurance;
  reseller: IReseller;
  accessories?: Array<IAccessorySelected>;
  lease?: ILeasePublicType;
}

export interface IBuyerProfilePublic extends BuyerRegisterType {
  _id: string;
}

// {
//   "_id": "629d23213f06c4c3a7ff1d9d",
//   "type": "Operating Lease",
//   "rate": 0.1,
//   "residualValueRate": 0.02,
//   "policyLength": 3,
//   "description": "string",
//   "paymentSchedule": "Quarterly",
//   "__v": 0
// }

export interface ILeasePublicType {
  _id: string;
  type: string;
  rate: number;
  residualValueRate: number;
  policyLength: string;
  paymentSchedule: string;
  description: string;
}

export interface IOfferPublicType {
  _id: string;
  offerNumber: string;
  offerGroup: ICategoryPublicType;
  startDate: Date;
  endData: Date;
  platformFee: number;
  product: IProduct;
  oem: string;
  quantity: number;
  stockAvailableDate: Date;
  indicativeBuyerPrice: number;
  nte: number;
  eligibleSellers: Array<string>;
  allowForAllSellers?: boolean;
  insurances?: Array<IInsurance>;
  leases?: Array<ILeasePublicType>;
}

export interface ISellerOfferPublicType {
  _id: string;
  offer: IOfferPublicType;
  seller: IReseller;
  price: number;
  leasePrice: number;
}

export interface ISchool {
  address: string;
  schoolName: string;
}
export interface GroupedOfferItemType {
  offers: Array<ISellerOfferPublicType>;
  minOutrightPurchasePrice: number;
  minLeasePrice: number;
  product: IProduct;
}

export type GroupedOffersByProductType = {
  [key: string]: GroupedOfferItemType;
};

export enum CATEGORY_GROUPTYPE {
  TOP = 'top',
  BOTTOM = 'bottom',
}

export enum OVERVIEW_TABS_ICONS {
  PRO = 'pro',
  STUDENTS = 'students',
  TEACHERS = 'teachers',
  ESPORTS = 'esports',
  LEARNING = 'learning',
}

export interface ICategoryPublicType {
  _id: string;
  name: string;
  icons?: OVERVIEW_TABS_ICONS;
  groupType: CATEGORY_GROUPTYPE;
}
