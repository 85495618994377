import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { message } from 'antd';
import { AppThunk, RootState } from '../store';
import { request } from '../api';
import { HTTP_METHODS } from '../project-types/common/http-types';
import { createAxiosConfig } from '../project-types/common/axios';
import { ApiGetProfile } from '../project-types/users/api-types';
import { ApiUpdateDonor } from '../project-types/donor/api-types';

// const a: ProfileState = { user: id };

type ProfileState = {
  user?: ApiGetProfile['successResponse']
};

const initialProfileState: ProfileState = {
  user: undefined,
};

export const userProfile = createSlice({
  name: 'UserProfile',
  initialState: initialProfileState,
  reducers: {
    setCurrentProfile: (state, action: PayloadAction<ProfileState['user']>) => ({ user: action.payload }),
  },
});

export const { setCurrentProfile } = userProfile.actions;

export const loadProfile = (): AppThunk => (dispatch) => {
  request(createAxiosConfig({
    url: ['/user'],
    method: HTTP_METHODS.GET,
  }), [200, 401, 403]).then(({ data, status }) => {
    if (status === 200) {
      dispatch(setCurrentProfile(data));
    } else if (status === 401) {
      message.error('Auth error');
    }
  });
};

export const changeDonorProfile = (formData: ApiUpdateDonor['data'], callbackFn?: () => void): AppThunk => (dispatch) => {
  request(createAxiosConfig<ApiUpdateDonor>({
    url: ['/donors'],
    data: formData,
    method: HTTP_METHODS.PATCH,
  })).then(() => {
    dispatch(loadProfile());
    message.success('Profile successfully changed');

    if (callbackFn) {
      callbackFn();
    }
  }).catch((err) => {
    if (err.response.data.errors && err.response.data.errors.donor && err.response.data.errors.donor[0]) {
      message.error(err.response.data.errors.donor[0]);
    } else {
      message.error('Changing profile error');
    }
  });
};

export const restorePassword = (email: string, onSuccessCb: () => void) => request(createAxiosConfig({
  url: ['/restore-pass'],
  method: HTTP_METHODS.POST,
  data: {
    email,
  },
})).then((res: { data: { message: string } }) => {
  message.success(res.data.message);
  onSuccessCb();
}).catch((err) => {
  if (err.response.data.errors && err.response.data.errors.user && err.response.data.errors.user[0]) {
    message.error(err.response.data.errors.user[0]);
  }
});

export default userProfile.reducer;

export const getUserProfile = (state: RootState) => state.userProfile?.user;
export const getIsUserAuth = (state: RootState) => !!state.userProfile?.user;
