import { message } from 'antd';
import { useEffect, useState } from 'react';
import { useGetProfileInfoQuery, useUpdateProfileInfoMutation } from '../../../api/services/buyerApi';
import BuyerLayout from '../layout/BuyerLayout';
import {
  BuyerAddressPublicType, BuyerAdminUsersTableRow, BuyerCreateAddressType, BuyerInviteUserType, BuyerUpdateBillingDetails, UpdateBuyerOrganisationDetails, UpdateBuyerPersonalDetails,
} from '../types';
import BuyerSettingsView from './BuyerSettingsView';

export default function BuyerSettingsPage() {
  const [selectedUsers, setSelectedUsers] = useState<Array<string>>([]);

  const { data: profileData } = useGetProfileInfoQuery();
  const [updateProfile, { isSuccess, isError }] = useUpdateProfileInfoMutation();

  useEffect(() => {
    if (isSuccess) {
      message.success('Profile successfully updated');
    }

    if (isError) {
      message.error('An error occurred while updating the profile');
    }
  }, [isSuccess, isError]);

  console.log(profileData);

  const onOrganisationDetailsSubmit = (values: UpdateBuyerOrganisationDetails) => console.log(values);
  const onPreferredResellerSubmit = (reseller: string) => console.log(reseller);
  const onSaveNewAddress = (values: BuyerCreateAddressType) => console.log(values);
  const onSaveEditAddress = (id: string, values: BuyerCreateAddressType) => console.log(id, values);
  const onRemoveUserClickHandler = () => console.log(selectedUsers);
  const onSubmitInviteUserForm = (values: BuyerInviteUserType) => console.log(values);
  const onSubmitUpdateBillingDetails = (values: BuyerUpdateBillingDetails) => console.log(values);
  const onSubmitUpdatePersonalDetails = (values: UpdateBuyerPersonalDetails) => {
    updateProfile(values);
  };
  const onChangePasswordClickHandler = () => console.log('change password');

  const mockAddresses: Array<BuyerAddressPublicType> = [
    {
      id: '1',
      adress1: '1 The Precincts',
      town: 'Morden',
      postcode: 'SM4 5PB',
      country: 'England',
    },
  ];

  const mockUsersTableData: Array<BuyerAdminUsersTableRow> = [
    {
      key: '1',
      user: 'John Doe',
      email: 'johndoe@demoresellera.com',
      role: 'Admin',
      status: 'Active',
    },
    {
      key: '2',
      user: 'Tristen Cannon',
      email: 'johndoe@demoresellera.com',
      role: 'Standard',
      status: 'Active',
    },
  ];

  return (
    <BuyerLayout>
      {profileData && (
        <BuyerSettingsView
          onOrganisationDetailsSubmit={onOrganisationDetailsSubmit}
          onPreferredResellerSubmit={onPreferredResellerSubmit}
          preferredResellersOptions={['Demo Reseller A', 'Demo Reseller B', 'Demo Reseller C']}
          addresses={mockAddresses}
          onSaveNewAddress={onSaveNewAddress}
          onSaveEditAddress={onSaveEditAddress}
          selectedUsers={selectedUsers}
          onSelectUser={setSelectedUsers}
          usersTableData={mockUsersTableData}
          onRemoveUserClickHandler={onRemoveUserClickHandler}
          onSubmitInviteUserForm={onSubmitInviteUserForm}
          onSubmitUpdateBillingDetails={onSubmitUpdateBillingDetails}
          onSubmitUpdatePersonalDetails={onSubmitUpdatePersonalDetails}
          onChangePasswordClickHandler={onChangePasswordClickHandler}
          isBuyerAdmin={false}
          profileData={profileData}
        />
      )}
    </BuyerLayout>
  );
}
