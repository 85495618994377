import styled from 'styled-components/macro';
import { PROCCESSORS } from '../config';
import FeaturedProcessors from './FeaturedProcessors';
import ChartImage from '../../../../../../img/buyer-overview/chart_image.png';

export default function StudentsTab() {
  return (
    <Container>
      <Content>
        <ContentLeftSide>
          <Title>Students</Title>
          <Description>
            <DescriptionText>
              Processor requirements for your device vary based upon the way it
              will be used. Generally, learners need more performance as they
              move through year groups:
            </DescriptionText>
            <StyledUl>
              <StyledLi>
                <b>Younger school students</b> will use devices mostly for
                accessing content and basic productivity applications, so they
                need a device that supports those tasks without interruption.
              </StyledLi>
              <StyledLi>
                <b>Later year groups</b> are likely to be introduced to advanced
                skills and technologies, so they’ll need performance
                capabilities that allow them to explore the possibilities and
                build their skill sets.
              </StyledLi>
              <StyledLi>
                <b>Older year group students</b> at School or College will begin
                to use advanced software for modeling, analytics, content
                creation, and more—which means they’ll need powerful performance
                to best apply their skills and unleash their full potential.
              </StyledLi>
            </StyledUl>
            <DescriptionText>
              Of course, to make an optimal investment, you should future-proof
              your choices by considering both the needs of today and what will
              be needed in the coming years.
            </DescriptionText>
            <DescriptionText>
              The chart below shows you how to best align Intel® processors with
              student use cases.
            </DescriptionText>
          </Description>
        </ContentLeftSide>
        <FeaturedProcessors processors={[PROCCESSORS.I5, PROCCESSORS.I3]} />
      </Content>
      <Title>How to best align Intel® processors with student use cases</Title>
      <ChartImageContainer>
        <ChartImageStyled />
      </ChartImageContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  gap: 90px;
  margin-bottom: 40px;

  @media (max-width: 660px) {
    flex-direction: column;
    gap: 40px;
  }
`;

const ContentLeftSide = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex-shrink: 2;
`;

const Title = styled.h3`
  margin: 0;
  padding: 0;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #102F54;
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const DescriptionText = styled.span`
  font-size: 16px;
  line-height: 19px;
  color: #102F54;
`;

const StyledUl = styled.ul`
  list-style-position: inside;
  font-size: 16px;
  line-height: 19px;
  color: #102F54;
  margin: 0;
  padding: 0;
`;

const StyledLi = styled.li`
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const ChartImageContainer = styled.div`
  width: 100%;
  max-width: 720px;
  height: 310px;
  align-self: center;

  @media (max-width: 660px) {
    flex: 1;
  }
`;

const ChartImageStyled = styled.img.attrs({ src: ChartImage })`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
