import styled from 'styled-components/macro';
import silverwingLogo from '../../img/silverwingLogo.png';

interface LogoProps {
  className?: string,
  onClick?: () => void
}

export default function SilverwingLogo({ className, onClick }: LogoProps) {
  return (
    <LogoStyled onClick={onClick} alt="logo" className={className} />
  );
}

const LogoStyled = styled.img.attrs({ src: silverwingLogo })`
  width: 140px;
`;
