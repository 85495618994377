import styled from 'styled-components/macro';
import React from 'react';
import DropDown from '../../../ui/DropDown';
import AccountPlaceholderImage from '../../../img/icons/accountImage.svg';

interface AccountMenuProps {
  accountName: string;
  children: React.ReactNode;
}

export default function AccountMenu({
  accountName, children,
}: AccountMenuProps) {
  return (
    <Container>
      <img alt="" src={AccountPlaceholderImage} />
      <DropDown title={accountName}>
        {children}
      </DropDown>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
`;
