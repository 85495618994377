import styled from 'styled-components/macro';
import { ICategoryPublicType } from '../../../types';
import { TAB_ICONS } from './config';

interface TabButtonProps {
  tab: ICategoryPublicType;
  onClick: () => void;
  isSelected: boolean;
}

export default function TabButton({ tab, onClick, isSelected }: TabButtonProps) {
  return (
    <Container isSelected={isSelected} onClick={onClick}>
      {tab.icons && (
        <>
          {TAB_ICONS[tab.icons]}
        </>
      )}
      <Label>{tab.name}</Label>
    </Container>
  );
}

const Container = styled.div<{ isSelected: boolean }>`
  box-sizing: border-box;
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 15px 35px;
  border-bottom: ${({ isSelected }) => (isSelected ? '2px solid #2682DE' : '2px solid #fff')};
  background-color: ${({ isSelected }) => isSelected && '#E1EDFA'};
  transition: all 0.15s ease-in-out;

  &:hover {
    border-bottom: ${({ isSelected }) => !isSelected && '2px solid #88c0f8'};
  }

  @media (max-width: 660px) {
    padding: 15px 20px;
  }
`;

const Label = styled.span`
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #102f54;
  white-space: nowrap;
`;
