import styled from 'styled-components/macro';
import { CheckOutlined } from '@ant-design/icons';
import { CONFIGURE_ORDER_STEPS } from '../types';

interface StepsComponentProps {
  currentStep: CONFIGURE_ORDER_STEPS;
  onStepClick: (step: CONFIGURE_ORDER_STEPS) => void;
  completedSteps: Array<CONFIGURE_ORDER_STEPS>;
}

export default function StepsComponent({ currentStep, onStepClick, completedSteps }: StepsComponentProps) {
  return (
    <Container>
      <StepItem isComplete={completedSteps.includes(CONFIGURE_ORDER_STEPS.SELECT_RESELLER)} isCurrent={currentStep === CONFIGURE_ORDER_STEPS.SELECT_RESELLER}>
        <NumberInSquare>
          {completedSteps.includes(CONFIGURE_ORDER_STEPS.SELECT_RESELLER) ? (<StyledCheck />) : (1)}
        </NumberInSquare>
        <StepText onClick={() => onStepClick(CONFIGURE_ORDER_STEPS.SELECT_RESELLER)}>{CONFIGURE_ORDER_STEPS.SELECT_RESELLER}</StepText>
        <Line />
      </StepItem>
      <StepItem isComplete={completedSteps.includes(CONFIGURE_ORDER_STEPS.MODIFY_INSURANCE)} isCurrent={currentStep === CONFIGURE_ORDER_STEPS.MODIFY_INSURANCE}>
        <NumberInSquare>
          {completedSteps.includes(CONFIGURE_ORDER_STEPS.MODIFY_INSURANCE) ? (<StyledCheck />) : (2)}
        </NumberInSquare>
        <StepText onClick={() => onStepClick(CONFIGURE_ORDER_STEPS.MODIFY_INSURANCE)}>{CONFIGURE_ORDER_STEPS.MODIFY_INSURANCE}</StepText>
        <Line />
      </StepItem>
      <StepItem isComplete={completedSteps.includes(CONFIGURE_ORDER_STEPS.SELECT_ACCESSORIES)} isCurrent={currentStep === CONFIGURE_ORDER_STEPS.SELECT_ACCESSORIES}>
        <NumberInSquare>
          {completedSteps.includes(CONFIGURE_ORDER_STEPS.SELECT_ACCESSORIES) ? (<StyledCheck />) : (3)}
        </NumberInSquare>
        <StepText onClick={() => onStepClick(CONFIGURE_ORDER_STEPS.SELECT_ACCESSORIES)}>{CONFIGURE_ORDER_STEPS.SELECT_ACCESSORIES}</StepText>
        <Line />
      </StepItem>
      <StepItem isComplete={completedSteps.includes(CONFIGURE_ORDER_STEPS.CHECKOUT)} isCurrent={currentStep === CONFIGURE_ORDER_STEPS.CHECKOUT}>
        <NumberInSquare>
          {completedSteps.includes(CONFIGURE_ORDER_STEPS.CHECKOUT) ? (<StyledCheck />) : (4)}
        </NumberInSquare>
        <StepText isCheckout>{CONFIGURE_ORDER_STEPS.CHECKOUT}</StepText>
        <Line />
      </StepItem>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;

const NumberInSquare = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 22px;
  height: 22px;
  margin-right: 14px;

  border-radius: 3px;
  background-color: #E5EAEF;
  font: inherit;
`;

const StepItem = styled.div<{ isCurrent: boolean, isComplete: boolean }>`
  display: flex;
  align-items: center;
  color: ${({ isCurrent }) => (isCurrent ? '#2682DE' : '#6B7A87')};
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;

  ${NumberInSquare} {
    background-color: ${({ isCurrent }) => (isCurrent && '#E8F4FF')};
    background-color: ${({ isComplete }) => (isComplete && '#DCF7EF')};
  }
`;

const StepText = styled.span<{ isCheckout?: boolean }>`
  font: inherit;
  margin-right: 20px;
  cursor: ${({ isCheckout }) => !isCheckout && 'pointer'};

  &:hover {
    text-decoration: ${({ isCheckout }) => !isCheckout && 'underline'};
  }
`;

const Line = styled.div`
  width: 100px;
  height: 1px;
  background-color: #D5DCE5;
`;

const StyledCheck = styled(CheckOutlined)`
  color: #10C08A;
  font-size: 11px;
`;
