import { useHistory } from 'react-router-dom';
import ROUTER_CONFIG from '../../../router/router-config';
import { formatDateDDMMMYYYY } from '../../../ui/DateFormatMethods';
import { showPriceDigits } from '../../../utils/formats';
import SupplierLayout from '../layout/SupplierLayout';
import { OrdersOverviewTableRow } from '../types';
import Overview from './Overview';

export default function OverviewPage() {
  const { push } = useHistory();

  const onReviewClickHandler = (orderId: string, paymentType: string) => push(ROUTER_CONFIG.SUPPLIER_ORDER_DETAILS.getPath({ orderId, paymentType }));
  const onViewOrdersClickHandler = () => push(ROUTER_CONFIG.SUPPLIER_ORDERS.getPath());
  const onViewOffersClickHandler = () => push(ROUTER_CONFIG.SUPPLIER_OFFERS.getPath());

  const mockTableData: Array<OrdersOverviewTableRow> = new Array(2).fill(1).reduce((acc, item, index) => {
    const rowItem: OrdersOverviewTableRow = {
      key: `${index}`,
      dateSubmitted: formatDateDDMMMYYYY(new Date(`2${index} nov 2021`)),
      poNumber: `1231838${index}`,
      status: 'Placed',
      buyer: 'Mona Vale School',
      paymentMethod: 'Supplier Credit',
      total: showPriceDigits(20928),
    };
    acc.push(rowItem);
    return acc;
  }, []);

  return (
    <SupplierLayout>
      <Overview
        onReviewClickHandler={onReviewClickHandler}
        tableData={mockTableData}
        onViewOrdersClickHandler={onViewOrdersClickHandler}
        onViewOffersClickHandler={onViewOffersClickHandler}
      />
    </SupplierLayout>
  );
}
