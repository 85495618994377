import styled from 'styled-components/macro';
import { CheckOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import { useFormik } from 'formik';
import OrderTypeWrapper from './OrderTypeWrapper';
import Input from '../../../../ui/forms/Input';
import { ButtonDark } from '../../../../ui/Button';
import {
  BuyerCreateLeaseOrderType,
  ILeasePublicType,
  ISellerOfferPublicType,
  OmitProductId,
  ORDER_TYPES,
} from '../../types';
import { createBuyerLeaseOrderSchema } from '../../buyer-schemas';
import { mergeLeaseOptions } from '../../groupSellerOffersByProductId';
import { Link } from 'react-router-dom';
import ROUTER_CONFIG from '../../../../router/router-config';

const { Option } = Select;

interface LeasePurchaseOptionProps {
  isSelect: boolean;
  orderType: string;
  price: number;
  offers: Array<ISellerOfferPublicType>;
  onSelect: () => void;
  onCreateOrder: (values: OmitProductId<BuyerCreateLeaseOrderType>) => void;
  leases: Array<ILeasePublicType>;
}

export default function LeasePurchaseOption({
  isSelect,
  orderType,
  price,
  onSelect,
  onCreateOrder,
  leases,
  offers = [],
}: LeasePurchaseOptionProps) {
  const formik = useFormik({
    initialValues: {
      paymentSchedule: '',
      termLength: '',
      quantity: 1,
      orderType: '' as ORDER_TYPES,
    },
    validationSchema: createBuyerLeaseOrderSchema,
    onSubmit: onCreateOrder,
    isInitialValid: false,
  });

  const selectedLease = leases
    .filter((lease) => lease.type === formik.values.orderType)
    .filter((lease) => lease.policyLength === formik.values.termLength)
    .filter((lease) => lease.paymentSchedule === formik.values.paymentSchedule);

  const totalPrice =
    selectedLease && selectedLease.length
      ? (offers.find((offer) =>
          offer.offer.leases?.find(
            (lease) => lease._id === selectedLease[0]._id
          )
        )?.price || 1) * selectedLease[0].rate
      : 0;

  const availableLeases = leases
    .filter((lease) =>
      formik.values.paymentSchedule
        ? lease.paymentSchedule === formik.values.paymentSchedule
        : true
    )
    .filter((lease) =>
      formik.values.termLength
        ? lease.policyLength === formik.values.termLength
        : true
    )
    .filter((lease) =>
      formik.values.orderType ? lease.type === formik.values.orderType : true
    );

  const { leaseOptions: mergedLeaseOptions } = mergeLeaseOptions(
    availableLeases
  );

  console.log(selectedLease);

  return (
    <OrderTypeWrapper
      isSelect={isSelect}
      orderType={orderType}
      price={price}
      onSelect={onSelect}
      totalLeasePrice={totalPrice}
      paymentSchedule={
        selectedLease && selectedLease.length
          ? selectedLease[0].paymentSchedule
          : undefined
      }
    >
      <Content onSubmit={formik.handleSubmit}>
        <TwoInputsRow>
          <InputBlock>
            <Label>Term Length</Label>
            <StyledSelect
              allowClear
              isError={!!formik.errors.termLength}
              value={formik.values.termLength}
              onChange={(value) => formik.setFieldValue('termLength', value)}
            >
              {mergedLeaseOptions.termLength.map((value) => (
                <Option key={value} value={value}>
                  {value}
                </Option>
              ))}
            </StyledSelect>
          </InputBlock>
          <InputBlock>
            <Label>Payment Schedule</Label>
            <StyledSelect
              allowClear
              isError={!!formik.errors.paymentSchedule}
              value={formik.values.paymentSchedule}
              onChange={(value) =>
                formik.setFieldValue('paymentSchedule', value)
              }
            >
              {mergedLeaseOptions.paymentSchedule.map((value) => (
                <Option key={value} value={value}>
                  {value}
                </Option>
              ))}
            </StyledSelect>
          </InputBlock>
        </TwoInputsRow>
        <InputBlock>
          <Label>Lease Type</Label>
          <StyledSelect
            allowClear
            isError={!!formik.errors.orderType}
            value={formik.values.orderType}
            onChange={(value) => formik.setFieldValue('orderType', value)}
          >
            {mergedLeaseOptions.orderType.map((type) => (
              <Option value={type} key={type}>
                {type}
              </Option>
            ))}
          </StyledSelect>
        </InputBlock>
        <Divider />
        <InfoBlock>
          <Label>Accidental Damage & Theft Insurance available</Label>
          <Advantages>
            <AdvantageItem>
              <CheckOutlined style={{ color: '#24B490' }} />
              <span>No excess for each student&apos;s first claim</span>
            </AdvantageItem>
            <AdvantageItem>
              <CheckOutlined style={{ color: '#24B490' }} />
              <span>Covers accidental damage & theft</span>
            </AdvantageItem>
            <AdvantageItem>
              <CheckOutlined style={{ color: '#24B490' }} />
              <span>Worldwide coverage</span>
            </AdvantageItem>
          </Advantages>
          <CustomLink
            to={ROUTER_CONFIG.STATIC_PAGE.getPath({
              pageName: 'insurance',
            })}
          >
            More details
          </CustomLink>
        </InfoBlock>
        <Divider />
        <StyledUl>
          <li>Fully compilant with education leasing protocols</li>
          <li>Education specific pricing</li>
        </StyledUl>
        <CustomLink
          to={ROUTER_CONFIG.STATIC_PAGE.getPath({
            pageName: 'lease',
          })}
        >
          <>
            <InfoCircleOutlined style={{ marginRight: 5 }} />
            Learn more about operating leases for school
          </>
        </CustomLink>
        <Divider />
        <Controls>
          <InputBlock>
            <Label>Quantity</Label>
            <StyledInput
              type="number"
              min={1}
              step={1}
              value={formik.values.quantity}
              onChange={(e) => formik.setFieldValue('quantity', e.target.value)}
            />
          </InputBlock>
          <StyledButtonDark type="submit">Start Order</StyledButtonDark>
        </Controls>
      </Content>
    </OrderTypeWrapper>
  );
}

const Content = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const StyledSelect = styled(Select)<{ isError: boolean }>`
  width: 100%;
  border: ${({ isError }) => isError && '1px solid red'};

  .ant-select-selector {
    box-sizing: border-box;
    padding: 0;
  }
`;

const Label = styled.span`
  font-size: 14px;
  line-height: 14px;
  color: #102f54;
  font-weight: bold;
`;

const TwoInputsRow = styled.div`
  display: flex;
  gap: 35px;
`;

const InputBlock = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #D5DCE5;
`;

const InfoBlock = styled.div``;

const Advantages = styled.div`
  padding-left: 20px;
  margin: 20px 0;
`;

const AdvantageItem = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const CustomLink = styled(Link)`
  cursor: pointer;
  color: #2682de;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const StyledUl = styled.ul`
  list-style-position: inside;
  margin: 0;
  padding: 0;
`;

const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 30px;
`;

const StyledInput = styled(Input)`
  height: unset;
  padding: 8px;
  width: 100%;
`;

const StyledButtonDark = styled(ButtonDark)`
  padding: 14px;
  width: 100%;
  max-width: 210px;
`;
