import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import ROUTER_CONFIG from '../../../../router/router-config';
import SupplierLayout from '../../layout/SupplierLayoutWithBack';
import EditOffer from './EditOffer';

export default function EditOfferPage() {
  const [price, setPrice] = useState('');
  const { push } = useHistory();
  const { offerId } = useParams<{ offerId: string }>();

  const onSaveClickHandler = () => console.log(price);

  const onBack = () => push(ROUTER_CONFIG.SUPPLIER_VIEW_OFFER.getPath({ offerId }));

  return (
    <SupplierLayout onBack={onBack}>
      <EditOffer
        price={price}
        onChangePrice={setPrice}
        onSaveClickHandler={onSaveClickHandler}
      />
    </SupplierLayout>
  );
}
