import styled from 'styled-components/macro';
import { DeleteOutlined } from '@ant-design/icons';
import Input from '../../../ui/forms/Input';
import InsurerImage from '../../../img/test_images/insurer.svg';
import { IAccessorySelected, ISellerOfferPublicType, ORDER_TYPES } from '../types';
import { showPriceDigits } from '../../../utils/formats';
import { BuyerOrderStateType } from '../../../feature/buyerState';
import { ORDER_POSITION_TYPE } from './BuyerOrderCurrentView';

interface OrderTableProps {
  order: BuyerOrderStateType;
  offer: ISellerOfferPublicType;
  onEditOrderPosition: (orderPositionType: ORDER_POSITION_TYPE) => void;
  onDeleteOrderPosition: (
    orderPositionType: ORDER_POSITION_TYPE,
    id?: string
  ) => void;
  onChangeQuantity: (type: 'product' | 'accessory', quantity: number, accessoryId?: string) => void;
}

export default function OrderTable({
  order,
  offer,
  onEditOrderPosition,
  onDeleteOrderPosition,
  onChangeQuantity,
}: OrderTableProps) {
  const { product } = offer.offer;

  const getPrice = (accessory: IAccessorySelected) => {
    if (order?.orderType !== ORDER_TYPES['Outright Purchase']) {
      return `${showPriceDigits(accessory.price * (order?.lease?.rate || 1))}/${order?.paymentSchedule}`;
    }

    return showPriceDigits(accessory.price);
  };

  return (
    <Table>
      <TableHeader>
        <GreyText />
        <GreyText>Item</GreyText>
        <GreyText>Price</GreyText>
        <GreyText>Qty</GreyText>
        <GreyText />
      </TableHeader>
      <TableRow>
        <ImageContainer>
          <Image alt="" src={`https://image-sds-5pjg3ckx7a-uc.a.run.app/${product.imageUrl}`} />
        </ImageContainer>
        <ProductNameCell>
          <BoldText>{product.name}</BoldText>
          <GreyText>{`Supplier: ${order?.reseller?.name}`}</GreyText>
        </ProductNameCell>
        <BoldText>
          {order?.orderType === ORDER_TYPES['Outright Purchase']
            ? showPriceDigits(offer.price)
            : `${showPriceDigits(offer.price * (order?.lease?.rate || 0) ?? 0)}/${
              order?.paymentSchedule
            }`}
        </BoldText>
        <StyledInput value={order?.quantity} onChange={(e) => onChangeQuantity('product', +e.target.value)} type="number" step={1} min={1} />
      </TableRow>
      {order?.services?.map((service) => (
        <TableRow key={service.serviceName}>
          <ImageContainer>
            <Image alt="" src={service.serviceIcon} />
          </ImageContainer>
          <ProductNameCell>
            <BoldText>{service.serviceName}</BoldText>
            <GreyText>{`Supplier: ${order.reseller?.name}`}</GreyText>
          </ProductNameCell>
          <BoldText>{showPriceDigits(service.price * (order?.quantity || 1))}</BoldText>
          <BoldText>{order?.quantity}</BoldText>
          <ControlsCell>
            <CustomLink
              onClick={() => onEditOrderPosition(ORDER_POSITION_TYPE.SERVICE)}
            >
              Edit
            </CustomLink>
            <StyledDelete
              onClick={() => onDeleteOrderPosition(ORDER_POSITION_TYPE.SERVICE, service.serviceName)}
            />
          </ControlsCell>
        </TableRow>
      ))}
      {order?.insurance ? (
        <TableRow>
          <ImageContainer>
            <Image alt="" src={InsurerImage} />
          </ImageContainer>
          <ProductNameCell>
            <BoldText>{order?.insurance?.name}</BoldText>
            <GreyText>Supplier: Compucover</GreyText>
          </ProductNameCell>
          <BoldText>
            {`${showPriceDigits(
              order?.insurance?.price || 0,
            )}/quarter`}

          </BoldText>
          <BoldText>{order?.quantity}</BoldText>
          <ControlsCell>
            {order && (
              <CustomLink
                onClick={() => onEditOrderPosition(ORDER_POSITION_TYPE.INSURANCE)}
              >
                Edit
              </CustomLink>
            )}
            <StyledDelete
              onClick={() => onDeleteOrderPosition(ORDER_POSITION_TYPE.INSURANCE)}
            />
          </ControlsCell>
        </TableRow>
      ) : null}
      <Divider />
      {order?.accessories?.map((acc) => {
        const { product: accessory } = acc.offer;

        return (
          <TableRow key={accessory.name}>
            <ImageContainer>
              <Image alt="" src={`https://image-sds-5pjg3ckx7a-uc.a.run.app/${accessory.imageUrl}`} />
            </ImageContainer>
            <ProductNameCell>
              <BoldText>{accessory.name}</BoldText>
              <GreyText>{`Supplier: ${order.reseller?.name}`}</GreyText>
            </ProductNameCell>
            <BoldText>{getPrice(acc)}</BoldText>
            <StyledInput value={acc.quantity} type="number" onChange={(e) => onChangeQuantity('accessory', +e.target.value, acc._id)} step={1} min={1} />
            <ControlsCell>
              <CustomLink
                onClick={() => onEditOrderPosition(ORDER_POSITION_TYPE.ACCESSORY)}
              >
                Edit
              </CustomLink>
              <StyledDelete
                onClick={() => onDeleteOrderPosition(ORDER_POSITION_TYPE.ACCESSORY, acc.offer.product.name)}
              />
            </ControlsCell>
          </TableRow>
        );
      })}
    </Table>
  );
}

const Table = styled.div`
  flex: 1;
  border-radius: 5px;
  /* border: 1px solid #D5DCE5; */
  padding: 0 20px 20px;
`;

const Text = styled.span`
  font-size: 14px;
  line-height: 17px;
  color: #102f54;
`;

const BoldText = styled(Text)`
  font-weight: bold;
`;

const GreyText = styled(BoldText)`
  color: #6b7a87;
`;

const ImageContainer = styled.div`
  width: 50px;
  height: 50px;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const TableRow = styled.div`
  display: grid;
  grid-template-columns: 50px 1fr 0.5fr 0.3fr 0.3fr;
  gap: 20px;
  align-items: center;
  margin-bottom: 10px;
`;

const TableHeader = styled(TableRow)`
  padding-bottom: 12px;
  border-bottom: 1px solid #d5dce5;
`;

const ProductNameCell = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const ControlsCell = styled.div`
  display: flex;
  align-items: center;
  gap: 9px;
`;

const StyledDelete = styled(DeleteOutlined)`
  color: #de2644;
  cursor: pointer;
`;

const CustomLink = styled(Text)`
  color: #2682de;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const StyledInput = styled(Input)`
  width: 100%;
  height: unset;
  padding: 10.5px 11.5px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #d5dce5;
  margin: 20px 0;
`;
