import styled from 'styled-components/macro';

interface ProductRowItemProps {
  image?: string;
  productName: string;
  amount: number;
  soldBy: string;
  price: string;
  onTermsClick: () => void;
}

export default function ProductRowItem({
  image, productName, amount, soldBy, price, onTermsClick,
}: ProductRowItemProps) {
  return (
    <Container>
      <InfoBlock>
        <ImageContainer>
          <ProductImage src={image} />
        </ImageContainer>
        <Info>
          <BoldText>{productName}</BoldText>
          <Text>
            {amount}
            {' '}
            Units
          </Text>
          <Text>
            Items sold by:
            {' '}
            {soldBy}
          </Text>
          <CustomLink onClick={onTermsClick}>Terms and conditions</CustomLink>
        </Info>
      </InfoBlock>
      <BoldText>{price}</BoldText>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const InfoBlock = styled.div`
  display: flex;
  gap: 20px;
  align-items: flex-start;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
`;

const ImageContainer = styled.div`
  width: 70px;
  height: 60px;
`;

const ProductImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const BoldText = styled.span`
  font-weight: bold;
  font-size: 14px;
  color: #102f54;
`;

const Text = styled(BoldText)`
  font-weight: 400;
`;

const CustomLink = styled(Text)`
  font-size: 12px;
  color: #2682DE;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;
