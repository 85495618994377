import styled from 'styled-components/macro';
import { BalanceTransfersTableRow } from '../../types';
import BalanceTransfersTable from './BalanceTransfersTable';

interface BalanceTransfersProps {
  tableData: Array<BalanceTransfersTableRow>;
  onViewClickHandler: (id: string) => void;
}

export default function BalanceTransfers({ tableData, onViewClickHandler }: BalanceTransfersProps) {
  return (
    <Container>
      <Title>Balance Transfer Statements</Title>
      <BalanceTransfersTable
        tableData={tableData}
        onViewClickHandler={onViewClickHandler}
      />
    </Container>
  );
}

const Container = styled.div``;

const Title = styled.h1`
  font-size: 30px;
  line-height: 37px;
  color: #102f54;
  font-weight: bold;
  margin-bottom: 40px;
`;
