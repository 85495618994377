import { RightCircleOutlined } from '@ant-design/icons';
import { HTMLAttributeAnchorTarget } from 'react';
import styled from 'styled-components/macro';

interface LinkWithArrowProps {
  linkText: string;
  onClick?: () => void;
  className?: string;
  href?: string;
  target?: HTMLAttributeAnchorTarget;
}

export default function LinkWithArrow({
  linkText, onClick, className, href, target,
}: LinkWithArrowProps) {
  return (
    <Container target={target} href={href} onClick={onClick} className={className}>
      <BlueText>{linkText}</BlueText>
      <RightCircleOutlined style={{ color: '#2173C4', fontSize: 12 }} />
    </Container>
  );
}

const BlueText = styled.span`
  color: #2173C4;
  font-size: 14px;
  line-height: 14px;
`;

const Container = styled.a`
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  color: inherit;

  &:hover {
    color: inherit;

    ${BlueText} {
      text-decoration: underline;
    }
  }
`;
