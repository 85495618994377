import styled from 'styled-components/macro';
import SearchInput from '../../../ui/elements/SearchInput';
import BuyerAccountMenu from './menu/BuyerAccountMenu';
import BuyerDevicesMenu from './menu/BuyerDevicesMenu';
import BuyerSchemeSupportMenu from './menu/BuyerSchemeSupportMenu';
import BuyerServicesMenu from './menu/BuyerServicesMenu';
import { ButtonLight } from '../../../ui/Button';

interface BuyerHeaderContentProps {
  searchValue: string;
  onChangeSearchValue: (search: string) => void;
  onSearchSubmit: () => void;
  isSignedIn: boolean;
  onSignInClick: () => void;
  onRegisterClick: () => void;
  accountName?: string;
}

export default function BuyerHeaderContent({
  searchValue, onChangeSearchValue, onSearchSubmit, isSignedIn, onRegisterClick, onSignInClick, accountName,
}: BuyerHeaderContentProps) {
  return (
    <Container>
      <LeftBlock>
        <BuyerDevicesMenu />
        <BuyerServicesMenu />
        <BuyerSchemeSupportMenu />
      </LeftBlock>
      <RightBlock>
        {/* <SearchInput searchValue={searchValue} onChangeSearchValue={onChangeSearchValue} onSearchSubmit={onSearchSubmit} /> */}
        {isSignedIn ? (
          <BuyerAccountMenu accountName={accountName ?? 'account'} />
        ) : (
          <LoginBlock>
            <SignIn onClick={onSignInClick}>Sign in</SignIn>
            <StyledButtonLight onClick={onRegisterClick}>Register</StyledButtonLight>
          </LoginBlock>
        )}
      </RightBlock>
    </Container>
  );
}

const Container = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  margin-left: 70px;
`;

const LeftBlock = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  flex: 1;
`;

const RightBlock = styled.div`
  display: flex;
  gap: 40px;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
`;

const LoginBlock = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
`;

const SignIn = styled.span`
  font-size: 14px;
  white-space: nowrap;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const StyledButtonLight = styled(ButtonLight)`
  padding: 8px 24px;
`;
