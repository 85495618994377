import styled from 'styled-components/macro';
import OrderSummary from './order-summary/OrderSummary';
import SubmitBlock from './submit-block/SubmitBlock';
import WhatHappensNow from '../../layout/elements/WhatHappensNow';
import { BuyerOrderStateType } from '../../../../feature/buyerState';
import { ISellerOfferPublicType } from '../../types';
import { CalculatedPricesType } from '../../calculateOrderPrice';

interface BuyerOrderSecureCheckoutSubmitViewProps {
  onSubmit: () => void;
  order: BuyerOrderStateType;
  offer: ISellerOfferPublicType;
  calculations: CalculatedPricesType;
}

export default function BuyerOrderSecureCheckoutSubmitView({
  onSubmit, order, offer, calculations,
}: BuyerOrderSecureCheckoutSubmitViewProps) {
  return (
    <Container>
      <Content>
        <Title>Secure Checkout</Title>
        <SubTitle>Order Summary</SubTitle>
        <StyledSummary order={order} offer={offer} calculations={calculations} />
        <div style={{ marginTop: 40 }}>
          <Text style={{ marginBottom: 16, display: 'block' }}>The insurance is underwritten by Inter Partner Assistance SA UK Branch (IPA) which is fully owned by the AXA Partners Group Inter Partner Assistance S.A. UK Branch, with a registered office at 106-118 Station Road, Redhill, RH1 1PR.</Text>
          <StyledUl>
            <li>
              Full product summary, including what’s insured, your obligations & restrictions can
              {' '}
              <CustomLink>be found here.</CustomLink>
            </li>
            <li>
              Full policy wording
              {' '}
              <CustomLink>be found here.</CustomLink>
            </li>
            <li>
              All devices must be kept in an approved case at all times. A link to all case brands that are approved by our insurance company can
              {' '}
              <CustomLink>be found here.</CustomLink>
            </li>
          </StyledUl>
          <Divider />
          <Text style={{ display: 'block', marginBottom: 23 }}>
            The sale of insurance products is regulated by the FCA. Silverwing is a trading style of Casqol Limited. Casqol Limited are an Appointed Representative of Borland Insurance Ltd. FCA Authorisation Number 419411
          </Text>
          <BoldText style={{ display: 'block', marginBottom: 23 }}>How do I cancel the contract?</BoldText>
          <Text style={{ display: 'block' }}>
            You have the right to cancel this policy within 30 days of the date you purchased the policy or when you received the policy documents, if this is later. This is known as your cooling off period. You do not need to provide a reason for cancellation, and we will provide a full refund of any premium paid, unless you have made a claim or there has been an incident likely to result in a claim.
            If you wish to cancel the policy after 30 days, we will provide a refund, less a proportionate charge for any cover already provided, unless a claim has been made or there has been an incident likely to result in a claim.
            This will be based on the number of days remaining until the expiry date, less an administration fee applied by the administrator of £10.
            Where a claim has occurred or there has been an incident likely to result in a claim no refund of premium will be provided.
          </Text>
        </div>
      </Content>
      <Info>
        <SubmitBlock
          onSubmit={onSubmit}
          order={order}
          calculations={calculations}
        />
        <WhatHappensNow />
      </Info>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 50px;
`;

const Content = styled.div`
  flex: 2;
  max-width: 870px;
`;

const Info = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 380px;
`;

const Title = styled.h1`
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  color: #102F54;
  margin: 0;
  margin-bottom: 40px;
  display: block;
  width: 100%;
  max-width: 600px;
  padding-bottom: 20px;
  border-bottom: 1px solid #D5DCE5;
`;

const SubTitle = styled.h2`
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #102F54;
  margin: 0;
  margin-bottom: 20px;
`;

const StyledSummary = styled(OrderSummary)``;

const Text = styled.span`
  font-size: 14px;
  line-height: 17px;
  color: #102f54;
`;

const CustomLink = styled(Text)`
  color: #2682DE;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const BoldText = styled(Text)`
  font-weight: bold;
`;

const StyledUl = styled.ul`
  list-style-position: inside;
  margin: 0;
  padding: 0;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #D5DCE5;
  margin: 35px 0;
`;
