import styled from 'styled-components/macro';
import { PROCCESSORS } from '../config';
import FeaturedProcessors from './FeaturedProcessors';
import ChartImage from '../../../../../../img/buyer-overview/chart_image.png';

export default function EsportsTab() {
  return (
    <Container>
      <Content>
        <ContentLeftSide>
          <Title>Esports</Title>
          <Description>
            Esports is a rapidly growing activity popular in education as a path
            for building, STEAM, and has seen increased student engagement,
            inclusion and social emotional learning. Like other team sports,
            starting and maintaining a league or team requires planning and
            investment. By building systems based on Intel technology, schools
            can tap into decades of experience in esports, education and
            technology.
          </Description>
        </ContentLeftSide>
        <FeaturedProcessors processors={[PROCCESSORS.I9, PROCCESSORS.I7]} />
      </Content>
      <Title>How to best align Intel® processors with student use cases</Title>
      <ChartImageContainer>
        <ChartImageStyled />
      </ChartImageContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
`;

const Content = styled.div`
  display: flex;
  gap: 90px;
  margin-bottom: 40px;

  @media (max-width: 660px) {
    flex-direction: column;
    gap: 40px;
  }
`;

const ContentLeftSide = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Title = styled.h3`
  margin: 0;
  padding: 0;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #102F54;
`;

const Description = styled.div`
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 19px;
  color: #102F54;
`;

const ChartImageContainer = styled.div`
  width: 100%;
  max-width: 720px;
  height: 310px;

  @media (max-width: 660px) {
    flex: 1;
  }
`;

const ChartImageStyled = styled.img.attrs({ src: ChartImage })`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
