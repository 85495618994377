export interface OrdersOverviewTableRow {
  key: string;
  dateSubmitted: string;
  poNumber: string;
  status: string;
  buyer: string;
  paymentMethod: string;
  total: string;
}

export interface JoinedOffersTableRow {
  key: string;
  sku: string;
  oem: string;
  product: string;
  availableQuantity: string;
  totalQuantity: string;
  customerBuyPrice: string;
  unitsSold: string;
}

export interface AvailableOffersTableRow {
  key: string;
  sku: string;
  oem: string;
  product: string;
  availableQuantity: string;
  totalQuantity: string;
  resellerBuyPrice: string;
}

export interface PaymentsTableRow {
  key: string;
  dateDue: string;
  invoiceNumber: string;
  order: string;
  buyer: string;
  btsNumber: string;
  amountDue: string;
}

export interface BalanceTransfersTableRow {
  key: string;
  date: string;
  btsNumber: string;
  balanceSettled: string;
}

export interface QuotesTableRow {
  key: string;
  date: string;
  reference: string;
  customer: string;
  total: string;
}

export interface SupplierAdminUsersTableRow {
  key: string;
  user: string;
  email: string;
  role: string;
  status: string;
}

export interface SupplierAdminServicesTableRow {
  key: string;
  serviceType: string;
  description: string;
  unitPrice: string;
}

export interface SupplierEditServiceType {
  description: string;
  unitPrice: string;
}

export interface SupplierCreateServiceType extends SupplierEditServiceType {
  serviceType: string;
}

export enum ORDER_TYPES {
  'Outright Purchase' = 'Outright Purchase',
  'Operating Lease' = 'Operating Lease',
  'Financing Lease' = 'Financing Lease',
}

export interface QuoteProductItem {
  name: string;
  unitPrice: string;
  quantity: string;
}

export interface QuoteServiceItem {
  name: string;
  price: string;
  quantity: string;
  description: string;
}

export interface QuoteCreateServiceType extends Omit<QuoteServiceItem, 'description'> {}

export interface QuoteCreateType {
  school: string;
  recipientName: string;
  recipientEmail: string;
  orderType: ORDER_TYPES;
  products: Array<QuoteProductItem>;
  services?: Array<QuoteCreateServiceType>;
  deliveryCharge?: number;
  isInsurance: boolean;
  quoteExpirationDate: string;
  leaseLength?: string;
  leaseProvider?: string;
  paymentSchedule?: string;
}

export interface UpdateSupplierOrganisationDetails {
  name: string;
  adress1: string;
  adress2?: string;
  adress3?: string;
  town: string;
  country: string;
  postcode: string;
  phoneNumber: string;
  email: string;
  vatNumber: string;
  companyNumber: string;
}

export interface UpdateSupplierDeliveryChargesDetails {
  defaultDeliveryChargeAmount: string;
}

export interface UpdateSupplierPersonalDetails {
  firstName: string;
  lastName: string;
  email: string;
}
