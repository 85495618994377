import styled from 'styled-components/macro';
import { ButtonDark, ButtonLightGray } from '../../../../../ui/Button';

interface ButtonsBlockProps {
  onAcceptHandler: () => void;
  onCancelHandler: () => void;
}

export function ButtonsBlock({ onAcceptHandler, onCancelHandler }: ButtonsBlockProps) {
  return (
    <Buttons>
      <ButtonStyled onClick={onAcceptHandler}>Accept</ButtonStyled>
      <ButtonStyledOutlined onClick={onCancelHandler}>
        Cancel
      </ButtonStyledOutlined>
    </Buttons>
  );
}

const ButtonStyled = styled(ButtonDark)`
  padding: 11px 0;
  width: 286px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 226px;
`;

const ButtonStyledOutlined = styled(ButtonLightGray)`
  padding: 11px 0;
  width: 286px;
  margin-left: 12px;
`;
