import * as yup from 'yup';
import { signUpSchema } from '../../project-types/users/schemas';
import { phoneRegExp } from '../../utils/phoneRegExp';
import {
  PersonalDetailsForm, DebitDetailsForm, EditAccountSettingsForm, ChangeDonorPasswordForm, ChangeDonorEmailForm, ChangeDebitDetailsForm,
} from './types';

export const EditAccountSettingsSchema: yup.SchemaOf<EditAccountSettingsForm> = yup.object({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
});

export const ChangeDonorPasswordSchema: yup.SchemaOf<ChangeDonorPasswordForm> = yup.object({
  validatePassword: yup.string().max(256).min(5).defined(),
  password: yup.string().max(256).min(5).defined(),
  confirmPassword: yup.string().required().oneOf([yup.ref('password'), null], 'Password must match'),
});

export const ChangeDonorEmailSchema: yup.SchemaOf<ChangeDonorEmailForm> = yup.object({
  validatePassword: yup.string().max(256).min(5).defined(),
  email: yup.string().email('email must be a valid email').defined('email must be a valid email'),
});

export const DebitDetailsSchema: yup.SchemaOf<DebitDetailsForm> = yup.object({
  title: yup.string().optional(),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  postcode: yup.string().required(),
  adress1: yup.string().required(),
  adress2: yup.string().optional(),
  adress3: yup.string().optional(),
  town: yup.string().optional(),
  country: yup.string().optional(),
  phoneNumber: yup.string().matches(phoneRegExp, 'Phone number is not valid').required(),
  email: yup.string().email('email must be a valid email').defined('email must be a valid email'),
  bankAccountName: yup.string().required(),
  branchSortCode1: yup.string().required(),
  branchSortCode2: yup.string().required(),
  branchSortCode3: yup.string().required(),
  accountNumber: yup.string().required(),
  agree: yup.boolean().required().oneOf([true]),
});

export const ChangeDebitDetailsSchema: yup.SchemaOf<ChangeDebitDetailsForm> = yup.object({
  validatePassword: yup.string().max(256).min(5).defined(),
})
  .concat(DebitDetailsSchema);

export const DonorDetailsSchema: yup.SchemaOf<PersonalDetailsForm> = yup.object({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  isCorporation: yup.boolean().required().oneOf([true, false]),
  confirmPassword: yup.string().required().oneOf([yup.ref('password'), null], 'Password must match'),
})
  .concat(signUpSchema);

export const DonorCreateSchemas = [DonorDetailsSchema, DebitDetailsSchema];
