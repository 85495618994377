import styled from 'styled-components/macro';
import { useHistory } from 'react-router-dom';
import BuyerLayout from '../layout/BuyerLayout';
import Login from '../../school/school-login/SchoolLogin';
import ROUTER_CONFIG from '../../../router/router-config';
import { useSignInMutation } from '../../../api/services/buyerApi';
import { ErrorData } from '../types';
import { useRedirectOnSuccessHook } from '../hooks';
import { mapErrors } from '../../../api/services/ApiHelpers';

export default function BuyerLoginPage() {
  const { push } = useHistory();

  const [onSignIn, { isLoading, error, isSuccess }] = useSignInMutation(); // todo add spinner

  useRedirectOnSuccessHook(isSuccess, ROUTER_CONFIG.BUYER_OVERVIEW.getPath());

  const onForgotPasswordClickHandler = () =>
    push(ROUTER_CONFIG.FORGOT_PASSWORD_PAGE.getPath(), { loginPage: 'buyer' });
  const onRegisterClickHandler = () =>
    push(ROUTER_CONFIG.BUYER_REGISTER.getPath());

  return (
    <StyledLayout>
      <Login
        title=""
        onForgotPasswordClickHandler={onForgotPasswordClickHandler}
        onSignIn={onSignIn}
        onRegisterClickHandler={onRegisterClickHandler}
        externalErrors={mapErrors(error as ErrorData)}
        isShowLogo
      />
    </StyledLayout>
  );
}

const StyledLayout = styled(BuyerLayout)`
  .buyer-content-wrapper {
    padding: 0;
  }
`;
