import styled from 'styled-components/macro';
import { BuyerOrderStateType } from '../../../../../feature/buyerState';
import { showPriceDigits } from '../../../../../utils/formats';
import { ORDER_TYPES } from '../../../types';

interface ProductRowProps {
  image?: string;
  name: string;
  price: number;
  quantity: number;
  reseller: string;
  order: BuyerOrderStateType;
}

export default function ProductRow({
  image, name, price, quantity, reseller, order,
}: ProductRowProps) {
  return (
    <Container>
      <ImageContainer>
        <Image alt="" src={image} />
      </ImageContainer>
      <Content>
        <JustifyItem>
          <BoldText>{name}</BoldText>
          {order?.orderType === ORDER_TYPES['Outright Purchase'] ? (
            <BoldText>{showPriceDigits(price)}</BoldText>
          ) : (
            <BoldText>{`${showPriceDigits(price)}/${order?.paymentSchedule}`}</BoldText>
          )}
        </JustifyItem>
        <JustifyItem>
          <GreyText>{`${quantity} units`}</GreyText>
          {/* <GreyText>3 Years</GreyText> */}
        </JustifyItem>
        <GreyText>{`Item sold by: ${reseller}`}</GreyText>
        <CustomLink>Terms and conditions</CustomLink>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  gap: 25px;
  padding-bottom: 20px;
  border-bottom: 1px solid #D5DCE5;
`;

const Text = styled.span`
  font-size: 14px;
  line-height: 17px;
  color: #102f54;
`;

const GreyText = styled(Text)`
  color: #6B7A87;
`;

const BoldText = styled(Text)`
  font-weight: bold;
`;

const CustomLink = styled(Text)`
  color: #2682DE;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const JustifyItem = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ColumnItem = styled.div`
  display: flex;
  flex-direction: column;
`;

const ImageContainer = styled.div`
  width: 70px;
  height: 70px;
  background-color: #fff;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const Content = styled(ColumnItem)`
  gap: 8px;
  flex: 1;
`;
