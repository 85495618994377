import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import { useLazyGetCategoriesQuery } from '../../../../api/services/buyerApi';
import { setSelectedTab } from '../../../../feature/buyerState';
import ROUTER_CONFIG from '../../../../router/router-config';
import DropDown, { DropDownItem } from '../../../../ui/DropDown';

export default function BuyerDevicesMenu() {
  const { push } = useHistory();

  const [getCategory, { data: categories }] = useLazyGetCategoriesQuery();

  useEffect(() => {
    getCategory();
  }, [getCategory]);

  return (
    <StyledDropdown title="Devices">
      <StyledText>Devices</StyledText>
      {categories &&
        categories.map((category) => (
          <DropDownItem
            key={category._id}
            title={category.name}
            onClick={() =>
              push(
                ROUTER_CONFIG.BUYER_PRODUCTS_CATEGORY.getPath({
                  categoryId: category._id,
                })
              )
            }
          />
        ))}
    </StyledDropdown>
  );
}

const StyledDropdown = styled(DropDown)`
  cursor: pointer;

  .dropdown__title {
    font-size: 14px;
  }
`;

const StyledText = styled.span`
  font-size: 10px;
  color: #909DA8;
  text-transform: uppercase;
  padding: 10px;
`;
