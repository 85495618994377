import styled from 'styled-components/macro';
import { showPriceDigits } from '../../../utils/formats';
import { formatDateDDMMMYYYY } from '../../../ui/DateFormatMethods';

import SurfaceImage from '../../../img/test_images/surface.png';
import EuroIcon from '../../../img/icons/euroIcon.svg';
import DocumentCheckIcon from '../../../img/icons/documentCheck.svg';
import ClockIcon from '../../../img/icons/clockIcon.svg';
import CubeIcon from '../../../img/icons/cubeIcon.svg';
import CertificateIcon from '../../../img/icons/certificateIcon.svg';
import CheckIcon from '../../../img/icons/checkIcon.svg';

export default function ProductSummaryInfoTable() {
  return (
    <OverviewTitleBlock>
      <ImageBlock>
        <img alt="" src={SurfaceImage} />
      </ImageBlock>
      <SubTitle>Microsoft Surface Go 2</SubTitle>
      <Container>
        <Title>Offer Overview</Title>
        <Table>
          <TableRowContainer>
            <TableRowTitle>
              <TableRowIcon alt="" src={CertificateIcon} />
              <TableRowTitleText>Qualifying Platforms</TableRowTitleText>
            </TableRowTitle>
            <TableRowValueText>Devices For Education</TableRowValueText>
          </TableRowContainer>
          <TableRowContainer>
            <TableRowTitle>
              <TableRowIcon alt="" src={CubeIcon} />
              <TableRowTitleText>SKU</TableRowTitleText>
            </TableRowTitle>
            <TableRowValueText>STV-00002</TableRowValueText>
          </TableRowContainer>
          <TableRowContainer>
            <TableRowTitle>
              <TableRowIcon alt="" src={DocumentCheckIcon} />
              <TableRowTitleText>OEM</TableRowTitleText>
            </TableRowTitle>
            <TableRowValueText>Microsoft</TableRowValueText>
          </TableRowContainer>
          <TableRowContainer>
            <TableRowTitle>
              <TableRowIcon alt="" src={CheckIcon} />
              <TableRowTitleText>Available Quantity</TableRowTitleText>
            </TableRowTitle>
            <TableRowValueText>
              2,000
              /5,000
            </TableRowValueText>
          </TableRowContainer>
          <TableRowContainer>
            <TableRowTitle>
              <TableRowIcon alt="" src={CubeIcon} />
              <TableRowTitleText>Deal ID</TableRowTitleText>
            </TableRowTitle>
            <TableRowValueText>1535-2353</TableRowValueText>
          </TableRowContainer>
          <TableRowContainer>
            <TableRowTitle>
              <TableRowIcon alt="" src={EuroIcon} />
              <TableRowTitleText>Indicative Reseller Buy Price</TableRowTitleText>
            </TableRowTitle>
            <TableRowValueText>{showPriceDigits(404.53)}</TableRowValueText>
          </TableRowContainer>
          <TableRowContainer>
            <TableRowTitle>
              <TableRowIcon alt="" src={EuroIcon} />
              <TableRowTitleText>NTE</TableRowTitleText>
            </TableRowTitle>
            <TableRowValueText>{showPriceDigits(440.83)}</TableRowValueText>
          </TableRowContainer>
          <TableRowContainer>
            <TableRowTitle>
              <TableRowIcon alt="" src={ClockIcon} />
              <TableRowTitleText>Start Date</TableRowTitleText>
            </TableRowTitle>
            <TableRowValueText>{formatDateDDMMMYYYY(new Date('12/02/2021'))}</TableRowValueText>
          </TableRowContainer>
          <TableRowContainer>
            <TableRowTitle>
              <TableRowIcon alt="" src={ClockIcon} />
              <TableRowTitleText>Estimated Delivery Date</TableRowTitleText>
            </TableRowTitle>
            <TableRowValueText>{formatDateDDMMMYYYY(new Date('12/02/2021'))}</TableRowValueText>
          </TableRowContainer>
        </Table>
      </Container>
    </OverviewTitleBlock>
  );
}

const OverviewTitleBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ImageBlock = styled.div`
  width: 93px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
`;

const Container = styled.div`
  padding: 20px;
  background-color: #F5F8FB;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Title = styled.span`
  font-size: 14px;
  line-height: 15px;
  color: #102F54;
  text-transform: uppercase;
  font-weight: bold;
`;

const SubTitle = styled.h2`
  font-size: 21px;
  line-height: 26px;
  color: #102f54;
  font-weight: bold;
`;

const Table = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12.5px;
`;

const TableRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 16.5px;
  border-top: 1px solid #D5DCE5;
`;

const TableRowTitle = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const TableRowIcon = styled.img`
  max-width: 15px;
  max-height: 15px;
`;

const TableRowTitleText = styled(Title)`
  text-transform: unset;
`;

const TableRowValueText = styled(TableRowTitleText)`
  font-weight: normal;
  text-align: right;
`;
