import DataTable from '../../../../ui/elements/Table';
import { DonorCampaignsTableRow, DonorCampaignsTableConfig } from '../../types';

interface DonorsTableProps {
  tableRows: Array<DonorCampaignsTableRow>;
  selectedCampaigns: Array<string>;
  onCampaignSelectHandler: (campaignIds: Array<string>) => void;
}

export default function DonorsTable({
  tableRows, selectedCampaigns, onCampaignSelectHandler,
}: DonorsTableProps) {
  const tableConfig: DonorCampaignsTableConfig = [
    {
      title: 'School',
      dataIndex: 'school',
      key: 'school',
    },
    {
      title: 'Campaign Name',
      dataIndex: 'campaignId',
      key: 'campaignId',
    },
    {
      title: 'Student Reference(s)',
      dataIndex: 'studentReferences',
      key: 'studentReferences',
    },
    {
      title: 'Active Period',
      dataIndex: 'activePeriod',
      key: 'activePeriod',
    },
    {
      title: 'Collection sum',
      dataIndex: 'collectionSum',
      key: 'collectionSum',
    },
  ];

  return (
    <DataTable
      dataSource={tableRows}
      columns={tableConfig}
      rowSelection={{
        type: 'checkbox',
        selectedRowKeys: selectedCampaigns,
        onChange: (ids) => onCampaignSelectHandler(ids as Array<string>),
      }}
    />
  );
}
