import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';

export const useRedirectOnSuccessHook = (isSuccess: boolean, path: string) => {
  const { push } = useHistory();

  useEffect(() => {
    if (isSuccess) {
      push(path);
    }
  }, [isSuccess]);
};
