import { useFormik } from 'formik';
import styled from 'styled-components/macro';
import CloseIcon from '../../../../../img/close.svg';
import { ButtonDark } from '../../../../../ui/Button';
import { FormConfig, renderFormConfig } from '../../../../../utils/form';
import { createBuyerAddressSchema } from '../../../buyer-schemas';
import { BuyerCreateAddressType } from '../../../types';
import Input from '../../../../../ui/forms/Input';
import FindYourAddress from '../../../../../ui/forms/FindYourAddress';

const StyledInput = styled(Input)`
  width: 100%;
  max-width: 420px;
  height: unset;
  padding: 10.5px 11.5px;
`;

interface AddressModalContentProps {
  onClose: () => void;
  onSubmit: (values: BuyerCreateAddressType) => void;
  initialValues?: BuyerCreateAddressType;
}

const formConfig: FormConfig = [
  {
    title: 'Address Line 1',
    name: 'adress1',
    component: StyledInput,
    isRequired: true,
  },
  {
    title: 'Address Line 2',
    name: 'adress2',
    component: StyledInput,
  },
  {
    title: 'Address Line 3',
    name: 'adress3',
    component: StyledInput,
  },
  {
    title: 'Town',
    name: 'town',
    component: StyledInput,
    isRequired: true,
  },
  {
    title: 'Country',
    name: 'country',
    component: StyledInput,
    isRequired: true,
  },
  {
    title: 'Postcode',
    name: 'postcode',
    component: StyledInput,
    isRequired: true,
  },
];

export default function AddressModalContent({ onClose, onSubmit, initialValues }: AddressModalContentProps) {
  const formik = useFormik({
    initialValues: initialValues ? { ...initialValues } : {
      postcode: '',
      adress1: '',
      adress2: '',
      adress3: '',
      town: '',
      country: '',
    },
    validationSchema: createBuyerAddressSchema,
    onSubmit: (values) => {
      onSubmit(values);
      onClose();
    },
  });

  return (
    <Container onSubmit={formik.handleSubmit}>
      <CloseButton alt="" src={CloseIcon} onClick={onClose} />
      <Title>New Address</Title>
      {renderFormConfig(formConfig, formik)}
      <FindYourAddress
        postCode={formik.values.postcode}
        formikInstance={formik}
      />
      <StyledButtonDark type="submit">Save</StyledButtonDark>
    </Container>
  );
}

const Container = styled.form`
  width: 100vw;
  max-width: 570px;
  padding: 24px 45px;

  position: relative;
  display: flex;
  flex-direction: column;
`;

const Title = styled.h1`
  margin: 0;
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
  color: #102f54;
  margin-bottom: 40px;
`;

const StyledButtonDark = styled(ButtonDark)`
  padding: 14px 70px;
  margin-top: 50px;
  align-self: flex-start;
`;

const CloseButton = styled.img`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
`;
