import styled from 'styled-components/macro';
import StarIcon from '../../../../img/icons/star.svg';
import ProductItem from '../../layout/elements/ProductItem';
import { GroupedOffersByProductType } from '../../types';

interface RecommendedDevicesBlockProps {
  groupedOffers: GroupedOffersByProductType;
  title?: string;
  onDeviceClick: (deviceId: string) => void;
}

export default function RecommendedDevicesBlock({
  groupedOffers,
  title,
  onDeviceClick,
}: RecommendedDevicesBlockProps) {
  return (
    <Container>
      {title && (
        <TitleBlock>
          <Title>{title}</Title>
        </TitleBlock>
      )}
      <Content>
        {Object.values(groupedOffers).map((groupedOffer) => (
          <ProductItem
            product={groupedOffer.product}
            minLeasePrice={groupedOffer.minLeasePrice}
            minOutrightPurchasePrice={groupedOffer.minOutrightPurchasePrice}
            key={groupedOffer.product?._id}
            onDeviceClick={onDeviceClick}
          />
        ))}
      </Content>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

const TitleBlock = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  flex-direction: column;
  margin-bottom: 20px;
`;

const Title = styled.h2`
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
  color: #102f54;
  margin: 0;
`;

const Content = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;

  @media (max-width: 660px) {
    flex-direction: column;
  }
`;
