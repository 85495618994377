import { useHistory } from 'react-router-dom';
import ROUTER_CONFIG from '../../../../router/router-config';
import SupplierLayoutWithBack from '../../layout/SupplierLayoutWithBack';
import QuoteCreated from './QuoteCreated';

export default function QuoteCreatedPage() {
  const { push } = useHistory();

  return (
    <SupplierLayoutWithBack onBack={() => push(ROUTER_CONFIG.SUPPLIER_QUOTES.getPath())}>
      <QuoteCreated />
    </SupplierLayoutWithBack>
  );
}
