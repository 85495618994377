import { Tabs } from 'antd';
import styled from 'styled-components/macro';
import { useFormik } from 'formik';
import { useState } from 'react';
import { UpdateSchoolStepsSchema } from '../school-schemas';
import { SchoolFormUpdateType, UpdateSchoolFormSteps } from '../types';
import SchoolDetailsForm from './forms/SchoolDetailsForm';
import PersonalDetailsForm from './forms/PersonalDetailsForm';
import BillingDetailsForm from './forms/BillingDetailsForm';

const { TabPane } = Tabs;

export interface UpdateSchoolFormTypes {
  onSignUp: (schoolFields: SchoolFormUpdateType) => void,
  externalErrors: Record<string, string[]>,
  profile: SchoolFormUpdateType,
  onChangePasswordClickHandler: () => void;
}

export default function UpdateSchoolForm({
  onSignUp, externalErrors, profile, onChangePasswordClickHandler,
}: UpdateSchoolFormTypes) {
  const [selectedStep, setSelectedStep] = useState<UpdateSchoolFormSteps>('schoolDetails');

  const formik = useFormik<SchoolFormUpdateType>({
    initialValues: {
      ...profile,
    },
    validationSchema: UpdateSchoolStepsSchema[selectedStep],
    onSubmit: (values) => {
      const { ...rows } = values;
      try {
        onSignUp(rows);
      } catch (e) {
        console.log(e);
      }
    },
  });

  const onTabChange = (key: UpdateSchoolFormSteps) => {
    setSelectedStep(key);
    formik.setErrors({});
  };

  return (
    <FormStyled onSubmit={formik.handleSubmit}>
      <StyledTabs onChange={(key) => onTabChange(key as UpdateSchoolFormSteps)}>
        <TabPane tab="School Details" key="schoolDetails">
          <SchoolDetailsForm formik={formik} />
        </TabPane>
        <TabPane tab="Your Details" key="personalDetails">
          <PersonalDetailsForm formik={formik} onChangePasswordClickHandler={onChangePasswordClickHandler} />
        </TabPane>
        <TabPane tab="Billing" key="billingDetails">
          <BillingDetailsForm formik={formik} />
        </TabPane>
      </StyledTabs>
    </FormStyled>
  );
}

const FormStyled = styled.form`
  width: 100%;
`;

const StyledTabs = styled(Tabs)`
  margin-top: 30px;
`;
