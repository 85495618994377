import { useHistory } from 'react-router';
import ROUTER_CONFIG from '../../../../router/router-config';
import { showPriceDigits } from '../../../../utils/formats';
import SchoolLayoutWithTabs from '../../layout/SchoolLayoutWithTabs';
import { DeviceTradeinTableRow } from '../../types';
import DeviceTradein from './DeviceTradein';

export default function DeviceTradeinPage() {
  const { push } = useHistory();

  const tableData: Array<DeviceTradeinTableRow> = [
    {
      key: '123',
      dateSubmitted: '24 Nov 2021',
      type: 'Community',
      nameOrReference: 'Year 7 1:1 Initiative',
      quantityRegistred: 20,
      expectedValue: showPriceDigits(800),
    },
    {
      key: '345',
      dateSubmitted: '24 Nov 2021',
      type: 'School',
      nameOrReference: 'Year 7 1:1 Initiative',
      quantityRegistred: 40,
      expectedValue: showPriceDigits(900),
    },
  ];

  const onRowClickHandler = (campaignId: string) => push(ROUTER_CONFIG.SCHOOL_DEVICE_TRADEIN_DETAILS.getPath({ campaignId }));
  const onNewSchemeClickHandler = () => console.log('new schema');
  const onGenerateNewValuationClickHandler = () => console.log('new valuation');

  return (
    <SchoolLayoutWithTabs>
      <DeviceTradein onNewSchemeClickHandler={onNewSchemeClickHandler} onGenerateNewValuationClickHandler={onGenerateNewValuationClickHandler} tableData={tableData} onRowClickHandler={onRowClickHandler} />
    </SchoolLayoutWithTabs>
  );
}
