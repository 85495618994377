import styled from 'styled-components/macro';
import { useFormik } from 'formik';
import { createSupplierService } from '../../../supplier-schemas';
import { SupplierCreateServiceType } from '../../../types';
import CloseIcon from '../../../../../img/close.svg';
import { renderFormConfig, FormConfig } from '../../../../../utils/form';
import Input from '../../../../../ui/forms/Input';
import SelectInput from '../../../../../ui/forms/SelectInput';
import TextArea from '../../../../../ui/forms/Textarea';
import { ButtonDark } from '../../../../../ui/Button';

const StyledInput = styled(Input)`
  width: 100%;
  max-width: 310px;
  height: unset;
  padding: 12px;
`;

const StyledTextarea = styled(TextArea)`
  height: unset;
  padding: 17px 20px;
  width: 100%;
`;

const StyledSelectInput = styled(SelectInput)`
  width: 180px;
`;

const formConfig: FormConfig = [
  {
    title: 'Service Type',
    name: 'serviceType',
    component: StyledSelectInput,
    isRequired: true,
    props: {
      initialData: ['Asset Tagging', 'Installation'],
      dropdownStyle: { zIndex: 999999 },
    },
  },
  {
    title: 'Service Description',
    name: 'description',
    component: StyledTextarea,
    isRequired: true,
  },
  {
    title: 'Unit Price',
    name: 'unitPrice',
    component: StyledInput,
    isRequired: true,
    props: {
      type: 'number',
      step: 0.01,
      min: 0.01,
    },
  },
];

interface NewServiceModalContentProps {
  onClose: () => void;
  onSubmit: (values: SupplierCreateServiceType) => void;
}

export default function NewServiceModalContent({ onClose, onSubmit }: NewServiceModalContentProps) {
  const formik = useFormik({
    initialValues: {
      serviceType: 'Asset Tagging',
      description: '',
      unitPrice: '',
    },
    validationSchema: createSupplierService,
    onSubmit: (values) => {
      onSubmit(values);
      onClose();
    },
  });

  return (
    <Container onSubmit={formik.handleSubmit}>
      <CloseButton alt="" src={CloseIcon} onClick={onClose} />
      <Title>New Service</Title>
      {renderFormConfig(formConfig, formik)}
      <StyledButtonDark type="submit">Save</StyledButtonDark>
    </Container>
  );
}

const Container = styled.form`
  width: 100vw;
  max-width: 570px;
  padding: 24px 45px;
  position: relative;
`;

const Title = styled.h1`
  margin: 0;
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
  color: #102f54;
  margin-bottom: 20px;
`;

const CloseButton = styled.img`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
`;

const StyledButtonDark = styled(ButtonDark)`
  padding: 14px 88px;
`;
