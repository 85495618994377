import * as yup from 'yup';
import { DonorIdsArrayType } from '../donor/types';
import { CreateInvitesListType, COLLECTION_PERRIODS, CreateCampignSchemaType, CampaignEditType } from './types';
import { validateDateString } from '../../project-types/utils/dateTransform';

const campaignBaseSchema ={
  name: yup.string(),
  description: yup.string(),
  collectionPeriod: yup.mixed().oneOf(Object.values(COLLECTION_PERRIODS)),
  startDate: yup.date().transform(validateDateString),
};

const campaignOptionalFieldsSchema = yup.object({
  numberOfCollections: yup.number().optional(),
  differentInitalDonation: yup.bool().optional(),
  initialDonation: yup.number().optional(),
  donationAmounts: yup.array(yup.number().optional()).optional(),
  allowCustomAmounts: yup.bool().optional(),
  isStudentsRefRequired: yup.bool().optional(),
  collectionAttempts: yup.number().min(0).max(3).optional(),
  notificationsEmails: yup.array(yup.string().optional()).optional(),
});
export const campaignSchema: yup.SchemaOf<CreateCampignSchemaType> = yup.object({
  name: campaignBaseSchema.name.required(),
  description: campaignBaseSchema.description.required(),
  collectionPeriod: campaignBaseSchema.collectionPeriod.required(),
  startDate: campaignBaseSchema.startDate.required(),
}).concat(campaignOptionalFieldsSchema);

export const invitesSchema: yup.SchemaOf<CreateInvitesListType> = yup.object({
  members: yup.array(),
  message: yup.string().optional(),
});

export const donorsListSchema: yup.SchemaOf<DonorIdsArrayType> = yup.object({
  donors: yup.array().of(yup.mixed()).required().min(1),
});

// EDIT SCHECMA
export const campaignEditSchema: yup.SchemaOf<CampaignEditType> = yup.object({
  name: campaignBaseSchema.name.optional(),
  description: campaignBaseSchema.description.optional(),
  collectionPeriod: campaignBaseSchema.collectionPeriod.optional(),
  startDate: campaignBaseSchema.startDate.optional(),
  school: yup.string().optional(),
}).concat(campaignOptionalFieldsSchema);
