import { useHistory, useParams } from 'react-router-dom';
import ROUTER_CONFIG from '../../../../router/router-config';
import SupplierLayoutWithBack from '../../layout/SupplierLayoutWithBack';
import OrderDetails from './OrderDetails';

export default function OrderDetailsPage() {
  const { push } = useHistory();
  const { orderId, paymentType } = useParams<{ orderId: string, paymentType: string }>();

  const onBack = () => push(ROUTER_CONFIG.SUPPLIER_ORDERS.getPath());

  return (
    <SupplierLayoutWithBack onBack={onBack}>
      <OrderDetails
        paymentType={paymentType}
      />
    </SupplierLayoutWithBack>
  );
}
