import styled from 'styled-components/macro';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { IInsurance } from '../../../types';
import InsuranceOption from './InsuranceOption';
import { ButtonDanger } from '../../../../../ui/Button';

interface ModifyInsuranceStepProps {
  insuranceOptions: Array<IInsurance>;
  onSelectInsurance: (insurance: IInsurance) => void;
  onRemoveInsurance: () => void;
  selectedInsurance?: IInsurance;
}

export default function ModifyInsuranceStep({
  insuranceOptions,
  onRemoveInsurance,
  onSelectInsurance,
  selectedInsurance,
}: ModifyInsuranceStepProps) {
  return (
    <>
      <FirstBlock>
        <StepTitle>Device Insurance</StepTitle>
        <Text>
          This insurance is designed to repair or replace your insured equipment
          if it is damaged as a result of accidental damage or is stolen.
        </Text>
        <BoldText>Accidental Damage & Theft Insurance</BoldText>
        <BoldText>Coverage & Exclusions</BoldText>
        <ProsAndConsBlockWrapper>
          <ProsAndConsBlock>
            <ProsAndConsItem>
              <StyledCheck />
              <Text>No excess</Text>
            </ProsAndConsItem>
            <ProsAndConsItem>
              <StyledCheck />
              <Text>Accidental damage</Text>
            </ProsAndConsItem>
            <ProsAndConsItem>
              <StyledCheck />
              <Text>Theft</Text>
            </ProsAndConsItem>
            <ProsAndConsItem>
              <StyledCheck />
              <Text>Fire and Liquid Damage</Text>
            </ProsAndConsItem>
          </ProsAndConsBlock>
          <ProsAndConsBlock>
            <ProsAndConsItem>
              <StyledX />
              <Text>
                Damage or theft as a result of You or an authorised person not
                taking care of an item of Equipment.
              </Text>
            </ProsAndConsItem>
            <ProsAndConsItem>
              <StyledX />
              <Text>Loss</Text>
            </ProsAndConsItem>
            <ProsAndConsItem>
              <StyledX />
              <Text>
                {' '}
                Any claim which is covered under the warranty or guarantee
                provided by the manufacturer or retailer.
              </Text>
            </ProsAndConsItem>
          </ProsAndConsBlock>
        </ProsAndConsBlockWrapper>
        <Divider />
      </FirstBlock>
      <IsuranceOptionsBlock>
        <Text>Select the insurance option below to add it to your order:</Text>
        {insuranceOptions.map((insurance) => (
          <InsuranceOption
            key={insurance._id}
            insurance={insurance}
            isSelected={
              selectedInsurance
                ? selectedInsurance._id === insurance._id
                : false
            }
            onSelect={() => onSelectInsurance(insurance)}
          />
        ))}
        <RemoveInsurance>
          <BoldText>Already have insurance or wanting to self insure?</BoldText>
          <Text>
            The terms of the operating lease require the devices to be insured.
            If you wish to self-insure the devices, or you have existing
            coverage, you can remove the device insurance from your order. The
            lessor may require proof of self-coverage or existing insurance to
            approve the lease request.
          </Text>
          <StyledDanger onClick={onRemoveInsurance}>
            Remove Insurance
          </StyledDanger>
        </RemoveInsurance>
        <InfoBlock>
          <Text>
            The insurance is underwritten by Inter Partner Assistance SA UK
            Branch (IPA) which is fully owned by the AXA Partners Group Inter
            Partner Assistance S.A. UK Branch, with a registered office at
            106-118 Station Road, Redhill, RH1 1PR.
          </Text>
          <StyledUl>
            <li>
              Full product summary, including what’s insured, your obligations &
              restrictions can <CustomLink>be found here.</CustomLink>
            </li>
            <li>
              Full policy wording <CustomLink>be found here.</CustomLink>
            </li>
            <li>
              All devices must be kept in an approved case at all times. A link
              to all case brands that are approved by our insurance company can{' '}
              <CustomLink>be found here.</CustomLink>
            </li>
          </StyledUl>
        </InfoBlock>
        <Divider />
      </IsuranceOptionsBlock>
      <BottomInfoBlock>
        <GreyText>
          The sale of insurance products is regulated by the FCA. Silverwing is
          a trading style of Casqol Limited. Casqol Limited are an Appointed
          Representative of Borland Insurance Ltd. FCA Authorisation Number
          419411
        </GreyText>
        <BoldText>How do I cancel the contract?</BoldText>
        <GreyText>
          You have the right to cancel this policy within 30 days of the date
          you purchased the policy or when you received the policy documents, if
          this is later. This is known as your cooling off period. You do not
          need to provide a reason for cancellation, and we will provide a full
          refund of any premium paid, unless you have made a claim or there has
          been an incident likely to result in a claim. If you wish to cancel
          the policy after 30 days, we will provide a refund, less a
          proportionate charge for any cover already provided, unless a claim
          has been made or there has been an incident likely to result in a
          claim. This will be based on the number of days remaining until the
          expiry date, less an administration fee applied by the administrator
          of £10. Where a claim has occurred or there has been an incident
          likely to result in a claim no refund of premium will be provided.
        </GreyText>
      </BottomInfoBlock>
    </>
  );
}

const StepTitle = styled.h2`
  font-size: 20px;
  line-height: 24px;
  color: #102f54;
  font-weight: bold;
  margin: 0;
`;

const Text = styled.span`
  font-size: 14px;
  line-height: 17px;
  color: #102f54;
`;

const GreyText = styled(Text)`
  color: #6B7A87;
`;

const BoldText = styled(Text)`
  font-weight: bold;
`;

const FirstBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ProsAndConsBlockWrapper = styled.div`
  display: flex;
  gap: 40px;
`;

const ProsAndConsBlock = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

const ProsAndConsItem = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const StyledCheck = styled(CheckOutlined)`
  color: #10C08A;
  font-size: 10px;
`;

const StyledX = styled(CloseOutlined)`
  color: #DE2644;
  font-size: 10px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #D5DCE5;
`;

const IsuranceOptionsBlock = styled(FirstBlock)`
  margin-top: 20px;
`;

const RemoveInsurance = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const StyledDanger = styled(ButtonDanger)`
  padding: 12px 36px;
  align-self: flex-start;
  margin-top: 20px;
`;

const InfoBlock = styled(FirstBlock)`
  margin-top: 20px;
`;

const StyledUl = styled.ul`
  list-style-position: inside;
  margin: 0;
  padding: 0;
`;

const CustomLink = styled(Text)`
  color: #2682DE;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const BottomInfoBlock = styled(FirstBlock)`
  margin-top: 40px;
`;
