import styled from 'styled-components/macro';
import { Select } from 'antd';
import { CSSProperties } from 'react';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

const { Option } = Select;

interface SelectInputProps {
  setFieldValue: (field: string, value: string) => void;
  initialData: Array<string>;
  name: string;
  value: string;
  className?: string;
  placeholder?: string;
  dropdownStyle?: CSSProperties;
  disabled?: boolean;
  size?: SizeType;
  isAllowClear?: boolean;
}

export default function SelectInput({
  setFieldValue,
  name,
  value,
  className,
  initialData,
  placeholder,
  dropdownStyle,
  disabled,
  size,
  isAllowClear = false,
}: SelectInputProps) {
  return (
    <StyledSelect
      allowClear={isAllowClear}
      dropdownStyle={dropdownStyle}
      placeholder={placeholder}
      className={className}
      value={value}
      disabled={disabled}
      size={size}
      onChange={(v) => setFieldValue(name, v as string)}
    >
      {initialData.map((d) => (
        <Option key={d} value={d}>
          {d}
        </Option>
      ))}
    </StyledSelect>
  );
}

const StyledSelect = styled(Select)`
  /* border-radius: 4px !important; */

  .ant-select-selector {
    box-sizing: border-box;
    padding: 0;
  }
`;
