import styled from 'styled-components/macro';
import { useHistory } from 'react-router';
import { DropDownItem } from '../../../../ui/DropDown';
import ROUTER_CONFIG from '../../../../router/router-config';
import AccountMenu from '../../../layout/Menu/AccountMenu';
import { removeToken } from '../../../../api';

interface BuyerAccountMenuProps {
  accountName: string;
}

export default function BuyerAccountMenu({ accountName }: BuyerAccountMenuProps) {
  const { push } = useHistory();

  const onCollectionCampaignsClick = () => 5;
  const onDeviceTradeinClick = () => 5;
  const onAccountSettingsClick = () => push(ROUTER_CONFIG.BUYER_SETTINGS.getPath());
  const onYourCurrentOrderClick = () => push(ROUTER_CONFIG.BUYER_ORDER_CURRENT.getPath());
  const onOrdersClick = () => push(ROUTER_CONFIG.BUYER_ORDERS.getPath());
  const onQuotesClick = () => push(ROUTER_CONFIG.BUYER_QUOTES.getPath());

  const onLogout = () => {
    push(ROUTER_CONFIG.BUYER_LOGIN.getPath());
    removeToken();
  };

  const MenuContent = () => (
    <>
      <DropDownItem title="Your Current Order" onClick={onYourCurrentOrderClick} />
      <StyledText>your outlay</StyledText>
      <DropDownItem title="Orders" onClick={onOrdersClick} />
      <DropDownItem title="Quotes" onClick={onQuotesClick} />
      <StyledText>your scheme support</StyledText>
      <DropDownItem title="Collection Campaigns" onClick={onCollectionCampaignsClick} />
      <DropDownItem title="Device Trade-in" onClick={onDeviceTradeinClick} />
      <StyledText>settings</StyledText>
      <DropDownItem title="Account Settings" onClick={onAccountSettingsClick} />
      <DropDownItem title="Sign Out" onClick={onLogout} />
    </>
  );

  return (
    <AccountMenu accountName={accountName}>
      <MenuContent />
    </AccountMenu>
  );
}

const StyledText = styled.span`
  font-size: 10px;
  color: #909DA8;
  text-transform: uppercase;
  padding: 10px;
`;
