import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { BuyerQuotesOverviewTableRow } from '../types';

interface BuyerQuotesTableProps {
  onRowClickHandler: (id: string) => void;
  tableData: Array<BuyerQuotesOverviewTableRow>;
}

export default function BuyerQuotesTable({ onRowClickHandler, tableData }: BuyerQuotesTableProps) {
  const tableConfig: ColumnsType<BuyerQuotesOverviewTableRow> = [
    {
      key: 'dateSubmitted',
      dataIndex: 'dateSubmitted',
      title: 'Date Submitted',
      sorter: (a, b) => {
        if (new Date(a.dateSubmitted) < new Date(b.dateSubmitted)) {
          return -1;
        }
        if (new Date(a.dateSubmitted) > new Date(b.dateSubmitted)) {
          return 1;
        }
        return 0;
      },
    },
    {
      key: 'reference',
      dataIndex: 'reference',
      title: 'Reference',
    },
    {
      key: 'supplier',
      dataIndex: 'supplier',
      title: 'Supplier',
    },
    {
      key: 'quoteExpirationDate',
      dataIndex: 'quoteExpirationDate',
      title: 'Quote Expiration Date',
    },
    {
      key: 'orderType',
      dataIndex: 'orderType',
      title: 'Order Type',
    },
  ];

  return (
    <Table
      dataSource={tableData}
      columns={tableConfig}
      style={{ borderTop: '2px solid #2682DE', marginRight: 2 }}
      onRow={(rowData) => ({
        onClick: () => onRowClickHandler(rowData.key),
        style: { cursor: 'pointer' },
      })}
    />
  );
}
