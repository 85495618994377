import styled from 'styled-components';
import LogoImg from '../../img/intelLogo.png';

interface IntelLogoProps {
  className?: string,
  onClick?: () => void
}

export default function IntelLogo({ className, onClick }: IntelLogoProps) {
  return (
    <LogoStyled onClick={onClick} alt="logo" className={className} />
  );
}

const LogoStyled = styled.img.attrs({ src: LogoImg })`
  width: 66px;
`;
