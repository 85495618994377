import styled from 'styled-components/macro';
import { Tabs } from 'antd';
import BuyerQuotesTable from './BuyerQuotesTable';
import { BuyerQuotesOverviewTableRow } from '../types';

const { TabPane } = Tabs;

interface BuyerQuotesOverviewProps {
  tableData: Array<BuyerQuotesOverviewTableRow>;
  onRowClickHandler: (id: string) => void;
}

export default function BuyerQuotesOverview({ tableData, onRowClickHandler }: BuyerQuotesOverviewProps) {
  return (
    <Container>
      <Title>Quotes</Title>
      <Tabs defaultActiveKey="1">
        <StyledTabPane tab="All Quotes" key="1">
          <BuyerQuotesTable
            tableData={tableData}
            onRowClickHandler={onRowClickHandler}
          />
        </StyledTabPane>
      </Tabs>
    </Container>
  );
}

const Container = styled.div``;

const Title = styled.h1`
  margin: 0;
  margin-bottom: 40px;
  font-size: 30px;
  line-height: 37px;
  color: #102F54;
  font-weight: bold;
`;

const StyledTabPane = styled(TabPane)`
  padding-top: 40px;
`;
