import { ReactNode } from 'react';
import styled from 'styled-components/macro';
import StudentsIcon from '../../../../../img/buyer-overview/students-icon.png';
import TeachersIcon from '../../../../../img/buyer-overview/teachers-icon.png';
import EsportsIcon from '../../../../../img/buyer-overview/esports-icon.png';
import ProPlatformIcon from '../../../../../img/buyer-overview/pro-platform-icon.png';
import LearningIcon from '../../../../../img/buyer-overview/learning-icon.png';

import CpuCeleronIcon from '../../../../../img/buyer-overview/cpu_celeron.png';
import CpuPentiumIcon from '../../../../../img/buyer-overview/cpu_pentium.png';
import CpuI3Icon from '../../../../../img/buyer-overview/cpu_i3.png';
import CpuI5Icon from '../../../../../img/buyer-overview/cpu_i5.png';
import CpuI7Icon from '../../../../../img/buyer-overview/cpu_i7.png';
import CpuI9Icon from '../../../../../img/buyer-overview/cpu_i9.png';

import CpuI5VPro from '../../../../../img/buyer-overview/i5vpro.png';
import CpuI7VPro from '../../../../../img/buyer-overview/i7vpro.png';

import { OVERVIEW_TABS_ICONS } from '../../../types';

const Icon = styled.img`
  height: 20px;
`;

const ProcessorIcon = styled.img`
  width: 40px;
  height: 40px;
`;

export enum TABS {
  STUDENTS = 'Students',
  TEACHERS = 'Teachers',
  ESPORTS = 'Esports',
  VPRO_PLATFORM = 'The Intel vPro® Platform',
  LEARNING = 'Device For Digital Content Access',
}

export enum PROCCESSORS {
  I9 = 'Intel® Core™ i9',
  I7 = 'Intel® Core™ i7',
  I7Pro = 'Intel® Core™ i7 vPro®',
  I5Pro = 'Intel® Core™ i5 vPro®',
  I5 = 'Intel® Core™ i5',
  I3 = 'Intel® Core™ i3',
  PENTIUM = 'Intel® Pentium',
  CELERON = 'Intel® Celeron',
}

export const TAB_ICONS: { [key in OVERVIEW_TABS_ICONS]: ReactNode } = {
  [OVERVIEW_TABS_ICONS.ESPORTS]: <Icon src={EsportsIcon} />,
  [OVERVIEW_TABS_ICONS.LEARNING]: <Icon src={LearningIcon} />,
  [OVERVIEW_TABS_ICONS.STUDENTS]: <Icon src={StudentsIcon} />,
  [OVERVIEW_TABS_ICONS.TEACHERS]: <Icon src={TeachersIcon} />,
  [OVERVIEW_TABS_ICONS.PRO]: <Icon src={ProPlatformIcon} />,
};

export const PROCESSOR_ICONS: { [key in PROCCESSORS]: ReactNode } = {
  [PROCCESSORS.CELERON]: <ProcessorIcon src={CpuCeleronIcon} />,
  [PROCCESSORS.PENTIUM]: <ProcessorIcon src={CpuPentiumIcon} />,
  [PROCCESSORS.I3]: <ProcessorIcon src={CpuI3Icon} />,
  [PROCCESSORS.I5]: <ProcessorIcon src={CpuI5Icon} />,
  [PROCCESSORS.I7]: <ProcessorIcon src={CpuI7Icon} />,
  [PROCCESSORS.I9]: <ProcessorIcon src={CpuI9Icon} />,
  [PROCCESSORS.I5Pro]: <ProcessorIcon src={CpuI5VPro} />,
  [PROCCESSORS.I7Pro]: <ProcessorIcon src={CpuI7VPro} />,
};
