import { useHistory } from 'react-router';
import { DropDownItem } from '../../../ui/DropDown';
import ROUTER_CONFIG from '../../../router/router-config';
import AccountMenu from '../../layout/Menu/AccountMenu';
import { removeToken } from '../../../api';

interface SupplierAccountMenuProps {
  accountName: string;
}

export default function SupplierAccountMenu({ accountName }: SupplierAccountMenuProps) {
  const { push } = useHistory();

  const onAccountSettingsClick = () => push(ROUTER_CONFIG.SUPPLIER_SETTINGS.getPath());
  const onLogout = () => {
    push(ROUTER_CONFIG.DONOR_LOGIN.getPath());
    removeToken();
  };

  const MenuContent = () => (
    <>
      <DropDownItem title="Account Settings" onClick={onAccountSettingsClick} />
      <DropDownItem title="Sign Out" onClick={onLogout} />
    </>
  );

  return (
    <AccountMenu accountName={accountName}>
      <MenuContent />
    </AccountMenu>
  );
}
