import styled from 'styled-components/macro';

import CubeIcon from '../../../../img/icons/cubeIcon.svg';
import mailIcon from '../../../../img/icons/mainIcon.svg';
import phoneIcon from '../../../../img/icons/phoneIcon.svg';

interface LessorOverviewProps {
  className?: string;
}

export default function LessorOverview({ className }: LessorOverviewProps) {
  return (
    <Container className={className}>
      <Title>Lessor Overview</Title>
      <TableRowContainer>
        <TableRowTitle>
          <TableRowIcon alt="" src={CubeIcon} />
          <TableRowTitleText>Organisation Name</TableRowTitleText>
        </TableRowTitle>
        <TableRowValueText>Wesleya Bank</TableRowValueText>
      </TableRowContainer>
      <TableRowContainer>
        <TableRowTitle>
          <TableRowIcon alt="" src={mailIcon} />
          <TableRowTitleText>Contact Email</TableRowTitleText>
        </TableRowTitle>
        <TableRowValueText>lindsay.screen@wesletan.co.uk</TableRowValueText>
      </TableRowContainer>
      <TableRowContainer>
        <TableRowTitle>
          <TableRowIcon alt="" src={phoneIcon} />
          <TableRowTitleText>Contact Telephone</TableRowTitleText>
        </TableRowTitle>
        <TableRowValueText>07725 602555</TableRowValueText>
      </TableRowContainer>
    </Container>
  );
}

const Container = styled.div`
  padding: 20px;
  background-color: #F5F8FB;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Title = styled.span`
  text-transform: uppercase;
  font-size: 14px;
  line-height: 15px;
  color: #102f54;
  font-weight: bold;
`;

const TableRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 16.5px;
  border-top: 1px solid #D5DCE5;
`;

const TableRowTitle = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const TableRowIcon = styled.img`
  max-width: 15px;
  max-height: 15px;
`;

const TableRowTitleText = styled(Title)`
  text-transform: unset;
`;

const TableRowValueText = styled(TableRowTitleText)`
  font-weight: normal;
  text-align: right;
`;
