import styled from 'styled-components/macro';
import { CampaignPublicType } from '../../../../../project-types/campaign/types';
import { formatDateDDMMMYYYY } from '../../../../../ui/DateFormatMethods';
import { showPriceDigits } from '../../../../../utils/formats';

interface CampaignDetailsProps {
  campaign: CampaignPublicType;
  initialDonationAmount: number;
  recurringDonationAmount: number;
}

export default function CampaignDetails({ campaign, initialDonationAmount, recurringDonationAmount }: CampaignDetailsProps) {
  return (
    <Details>
      <DetailsItem>
        <SmallText>Start Date</SmallText>
        <DetailsValue>{formatDateDDMMMYYYY(new Date(campaign.startDate))}</DetailsValue>
      </DetailsItem>
      <DetailsItem>
        <SmallText>Initial Donation Amount</SmallText>
        <DetailsValue>{showPriceDigits(initialDonationAmount)}</DetailsValue>
      </DetailsItem>
      <DetailsItem>
        <SmallText>Recurring Donation Amount</SmallText>
        <DetailsValue>{showPriceDigits(recurringDonationAmount)}</DetailsValue>
      </DetailsItem>
      <DetailsItem>
        <SmallText>Collection Frequency</SmallText>
        <DetailsValue>{`${campaign.collectionPeriod[0].toUpperCase() + campaign.collectionPeriod.slice(1)}`}</DetailsValue>
      </DetailsItem>
      <DetailsItem>
        <SmallText>Number of Collections</SmallText>
        <DetailsValue>{campaign?.numberOfCollections || 'unset'}</DetailsValue>
      </DetailsItem>
      <DetailsItem>
        <SmallText>Payment Method</SmallText>
        <DetailsValue>Direct Debit</DetailsValue>
      </DetailsItem>
    </Details>
  );
}

const Details = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 24px 28px;
  background-color: rgba(245, 248, 251, 0.7);
`;

const DetailsItem = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SmallText = styled.p`
  font-size: 14px;
  color: #102F54;
`;

const DetailsValue = styled(SmallText)`
  font-weight: bold;
`;
