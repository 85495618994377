import styled from 'styled-components/macro';
import { PROCCESSORS } from '../config';
import FeaturedProcessors from './FeaturedProcessors';
import TeachersChartImage from '../../../../../../img/buyer-overview/teachers_chart_image.png';

export default function TeachersTab() {
  return (
    <Container>
      <Content>
        <ContentLeftSide>
          <Title>Teachers</Title>
          <Description>
            <DescriptionText>
              The graphic below highlights examples of an evolving School day
              faced by teachers. Intel® Core™ powered devices equip staff with
              technology that helps support this increasingly dynamic new
              learning environment.
            </DescriptionText>
          </Description>
          <TeachersChartImageContainer>
            <TeachersChartImageStyled />
          </TeachersChartImageContainer>
        </ContentLeftSide>
        <FeaturedProcessors processors={[PROCCESSORS.I5]} />
      </Content>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  /* align-items: center; */
`;

const Content = styled.div`
  display: flex;
  gap: 90px;
  margin-bottom: 40px;
  justify-content: space-between;

  @media (max-width: 660px) {
    flex-direction: column;
    gap: 40px;
  }
`;

const ContentLeftSide = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Title = styled.h3`
  margin: 0;
  padding: 0;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #102f54;
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const DescriptionText = styled.span`
  font-size: 16px;
  line-height: 19px;
  color: #102F54;
`;

const TeachersChartImageContainer = styled.div`
  width: 100%;
  max-width: 680px;
  height: 380px;
  flex: 1;
`;

const TeachersChartImageStyled = styled.img.attrs({ src: TeachersChartImage })`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
