import styled from 'styled-components/macro';
import DocumentIcon from '../../img/icons/document_icon.svg';
import { formatDateDDMMMYYYY } from '../DateFormatMethods';

interface DocumentInfoItemProps {
  documentTitle: string;
  createdDate: Date;
  className?: string
}

export default function DocumentInfoItem({ documentTitle, createdDate, className }: DocumentInfoItemProps) {
  return (
    <DocumentInfo className={className}>
      <img alt="" src={DocumentIcon} />
      <DocumentDescription>
        <Text style={{ marginBottom: 5 }}>{documentTitle}</Text>
        <GrayText>
          {`${createdDate.getHours()}:${createdDate.getMinutes()}`}
          {' '}
          on
          {' '}
          {formatDateDDMMMYYYY(createdDate)}
        </GrayText>
      </DocumentDescription>
    </DocumentInfo>
  );
}

const Text = styled.span`
  font-size: 14px;
  line-height: 14px;
  color: #102f54;
  font-weight: bold;
`;

const GrayText = styled.span`
  font-size: 14px;
  line-height: 14px;
  color: #6B7A87;
`;

const DocumentInfo = styled.div`
  display: flex;
  gap: 17px;
`;

const DocumentDescription = styled.div`
  display: flex;
  flex-direction: column;
`;
