import styled from 'styled-components/macro';
import { useFormik } from 'formik';
import Checkbox from '../../../../ui/forms/Checkbox';
import OrderTypeWrapper from './OrderTypeWrapper';
import { ButtonDark } from '../../../../ui/Button';
import Input from '../../../../ui/forms/Input';
import {
  BuyerCreateOutrightPurchaseOrderType,
  OmitProductId,
  ORDER_TYPES,
} from '../../types';
import { createBuyerOutrightPurchaseOrderSchema } from '../../buyer-schemas';
import { Link } from 'react-router-dom';
import ROUTER_CONFIG from '../../../../router/router-config';

interface OutrightPurchaseOptionProps {
  isSelect: boolean;
  orderType: string;
  price: number;
  onSelect: () => void;
  onCreateOrder: (
    values: OmitProductId<BuyerCreateOutrightPurchaseOrderType>
  ) => void;
}

export default function OutrightPurchaseOption({
  isSelect,
  orderType,
  price,
  onSelect,
  onCreateOrder,
}: OutrightPurchaseOptionProps) {
  const formik = useFormik({
    initialValues: {
      quantity: 1,
      includeDeviceInsurance: false,
      orderType: ORDER_TYPES['Outright Purchase'],
    },
    validationSchema: createBuyerOutrightPurchaseOrderSchema,
    onSubmit: (values) => onCreateOrder(values),
  });

  return (
    <OrderTypeWrapper
      isSelect={isSelect}
      orderType={orderType}
      price={price}
      onSelect={onSelect}
    >
      <Content onSubmit={formik.handleSubmit}>
        <Divider />
        <InputBlock>
          <Label>Include Device Insurance</Label>
          <InputWrapper>
            <StyledCheckbox
              checked={formik.values.includeDeviceInsurance}
              onClick={() =>
                formik.setFieldValue(
                  'includeDeviceInsurance',
                  !formik.values.includeDeviceInsurance
                )
              }
            />
            <InputDetails>
              <span>Accidental Damage & Theft Insurance</span>
              <Price>+£2.55/mo.</Price>
            </InputDetails>
          </InputWrapper>
          <CustomLink
            to={ROUTER_CONFIG.STATIC_PAGE.getPath({
              pageName: 'insurance',
            })}
          >
            More details
          </CustomLink>
        </InputBlock>
        <Divider />
        <StyleUl>
          <li>Education specific pricing</li>
        </StyleUl>
        <Divider />
        <Controls>
          <QuantityBlock>
            <Label>Quantity</Label>
            <StyledInput
              type="number"
              min={1}
              step={1}
              value={formik.values.quantity}
              onChange={(e) => formik.setFieldValue('quantity', e.target.value)}
            />
          </QuantityBlock>
          <StyledButtonDark type="submit">Start Order</StyledButtonDark>
        </Controls>
      </Content>
    </OrderTypeWrapper>
  );
}

const Content = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #D5DCE5;
`;

const Label = styled.span`
  font-size: 14px;
  line-height: 14px;
  font-weight: bold;
  color: #102f54;
`;

const InputBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const InputWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const InputDetails = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
  align-items: center;
`;

const StyledCheckbox = styled(Checkbox)`
  width: 15px;
  height: 15px;
`;

const Price = styled(Label)`
  color: #DE2644;
`;

const CustomLink = styled(Link)`
  font-weight: 400;
  color: #2682DE;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const StyleUl = styled.ul`
  list-style-position: inside;
  margin: 0;
  padding: 0;
`;

const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 30px;
`;

const QuantityBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-width: 150px;
`;

const StyledInput = styled(Input)`
  height: unset;
  padding: 8px;
  width: 100%;
`;

const StyledButtonDark = styled(ButtonDark)`
  padding: 14px;
  width: 100%;
  max-width: 210px;
`;
