import styled from 'styled-components/macro';
import { Tabs } from 'antd';
import InfoTable, { InfoTableItem } from '../../../../ui/elements/InfoTable';
import { serializeStatisticsPrice } from '../../../../utils/formats';
import PaymentsTable from './PaymentsTable';
import { PaymentsTableRow } from '../../types';

const { TabPane } = Tabs;

interface PaymentsOverviewProps {
  tableData: Array<PaymentsTableRow>;
}

export default function PaymentsOverview({ tableData }: PaymentsOverviewProps) {
  const Stats: Array<InfoTableItem> = [
    {
      title: 'Outstanding',
      value: serializeStatisticsPrice(20467.58),
      description: '1 order',
    },
    {
      title: 'Overdue',
      value: serializeStatisticsPrice(0),
    },
    {
      title: 'Received',
      value: serializeStatisticsPrice(222619.15),
      description: 'This Month',
    },
  ];

  return (
    <Container>
      <Title>Payments</Title>
      <StyledInfoTable data={Stats} />
      <Tabs defaultActiveKey="1">
        <StyledTabPane tab="Outstanding" key="1">
          <PaymentsTable tableData={tableData} />
        </StyledTabPane>
        <StyledTabPane tab="Overdue" key="2">
          <PaymentsTable tableData={[]} />
        </StyledTabPane>
        <StyledTabPane tab="Received" key="3">
          <PaymentsTable tableData={[]} />
        </StyledTabPane>
        <StyledTabPane tab="SW Invoices" key="4">
          <PaymentsTable tableData={[]} />
        </StyledTabPane>
      </Tabs>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const Title = styled.h1`
  margin: 0;
  font-size: 30px;
  line-height: 37px;
  color: #102f54;
  font-weight: bold;
`;

const StyledInfoTable = styled(InfoTable)`
  max-width: 630px;
  margin-bottom: 20px;
`;

const StyledTabPane = styled(TabPane)`
  padding-top: 40px;
`;
