import styled from 'styled-components/macro';
import OrderDetailsInfoTable from './OrderDetailsInfoTable';
import PaymentsInfoTable from './PaymentsInfoTable';
import { ButtonDark, ButtonLight, ButtonDanger } from '../../../../ui/Button';

import ActionRequiredIcon from '../../../../img/icons/action_required_icon.svg';
import OrganisationOverviewBlock from './OrganisationOverviewBlock';

interface OrderDetailsProps {
  paymentType: string;
}

export default function OrderDetails({ paymentType }: OrderDetailsProps) {
  return (
    <Container>
      <OrderDetailsBlock>
        <Title>Order Details</Title>
        <OrderDetailsInfoTable />
        <PaymentsBlock>
          <SubTitle>Payments</SubTitle>
          <PaymentsInfoTable payments={[]} />
        </PaymentsBlock>
        <DocumentsBlock>
          <SubTitle>Documents</SubTitle>
          <DocumentsContent>
            DOCUMENTS
          </DocumentsContent>
        </DocumentsBlock>
      </OrderDetailsBlock>
      <ActionsBlock>
        <ActionRequiredBlock>
          <img alt="" src={ActionRequiredIcon} />
          <BoldText>ACTION REQUIRED</BoldText>
        </ActionRequiredBlock>
        <StyledButtonDark>Accept Order</StyledButtonDark>
        {paymentType === 'Operating Lease' && (
          <StyledButtonLight>Request Credit Agreement</StyledButtonLight>
        )}
        <StyledButtonDanger>Reject Order</StyledButtonDanger>
        <StyledOrganisationOverview />
      </ActionsBlock>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex: 1;
  gap: 100px;
`;

const OrderDetailsBlock = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const Title = styled.h1`
  font-size: 30px;
  line-height: 34px;
  color: #102f54;
  font-weight: bold;
`;

const Text = styled.span`
  font-size: 14px;
  line-height: 14px;
  color: #102F54;
`;

const BoldText = styled(Text)`
  font-weight: bold;
`;

const SubTitle = styled.h2`
  font-size: 21px;
  line-height: 26px;
  color: #102f54;
  font-weight: bold;
`;

const PaymentsBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const DocumentsBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const DocumentsContent = styled.div`
  border-top: 1px solid #D5DCE5;
  border-bottom: 1px solid #D5DCE5;
  padding: 20px 0;
`;

const ActionsBlock = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: 80px;
  gap: 20px;
`;

const ActionRequiredBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const StyledButtonDark = styled(ButtonDark)`
  padding: 13px;
`;

const StyledButtonLight = styled(ButtonLight)`
  padding: 13px;
`;

const StyledButtonDanger = styled(ButtonDanger)`
  padding: 13px;
`;

const StyledOrganisationOverview = styled(OrganisationOverviewBlock)`
  margin-top: 50px;
`;
