import styled from 'styled-components/macro';
import { useFormik } from 'formik';
import { updateSupplierDeliveryChargesDetails } from '../../supplier-schemas';
import { renderFormConfig } from '../../../../utils/form';
import Input from '../../../../ui/forms/Input';
import { ButtonDark } from '../../../../ui/Button';

export default function DeliveryChargeTab() {
  const formik = useFormik({
    initialValues: {
      defaultDeliveryChargeAmount: '',
    },
    validationSchema: updateSupplierDeliveryChargesDetails,
    onSubmit: (values) => console.log(values),
  });

  return (
    <Container onSubmit={formik.handleSubmit}>
      {renderFormConfig([{
        title: 'Default Delivery Charge Amount',
        name: 'defaultDeliveryChargeAmount',
        component: StyledInput,
        props: {
          type: 'number',
          step: 0.01,
          min: 0.01,
        },
      }], formik)}
      <StyledButtonDark type="submit">Save</StyledButtonDark>
      <PleaseContactBlock>
        <Text>
          Please contact
          {' '}
          <LinkText>Silverwing Support</LinkText>
          {' '}
          if you require this information to be changed.
        </Text>
      </PleaseContactBlock>
    </Container>
  );
}

const Container = styled.form`
  width: 420px;
`;

const StyledInput = styled(Input)`
  width: 100%;
  max-width: 420px;
  padding: 12px 14px;
  height: unset;
`;

const StyledButtonDark = styled(ButtonDark)`
  padding: 10px 46px;
`;

const PleaseContactBlock = styled.div`
  padding: 13px;
  background-color: rgba(193, 230, 255, 0.2);
  margin-top: 32px;
`;

const Text = styled.p`
  margin: 0;
  font-size: 14px;
  line-height: 17px;
  color: #102F54;
`;

const LinkText = styled.span`
  cursor: pointer;
  color: #2682DE;

  &:hover {
    text-decoration: underline;
  }
`;
