import styled from 'styled-components/macro';
import { ButtonDark } from '../../../../ui/Button';

interface PayoutsProps {
  withdrawalsAvalible: boolean,
  onWithdrawals: () => void
}

export default function Payouts({ withdrawalsAvalible, onWithdrawals }: PayoutsProps) {
  return (
    <Wrap>
      <MainHeader>Balance Transfer Statements</MainHeader>
      <SumWrap>
        <SumItem>
          <SumItemHead>
            Total Remittance
            <SumItemDesc>(after fees)</SumItemDesc>
          </SumItemHead>
          <SumItemMain>£ 2350.00</SumItemMain>
        </SumItem>
        <SumItem>
          <SumItemHead>Total Gift Aid Amount</SumItemHead>
          <SumItemMain>£ 500.00</SumItemMain>
        </SumItem>
        {withdrawalsAvalible ? <ButtonWithdrawals onClick={onWithdrawals}>Set up withdrawals</ButtonWithdrawals> : <ButtonWithdrawalsEmpty />}
      </SumWrap>
    </Wrap>
  );
}

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 96px;
`;

const MainHeader = styled.h1`
  margin-bottom: 50px;
  font-weight: 700;
  font-size: 24px;
  line-height: 26px;
`;

const SumWrap = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: stretch;
  min-height: 110px;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 12px 25px 5px;
`;

const SumItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 25px;
`;

const SumItemHead = styled.h3`
  font-weight: 750;
  font-size: 14px;
  line-height: 16px;
  color: #828282;
  margin-bottom: 6px;
  text-transform: uppercase;
  color: #1B9D75;
`;

const SumItemDesc = styled.span`
  font-weight: 400;
  display: block;
  text-transform: capitalize;
  color: #6A7E95;
`;

const SumItemMain = styled.div`
  font-weight: 450;
  font-size: 21px;
  margin-top: auto;
`;

const ButtonWithdrawals = styled(ButtonDark)`
  padding: 11px;
  width: 285px;
  flex-grow: 0;
  align-self: center;
`;

const ButtonWithdrawalsEmpty = styled.div`
  padding: 11px;
  width: 285px;
  flex-grow: 0;
  align-self: center;
`;
