import styled from 'styled-components/macro';

export default function WhatToConsiderBlock() {
  return (
    <Container>
      <Title>Functionality: What to Consider</Title>
      <Content>
        <ContentItem>
          <SubTitle>Performance</SubTitle>
          <Text>
            The amount of horsepower your device requires is dictated by how it will be used. Advanced skills-building workloads like simulation and data science require higher performance processors. Less complex tasks such as internet browsing or word processing aren’t as CPU intensive. As students progress through grade levels, they’ll need higher-performance devices to continue building their skills.
          </Text>
        </ContentItem>
        <ContentItem>
          <SubTitle>Portability and Flexibility</SubTitle>
          <Text>
            Today’s students need to learn comfortably from anywhere. Be sure to consider the amount of portability that will be required from your device purchase, especially if it will be used in a flipped classroom environment. Integrated webcams and microphones are essential for remote learners. The 2-in-1 configuration allows more flexibility by providing both laptop and tablet form factors in one device.
          </Text>
        </ContentItem>
        <ContentItem>
          <SubTitle>Connectivity</SubTitle>
          <Text>
            Be mindful of network adapters, USB ports, display ports, and other compatibility requirements. Be sure to align your choice with any preexisting investments. The right connectivity options ensure a smoother experience for the student user.
          </Text>
        </ContentItem>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  padding: 70px 150px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  background-color: #568FAC;
  margin: 0 -150px;
  color: #fff;

  @media (max-width: 660px) {
    margin: 0 -36px;
    margin-bottom: 20px;
    padding: 38px 36px;
  }
`;

const Title = styled.span`
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
`;

const SubTitle = styled.span`
  font-weight: bold;
  font-size: 21px;
  line-height: 26px;
`;

const Text = styled.span`
  font-size: 16px;
  line-height: 19px;
`;

const Content = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(3, 1fr);
  gap: 25px;

  @media (max-width: 660px) {
    grid-auto-flow: row;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, auto);
  }
`;

const ContentItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
