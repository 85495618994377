import { useState } from 'react';
import { Table, message } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import styled from 'styled-components/macro';
import { ButtonDark, ButtonLight } from '../../../../ui/Button';
import Input from '../../../../ui/forms/Input';
import { QuoteCreateServiceType, QuoteServiceItem } from '../../types';

interface ServicesTableProps {
  data: Array<QuoteServiceItem>;
  selectedServices: Array<QuoteCreateServiceType>;
  onAddNewItem: (service: QuoteCreateServiceType) => void;
}

type StateType = { [key: string]: QuoteServiceItem };

export default function ServicesTable({
  data, onAddNewItem, selectedServices,
}: ServicesTableProps) {
  const state = data.reduce((acc: StateType, item) => {
    acc[item.name] = item;

    return acc;
  }, {});
  const [editableServices, setEditableServices] = useState<StateType>(state);

  const isItemSelected = (serviceName: string) => selectedServices.find((s) => s.name === serviceName);
  const onChangePrice = (price: string, serviceName: string) => {
    setEditableServices((services) => ({ ...services, [serviceName]: { ...services[serviceName], price } }));
  };
  const onChangeQuantity = (quantity: string, serviceName: string) => {
    setEditableServices((services) => ({ ...services, [serviceName]: { ...services[serviceName], quantity } }));
  };
  const onAddNewItemHandler = (serviceName: string) => (editableServices[serviceName].quantity && editableServices[serviceName].price ? onAddNewItem({ name: serviceName, quantity: editableServices[serviceName].quantity, price: editableServices[serviceName].price }) : message.error('Enter price and quantity'));

  const tableConfig: ColumnsType<QuoteServiceItem> = [
    {
      title: 'Service Type',
      key: 'name',
      dataIndex: 'name',
      render: (v) => <span style={{ whiteSpace: 'nowrap' }}>{v}</span>,
    },
    {
      title: 'Description',
      key: 'description',
      dataIndex: 'description',
    },
    {
      title: 'Unit Price',
      key: 'price',
      dataIndex: 'price',
      render: (_v, r) => <StyledInput type="number" step={0.01} min={0.01} disabled={!!isItemSelected(r.name)} value={editableServices[r.name].price} onChange={(e) => onChangePrice(e.target.value, r.name)} />,
    },
    {
      title: 'Quantity',
      key: 'quantity',
      dataIndex: 'quantity',
      render: (_v, r) => <StyledInput type="number" step={1} min={1} disabled={!!isItemSelected(r.name)} value={editableServices[r.name].quantity} onChange={(e) => onChangeQuantity(e.target.value, r.name)} />,
    },
    {
      title: '',
      key: 'add',
      dataIndex: 'add',
      render: (_v, r) => (isItemSelected(r.name) ? <AddedButton onClick={() => onAddNewItemHandler(r.name)}>Added</AddedButton> : <AddButton onClick={() => onAddNewItemHandler(r.name)}>Add</AddButton>),
    },
  ];

  return (
    <Container>
      <Table<QuoteServiceItem>
        columns={tableConfig}
        dataSource={data}
        style={{ borderTop: '2px solid #2682DE', boxShadow: '0px 1px 6px rgba(161, 166, 172, 0.161)' }}
        pagination={false}
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const StyledInput = styled(Input)`
  width: 100%;
  max-width: 120px;
  height: unset;
  padding: 4px 8px;

  &:disabled {
    background: #f2f2f2;
  }
`;

const AddButton = styled(ButtonDark)`
  padding: 10px 35px;
  height: 45px;
  width: 140px;
`;

const AddedButton = styled(ButtonLight)`
  padding: 10px 35px;
  height: 45px;
  width: 140px;
`;
