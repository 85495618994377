import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import styled from 'styled-components/macro';
import { ButtonDark } from '../../../../ui/Button';
import Input from '../../../../ui/forms/Input';
import SelectInput from '../../../../ui/forms/SelectInput';
import { QuoteProductItem } from '../../types';

interface ItemsTableProps {
  name: string;
  setValue: (field: string, value: string) => void;
  data: Array<QuoteProductItem>;
  onAddNewItem: () => void;
}

export default function ItemsTable({
  name, setValue, data, onAddNewItem,
}: ItemsTableProps) {
  const tableConfig: ColumnsType<QuoteProductItem> = [
    {
      title: 'Offer (Product)',
      key: 'name',
      dataIndex: 'name',
      render: (_v, r, i) => <StyledSelectInput name={`${name}[${i}].name`} value={data[i].name} setFieldValue={setValue} initialData={['Microsoft Surface Go 2 STQ-00003']} />,
    },
    {
      title: 'Unit Price',
      key: 'unitPrice',
      dataIndex: 'unitPrice',
      render: (_v, r, i) => <StyledInput type="number" step={0.01} min={0.01} name={`${name}[${i}].unitPrice`} value={data[i].unitPrice} onChange={(e) => setValue(`${name}[${i}].unitPrice`, e.target.value)} />,
    },
    {
      title: 'Quantity',
      key: 'quantity',
      dataIndex: 'quantity',
      render: (_v, r, i) => <StyledInput type="number" step={1} min={0} name={`${name}[${i}].quantity`} value={data[i].quantity} onChange={(e) => setValue(`${name}[${i}].quantity`, e.target.value)} />,
    },
  ];

  return (
    <Container>
      <AddItemButton onClick={onAddNewItem}>+ Add Item</AddItemButton>
      <Table<QuoteProductItem>
        columns={tableConfig}
        dataSource={data}
        style={{ borderTop: '2px solid #2682DE', boxShadow: '0px 1px 6px rgba(161, 166, 172, 0.161)' }}
        pagination={false}
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const AddItemButton = styled(ButtonDark)`
  padding: 10px 25px;
  align-self: flex-start;
`;

const StyledInput = styled(Input)`
  width: 100%;
  max-width: 120px;
  height: unset;
  padding: 4px 8px;
`;

const StyledSelectInput = styled(SelectInput)`
  width: 100%;
  max-width: 424px;
`;
