import { ReactNode, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { getIsUserAuth, getUserProfile, loadProfile } from '../../../feature/userState';
import ROUTER_CONFIG from '../../../router/router-config';
import { MainHeader } from '../../../ui/Headers';
import DonorLayout from './DonorLayout';
import DonorAccountMenu from './menu/DonorAccountMenu';

interface ChangeLayoutProps {
  children: ReactNode;
  title: string;
}

export default function ChangeLayout({
  children, title,
}: ChangeLayoutProps) {
  const dispatch = useDispatch();

  const profile = useSelector(getUserProfile);
  const isAuth = useSelector(getIsUserAuth);

  useEffect(() => {
    if (!isAuth) {
      dispatch(loadProfile());
    }
  }, [isAuth, dispatch]);

  return (
    <DonorLayout
      menu={(
        <DonorAccountMenu
          accountName={`${profile?.donorProfile?.firstName} ${profile?.donorProfile?.lastName}` || 'Account'}
        />
    )}
      headerLoginLinkTo={ROUTER_CONFIG.DONOR_LOGIN.path}
    >
      <Wrap>
        <Content>
          <StyledSecondHeader>{title}</StyledSecondHeader>
          {children}
        </Content>
      </Wrap>
    </DonorLayout>
  );
}

const Wrap = styled.div`
  padding-top: 100px;
  padding-bottom: 100px;
  box-sizing: border-box;
  box-sizing: border-box;
  min-height: calc(100vh - 165px);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;
`;

const Content = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  width: 640px;
  box-shadow: 3px 3px 14px 14px #00000014;
  background-color: #fff;
  padding: 60px 100px 60px 75px;
`;

const StyledSecondHeader = styled(MainHeader)`
  margin-bottom: 35px;
`;
