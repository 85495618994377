import React from 'react';
import styled from 'styled-components/macro';
import BackButton from '../../../ui/elements/BackButton';
import SupplierLayout from './SupplierLayout';

interface SupplierLayoutWithBackProps {
  onBack: () => void;
  children: React.ReactNode;
}

export default function SupplierLayoutWithBack({ onBack, children }: SupplierLayoutWithBackProps) {
  return (
    <StyledLayout>
      <Container>
        <BackButton onClickHandler={onBack} />
        {children}
      </Container>
    </StyledLayout>
  );
}

const StyledLayout = styled(SupplierLayout)`
  .supplier_content_wrapper {
    padding: 38px 80px 38px 29px;
  }
`;

const Container = styled.div`
  display: flex;
  gap: 29px;
`;
