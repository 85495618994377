import styled from 'styled-components/macro';
import { BuyerOrderStateType } from '../../../feature/buyerState';
import { ButtonDark, ButtonLight } from '../../../ui/Button';
import { CalculatedPricesType } from '../calculateOrderPrice';
import { ORDER_TYPES } from '../types';

interface SummaryInfoProps {
  onSaveClickHandler: () => void;
  onProceedClickHandler: () => void;
  calculations: CalculatedPricesType;
  order: BuyerOrderStateType;
  isCreateQuoteSuccess: boolean;
  isGenerateQuoteDisabled: boolean;
}

export default function SummaryInfo({
  onProceedClickHandler, onSaveClickHandler, calculations, order, isCreateQuoteSuccess, isGenerateQuoteDisabled,
}: SummaryInfoProps) {
  return (
    <Container>
      <Title>Order Summary</Title>
      <Content>
        {order?.orderType !== ORDER_TYPES['Outright Purchase'] && (
          <BoldText>Lease Summary</BoldText>
        )}
        <InfoBlock>
          <InfoBlockItem>
            <InfoBlockTitle>
              <BoldText>Subtotal (Ex-VAT)</BoldText>
              <GreyText>Billed Quarterly</GreyText>
            </InfoBlockTitle>
            <BoldText>{calculations.productsSubtotalExVat}</BoldText>
          </InfoBlockItem>
          <InfoBlockItem>
            <InfoBlockTitle>
              <BoldText>VAT (20%)</BoldText>
              <GreyText>{`Billed ${order?.paymentSchedule || 'one time'}`}</GreyText>
            </InfoBlockTitle>
            <BoldText>{calculations.productsVat}</BoldText>
          </InfoBlockItem>
          <InfoBlockItem>
            <InfoBlockTitle>
              <BoldText>Total (Inc-VAT)</BoldText>
              <GreyText>Billed Quarterly</GreyText>
            </InfoBlockTitle>
            <Price>{calculations.productsTotalWithVat}</Price>
          </InfoBlockItem>
          {order?.orderType !== ORDER_TYPES['Outright Purchase'] && (
            <>
              <Divider />
              <InfoBlockItem>
                <GreyText>Number of payments</GreyText>
                <GreyText>{calculations.numberOfPaymentsToSilverwing}</GreyText>
              </InfoBlockItem>
              <InfoBlockItem>
                <GreyText>Provider</GreyText>
                <GreyText>{order?.reseller?.name}</GreyText>
              </InfoBlockItem>
            </>
          )}
        </InfoBlock>

        {!!order?.insurance && (
          <>
            <BoldText>Insurance Summary</BoldText>
            <InfoBlock>
              <InfoBlockItem>
                <InfoBlockTitle>
                  <BoldText>Subtotal (Ex-VAT)</BoldText>
                  <GreyText>Billed Quarterly</GreyText>
                </InfoBlockTitle>
                <BoldText>{calculations.insuranceSubtotalExVat}</BoldText>
              </InfoBlockItem>
              <InfoBlockItem>
                <InfoBlockTitle>
                  <BoldText>VAT (20%)</BoldText>
                  <GreyText>Billed Quarterly</GreyText>
                </InfoBlockTitle>
                <BoldText>{calculations.insuranceVat}</BoldText>
              </InfoBlockItem>
              <InfoBlockItem>
                <InfoBlockTitle>
                  <BoldText>Total (Inc-VAT)</BoldText>
                  <GreyText>Billed Quarterly</GreyText>
                </InfoBlockTitle>
                <Price>{calculations.insuranceTotalWithVat}</Price>
              </InfoBlockItem>
              <Divider />
              <InfoBlockItem>
                <GreyText>Number of payments</GreyText>
                <GreyText>{calculations.numberOfInsurancePayments}</GreyText>
              </InfoBlockItem>
              <InfoBlockItem>
                <GreyText>Provider</GreyText>
                <GreyText>Compucover</GreyText>
              </InfoBlockItem>
            </InfoBlock>
          </>
        )}
        <ProceedButton onClick={onProceedClickHandler}>Proceed to checkout</ProceedButton>
        <SaveButton disabled={isGenerateQuoteDisabled} onClick={onSaveClickHandler}>
          {isCreateQuoteSuccess ? (
            'Quote successfully generated'
          ) : (
            'Save & generate quote'
          )}
        </SaveButton>
        <GreyText style={{ fontWeight: 'normal' }}>Generating a quote will automatically save your order to the ‘Your Quotes’ section in your account.</GreyText>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  max-width: 380px;
  background: #F5F8FB;
  border-radius: 3px;
  padding: 20px;
`;

const Title = styled.h2`
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #102F54;
  margin: 0;
  margin-bottom: 20px;
`;

const Text = styled.span`
  font-size: 14px;
  line-height: 17px;
  color: #102f54;
`;

const BoldText = styled(Text)`
  font-weight: bold;
`;

const GreyText = styled(BoldText)`
  color: #6B7A87;
`;

const Price = styled(BoldText)`
  color: #DE2644;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const InfoBlock = styled(Content)`
  gap: 20px;
  padding: 18px;
  background: #fff;
`;

const InfoBlockItem = styled.div`
  display: flex;
  justify-content: space-between;
`;

const InfoBlockTitle = styled(Content)`
  gap: 5px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #D5DCE5;
`;

const ProceedButton = styled(ButtonDark)`
  padding: 13px;
`;

const SaveButton = styled(ButtonLight)`
  padding: 13px;

  &:disabled {
    background: #F5F5F5;
    color: #909DA8;
  }
`;
