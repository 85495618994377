import styled from 'styled-components/macro';
import { useFormik } from 'formik';
import { updateSupplierPersonalDetails } from '../../supplier-schemas';
import { renderFormConfig, FormConfig } from '../../../../utils/form';
import Input from '../../../../ui/forms/Input';
import { ButtonDark } from '../../../../ui/Button';

const StyledInput = styled(Input)`
  width: 100%;
  max-width: 313px;
  padding: 12px;
  height: unset;

  &:disabled {
    background-color: #F9F9F9;
  }
`;

const formConfig: FormConfig = [
  {
    title: 'First Name',
    name: 'firstName',
    component: StyledInput,
  },
  {
    title: 'Second Name',
    name: 'lastName',
    component: StyledInput,
  },
  {
    title: 'Email',
    name: 'email',
    component: StyledInput,
    props: {
      disabled: true,
    },
  },
];

export default function PersonalDetailsTab() {
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: 'test@test.com',
    },
    validationSchema: updateSupplierPersonalDetails,
    onSubmit: (values) => console.log(values),
  });

  return (
    <Container onSubmit={formik.handleSubmit}>
      {renderFormConfig(formConfig, formik)}
      <PleaseContactBlock>
        <Text>
          Please contact
          {' '}
          <LinkText>Silverwing Support</LinkText>
          {' '}
          if you require this information to be changed.
        </Text>
      </PleaseContactBlock>
      <LargeText>Password</LargeText>
      <StyledButtonDark onClick={(e) => e.preventDefault()}>Change Password</StyledButtonDark>
      <Divider />
      <StyledButtonDark type="submit">Save</StyledButtonDark>
    </Container>
  );
}

const Container = styled.form`
  max-width: 420px;
`;

const PleaseContactBlock = styled.div`
  padding: 13px;
  background-color: rgba(193, 230, 255, 0.2);
  margin-top: 32px;
`;

const Text = styled.p`
  margin: 0;
  font-size: 14px;
  line-height: 17px;
  color: #102F54;
`;

const LargeText = styled(Text)`
  font-size: 16px;
  margin-top: 30px;
  margin-bottom: 11px;
`;

const StyledButtonDark = styled(ButtonDark)`
  padding: 14px;
  width: 210px;
`;

const LinkText = styled.span`
  cursor: pointer;
  color: #2682DE;

  &:hover {
    text-decoration: underline;
  }
`;

const Divider = styled.div`
  height: 1px;
  border-top: 1px solid #D5DCE5;
  margin: 40px 0;
`;
