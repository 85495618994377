import styled from 'styled-components/macro';
import ProductItem from '../layout/elements/ProductItem';
import { GroupedOffersByProductType } from '../types';

interface BuyerProductsCategoryProps {
  category: string;
  groupedOffers: GroupedOffersByProductType;
  onDeviceClick: (id: string) => void;
}

export default function BuyerProductsCategory({ category, groupedOffers, onDeviceClick }: BuyerProductsCategoryProps) {
  return (
    <Container>
      <Title>{category}</Title>
      <ProductsContainer>
        {Object.values(groupedOffers).map((groupedOffer) => (
          <ProductItem
            product={groupedOffer.product}
            minLeasePrice={groupedOffer.minLeasePrice}
            minOutrightPurchasePrice={groupedOffer.minOutrightPurchasePrice}
            key={groupedOffer.product?._id}
            onDeviceClick={onDeviceClick}
          />
        ))}
      </ProductsContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const Title = styled.h1`
  font-weight: bold;
  font-size: 30px;
  line-height: 37px;
  color: #071730;
  margin: 0;
  margin-bottom: 31px;
`;

const ProductsContainer = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;

  @media (max-width: 660px) {
    flex-direction: column;
  }
`;
