/* eslint-disable no-nested-ternary */
import styled from 'styled-components/macro';
import { InfoCircleOutlined, CheckOutlined } from '@ant-design/icons';
import { Radio } from 'antd';
import React from 'react';
import { ORDER_TYPES } from '../../types';
import { showPriceDigits } from '../../../../utils/formats';

interface OrderTypeWrapperProps {
  isSelect: boolean;
  onSelect: () => void;
  orderType: string;
  price: number;
  children: React.ReactNode;
  totalLeasePrice?: number;
  paymentSchedule?: string;
}

export default function OrderTypeWrapper({
  isSelect,
  orderType,
  price,
  children,
  onSelect,
  totalLeasePrice,
  paymentSchedule,
}: OrderTypeWrapperProps) {
  return (
    <Container onClick={onSelect} isSelect={isSelect}>
      {isSelect && (
        <CheckBlock>
          <StyledCheck />
        </CheckBlock>
      )}
      <Header>
        <TitleBlock>
          <StyledRadio checked={isSelect} onClick={onSelect} />
          <Title>{orderType}</Title>
        </TitleBlock>
        {orderType !== ORDER_TYPES['Outright Purchase'] ? (
          totalLeasePrice ? (
            <Price>{`From ${showPriceDigits(totalLeasePrice)}/${
              paymentSchedule || ''
            }`}</Price>
          ) : (
            <Price>{`From ${showPriceDigits(price)}/Quarterly`}</Price>
          )
        ) : (
          <Price>{`From ${showPriceDigits(price)}`}</Price>
        )}
      </Header>
      {orderType === ORDER_TYPES['Operating Lease'] && !isSelect && (
        <OperatingLeaseInactive>
          <Divider />
          <StyledUl>
            <li>Fully compliant with education leasing protocols</li>
            <li>Education specific pricing</li>
          </StyledUl>
          <CustomLink>
            <InfoCircleOutlined style={{ color: '#2682DE', marginRight: 5 }} />
            Learn more about operating leases for schools
          </CustomLink>
        </OperatingLeaseInactive>
      )}
      {isSelect && <Content>{children}</Content>}
    </Container>
  );
}

const Container = styled.div<{ isSelect: boolean }>`
  padding: 20px;
  display: flex;
  flex-direction: column;
  border: ${({ isSelect }) =>
    isSelect ? '1px solid #619DD8' : '1px solid #D5DCE5'};
  background-color: #fff;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledRadio = styled(Radio)`
  .ant-radio-inner {
    box-sizing: border-box;
  }
`;

const TitleBlock = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.span`
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  color: #102F54;
`;

const Price = styled.span`
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
  color: #DE2644;
`;

const Content = styled.div`
  flex: 1;
  margin-top: 20px;
`;

const OperatingLeaseInactive = styled.div``;

const StyledUl = styled.ul`
  list-style-position: inside;
  margin: 0;
  padding: 0;
  margin-bottom: 17px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #D5DCE5;
  margin: 20px 0;
`;

const CustomLink = styled.span`
  cursor: pointer;
  color: #2682de;

  &:hover {
    text-decoration: underline;
  }
`;

const CheckBlock = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-top: 25px solid #2682de;
  border-left: 25px solid transparent;
`;

const StyledCheck = styled(CheckOutlined)`
  color: #fff;
  font-size: 12px;
  position: absolute;
  bottom: 11px;
  right: 1px;
`;
