import styled from 'styled-components/macro';
import { PROCCESSORS, PROCESSOR_ICONS } from '../config';

interface FeaturedProcessorsProps {
  processors: Array<PROCCESSORS>;
}

export default function FeaturedProcessors({ processors }: FeaturedProcessorsProps) {
  return (
    <Container>
      <Title>Featured Processors</Title>
      <Content>
        {processors.map((cpu) => (
          <ProcessorRow key={cpu}>
            {PROCESSOR_ICONS[cpu]}
            <ProcessorLabel>{cpu}</ProcessorLabel>
          </ProcessorRow>
        ))}
      </Content>
    </Container>
  );
}

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 22px;
  width: 376px;
  flex-shrink: 0;
  text-align: left;

  @media (max-width: 660px) {
    width: 100%;
  }
`;

const Title = styled.span`
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #6B7A87;
`;

const Content = styled.div`
  padding: 20px;
  display: flex;
  background-color: #FAFBFC;
  border-radius: 5px;

  flex-direction: column;
  gap: 20px;
`;

const ProcessorLabel = styled.span`
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #102F54;
  white-space: nowrap;
`;

const ProcessorRow = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;
