import { useHistory } from 'react-router-dom';
import {
  useGetOffersQuery,
  useGetOrdersListQuery,
} from '../../../api/services/buyerApi';
import ROUTER_CONFIG from '../../../router/router-config';
import { formatDateDDMMMYYYY } from '../../../ui/DateFormatMethods';
import calculateOrderPrice from '../calculateOrderPrice';
import BuyerLayout from '../layout/BuyerLayout';
import { parseOrder } from '../parseOrder';
import { BuyerOrdersOverviewTableRow, ORDER_STATUS_ENUM } from '../types';
import BuyerOrdersOverview from './BuyerOrdersOverview';

export default function BuyerOrdersOverviewPage() {
  const { push } = useHistory();

  const { data: orders } = useGetOrdersListQuery();
  const { data: offers } = useGetOffersQuery();

  const tableData: Array<BuyerOrdersOverviewTableRow> = orders?.filter((acc) => acc.status !== ORDER_STATUS_ENUM.QUOTE).map((order) => {
    const parsedOrder = parseOrder(order);

    const prices = calculateOrderPrice({
      quantity: parsedOrder.quantity,
      reseller: parsedOrder.reseller,
      accessories: parsedOrder.accessories,
      lease: parsedOrder.lease,
      services: parsedOrder.services,
      orderType: parsedOrder.orderType,
      offer: offers?.find((offer) => offer._id === order.sellerOfferId),
      insurance: parsedOrder.insurance,
    });

    return {
      key: order._id,
      dateSubmitted: formatDateDDMMMYYYY(new Date()),
      poNumber: order._id,
      products: order.productId,
      orderType: order.orderType,
      status: order.status,
      total: prices.productsTotalWithVat,
    };
  }) || [];

  const onRowClickHandler = (orderId: string) => push(ROUTER_CONFIG.BUYER_ORDER_DETAILS.getPath({ orderId }));

  return (
    <BuyerLayout>
      <BuyerOrdersOverview
        tableData={tableData}
        onRowClickHandler={onRowClickHandler}
      />
    </BuyerLayout>
  );
}
