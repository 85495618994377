import { useState } from 'react';
import styled from 'styled-components/macro';
import {
  BuyerCreateLeaseOrderType,
  BuyerCreateOutrightPurchaseOrderType,
  GroupedOfferItemType,
  ILeasePublicType,
  OmitProductId,
  ORDER_TYPES,
} from '../../types';
import LeasePurchaseOption from './LeasePurchaseOption';
import OutrightPurchaseOption from './OutrightPurchaseOption';
import HelpSvg from '../../../../img/icons/help.svg';
import { MergedLeaseOptionsResponse } from '../../groupSellerOffersByProductId';

type ORDER_OPTIONS = 'Lease' | 'OutrightPurchase';

interface OrderOptionsProps {
  groupedOffer: GroupedOfferItemType;
  onStartLeaseOrderClickHandler: (
    values: OmitProductId<BuyerCreateLeaseOrderType>
  ) => void;
  onStartOutrightPurchaseOrderClickHandler: (
    values: OmitProductId<BuyerCreateOutrightPurchaseOrderType>
  ) => void;
  className?: string;
  leases: Array<ILeasePublicType>;
}

export default function OrderOptions({
  groupedOffer,
  className,
  onStartLeaseOrderClickHandler,
  onStartOutrightPurchaseOrderClickHandler,
  leases,
}: OrderOptionsProps) {
  const [orderType, setOrderType] = useState<ORDER_OPTIONS>('OutrightPurchase');

  return (
    <Container className={className}>
      <Text>Available order options</Text>
      <Types>
        <OutrightPurchaseOption
          onSelect={() => setOrderType('OutrightPurchase')}
          isSelect={orderType === 'OutrightPurchase'}
          orderType={ORDER_TYPES['Outright Purchase']}
          price={groupedOffer.minOutrightPurchasePrice}
          onCreateOrder={onStartOutrightPurchaseOrderClickHandler}
        />
        {leases.length ? (
          <LeasePurchaseOption
            onSelect={() => setOrderType('Lease')}
            isSelect={orderType === 'Lease'}
            orderType="Lease"
            price={groupedOffer.minLeasePrice}
            onCreateOrder={onStartLeaseOrderClickHandler}
            leases={leases}
            offers={groupedOffer.offers}
          />
        ) : null}
      </Types>
      <NeedHelpBlock>
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <img style={{ marginRight: 5 }} alt="" src={HelpSvg} />
          Need help?
        </span>
        <span>
          Speak to a specialist{' '}
          <CustomLink href="https://www.devicesforeducation.com/support">
            here
          </CustomLink>
        </span>
      </NeedHelpBlock>
    </Container>
  );
}

const Container = styled.div`
  padding: 20px;
  background-color: #F5F8FB;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
`;

const Types = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 20px 0;
`;

const Text = styled.span`
  font-weight: bold;
`;

const NeedHelpBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const CustomLink = styled.a`
  cursor: pointer;
  color: #2682DE;
  text-decoration: none;


  &:hover {
    text-decoration: underline;
  }
`;
