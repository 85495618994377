/* eslint-disable no-param-reassign */
import { combineReducers, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { message } from 'antd';
import { buyerApi } from '../api/services/buyerApi';
import { CalculatedPricesType } from '../components/buyer/calculateOrderPrice';
import {
  BuyerCreateGlobalOrderType,
  BuyerCreateLeaseOrderType,
  BuyerCreateOutrightPurchaseOrderType,
  BuyerSecureCheckoutFormType,
  IAccessorySelected,
  IInsurance,
  ILeasePublicType,
  IReseller,
  IService,
} from '../components/buyer/types';
import { RootState } from '../store';

export type BuyerOrderStateType =
  | (Omit<
      BuyerCreateGlobalOrderType,
      'reseller' | 'accessories' | 'insurance' | 'lease'
    > & {
      reseller?: IReseller;
      accessories?: Array<IAccessorySelected>;
      insurance?: IInsurance;
      lease?: ILeasePublicType;
    })
  | null;

const buyerAuthInitialState = {
  isAuthenticated: false,
};
type BuyerOfferIdStateType = null | string;
type BuyerTabsStateType = string;
const buyerOrderInitialState: BuyerOrderStateType = null;
const buyerCalculationsInitialState: CalculatedPricesType = {
  productsSubtotalExVat: '',
  productsVat: '',
  productsTotalWithVat: '',
  insuranceSubtotalExVat: '',
  insuranceVat: '',
  insuranceTotalWithVat: '',
  numberOfPaymentsToSilverwing: 0,
  numberOfInsurancePayments: 0,
  totalToSilverwing: '',
  totalExVat: '',
  totalVat: '',
  totalWithVat: '',
  leasePricing: '',
};

export const INITIAL_ORDER: Omit<
  BuyerCreateGlobalOrderType,
  | 'orderType'
  | 'productId'
  | 'quantity'
  | 'reseller'
  | 'accessories'
  | 'insurance'
  | 'lease'
> = {
  // reseller: '',
  deliveryOption: '',
  attentionOf: '',
  deliveryAddress: '',
  contactName: '',
  contactEmail: '',
  invoiceAddress: '',
  sellerOfferId: '',
  services: [],
};

const buyerAuthState = createSlice({
  name: 'buyer',
  initialState: buyerAuthInitialState,
  reducers: {
    logout: () => buyerAuthInitialState,
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(buyerApi.endpoints.signIn.matchFulfilled, (state, action) => {
        localStorage.setItem('accessToken', action.payload.accessToken);
        state.isAuthenticated = true;
      })
      .addMatcher(buyerApi.endpoints.signIn.matchRejected, (state, action) => {
        console.log('rejected', action);
      });
  },
});

const buyerOrderState = createSlice({
  name: 'buyerOrder',
  initialState: buyerOrderInitialState as BuyerOrderStateType,
  reducers: {
    resetOrder: () => null,
    setOrder: (state, action: PayloadAction<BuyerOrderStateType>) =>
      action.payload,
    createInitialOrder: (
      state,
      action: PayloadAction<
        BuyerCreateLeaseOrderType | BuyerCreateOutrightPurchaseOrderType
      >
    ) => ({ ...INITIAL_ORDER, ...action.payload }),
    setOrderReseller: (state, action: PayloadAction<IReseller>) => {
      if (!state) return;
      state.reseller = action.payload;
    },
    setOrderInsurance: (
      state,
      action: PayloadAction<IInsurance | undefined>
    ) => {
      if (!state) return;
      state.insurance = action.payload;
    },
    removeOrderInsurance: (state) => {
      if (!state) return;
      state.insurance = undefined;
    },
    setOrderAccessories: (
      state,
      action: PayloadAction<Array<IAccessorySelected>>
    ) => {
      if (!state) return;
      state.accessories = action.payload;
    },
    setOrderLease: (state, action: PayloadAction<ILeasePublicType>) => {
      if (!state) return;

      state.lease = action.payload;
    },
    removeOrderAccessory: (state, action: PayloadAction<string>) => {
      if (!state) return;
      state.accessories = state.accessories?.filter(
        (acc) => acc.offer.product.name !== action.payload
      );
    },
    removeOrderService: (state, action: PayloadAction<string>) => {
      if (!state) return;

      state.services = state.services?.filter(
        (service) => service.serviceName !== action.payload
      );
    },
    changeOrderServices: (
      state,
      action: PayloadAction<{ service: IService; resellerId: string }>
    ) => {
      if (!state) return;

      if (state.reseller?._id !== action.payload.resellerId) {
        message.error('You can`t add services of an unselected reseller');
        return;
      }

      if (
        state.services?.find(
          (s) => s.serviceName === action.payload.service.serviceName
        )
      ) {
        state.services = state.services.filter(
          (s) => s.serviceName !== action.payload.service.serviceName
        );
      } else {
        state.services = [...(state.services || []), action.payload.service];
      }
    },
    setOrderServices: (state, action: PayloadAction<Array<IService>>) => {
      if (!state) return;

      state.services = action.payload;
    },
    changeItemsQuantity: (
      state,
      action: PayloadAction<{
        type: 'product' | 'accessory';
        quantity: number;
        accessoryId?: string;
      }>
    ) => {
      if (!state) return;

      if (action.payload.type === 'product') {
        state.quantity = action.payload.quantity;
      } else {
        if (!action.payload.accessoryId || !state.accessories) return;

        const i = state.accessories.findIndex(
          (acc) => acc._id === action.payload.accessoryId
        );

        if (i !== -1) {
          state.accessories[i].quantity = action.payload.quantity;
        }
      }
    },
    setOrderSecureCheckoutDetails: (
      state,
      action: PayloadAction<BuyerSecureCheckoutFormType>
    ) => state && { ...state, ...action.payload },
  },
});

const buyerCalculationsState = createSlice({
  name: 'buyerOrderCalculations',
  initialState: buyerCalculationsInitialState,
  reducers: {
    setOrderCalculations: (
      state,
      action: PayloadAction<CalculatedPricesType>
    ) => action.payload,
  },
});

const buyerOfferIdState = createSlice({
  name: 'buyerOfferId',
  initialState: null as BuyerOfferIdStateType,
  reducers: {
    setOfferId: (state, action: PayloadAction<string>) => action.payload,
  },
});

const buyerTabsState = createSlice({
  name: 'buyerTabs',
  initialState: {
    selectedTabs: '',
  },
  reducers: {
    setSelectedTab: (state, action: PayloadAction<string>) => ({
      selectedTabs: action.payload,
    }),
  },
});

export const { logout } = buyerAuthState.actions;
export const {
  resetOrder,
  createInitialOrder,
  setOrderAccessories,
  removeOrderAccessory,
  setOrderInsurance,
  removeOrderInsurance,
  setOrderReseller,
  setOrderSecureCheckoutDetails,
  setOrder,
  setOrderLease,
  changeItemsQuantity,
  changeOrderServices,
  setOrderServices,
  removeOrderService,
} = buyerOrderState.actions;
export const { setOrderCalculations } = buyerCalculationsState.actions;
export const { setOfferId } = buyerOfferIdState.actions;
export const { setSelectedTab } = buyerTabsState.actions;

const buyerState = combineReducers({
  buyerAuth: buyerAuthState.reducer,
  buyerOrder: buyerOrderState.reducer,
  buyerOrderCalculations: buyerCalculationsState.reducer,
  buyerOfferId: buyerOfferIdState.reducer,
  buyerTabs: buyerTabsState.reducer,
});

export default buyerState;

// export default buyerAuthState.reducer;

export const IsBuyerAuthenticated = (state: RootState) =>
  state.buyerState.buyerAuth.isAuthenticated;
export const selectSelectedTabs = (state: RootState) =>
  state.buyerState.buyerTabs?.selectedTabs;
export const selectBuyerOrder = (state: RootState) =>
  state.buyerState.buyerOrder;
export const selectBuyerCalculations = (state: RootState) =>
  state.buyerState.buyerOrderCalculations;
export const selectBuyerOfferId = (state: RootState) =>
  state.buyerState.buyerOfferId;
