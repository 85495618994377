import DataTable from '../../../../../ui/elements/Table';
import { TableColumns, TableRow } from '../FundraisingOverviewPage';

interface FundraisingTableProps {
  tableRows: Array<TableRow>;
}

const tableConfig: TableColumns = [
  {
    title: 'Campaign Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Start Date',
    dataIndex: 'startDate',
    key: 'startDate',
  },
  {
    title: 'End Date',
    dataIndex: 'endDate',
    key: 'endDate',
  },
  {
    title: 'Total amount expected',
    dataIndex: 'totalAmountExpected',
    key: 'totalAmountExpected',
  },
  {
    title: 'Total amount collected',
    dataIndex: 'totalAmountCollected',
    key: 'totalAmountCollected',
  },
  {
    title: 'Total amount not collected',
    dataIndex: 'totalAmountNotCollected',
    key: 'totalAmountNotCollected',
  },
  {
    title: 'Total Giftaid Amount',
    dataIndex: 'totalGiftAidAmount',
    key: 'totalGiftAidAmount',
  },
  {
    title: 'Platform payouts',
    dataIndex: 'platformPayouts',
    key: 'platformPayouts',
  },
];

export default function FundraisingTable({ tableRows }: FundraisingTableProps) {
  return (
    <DataTable
      dataSource={tableRows}
      columns={tableConfig}
    />
  );
}
