import { useHistory } from 'react-router-dom';
import ROUTER_CONFIG from '../../../../router/router-config';
import { showPriceDigits } from '../../../../utils/formats';
import SupplierLayout from '../../layout/SupplierLayout';
import { AvailableOffersTableRow, JoinedOffersTableRow } from '../../types';
import OffersView from './OffersView';

export default function OffersPage() {
  const history = useHistory();

  const onJoinedOffersRowClick = (offerId: string) => history.push(ROUTER_CONFIG.SUPPLIER_VIEW_OFFER.getPath({ offerId }));
  const onAvailableOffersJoinClick = (offerId: string) => history.push(ROUTER_CONFIG.SUPPLIER_JOIN_OFFER.getPath({ offerId }));

  const mockJoinedTableData: Array<JoinedOffersTableRow> = new Array(2).fill(1).reduce((acc, item, index) => {
    const rowItem: JoinedOffersTableRow = {
      key: `${index}`,
      sku: `STQ-0000${index + 1}`,
      oem: 'Microsoft',
      product: 'Microsoft Surface Go 2',
      availableQuantity: '2,000',
      totalQuantity: '5,000',
      customerBuyPrice: showPriceDigits(440),
      unitsSold: '1515',
    };
    acc.push(rowItem);
    return acc;
  }, []);

  const mockAvailableTableData: Array<AvailableOffersTableRow> = new Array(2).fill(1).reduce((acc, item, index) => {
    const rowItem: AvailableOffersTableRow = {
      key: `${index}`,
      sku: `STQ-0000${index + 1}`,
      oem: 'Microsoft',
      product: 'Microsoft Surface Go 2',
      availableQuantity: '2,000',
      totalQuantity: '5,000',
      resellerBuyPrice: showPriceDigits(440),
    };
    acc.push(rowItem);
    return acc;
  }, []);

  return (
    <SupplierLayout>
      <OffersView
        onJoinedOffersRowClick={onJoinedOffersRowClick}
        joinedOffersTableData={mockJoinedTableData}
        availableOffersTableData={mockAvailableTableData}
        onAvailableOffersJoinClick={onAvailableOffersJoinClick}
      />
    </SupplierLayout>
  );
}
