import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useGetOfferQuery } from '../../../api/services/buyerApi';
import { selectBuyerCalculations, selectBuyerOrder } from '../../../feature/buyerState';
import BuyerLayout from '../layout/BuyerLayout';
import BuyerOrderSubmittedView from './BuyerOrderSubmittedView';

export default function BuyerOrderSubmittedPage() {
  const { orderId } = useParams<{ orderId: string }>();

  const { data: offer } = useGetOfferQuery(orderId);
  const order = useSelector(selectBuyerOrder);
  const calculations = useSelector(selectBuyerCalculations);

  return (
    <BuyerLayout>
      {offer && (
        <BuyerOrderSubmittedView offer={offer} order={order} calculations={calculations} />
      )}
    </BuyerLayout>
  );
}
