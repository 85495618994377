import styled from 'styled-components/macro';
import { useFormik } from 'formik';
import { MainHeader, SecondHeader } from '../../../ui/Headers';
import FindYourAddress from '../../../ui/forms/FindYourAddress';
import PostcodeInput from '../../school/create-school/PostcodeInput';
import { renderFormConfig, FormConfig } from '../../../utils/form';
import Input from '../../../ui/forms/Input';
import { ButtonDark, ButtonLightGray } from '../../../ui/Button';
import { newSchoolAccountSchema } from '../admin-schemas';
import { ApiAdminCreateSchool } from '../../../project-types/admin-schools/api-types';

interface NewSchoolFormProps {
  onFormSubmitHandler: (formData: ApiAdminCreateSchool['data']) => void;
  onCancelClickHandler: () => void;
}

export default function NewSchoolForm({ onFormSubmitHandler, onCancelClickHandler }: NewSchoolFormProps) {
  const formik = useFormik({
    initialValues: {
      schoolName: '',
      password: '',
      confirmPassword: '',
      URN: '',
      charityName: '',
      charityNumber: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: '',
      postcode: '',
      adress1: '',
      adress2: '',
      adress3: '',
      town: '',
      country: '',
    },
    validationSchema: newSchoolAccountSchema,
    onSubmit: (values) => {
      const {
        confirmPassword, email, password, ...rest
      } = values;
      const createSchoolValue = {
        email,
        password,
        schoolProfile: { ...rest },
      };
      onFormSubmitHandler(createSchoolValue);
    },
  });

  return (
    <Wrap onSubmit={formik.handleSubmit}>
      <StyledMainHeader>New school account</StyledMainHeader>
      {renderFormConfig(formConfig1, formik)}
      <StyledSecondHeader>Person acting on behalf of the school</StyledSecondHeader>
      {renderFormConfig(formConfig2, formik)}
      <FindYourAddress postCode={formik.values.postcode} formikInstance={formik} />
      {renderFormConfig(formConfig3, formik)}
      <ButtonsBlock>
        <StyledGrayButton onClick={(e) => {
          e.preventDefault();
          onCancelClickHandler();
        }}
        >
          Cancel

        </StyledGrayButton>
        <StyledButtonDark type="submit">Create Account</StyledButtonDark>
      </ButtonsBlock>
    </Wrap>
  );
}

const Wrap = styled.form`
  padding: 102px 54px 48px;
  flex-grow: 1;
  max-width: 1020px;
`;

const StyledMainHeader = styled(MainHeader)`
  font-size: 24px;
  line-height: 26px;
  margin-bottom: 50px;
`;

const StyledSecondHeader = styled(SecondHeader)`
  font-size: 16px;
  line-height: 22px;
  margin-top: 44px;
  margin-bottom: 24px;
`;

const StyledInput = styled(Input)`
  width: 100%;
  max-width: 630px;
`;

const StyledPostcodeInput = styled(PostcodeInput)`
  width: 100%;
  max-width: 630px;
`;

const ButtonsBlock = styled.div`
  display: flex;
  width: 100%;
  max-width: 630px;
  gap: 28px;
  margin-top: 50px;
`;

const StyledButtonDark = styled(ButtonDark)`
  padding: 12px 0;
  width: 100%;
`;

const StyledGrayButton = styled(ButtonLightGray)`
  padding: 12px 0;
  width: 100%;
`;

const formConfig1: FormConfig = [
  {
    title: 'School Name',
    name: 'schoolName',
    component: StyledInput,
  },
  {
    title: 'URN (Unique Reference Number',
    name: 'URN',
    component: StyledInput,
  },
  {
    title: 'Charity Name',
    name: 'charityName',
    component: StyledInput,
  },
  {
    title: 'Charity Number',
    name: 'charityNumber',
    component: StyledInput,
  },
];

const formConfig2: FormConfig = [
  {
    title: 'First Name',
    name: 'firstName',
    component: StyledInput,
  },
  {
    title: 'Last Name',
    name: 'lastName',
    component: StyledInput,
  },
  {
    title: 'Phone number',
    name: 'phoneNumber',
    component: StyledInput,
  },
  {
    title: 'Email',
    name: 'email',
    component: StyledInput,
  },
  {
    title: 'Password',
    name: 'password',
    props: {
      type: 'password',
    },
    component: StyledInput,
  },
  {
    title: 'Confirm password',
    name: 'confirmPassword',
    props: {
      type: 'password',
    },
    component: StyledInput,
  },
  {
    title: 'Postcode',
    name: 'postcode',
    component: StyledPostcodeInput,
  },
];

const formConfig3: FormConfig = [
  {
    title: 'Address line 1*',
    name: 'adress1',
    component: StyledInput,
  },
  {
    title: 'Address line 2',
    name: 'adress2',
    component: StyledInput,
  },
  {
    title: 'Address line 3',
    name: 'adress3',
    component: StyledInput,
  },
  {
    title: 'Town',
    name: 'town',
    component: StyledInput,
  },
  {
    title: 'Country',
    name: 'country',
    component: StyledInput,
  },
];
