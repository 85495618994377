import styled from 'styled-components/macro';
import InfoTable from '../../../../ui/elements/InfoTable';
import { serializeStatisticsPrice } from '../../../../utils/formats';

import SummaryInfo from '../../layout/ProductSummaryInfoTable';

interface ViewOfferProps {
  onEditClickHandler: () => void;
  onViewOrdersClickHandler: () => void;
}

export default function ViewOffer({ onEditClickHandler, onViewOrdersClickHandler }: ViewOfferProps) {
  const infoTableStats = [
    {
      title: 'Total revenue',
      value: serializeStatisticsPrice(650000),
      link: 'View Orders',
      onLinkClickHandler: onViewOrdersClickHandler,
    },
    {
      title: 'Units sold',
      value: '2515',
      description: '(50.5% of Total Units Sold)',
      link: 'View Orders',
      onLinkClickHandler: onViewOrdersClickHandler,
    },
    {
      title: 'Customer buy price',
      value: serializeStatisticsPrice(441),
      link: 'Edit',
      onLinkClickHandler: onEditClickHandler,
    },
  ];

  return (
    <Container>
      <Content>
        <SummaryBlock>
          <Title>View Offer</Title>
          <SubTitle>Summary</SubTitle>
          <StyledInfoTable data={infoTableStats} />
        </SummaryBlock>
        <OverviewInfoBlock>
          <SummaryInfo />
        </OverviewInfoBlock>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  gap: 29px;
  flex: 1;
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  gap: 100px;
`;

const SummaryBlock = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const Title = styled.h1`
  font-size: 30px;
  line-height: 34px;
  color: #102f54;
  font-weight: bold;
`;

const SubTitle = styled.h2`
  font-size: 21px;
  line-height: 26px;
  color: #102f54;
  font-weight: bold;
`;

const StyledInfoTable = styled(InfoTable)``;

const OverviewInfoBlock = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
