import DataTable from '../../../../ui/elements/Table';
import { DonorCollectionsTableConfig, DonorCollectionsTableRow } from '../../types';

const tableConfig: DonorCollectionsTableConfig = [
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'School',
    dataIndex: 'school',
    key: 'school',
  },
  {
    title: 'Campaign Name',
    dataIndex: 'campaignId',
    key: 'campaignId',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: 'Amount',
    dataIndex: 'totalAmount',
    key: 'totalAmount',
  },
];

interface CollectionTableProps {
  tableData: Array<DonorCollectionsTableRow>;
}

export default function CollectionTable({ tableData }: CollectionTableProps) {
  return (<DataTable dataSource={tableData} columns={tableConfig} />);
}
