import { useHistory } from 'react-router-dom';
import ROUTER_CONFIG from '../../../../router/router-config';
import SupplierLayoutWithBack from '../../layout/SupplierLayoutWithBack';
import { QuoteCreateType, QuoteServiceItem } from '../../types';
import BuildQuote from './BuildQuote';

const Services: Array<QuoteServiceItem> = [
  {
    name: 'Asset Tagging',
    description: 'Asset identification and tagging is an essential component of a good asset management strategy. It helps a company’s assets to become quickly identifiable and ties the asset to the overall asset plan.',
    price: '',
    quantity: '',
  },
  {
    name: 'Installation',
    description: 'Our Installation Service provides for the basic installation of servers, workstations, desktop systems, notebook PCs, thin clients, storage devices, printers, networking, and software products.',
    price: '',
    quantity: '',
  },
];

export default function BuildQuotePage() {
  const { push } = useHistory();

  const onFormSubmit = (values: QuoteCreateType) => {
    console.log(values);
    push(ROUTER_CONFIG.SUPPLIER_QUOTE_CREATED.getPath());
  };

  return (
    <SupplierLayoutWithBack onBack={() => push(ROUTER_CONFIG.SUPPLIER_QUOTES.getPath())}>
      <BuildQuote
        onFormSubmit={onFormSubmit}
        services={Services}
      />
    </SupplierLayoutWithBack>
  );
}
