import styled from 'styled-components/macro';
import { InfoCircleOutlined } from '@ant-design/icons';
import { ButtonDark } from '../../../../../ui/Button';
import { BuyerOrderStateType } from '../../../../../feature/buyerState';
import { CalculatedPricesType } from '../../../calculateOrderPrice';

interface SubmitBlockProps {
  onSubmit: () => void;
  order: BuyerOrderStateType;
  calculations: CalculatedPricesType;
}

export default function SubmitBlock({
  onSubmit, order, calculations,
}: SubmitBlockProps) {
  return (
    <Container>
      <SubmitButton onClick={onSubmit}>Submit Order</SubmitButton>
      <Text>By placing your order you agree to the Supplier’s Terms and Conditions.</Text>
      <Divider />
      <Title>Who you’ll pay</Title>
      <JustifyItem>
        <BoldText>{order?.reseller?.name}</BoldText>
        <CursiveGreyText>{`${order?.orderType} Approval`}</CursiveGreyText>
      </JustifyItem>
      <ContentBlock>
        <JustifyItem>
          <ColumnItem style={{ gap: 2 }}>
            <BoldText>Total (Inc-VAT)</BoldText>
            <GreyText>Billed Quarterly</GreyText>
          </ColumnItem>
          <BoldText>{calculations.totalWithVat}</BoldText>
        </JustifyItem>
        <JustifyItem>
          <BoldText>Number of payments</BoldText>
          <BoldText>{calculations.numberOfPaymentsToSilverwing}</BoldText>
        </JustifyItem>
        <Divider />
        <GreyText>Payments will start once you have signed the lease agreement following delivery.</GreyText>
      </ContentBlock>
      <JustifyItem>
        <BoldText>Silverwing</BoldText>
        <CustomLink>
          <InfoCircleOutlined style={{ marginRight: 5 }} />
          Why Silverwing?
        </CustomLink>
      </JustifyItem>
      <ContentBlock>
        <JustifyItem>
          <ColumnItem style={{ gap: 2 }}>
            <BoldText>Total (Inc-VAT)</BoldText>
            <GreyText>Billed Quarterly</GreyText>
          </ColumnItem>
          <BoldText>{`${calculations.totalToSilverwing}/quarter`}</BoldText>
        </JustifyItem>
        <JustifyItem>
          <BoldText>Number of payments</BoldText>
          <BoldText>{calculations.numberOfPaymentsToSilverwing}</BoldText>
        </JustifyItem>
        <Divider />
        <GreyText>Payments will start once you have signed the lease agreement following delivery.</GreyText>
      </ContentBlock>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  padding: 20px;
  background: #F5F8FB;
  align-self: flex-start;

  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Text = styled.span`
  font-size: 14px;
  line-height: 17px;
  color: #102f54;
`;

const CustomLink = styled(Text)`
  color: #2682DE;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const GreyText = styled(Text)`
  color: #6B7A87;
  font-weight: bold;
`;

const CursiveGreyText = styled(GreyText)`
  font-style: italic;
`;

const BoldText = styled(Text)`
  font-weight: bold;
`;

const Title = styled.h3`
  font-size: 20px;
  line-height: 24px;
  color: #102F54;
  margin: 0;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #D5DCE5;
`;

const SubmitButton = styled(ButtonDark)`
  padding: 13px;
`;

const JustifyItem = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ColumnItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ContentBlock = styled(ColumnItem)`
  padding: 20px;
  background-color: #fff;
`;
