import { useFormik } from 'formik';
import styled from 'styled-components/macro';
import LoginImage from '../../../img/loginImg.svg';
import { buyerRegisterSchema } from '../buyer-schemas';
import { BuyerRegisterType, ResGlobalError } from '../types';
import BuyerRegisterForm from './BuyerRegisterForm';

interface BuyerRegisterViewProps {
  onSubmit: (values: BuyerRegisterType) => void;
  preferredResellersList: Array<string>;
  externalErrors?: Record<string, string[]> | ResGlobalError;
}

export default function BuyerRegisterView({
  onSubmit,
  preferredResellersList,
  externalErrors,
}: BuyerRegisterViewProps) {
  const formik = useFormik({
    initialValues: {
      school: '',
      schoolAddress: '',
      firstName: '',
      lastName: '',
      workEmail: '',
      phoneNumber: '',
      jobTitle: '',
      // preferredReseller: '',
      // isStartingParentalContributionScheme: undefined,
      // percentageOfTheDeviceCostsForParentsToContribute: undefined,
      // preferredPurchaseMethod: undefined,
      agree: false,
      password: '',
      confirmPassword: '',
    },
    validationSchema: buyerRegisterSchema,
    onSubmit: (values) => onSubmit(values),
  });

  return (
    <Container>
      <Content>
        <MainHeader>Register</MainHeader>
        <StyledText>
          Please provide the required information below to register for a
          Devices For Education account.
        </StyledText>
        <BuyerRegisterForm
          formikInstance={formik}
          preferredResellersList={preferredResellersList}
          errors={externalErrors}
        />
      </Content>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  background: url(${LoginImage}) no-repeat right 150px top 80px/25%,
    linear-gradient(to right, white 70%, #F1F9F8 70% 100%);
  min-height: 700px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 1000px) {
    background: linear-gradient(to right, white 70%, #F1F9F8 70% 100%);
    backgoundImage: none;
  }
`;

const MainHeader = styled.h1`
  font-weight: 600;
  font-size: 30px;
  margin-bottom: 31px;
  color: #102F54;
`;

const StyledText = styled.p`
  color: #102f54;
  margin-bottom: 48px;
`;

const Content = styled.div`
  padding: 66px 0 0 150px;
  max-width: 400px;
`;
