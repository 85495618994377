import styled from 'styled-components/macro';
import { MainHeader } from '../../../ui/Headers';
import InfoTilel from '../../../ui/elements/InfoTiles';
import CollectionPeriodComponent from './CollectionPeriodComponent';
import CollectionsTable from './CollectionsTable';
import { AllCollectionsTableRow } from '../types';
import { formatDateDDMMMYYYY } from '../../../ui/DateFormatMethods';
import { serializeStatisticsData, serializeStatisticsPrice, showPriceDigits } from '../../../utils/formats';
import { AdminPaymentItem, AdminPaymentStatsType } from '../../../project-types/admin-payment/types';

interface AllCollectionsProps {
  onMonthChange: (monthNum: number) => void;
  currentMonth: number;
  adminCollections: Array<AdminPaymentItem>;
  adminCollectionsStatistics: AdminPaymentStatsType | null;
  donorSearch: string;
  onChangeDonorSearch: (donor: string) => void;
  schoolSearch: string;
  onChangeSchoolSearch: (school: string) => void;
  campaignSearch: string;
  onChangeCampaignSearch: (campaign: string) => void;
  dateSearch: string;
  onChangeDateSearch: (date: string) => void;
  onChangeStatusSearch: (status: Array<any> | null) => void;
}

export default function AllCollections({
  onMonthChange, currentMonth, adminCollections, adminCollectionsStatistics, donorSearch, onChangeDonorSearch, schoolSearch, onChangeSchoolSearch, campaignSearch, onChangeCampaignSearch, dateSearch, onChangeDateSearch, onChangeStatusSearch,
}: AllCollectionsProps) {
  const tableData: Array<AllCollectionsTableRow> = adminCollections ? adminCollections.map((collection: any) => ({
    status: collection.status,
    date: formatDateDDMMMYYYY(new Date(collection.postedDate)),
    donor: collection.donorName,
    school: collection.schoolName,
    campaignId: collection.campaign,
    ammount: showPriceDigits(collection.amount),
    giftAid: 'yes',
    // giftAid: collection.giftAid ? 'yes' : 'no',
    giftAidAmount: showPriceDigits(collection.amount * 0.25)
  })) : [];

  const onIncDateRange = () => {
    if (currentMonth < 12) {
      onMonthChange(currentMonth + 1);
    } else {
      onMonthChange(1);
    }
  };
  const onDecrDateRange = () => {
    if (currentMonth > 1) {
      onMonthChange(currentMonth - 1);
    } else {
      onMonthChange(12);
    }
  };

  const InfoContent = [
    {
      title: 'Collected in the current period',
      value: serializeStatisticsPrice(adminCollectionsStatistics?.collectedInPeriod),
    },
    {
      title: 'Collected total for all time',
      value: serializeStatisticsPrice(adminCollectionsStatistics?.colectedTotal),
    },
    {
      title: 'Gift Aid in the current period',
      value: serializeStatisticsData(0),
    },
  ];

  return (
    <Wrapper>
      <StyledMainHeader>All Collections</StyledMainHeader>
      <InfoTilel data={InfoContent} />
      <CollectionPeriodComponent styles={{ margin: '40px 0' }} monthNum={currentMonth} onIncDateRange={onIncDateRange} onDecrDateRange={onDecrDateRange} />
      <CollectionsTable donorSearch={donorSearch} onChangeDonorSearch={onChangeDonorSearch} schoolSearch={schoolSearch} onChangeSchoolSearch={onChangeSchoolSearch} campaignSearch={campaignSearch} onChangeCampaignSearch={onChangeCampaignSearch} dateSearch={dateSearch} onChangeDateSearch={onChangeDateSearch} onChangeStatusSearch={onChangeStatusSearch} tableRows={tableData} />
    </Wrapper>
  );
}

const Wrapper = styled.main`
  box-sizing: border-box;
  padding: 102px 54px 48px;
  flex-grow: 1;
  max-width: 1020px;
`;

const StyledMainHeader = styled(MainHeader)`
  margin-bottom: 60px;
  font-size: 24px;
  line-height: 26px;
`;
