import { DatePicker } from 'antd';

interface DatePickerProps {
  setFieldValue: (field: string, value: string) => void;
  name: string;
  className?: string;
}

export default function Datepicker({ setFieldValue, name, className }: DatePickerProps) {
  return <DatePicker onChange={(_, dateString) => setFieldValue(name, dateString)} className={className} />;
}
