import styled from 'styled-components/macro';
import DocumentInfoItem from '../../../ui/elements/DocumentInfoItem';
import OrderOverviewInfo from './order-overview-info/OrderOverviewInfo';
import PaymentsInfoTable from './PaymentsInfoTable';
import { ButtonLight } from '../../../ui/Button';
import ResellerOverview from '../layout/elements/ResellerOverview';
import LessorOverview from '../layout/elements/LessorOverview';
import {
  IBuyerOrderPublic, IReseller, ISellerOfferPublicType, ORDER_TYPES,
} from '../types';
import { CalculatedPricesType } from '../calculateOrderPrice';

interface BuyerOrderDetailsViewProps {
  order: IBuyerOrderPublic;
  calculations: CalculatedPricesType;
  offer: ISellerOfferPublicType;
  reseller?: IReseller;
}

export default function BuyerOrderDetailsView({
  order, calculations, offer, reseller,
}: BuyerOrderDetailsViewProps) {
  return (
    <Container>
      <OrderDetailsBlock>
        <Title>Order Details</Title>
        <OrderOverviewInfo
          calculations={calculations}
          order={order}
          offer={offer}
          reseller={reseller}
        />
        <PaymentsTableBlock>
          <SubTitle>Payments</SubTitle>
          <PaymentsInfoTable payments={[]} />
        </PaymentsTableBlock>
        <DocumentsBlock>
          <SubTitle>Documents</SubTitle>
          <Divider />
          <DocumentRows>
            <DocumentRow>
              <StyledDocumentInfoItem
                documentTitle="Purchase Order - PO#12181381"
                createdDate={new Date()}
              />
              <StyledButtonLight>View Document</StyledButtonLight>
            </DocumentRow>
          </DocumentRows>
          <Divider />
        </DocumentsBlock>
      </OrderDetailsBlock>
      <OverviewInfoBlock>
        <ResellerOverview reseller={reseller} />
        {order.orderType === ORDER_TYPES['Operating Lease'] && (
          <LessorOverview />
        )}
      </OverviewInfoBlock>
    </Container>
  );
}

const Container = styled.div`
  flex: 1;
  display: flex;
  gap: 100px;
`;

const OrderDetailsBlock = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const Title = styled.h1`
  font-weight: bold;
  font-size: 30px;
  line-height: 34px;
  color: #102F54;
`;

const SubTitle = styled.h2`
  font-size: 21px;
  line-height: 26px;
  color: #102F54;
  font-weight: bold;
  margin-bottom: 30px;
`;

const PaymentsTableBlock = styled.div``;

const DocumentsBlock = styled.div``;

const DocumentRows = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 20px 0;
`;

const DocumentRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledDocumentInfoItem = styled(DocumentInfoItem)`
  flex: 1;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #D5DCE5;
`;

const OverviewInfoBlock = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 50px;
`;

const StyledButtonLight = styled(ButtonLight)`
  padding: 8px 28px;
`;
