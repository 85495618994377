import styled from 'styled-components/macro';
import Tag from '../../../../../ui/elements/Tag';
import ProductRow from './ProductRow';
import { formatDateDDMMMYYYY } from '../../../../../ui/DateFormatMethods';
import { showPriceDigits } from '../../../../../utils/formats';
import { ISellerOfferPublicType, ORDER_TYPES } from '../../../types';
import { BuyerOrderStateType } from '../../../../../feature/buyerState';
import { CalculatedPricesType } from '../../../calculateOrderPrice';

interface OrderSummaryProps {
  className?: string;
  offer: ISellerOfferPublicType;
  order: BuyerOrderStateType;
  calculations: CalculatedPricesType;
}

export default function OrderSummary({
  className, offer, order, calculations,
}: OrderSummaryProps) {
  const { product } = offer.offer;

  return (
    <Container className={className}>
      <Content>
        <StyledTag color="#2682DE" bgColor="#E8F4FF">
          {`${order?.orderType !== ORDER_TYPES['Outright Purchase'] ? 'LEASE REQUEST' : 'PO'} #49318386`}
        </StyledTag>
        <Divider />
        <ProductRow
          image={`https://image-sds-5pjg3ckx7a-uc.a.run.app/${product.imageUrl}`}
          name={product.name}
          price={order?.orderType === ORDER_TYPES['Outright Purchase'] ? offer.price : offer.leasePrice}
          quantity={order?.quantity || 50}
          reseller={order?.reseller?.name || ''}
          order={order}
        />
        {order?.services?.map((service) => (
          <ProductRow
            key={service.serviceName}
            image={service.serviceIcon}
            name={service.serviceName}
            price={service.price}
            quantity={order.quantity}
            reseller={order?.reseller?.name || ''}
            order={order}
          />
        ))}
        {order?.accessories?.map((acc) => (
          <ProductRow
            image={`https://image-sds-5pjg3ckx7a-uc.a.run.app/${acc.offer.product.imageUrl}`}
            name={acc.offer.product.name}
            price={order?.orderType !== ORDER_TYPES['Outright Purchase'] ? acc.price : acc.price * (order?.lease?.rate || 1)}
            quantity={acc.quantity}
            reseller={order?.reseller?.name || ''}
            order={order}
          />
        ))}
        <JustifyItem>
          <BoldText>{order?.deliveryOption}</BoldText>
          <BoldText>{showPriceDigits(order?.reseller?.deliveryPrice || 0)}</BoldText>
        </JustifyItem>
        <Divider />
        <CalculationsBlock>
          <CalculationTitle style={{ flex: 1 }}>
            <GreyText>Expected Delivery</GreyText>
            <BoldText>{formatDateDDMMMYYYY(new Date())}</BoldText>
          </CalculationTitle>
          <CalculationColumnBlock>
            <JustifyItem>
              <CalculationTitle>
                <BoldText>Subtotal (Ex-VAT)</BoldText>
                {order?.orderType !== ORDER_TYPES['Outright Purchase'] && <GreyText>{`Billed ${order?.paymentSchedule}`}</GreyText>}
              </CalculationTitle>
              <BoldText>{calculations.totalExVat}</BoldText>
            </JustifyItem>
            <JustifyItem>
              <CalculationTitle>
                <BoldText>VAT (20%)</BoldText>
                {order?.orderType !== ORDER_TYPES['Outright Purchase'] && <GreyText>{`Billed ${order?.paymentSchedule}`}</GreyText>}
              </CalculationTitle>
              <BoldText>{calculations.totalVat}</BoldText>
            </JustifyItem>
            <JustifyItem>
              <CalculationTitle>
                <BoldText>Total (Inc-VAT)</BoldText>
                {order?.orderType !== ORDER_TYPES['Outright Purchase'] && <GreyText>{`Billed ${order?.paymentSchedule}`}</GreyText>}
              </CalculationTitle>
              <Price>{calculations.totalWithVat}</Price>
            </JustifyItem>
          </CalculationColumnBlock>
        </CalculationsBlock>
        <Divider />
        <JustifyItem style={{ gap: 20 }}>
          <CalculationTitle>
            <GreyText>Delivery Address</GreyText>
            <Text>
              {order?.deliveryAddress}
            </Text>
          </CalculationTitle>
          <CalculationTitle>
            <GreyText>Invoice Address</GreyText>
            <Text>
              {order?.invoiceAddress}
            </Text>
          </CalculationTitle>
          <CalculationTitle>
            <GreyText>Payment Method</GreyText>
            <Text>
              {order?.orderType === ORDER_TYPES['Outright Purchase'] ? `${order.orderType}` : `${order?.orderType} with ${order?.reseller?.name}`}
            </Text>
          </CalculationTitle>
        </JustifyItem>
      </Content>

      {order?.insurance && (
        <Content>
          <StyledTag color="#2682DE" bgColor="#E8F4FF">{`${order?.orderType !== ORDER_TYPES['Outright Purchase'] ? 'LEASE REQUEST' : 'PO'} #12318382`}</StyledTag>
          <Divider />
          <ColumnItem style={{ gap: '5px' }}>
            <JustifyItem>
              <Text>
                {order?.insurance?.name}
              </Text>
              <BoldText>{`${showPriceDigits(order.insurance.price)}/${order.insurance.paymentSchedule}`}</BoldText>
            </JustifyItem>
            <JustifyItem>
              <Text>{`${order.quantity} Units`}</Text>
              <Text>{`${order.insurance.policyLength} Years`}</Text>
            </JustifyItem>
            <ColumnItem>
              <Text>Presented by: Demo Insurance Ltd.</Text>
              <CustomLink>Terms and conditions</CustomLink>
            </ColumnItem>
          </ColumnItem>
          <Divider />
          <CalculationsBlock style={{ gap: '20px' }}>
            <CalculationTitle style={{ flex: 1 }}>
              <GreyText>Expected Delivery</GreyText>
              <Text>Policy documents will be sent by email and available within 72 hours after your order has been accepted.</Text>
            </CalculationTitle>
            <CalculationColumnBlock>
              <JustifyItem>
                <CalculationTitle>
                  <BoldText>Subtotal (Ex-VAT)</BoldText>
                  <GreyText>{`Billed ${order.insurance.paymentSchedule}`}</GreyText>
                </CalculationTitle>
                <BoldText>{calculations.insuranceSubtotalExVat}</BoldText>
              </JustifyItem>
              <JustifyItem>
                <CalculationTitle>
                  <BoldText>VAT (20%)</BoldText>
                  <GreyText>{`Billed ${order.insurance.paymentSchedule}`}</GreyText>
                </CalculationTitle>
                <BoldText>{calculations.insuranceVat}</BoldText>
              </JustifyItem>
              <JustifyItem>
                <CalculationTitle>
                  <BoldText>Total (Inc-VAT)</BoldText>
                  <GreyText>{`Billed ${order.insurance.paymentSchedule}`}</GreyText>
                </CalculationTitle>
                <Price>{calculations.insuranceTotalWithVat}</Price>
              </JustifyItem>
            </CalculationColumnBlock>
          </CalculationsBlock>
          <Divider />
          <JustifyItem style={{ gap: 20 }}>
            <CalculationTitle>
              <GreyText>Invoice Address</GreyText>
              <Text>
                {order.invoiceAddress}
              </Text>
            </CalculationTitle>
            <CalculationTitle>
              <GreyText>Payment Method</GreyText>
              <Text>Direct Debit</Text>
              <GreyText style={{ fontWeight: 'normal' }}>When your order is accepted we will contact your accounts payable team to set up your direct debit payments.</GreyText>
            </CalculationTitle>
          </JustifyItem>
        </Content>
      )}
    </Container>
  );
}

const Text = styled.span`
  font-size: 14px;
  line-height: 17px;
  color: #102f54;
`;

const CustomLink = styled(Text)`
  color: #2682DE;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const GreyText = styled(Text)`
  color: #6B7A87;
  font-weight: bold;
`;

const BoldText = styled(Text)`
  font-weight: bold;
`;

const Price = styled(BoldText)`
  color: #DE2644;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #D5DCE5;
`;

const JustifyItem = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ColumnItem = styled.div`
  display: flex;
  flex-direction: column;
`;

const Container = styled.div`
  background-color: #FAFBFC;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  border-radius: 3px;
`;

const Content = styled(ColumnItem)`
  padding: 20px;
  background-color: #fff;
  border-radius: 3px;
  gap: 20px;
`;

const StyledTag = styled(Tag)`
  align-self: flex-start;
  text-align: center;
`;

const CalculationsBlock = styled(JustifyItem)`
`;

const CalculationTitle = styled(ColumnItem)`
  gap: 6px;
`;

const CalculationColumnBlock = styled(ColumnItem)`
  flex: 1;
  gap: 14px;
`;
