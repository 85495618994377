import { AxiosRequestConfig } from 'axios';
import { ActionRequestType } from './api-types';

export type AxiosRequestParams<REQUEST extends ActionRequestType> = Pick<
  REQUEST,
  'url' | 'method' | 'params' | 'data'
>;

export const createAxiosConfig = <A extends Pick<ActionRequestType, 'method' | 'params' | 'data' | 'url'>>(
  config: Pick<A, 'method' | 'params' | 'data' | 'url'>,
) => {
  const requestConfig = {
    method: config.method,
    params: config.params,
    data: config.data,
    url: config.url.join(''),
  };

  return requestConfig;
};
