import styled from 'styled-components/macro';
import { InfoCircleOutlined } from '@ant-design/icons';

interface WhatHappensNowProps {
  className?: string;
}

export default function WhatHappensNow({ className }: WhatHappensNowProps) {
  return (
    <ColumnItem style={{ gap: 25 }} className={className}>
      <RowItem style={{ alignItems: 'center', gap: 10 }}>
        <InfoCircleOutlined style={{ color: '#2682DE' }} />
        <Title>What happens now</Title>
      </RowItem>
      <RowItem>
        <NumberInSquare>1</NumberInSquare>
        <ColumnItem>
          <BoldText>Your Lease Agreement Will Be Finalised</BoldText>
          <Text>
            Your lease request will be sent to the lessor for approval. When the
            request is approved we will send over the relevant lease paperwork
            for signature and send your order to the reseller.
          </Text>
        </ColumnItem>
      </RowItem>
      <RowItem>
        <NumberInSquare>2</NumberInSquare>
        <ColumnItem>
          <BoldText>Your Devices Will Be Reserved</BoldText>
          <Text>
            Whilst your lease is being finalised, we will reserve the stock for
            your order to ensure it remains available.
          </Text>
        </ColumnItem>
      </RowItem>
      <RowItem>
        <NumberInSquare>3</NumberInSquare>
        <ColumnItem>
          <BoldText>Your Equipment Will Be Delivered</BoldText>
          <Text>
            Equipment is delivered to your delivery address, you will then be
            asked to confirm you have accepted the delivery.
          </Text>
        </ColumnItem>
      </RowItem>
      <RowItem>
        <NumberInSquare>4</NumberInSquare>
        <ColumnItem>
          <BoldText>Your Payments Will Begin</BoldText>
          <Text>
            The lessor will pay your chosen reseller for the order and your
            operating lease payments to the lessor will begin.
          </Text>
        </ColumnItem>
      </RowItem>
      <NeedInfoBlock>
        <BoldText>Need help?</BoldText>
        <Text>
          Speak to a specialist{' '}
          <CustomLink href="https://www.devicesforeducation.com/support">
            here.
          </CustomLink>
        </Text>
      </NeedInfoBlock>
    </ColumnItem>
  );
}

const Text = styled.span`
  font-size: 14px;
  line-height: 17px;
  color: #102f54;
`;

const Title = styled.h3`
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #102F54;
  margin: 0;
`;

const CustomLink = styled.a`
  color: #2682DE;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const BoldText = styled(Text)`
  font-weight: bold;
`;

const RowItem = styled.div`
  display: flex;
  gap: 14px;
`;

const ColumnItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const NumberInSquare = styled.div`
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  color: #2682DE;
  background-color: #E8F4FF;
  flex-shrink: 0;
`;

const NeedInfoBlock = styled(ColumnItem)`
  align-items: center;
  gap: 3px;
`;
