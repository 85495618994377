import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { message as antMessage } from 'antd';
import ModalInPortal from '../../../ui/Modal/ModalPortal';
import SchoolLayout from '../layout/SchoolLayoutWithTabs';
import InviteDonors from './InviteDonors';
import CongratulationsModal from './CongratulationsModal';
import InviteTemplateModal from './InviteTemplateModal';
import InviteSentModal from './InviteSentModal';
import SendInvitesModal from './SendInvitesModal';
import { CreateInvitesListType } from '../../../project-types/campaign/types';
import Modal from '../../../ui/Modal/Modal';
import { createIvite, loadCampaign, selectCurrentCampaign } from '../../../feature/campaignState';
import CampaignInfo from '../campaign/campaign-folder/CampaignInfoPage';
import ROUTER_CONFIG from '../../../router/router-config';

import { getUserProfile } from '../../../feature/userState';

export default function InvitePage() {
  const CongratulationPortal = ModalInPortal();
  const IviteTemplatePortal = ModalInPortal();
  const InviteSentPortal = ModalInPortal();
  // const SendInvitesPortal = ModalInPortal();

  const campaign = useSelector(selectCurrentCampaign);
  const dispatch = useDispatch();
  const { campaignId } = useParams<{ campaignId: string }>();

  useEffect(() => {
    dispatch(loadCampaign(campaignId));
  }, []);

  const profile = useSelector(getUserProfile);

  const { push } = useHistory();

  // const onCreateCampaign = () => {
  //   CongratulationPortal.onShow();
  //   // push(RC.SCHOOL_CAMPAIGN_CREATE.getPath());
  // };

  // const onBack = () => {
  //   push(ROUTER_CONFIG.SCHOOL_CAMPAIGNS_LIST.getPath());
  // };

  useEffect(() => {
    // SendInvitesPortal.onShow();
  }, []);

  const onIviteEmail = () => {
    IviteTemplatePortal.onClose();
    // SendInvitesPortal.onShow();
    setShowSend(true);
  };

  const showTemplate = () => {
    // SendInvitesPortal.onClose();
    setShowSend(false);
    IviteTemplatePortal.onShow();
  };

  const sendEmail = () => {
    const filteredContacts = contacts.filter((c) => c.email);
    setContacts(filteredContacts);

    if (!filteredContacts.length) return;

    sendIvites().then(() => {
      setShowSend(false);
      InviteSentPortal.onShow();
    });
  };
  // TODO dirty hack
  const [showSend, setShowSend] = useState<boolean>(false);

  const [contacts, setContacts] = useState<CreateInvitesListType['members']>([]);
  const [message, setMessage] = useState<CreateInvitesListType['message']>('');

  const sendIvites = () => createIvite(campaignId, {
    members: contacts,
    message,
  });

  const onGoBackToDashBoard = () => {
    push(ROUTER_CONFIG.SCHOOL_CAMPAIGN_INFO.getPath({ campaignId }));
  };

  const onGoToFundraising = () => {
    push(ROUTER_CONFIG.SCHOOL_CAMPAIGN_FUNRAISING.getPath());
  };

  const onGoToSettings = () => {
    // s#ref-bank-details
    push(ROUTER_CONFIG.SCHOOL_SETTINGS.getPath('/#ref-bank-details'));
  };

  const onCopyToClipboard = () => {
    console.log(campaign?.inviteCode);
    if (campaign && campaign.inviteCode) {
      const { protocol, host, port } = window.location;
      navigator.clipboard.writeText(
        `${protocol}//${host}/donor/invite/accept/${campaignId}/${campaign.inviteCode}`,
      );

      antMessage.info('Invite link copied');
    }
  };

  return (
    <SchoolLayout>

      {showSend && (
      <Modal onClose={() => { }}>
        <SendInvitesModal
          message={message}
          setMessage={setMessage}
          contacts={contacts}
          setContacts={setContacts}
          onClose={() => setShowSend(false)}
          onPreview={showTemplate}
          onNext={sendEmail}
        />
      </Modal>
      )}

      {/* {SendInvitesPortal.ModalRender && <SendInvitesPortal.ModalRender>
      <SendInvitesModal
        message={message}
        setMessage={setMessage}
        contacts={contacts}
        setContacts={setContacts}
        onClose={SendInvitesPortal.onClose}
        onPreview={showTemplate}
        onNext={sendEmail}
      />
    </SendInvitesPortal.ModalRender>} */}
      {IviteTemplatePortal.ModalRender && (
      <IviteTemplatePortal.ModalRender>
        <InviteTemplateModal
          schoolName={profile?.schoolProfile?.schoolName || 'School name'}
          campaignName={campaign?.name || ''}
          onClose={onIviteEmail}
          onNext={() => { }}
          fullName={`${profile?.schoolProfile?.firstName} ${profile?.schoolProfile?.lastName}`}
        />
      </IviteTemplatePortal.ModalRender>
      )}
      {InviteSentPortal.ModalRender && (
      <InviteSentPortal.ModalRender>
        <InviteSentModal number={contacts.length} onClose={InviteSentPortal.onClose} onNext={() => { }} />
      </InviteSentPortal.ModalRender>
      )}
      {CongratulationPortal.ModalRender && (
      <CongratulationPortal.ModalRender>
        <CongratulationsModal
          onClose={() => { }}
          onNext={onGoBackToDashBoard}
          onGoToFundraising={onGoToFundraising}
          onGoToSettings={onGoToSettings}
          campaignName={campaign?.name || ''}
        />
      </CongratulationPortal.ModalRender>
      )}
      <InviteDonors
        numberOfInvites={contacts.length}
        onCopyToClipboard={onCopyToClipboard}
        onIviteEmail={onIviteEmail}
        onBack={onGoBackToDashBoard}
        onViewContactList={onIviteEmail}
      />
    </SchoolLayout>
  );
}
