interface IconProps {
  isSelected: boolean;
}

export function HomeIcon({ isSelected }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      fill="none"
      viewBox="0 0 19 19"
    >
      <path
        fill={isSelected ? '#C1E6FF' : '#F5F5F5'}
        d="M17.441 17.865H1.547V8.144l.248-.224 7.198-6.477.501-.45.502.45 7.197 6.477.248.224v9.721z"
      />
      <path
        fill={isSelected ? '#2682DE' : '#6B7A87'}
        d="M9.494 2.001L2.297 8.478v8.637h14.394V8.478L9.494 2zm0-1.5c.36 0 .718.128 1.004.385l7.197 6.476c.316.285.496.69.496 1.116v8.637a1.5 1.5 0 01-1.5 1.5H2.297a1.5 1.5 0 01-1.5-1.5V8.478c0-.425.18-.83.497-1.116L8.49.886A1.495 1.495 0 019.494.5z"
      />
    </svg>
  );
}

export function OffersIcon({ isSelected }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="17"
      fill={isSelected ? '#C1E6FF' : 'none'}
      viewBox="0 0 19 17"
    >
      <path stroke={isSelected ? '#2682DE' : '#6B7A87'} d="M16.938 15.454H2.048V1.55h14.89v13.904z" />
      <path
        stroke={isSelected ? '#2682DE' : '#6B7A87'}
        d="M2.298 1.3h-.5v14.404h15.39V1.3H2.298zm0-.5h14.39a1 1 0 011 1v13.404a1 1 0 01-1 1H2.298a1 1 0 01-1-1V1.8a1 1 0 011-1zM9.495 1.27v14.465M16.988 8.501H2"
      />
    </svg>
  );
}

export function OrdersIcon({ isSelected }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="15"
      fill="none"
      viewBox="0 0 18 15"
    >
      <path fill={isSelected ? '#C1E6FF' : '#F5F5F5'} d="M17.144 14.154H1.25V1.138h15.894v13.016z" />
      <path
        fill={isSelected ? '#2682DE' : '#6B7A87'}
        d="M2 1.888v11.516h14.394V1.888H2zm0-1.5h14.394a1.5 1.5 0 011.5 1.5v11.516a1.5 1.5 0 01-1.5 1.5H2a1.5 1.5 0 01-1.5-1.5V1.888A1.5 1.5 0 012 .388z"
      />
      <path fill={isSelected ? '#C1E6FF' : '#F5F5F5'} d="M9.197 1.887v4.318-4.318z" />
      <path stroke={isSelected ? '#2682DE' : '#6B7A87'} strokeWidth="1.5" d="M9.197 1.887v4.318" />
    </svg>
  );
}

export function PaymentsIcon({ isSelected }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="15"
      fill="none"
      viewBox="0 0 18 15"
    >
      <path fill={isSelected ? '#C1E6FF' : '#F5F5F5'} d="M16.394 1.74H2.004v11.52h14.39V1.74z" />
      <path
        stroke={isSelected ? '#2682DE' : '#6B7A87'}
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M17.144.99H1.254v13.02h15.89V.99z"
      />
      <path
        stroke={isSelected ? '#2682DE' : '#6B7A87'}
        strokeLinecap="round"
        strokeWidth="1.5"
        d="M16.685 7.5h-4.247"
      />
      <path
        fill="#fff"
        d="M12.438 7.86a.36.36 0 100-.72.36.36 0 000 .72z"
      />
    </svg>
  );
}

export function QuotesIcon({ isSelected }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="16"
      fill={isSelected ? '#C1E6FF' : 'none'}
      viewBox="0 0 18 16"
    >
      <path fill="#F5F5F5" d="M16.392 2H2.002v11.52h14.39V2z" />
      <path
        stroke={isSelected ? '#2682DE' : '#6B7A87'}
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M17.142 1.25H1.252v13.02h15.89V1.25z"
      />
      <path
        stroke={isSelected ? '#2682DE' : '#6B7A87'}
        strokeLinecap="round"
        strokeWidth="1.5"
        d="M7.683 4.26H3.436M14.404 7.26H3.436M14.404 10.26H3.436"
      />
    </svg>
  );
}
