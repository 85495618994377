import styled from 'styled-components/macro';
import { Tabs } from 'antd';
import BuyerOrdersTable from './BuyerOrdersTable';
import { BuyerOrdersOverviewTableRow, ORDER_STATUS_ENUM } from '../types';

const { TabPane } = Tabs;

interface BuyerOrdersOverviewProps {
  tableData: Array<BuyerOrdersOverviewTableRow>;
  onRowClickHandler: (id: string) => void;
}

export default function BuyerOrdersOverview({
  tableData,
  onRowClickHandler,
}: BuyerOrdersOverviewProps) {
  return (
    <Container>
      <Title>Orders</Title>
      <Tabs defaultActiveKey="1">
        <StyledTabPane tab="All Orders" key="1">
          <BuyerOrdersTable
            tableData={tableData}
            onRowClickHandler={onRowClickHandler}
          />
        </StyledTabPane>
        <StyledTabPane tab="Placed" key="2">
          <BuyerOrdersTable
            tableData={tableData.filter(
              (data) => data.status === ORDER_STATUS_ENUM.PLACED
            )}
            onRowClickHandler={onRowClickHandler}
          />
        </StyledTabPane>
        <StyledTabPane tab="Accepted" key="3">
          <BuyerOrdersTable
            tableData={tableData.filter(
              (data) => data.status === ORDER_STATUS_ENUM.ACCEPTED
            )}
            onRowClickHandler={onRowClickHandler}
          />
        </StyledTabPane>
        <StyledTabPane tab="Rejected" key="4">
          <BuyerOrdersTable
            tableData={tableData.filter(
              (data) => data.status === ORDER_STATUS_ENUM.REJECTED
            )}
            onRowClickHandler={onRowClickHandler}
          />
        </StyledTabPane>
      </Tabs>
    </Container>
  );
}

const Container = styled.div``;

const Title = styled.h1`
  margin: 0;
  margin-bottom: 40px;
  font-size: 30px;
  line-height: 37px;
  color: #102F54;
  font-weight: bold;
`;

const StyledTabPane = styled(TabPane)`
  padding-top: 40px;
`;
